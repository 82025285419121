import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const Transaction = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem('jwt');
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [results, setResults] = useState([]);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);

    useEffect(() => {
        getInterviewee(pn, ps);
    }, []);

    const getInterviewee = (pageNumber, pageSize) => {
        apiService(`payment/alllist?pn=${pageNumber}&ps=${pageSize}`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.content.map(data => {
                        var dt = new Date(data.paymentat + 'Z');
                        resultPush.push({
                            id: data.id,
                            intervieweename: data.intervieweefirstname + " " + data.intervieweelastname,
                            interviewername:  data.interviewerfirstname + " " + data.interviewerlastname,
                            amount: data.amount,
                            paymentat: dt,
                            orderid: data.orderid,
                            paymentid: data.paymentid
                        });
                    });
                    setTotalpages(result.data.totalelements)
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const dataSource = [];
    if (results.length)
        results.map(value => {
            const { id, intervieweename, interviewername, amount ,paymentat,orderid,paymentid} = value;
            return dataSource.push({
                key: id,
                intervieweename: <span className="date-started">{intervieweename}</span>,
                interviewername: <span className="date-started">{interviewername}</span>,
                amount: <span className="date-finished">{amount}</span>,
                orderid: <span className="date-started">{orderid}</span>,
                paymentid: <span className="date-started">{paymentid}</span>,
                paymentat: <span className="date-finished">{moment(paymentat).format('DD-MM-YYYY hh:mm A')}</span>,
            });
        });


    const columns = [
        {
            title: 'Interviewer Name',
            dataIndex: 'interviewername',
            key: 'interviewername',
        },
        {
            title: 'Interviewee Name',
            dataIndex: 'intervieweename',
            key: 'intervieweename',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'OrderId',
            dataIndex: 'orderid',
            key: 'orderid',
        },
        {
            title: 'PaymentId',
            dataIndex: 'paymentid',
            key: 'paymentid',
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentat',
            key: 'paymentat',
        },
        
    ];
    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getInterviewee(values, ps)
    };


    return (
        <>
            <PageHeader ghost title="TRANSACTION" />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                            <div className='interviewee-container'>
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards title="Transaction List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col>
                                </Row>
                               
                            </div>
                        </div>
                    }
                </div>
            </Main>
        </>




    );

}

export default Transaction;
