import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import {useHistory } from 'react-router-dom';
import Notification from "../../components/notification/notification";


const Logout = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);




    useEffect(() => {
            localStorage.clear();
            // window.location = '/login'
            Notification.openNotificationSuccess("Logout Successful")
            history.push('login');
            
    }, []);


    return (
        <div>
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> : null}
        </div>
    );
};

export default Logout;