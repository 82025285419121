import VendorImage from './5666-zezinho.json';
import RecordNotFound from './28705-student-university-character.json';
import AccessDenied from './5270-locked.json';
import Interview from './interview.json';
import Profile from './profile.json';
import NoData from './37343-nodata.json'
import NoInterviews from './no-data-loader.json'

const LottieFile = {
	VendorImage,
	RecordNotFound,
	AccessDenied,
	Interview,
	Profile,
	NoData,
	NoInterviews
};

export default LottieFile;
