import './mySlots.scss';
import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { TimePicker } from 'antd';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Modal, Pagination } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import Notification from "../../../components/notification/notification";
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import Heading from '../../../components/heading/heading';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Slots = () => {
    const jwt = localStorage.getItem('jwt');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userId = localStorage.getItem('userId');
    const [results, setResults] = useState([]);
    const [slots, setSlots] = useState([]);
    const [date, setDate] = useState("");
    const [time, setTime] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { Column, ColumnGroup } = Table;
    const { confirm } = Modal;
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [totalpages, setTotalpages] = useState(0);
    const [slotsTime, setslotsTime] = useState([]);
    const [slotsTimes, setslotsTimes] = useState([]);
    const [slotsTimesCheck, setslotsTimesCheck] = useState([]);
    const [timeEvents, settimeEvents] = useState([]);

    const { Option } = Select;
    const localData = localStorage.getItem("loginData");
    const loginData = JSON.parse(localData);


    useEffect(() => {
        getSlots(pn, ps);


        var times = [
            { "timeId": "10:00:00", "timeValue": "10:00 AM" },
            { "timeId": "11:00:00", "timeValue": "11:00 AM" },
            { "timeId": "12:00:00", "timeValue": "12:00 PM" },
            { "timeId": "13:00:00", "timeValue": "01:00 PM" },
            { "timeId": "14:00:00", "timeValue": "02:00 PM" },
            { "timeId": "15:00:00", "timeValue": "03:00 PM" },
            { "timeId": "16:00:00", "timeValue": "04:00 PM" },
            { "timeId": "17:00:00", "timeValue": "05:00 PM" },
            { "timeId": "18:00:00", "timeValue": "06:00 PM" },
            { "timeId": "19:00:00", "timeValue": "07:00 PM" },
            { "timeId": "20:00:00", "timeValue": "08:00 PM" },
            { "timeId": "21:00:00", "timeValue": "09:00 PM" },
        ];
        settimeEvents(times);
        // var currentTimeCheck = moment().format('HH:MM:SS');
        // const slotsTimeing = [];
        // events.map(res => {
        //     if (res.timeId >= currentTimeCheck) {
        //         console.log(res)
        //         slotsTimeing.push({
        //             "timeId": res.timeId, "timeValue": res.timeValue
        //         });
        //     }
        // });
        // console.log(currentTimeCheck)
        setslotsTime(times);
        getSlots50Datas();
    }, []);


    const getSlots = (pageNumber, pageSize) => {
        var apiUrl = "myinterviews/slotlist/" + userId + "?statusId=44,45,46,47,48,49" + "&pn=" + pageNumber + "&ps=" + pageSize;
        apiService(apiUrl, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    var datecheck = moment().format('YYYY-MM-DD');
                    var timeCheck = moment().format('HH:mm:ss');
                    result.data.content.map(data => {
                        if (datecheck == data.date) {
                            if (data.time >= timeCheck) {
                                resultPush.push({
                                    id: data.id,
                                    date: data.date,
                                    time: data.time,
                                    status: data.status,
                                    datetime: data.date + " " + data.time,
                                });
                            }
                        } else {
                            resultPush.push({
                                id: data.id,
                                date: data.date,
                                time: data.time,
                                status: data.status,
                                datetime: data.date + " " + data.time,
                            });
                        }
                    });
                    resultPush.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
                    setSlots(resultPush);

                    // setSlots([...result.data.content]);
                    setTotalpages(result.data.totalelements);
                    setIsLoading(true);
                }
            },
            (error) => {

            });
    };
    const getSlots50Datas = () => {
        var apiUrl = `myinterviews/slotlist/${userId}?statusId=44,45,46,47,48,49&pn=${1}&ps=${50}`;
        apiService(apiUrl, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    console.log("1st API Call --> ", result.data);
                    setResults([...result.data.content]);
                    setDate("");
                }
            },
            (error) => {

            });
    };
    const getSlotsByIdByDate = (datee) => {
        try{
            setslotsTimes(timeEvents);
            var apiUrl = `myinterviews/slotlistbyuser/${userId}?fromDate=${datee}`;
        apiService(apiUrl, 'get', '', false, jwt, result => {
            datee === moment().format('YYYY-MM-DD') ? setslotsTimes(timeEvents.filter(item=>moment(item.timeValue,"hh:mm A").isSameOrAfter(moment(),'seconds') && !result['data'].includes(item.timeId))) : setslotsTimes(timeEvents.filter(item=>!result['data'].includes(item.timeId)));
            return result['data'];
        },
            (error) => {
                console.log("😡 Timing Filter Response Error ---> ", error);
            });
        }
        catch(err) {
            console.error(err);
            throw err;
        }
    }; // getSlotsByIdByDate ends

    const getAllSlotsTimes = (datee, resultPushSlotTime, today, currentDate, slotsTimeing) => {
        var data;
        var apiUrl = `myinterviews/slottimes?statusId=44,45,46,47,48,49&scheduleDate=${datee}`;
        apiService(apiUrl, 'get', '', false, jwt, result => {
            var AlreadyClaimedSlot = result['data'];
            resultPushSlotTime = AlreadyClaimedSlot.length !== 0 ? [...resultPushSlotTime, ...AlreadyClaimedSlot] : resultPushSlotTime;
            setslotsTimesCheck(resultPushSlotTime);
            console.log("🤎resultPushSlotTime --> ", resultPushSlotTime);
            console.log("🖤slotsTimeing --> ", slotsTimeing);
            // if(datecheck === currentDate){
            //     data = slotsTimeing.filter(item => {
            //         console.log("💦💦💤💤item --> ", item);
            //         console.log("💦💤1st COND --> ", !resultPushSlotTime.includes(item.timeId));
            //         console.log("💦💤2nd COND --> ", moment(item.timeValue,"hh:mm A").isAfter(moment()));
            //         return !resultPushSlotTime.includes(item.timeId) && moment(item.timeValue,"hh:mm A").isAfter(moment());
            //     });
            // }else{
            //     data = timeEvents.filter(item => {
            //         console.log("💦💦item --> ", item);
            //         return !resultPushSlotTime.includes(item.timeId);
            //     });
            // }
            // console.log("💛💛 datee --> ", datee);
            // console.log("💛💛 Today --> ", moment().format('YYYY-MM-DD'));
            getSlotsByIdByDate(datee);
            return result['data'];
        },
            (error) => {
                console.log("😡😡😡 SLOT timing Response ---> ", error);
            });
    };

    const dataSource = [];
    if (slots.length)
        slots.map(value => {
            const { id, date, time, status } = value;
            var editDeleteBtn = <Space>
                <Button onClick={() => {
                    showConfirm(value);
                }} type="primary" >Delete</Button>
            </Space>;
            if (value.booked == "Y" || status !== "UNALLOCATED") {
                editDeleteBtn = <Button disabled>Delete</Button>
            }
            return dataSource.push({
                key: id,
                date: <span className="date-started">{moment(date).format('DD-MM-YYYY')}</span>,
                time: <span className="date-finished">{moment(date + " " + time).format('hh:mm A')}</span>,
                status: <span className="date-finished">{status}</span>,
                action: editDeleteBtn,
            });
        });

    const onChange = async (date, dateString) => {
        console.log("💥💢💥 ---> ", date);
        date !== null ? setDate(moment(date).format('YYYY-MM-DD')) : setDate("");
        date === null && setTime([]);
        var selectedDate = moment(date).format('YYYY-MM-DD');
        let resultPushSlotTime = [];
        for (let data of results) {
            if (selectedDate === data.date) {
                // console.log(data);
                resultPushSlotTime.push(
                    data.time,
                );
            }
        }
        var currentTimeCheck = moment().format('HH:MM:SS');
        var currentDate = moment().format('YYYY-MM-DD');
        const slotsTimeing = [];
        setslotsTime([]);
        timeEvents.map(res => {
            if (res.timeId >= currentTimeCheck && selectedDate == currentDate) {
                slotsTimeing.push({
                    "timeId": res.timeId, "timeValue": res.timeValue
                });
            }
        });
        getAllSlotsTimes(moment(date).format('YYYY-MM-DD'), resultPushSlotTime, selectedDate, currentDate, slotsTimeing);
    }



    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
    ];

    const showModal = () => {
        form.resetFields();
        setIsModalVisible({ isModalVisible: true });
    };

    const handleOk = () => {
        //   onCancel();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setDate("");
    };

    const handleSubmit = () => {
        setIsModalVisible(false);
        setIsLoading(false);
        apiService(`myinterview/schedule`, 'post', {
            "scheduleDto": [
                {
                    "scheduleDate": date,
                    "scheduleTime": moment(date + " " + time).format('HH:mm')
                },
            ]
        }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getSlots(pageDefaultValue, ps);
                    getSlots50Datas();
                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    function showConfirm(item) {
        confirm({
            title: 'Are you sure you want to delete this slot ?',
            icon: <ExclamationCircleOutlined />,
            okText: "Yes, I'm Sure!",
            okType: 'danger',
            cancelText: 'Not Now!',
            cancelType: 'primary',
            onOk() {
                setIsLoading(false);
                deleteSlot(item);
            },
            onCancel() {
            },
        });
    }

    const deleteSlot = (item) => {
        apiService(`myinterview/deleteslot/` + item.id, 'delete', '', false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getSlots(pageDefaultValue, ps);
                    getSlots50Datas();
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });

    }
    const onChangeDate = (date, dateString) => {
        setDate(moment(date).format('YYYY-MM-DD'));
    }
    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getSlots(values, ps)
    };
    return (

        <>
            <PageHeader ghost title="SLOTS"
                buttons={[
                    <Button disabled={loginData.approve === 'N' ? true : false} key="1" onClick={showModal} type="primary" size="default">
                        Add Slot
                    </Button>,
                ]} />
            <Main>
                <div className='interviewer_page slots_page'>
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <>
                            <Row gutter={25}>
                                <Col xs={24}>
                                    <Cards title="Slots List">
                                        <ProjectList>
                                            <div className="table-responsive">
                                                <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                                            </div>
                                        </ProjectList>
                                    </Cards>
                                </Col>
                            </Row>
                        </>
                    }
                    <Modal title="Add Slot"
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form className='date-picker' name="slot" form={form} onFinish={handleSubmit} layout="vertical">
                            <Row>
                                <Col span={12}>
                                    <label >Select Date</label>
                                    <Form.Item name="date"
                                        rules={[{ message: 'Please select date!', required: true }]} >
                                        <DatePicker disabledDate={(current) => {
                                            let customDate = moment().format("DD-MM-YYYY");
                                            return /*JagaCommented-> current && */ current < moment(customDate, "DD-MM-YYYY");
                                        }} onChange={onChange} format="DD-MM-YYYY" status="error" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <label >Select Time</label>
                                    <Form.Item
                                        name="time"
                                        rules={[{ message: 'Please select time!', required: true }]}>
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            value={date === "" ? "" : time} onChange={e => setTime(e)}
                                            placeholder="SELECT TIME"
                                            disabled={date === "" ? true : false}
                                        >

                                            {slotsTimes.map(item => <Option key={item.timeId} value={item.timeId}>{item.timeValue}</Option>)}


                                            {/* <Option value="10:00">10:00 AM</Option>
                                                <Option value="11:00">11:00 AM</Option>
                                                <Option value="12:00">12:00 PM</Option>
                                                <Option value="13:00">01:00 PM</Option>
                                                <Option value="14:00">02:00 PM</Option>
                                                <Option value="15:00">03:00 PM</Option>
                                                <Option value="16:00">04:00 PM</Option>
                                                <Option value="17:00">05:00 PM</Option>
                                                <Option value="18:00">06:00 PM</Option>
                                                <Option value="19:00">07:00 PM</Option>
                                                <Option value="20:00">08:00 PM</Option>  */}

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>
                </div>
            </Main>

        </>
        // <div className='interviewer_page'>
        //     {isLoading == false ?
        //         <div className="spinner">
        //             <Spin />
        //         </div> :
        //         <div>
        //             <div className='btn'>
        //                 <Button type="primary" onClick={showModal}>Add Slot</Button>

        //             </div>
        //             <div className="interviewer_page_container p_10">

        //                 <Modal title="Add Slot" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        //                     <Form name="slot" form={form} onFinish={handleSubmit} layout="vertical">

        //                         <Row>
        //                             <Col span={12}>
        //                                 <label >Select Date</label>
        //                                 <DatePicker onChange={onChange} />
        //                             </Col>
        //                             <Col span={12}>
        //                                 <label >Select Time</label>
        //                                 <Form.Item
        //                                     name="time"
        //                                     rules={[{ message: 'Please select time!', required: true }]}>
        //                                     <Select
        //                                         showSearch
        //                                         style={{ width: '100%' }}
        //                                         value={time} onChange={e => setTime(e)}
        //                                         placeholder="SELECT TIME"
        //                                         optionFilterProp="children"
        //                                         filterOption={(input, option) =>
        //                                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        //                                         }
        //                                         filterSort={(optionA, optionB) =>
        //                                             optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        //                                         }
        //                                     >

        //                                         <Option value="01:00">01:00</Option>
        //                                         <Option value="02:00">02:00</Option>
        //                                         <Option value="03:00">03:00</Option>
        //                                         <Option value="04:00">04:00</Option>
        //                                         <Option value="05:00">05:00</Option>
        //                                         <Option value="06:00">06:00</Option>
        //                                         <Option value="07:00">07:00</Option>
        //                                         <Option value="08:00">08:00</Option>
        //                                         <Option value="09:00">09:00</Option>
        //                                         <Option value="10:00">10:00</Option>
        //                                         <Option value="11:00">12:00</Option>

        //                                     </Select>
        //                                 </Form.Item>
        //                             </Col>
        //                         </Row>
        //                     </Form>

        //                 </Modal>

        //                 <div className='interview-container'>
        //                     <div className="App">
        //                     </div>
        //                     <div>
        //                         <Table dataSource={dataSource} columns={columns} />

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     }
        // </div>



    );

}

export default Slots;
