import './interviewee-details.scss';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const Interviewee = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem('jwt');
    const [form] = Form.useForm();
    // const [dataSource, setdataSource] = useState([]);
    const [pagesize, setPagesize] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [results, setResults] = useState([]);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const { confirm } = Modal;
    const [visible, setVisible] = useState(false);
    const [firstName, setFirstName] = useState("");

    const [lastName, setLastName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [email, setEmail] = useState("");
    const [skillSelectValue, setskillSelectValue] = useState([]);
    const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
    const [CompanyName, setCompanyName] = useState("");



    const handleTableChange = (item) => {
        console.log(item)
        history.push({ pathname: '/admin/interviewee-details', state: { Interviewee: item } });
        console.log({ Interviewee: item });
    };



    useEffect(() => {
        getInterviewee(pn, ps);
    }, []);


    const getInterviewee = (pageNumber, pageSize) => {
        apiService(`users/userslist/2?` + "pn=" + pageNumber + "&ps=" + pageSize, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.content.map(data => {
                        resultPush.push({
                            id: data.id,
                            email: data.email,
                            name: data.firstname + " " + data.lastname,
                            contactno: data.contactno,

                        });
                    });
                    setPagesize(result.data.pagesize);
                    setTotalpages(result.data.totalelements)
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };




    const dataSource = [];
    if (results.length)
        results.map(value => {
            const { id, name, email, contactno } = value;
            var editDeleteBtn = <Space>
                <Button onClick={() => {
                    handleTableChange(value);
                }} type="primary" >Details</Button>
                <Button onClick={() => {
                    showConfirm(value);
                }} type="primary" >Delete</Button>
            </Space>
            return dataSource.push({
                key: id,
                name: <span className="date-started">{name}</span>,
                email: <span className="date-started">{email}</span>,
                contactno: <span className="date-finished">{contactno}</span>,
                action: editDeleteBtn,
            });
        });


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'contactno',
            key: 'contactno',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
    ];
    function showConfirm(item) {
        confirm({
            title: 'Do you want to delete?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                handleTableDelete(item);
            },
            onCancel() {
            },
        });
    }

    const handleTableDelete = (item) => {
        apiService(`users/delete/` + item.id, 'delete', '', false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getInterviewee(pageDefaultValue, ps);
                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };
    const IntervieweeDetailsShow = (item) => {
        console.log(item)
        setIsLoading(false);
        getProfile(item.id);
    };

    const showModal = () => {
        setFirstName("");
        setLastName("");
        setContactNo("");
        setEmail("");

        setskillDefSelectValue([]);
        setCompanyName("");

    }
    const getProfile = (id) => {
        setskillSelectValue([]);
        apiService(`users/details/` + id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const dataSource = [];
                    console.log(result.data)
                    setFirstName(result.data.firstName);
                    setLastName(result.data.lastName);
                    setContactNo(result.data.contactNo);
                    setEmail(result.data.email);
                    setVisible(true)
                    setskillDefSelectValue(dataSource)
                    setIsLoading(true)

                }
            },
            (error) => {

            });
    };
    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getInterviewee(values, ps)
    };
    const handleOk = () => {
    };

    return (
        <>
            <PageHeader ghost title="INTERVIEWEE" />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                            <div className='interviewee-container'>
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards title="Interviewee List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col>
                                </Row>
                                <Modal
                                    title="Details"
                                    centered
                                    visible={visible}
                                    onClick={() => setVisible(false)}
                                    onCancel={() => setVisible(false)}
                                    onOk={handleOk}
                                    width={850}
                                    okText="Save"
                                    footer={null}
                                >

                                    <div className='modalmain'>

                                        <Form className='interviewer-form' form={form} name="nest-messages" layout="vertical">
                                            <Row>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="firstName"
                                                        label="First Name"

                                                    >
                                                        <text>{firstName}</text>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="lastName"
                                                        label="Last Name"

                                                    >
                                                        <text>{lastName}</text>

                                                    </Form.Item>
                                                </Col>

                                                <Col span={8}>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"

                                                    >
                                                        <text>{email}</text>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="ContactNo"
                                                        label="Mobile"

                                                    >
                                                        <text>{contactNo}</text>

                                                    </Form.Item>
                                                </Col>



                                                <Col span={8}>
                                                    <Form.Item
                                                        name="degree"
                                                        label="Degree"

                                                    >
                                                        <text>BE</text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="Specialization"
                                                        label="Specialization"

                                                    >
                                                        <text>CSE</text>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col span={8}>
                                                    <Form.Item
                                                        name="year"
                                                        label="Year"

                                                    >
                                                        <text>2018</text>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={8}>
                                                    <Form.Item
                                                        name="cgpa"
                                                        label="CGPA"

                                                    >
                                                        <text>65</text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="college"
                                                        label="College"

                                                    >
                                                        <text>GCE Thanajavur</text>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col span={8}>
                                                    <Form.Item
                                                        name="experiance"
                                                        label="Experiance"

                                                    >
                                                        <text>3.5yr</text>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={8}>
                                                    <Form.Item
                                                        name="CompanyName"
                                                        label="Organization"

                                                    >
                                                        <text>TCS</text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="skillDefSelectValue"
                                                        label="Skill"
                                                    >
                                                        <text>BACKEND DEVELOPER</text>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    }
                </div>
            </Main>
        </>
    );

}

export default Interviewee;
