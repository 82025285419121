import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal, Upload, message, Input, } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PrepTestDetailsWrapper } from '../style';
import siteUrl from "../../../api/apiUrls";


const PrepTestDetails = () => {
    const history = useHistory();
    const { confirm } = Modal;

    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem('jwt');
    const [results, setResults] = useState([]);
    let data = useLocation();
    let preptestDetails = data.state.preptestDetails;

    useEffect(() => {
        getpreptest();
    }, []);

    const getpreptest = () => {
        apiService(`preptest/skill/` + preptestDetails.id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    console.log(result.data)
                    setResults(result.data);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };









    function showConfirm(item) {
        confirm({
            title: 'Do you want to delete?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                handleTableDelete(item);
            },
            onCancel() {
            },
        });
    }
    const handleTableDelete = (item) => {
        apiService(`preptest/delete/` + item.id, 'delete', '', false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getpreptest();
                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };
    const fileDownload = (item) => {
        console.log(item)
        var url = siteUrl+"unsecure/download/"+item.files.id;
        window.open(url, '_self');
    };
    const fileUrlOpen = (item) => {
        var url = item.url;
        window.open(url);
    };




    return (
        <>
            <PageHeader ghost title={preptestDetails.skillName}
             buttons={[
                <div key="1" className="page-header-actions">
                    <Link to={{ pathname: "/admin/preptest" }}>
                        <Button className="go-back-btn" size="large" key="4" type="primary">
                            Go Back
                        </Button>
                    </Link>
                </div>,
            ]}
            />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                            <PrepTestDetailsWrapper>
                                <div className='interviewee-container'>
                                    <Row gutter={25}>
                                        <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                            <Cards title="Prep Test Documents">
                                                <div className="file-list">
                                                {results.prepTestDoc.map((data, i) =>
                                                    <div className="file-list__single d-flex">
                                                       
                                                            <>
                                                                <div className="file-single-info d-flex">

                                                                    <div className="file-single__content">
                                                                        <span className="file-name">{++i}.  {data.files.name}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="file-single-action">
                                                                    <span className="file-content-action">
                                                                        <a onClick={() => { fileDownload(data) }}>Download</a>
                                                                        <a onClick={() => { showConfirm(data) }}>Delete</a>
                                                                    </span>
                                                                </div>
                                                            </>

                                                       
                                                    </div>
                                                     )

                                                    }
                                                </div>
                                            </Cards>
                                        </Col>
                                        <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                            <Cards title="Prep Test Links">
                                            <div className="file-list">
                                            {results.prepTestLink.map((data, i) =>
                                                    <div className="file-list__single d-flex">
                                                    
                                                            <>
                                                                <div className="file-single-info d-flex">

                                                                    <div className="file-single__content">
                                                                        <span className="file-name">{++i}.  {data.url}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="file-single-action">
                                                                    <span className="file-content-action">
                                                                        <a onClick={() => { fileUrlOpen(data) }}>View</a>
                                                                        <a onClick={() => { showConfirm(data) }}>Delete</a>
                                                                    </span>
                                                                </div>
                                                            </>

                                                       
                                                    </div>
                                                     )

                                                    }
                                                </div>
                                            </Cards>
                                        </Col>
                                    </Row>
                                </div>
                            </PrepTestDetailsWrapper>
                        </div>
                    }

                </div>

            </Main>
        </>




    );

}

export default PrepTestDetails;
