import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Pagination, Skeleton, Select, Form, Rate, DatePicker, Avatar } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link, Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import { name_text, UsercardWrapper, UserCarrdTop, UsercardWrapperNew, UserCard } from './style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../../styled';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Heading from '../../../components/heading/heading';
import userImg from '../../../../src/assets/imges/blank-profile.png';
import { PaginationWrapper, NotFoundWrapper } from '../interviews/Style';
import LottieComponent from '@sharedComponent/lottie-component';
import LottieFile from 'src/assets/lottie-files';
import parse from 'html-react-parser'
import moment from 'moment';
import siteUrl from "../../../api/apiUrls";
import noDataImg from 'src/assets/imges/No data.gif';
import './schedule-page.scss';
const { Option } = Select;

const Users = () => {
  const jwt = localStorage.getItem('jwt');
  const userId = localStorage.getItem('userId');
  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();
  const [scheduleList, setScheduleList] = useState([]);
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  const [filterExperienceList, setFilterExperienceList] = useState([]);
  const [filterRatingList, setFilterRatingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skillSelectId, setskillSelectId] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [companySelectName, setcompanySelectName] = useState("");
  const [experienceSelect, setexperienceSelect] = useState("");
  const [ratingSelect, setratingSelect] = useState("");
  const [dateErrorShow, setdateErrorShow] = useState(false);
  const [dateErrorShowStart, setdateErrorShowStart] = useState(false);
  const [dateValueClear, setdateValueClear] = useState("false");
  const { searchData, users, userGroup } = useSelector(state => {
    return {
      searchData: state.headerSearchData,
      users: state.users,
      userGroup: state.userGroup,
    };
  });
  const { RangePicker } = DatePicker;
  const { path } = useRouteMatch();
  const [state, setState] = useState({
    notData: searchData,
    current: 0,
    pageSize: 0,
    page: 0,
  });
  const [isLgScreen, setIsLgScreen] = useState(false);
  const { notData } = state;
  const handleSearch = searchText => {
    const data = searchData.filter(item => item.title.toUpperCase().startsWith(searchText.toUpperCase()));
    setState({
      ...state,
      notData: data,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, current, pageSize });
  };

  const onChange = page => {
    setState({ ...state, page });
  };


  useEffect(() => {
    getScheduleList(skillSelectId, companySelectName, experienceSelect, ratingSelect, "", "");
    getFilterData();
    getFilterCompany();
    localStorage.removeItem("rescheduleId");
    localStorage.removeItem("rescheduleAmount");
    localStorage.removeItem("slotId");
    localStorage.removeItem("refTransactionId");
    localStorage.removeItem("rescheduleReason");
    localStorage.removeItem("rescheduleSuggestion");
    const mediaQuery = window.matchMedia('(min-width: 1920px)');
    const handleMediaQueryChange = (e) => {
      setIsLgScreen(e.matches);
    };
    setIsLgScreen(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);


  const getScheduleList = (skillSelectId, companySelectName, experienceSelect, fdate, tdate) => {


    let queryParam = "myinterviews/favlist?skillId=";

    if (skillSelectId !== "") {
      queryParam = queryParam + skillSelectId;
    }
    if (companySelectName != "") {
      queryParam = queryParam + `&${'companyName'}=${companySelectName}`;
    } else {
      queryParam = queryParam + `&${'companyName'}=`;
    }
    if (experienceSelect != "") {
      queryParam = queryParam + `&${'experience'}=${experienceSelect}`;
    } else {
      queryParam = queryParam + `&${'experience'}=`;
    }
    if (fdate != "" && tdate != "") {
      queryParam = queryParam + "&rating=" + "&fromDate=" + fdate + "&toDate=" + tdate;
    } else {
      queryParam = queryParam + "&rating=&fromDate=&toDate=";
    }
    console.log(queryParam);




    // var apiUrl = `myinterviews/favlist?skillId=&companyName=&experience=&rating=&fromDate=&toDate=`;
    // // if (skillSelectId != "" && companySelectName != "" && experienceSelect != ""  && fromDate != "" && toDate != "") {
    // //   apiUrl = "myinterviews/favlist?skillId=" + skillSelectId + "&companyName=" + companySelectName + "&experience=" + experienceSelect+"&rating="+ "&fromDate=" + fromDate + "&toDate=" + toDate;
    // // }else
    // if (skillSelectId != "" && companySelectName != "" && experienceSelect != "" && fdate != "" && tdate != "") {
    //   apiUrl = "myinterviews/favlist?skillId=" + skillSelectId + "&companyName=" + companySelectName + "&experience=" + experienceSelect + "&rating=" + "&fromDate=" + fdate + "&toDate=" + tdate;
    // } else if (skillSelectId != "" && companySelectName != "" && experienceSelect != "") {
    //   apiUrl = "myinterviews/favlist?skillId=" + skillSelectId + "&companyName=" + companySelectName + "&experience=" + experienceSelect + "&rating=&fromDate=&toDate=";
    // }
    // console.log(apiUrl)
    apiService(queryParam, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setScheduleList([...result.data]);
          setIsLoading(true)
        }
      },
      (error) => {

      });
  };
  const getFilterData = () => {
    apiService(`signup/skilllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setFilterSkillList([...result.data]);
          console.log(result.data)
        }
      },
      (error) => {

      });
  };
  const getFilterCompany = () => {
    apiService(`users/companyname`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setFilterCompanyList([...result.data]);
          setFilterExperienceList([
            { "id": "1", "value": ">1" },
            { "id": "3", "value": ">3" },
            { "id": "5", "value": ">5" },
            { "id": "7", "value": ">7" },
            { "id": "9", "value": ">9" },
            { "id": "11", "value": ">11" },
          ]);
          setFilterRatingList([1, 2, 3, 4, 5]);
          console.log(result.data)
        }
      },
      (error) => {

      });
  };
  const handleSubmit = () => {
    if (fromDate != null || toDate != null) {
      if (validate(fromDate, toDate)) {
        var fdate = moment(fromDate).format('YYYY-MM-DD');
        var edate = moment(toDate).format('YYYY-MM-DD');
        setIsLoading(false);

        getScheduleList(skillSelectId, companySelectName, experienceSelect, fdate, edate);
      }
    } else {
      setIsLoading(false);
      setdateErrorShow(false);
      setdateErrorShowStart(false);
      getScheduleList(skillSelectId, companySelectName, experienceSelect, "", "");
    }
  }
  const clearFilter = () => {
    console.log(skillSelectId)
    console.log(companySelectName)
    setIsLoading(false);
    form.resetFields();
    setToDate(null);
    setFromDate(null)
    setcompanySelectName("");
    setskillSelectId("");
    setexperienceSelect("");
    getScheduleList("", "", "", "", "", "");
  }
  const validate = (fdate, edate) => {
    setdateErrorShow(false);
    setdateErrorShowStart(false);
    console.log(fromDate);
    console.log(toDate)
    var valid = true;
    if (fdate == "Invalid date" || fdate == "" || fdate == null) {
      setdateErrorShowStart(true);
      valid = false;
    } else if (edate == "Invalid date" || edate == "" || edate == null) {
      setdateErrorShow(true);
      valid = false;
    }

    return valid;

  }
  function onChangeStart(dates, dateStrings) {
    if (dateStrings != "") {
      setFromDate(moment(dateStrings).format('YYYY-MM-DD'));
    } else {
      setFromDate("");
    }

  }
  function onChangeEnd(dates, dateStrings) {
    if (dateStrings != "") {
      setToDate(moment(dateStrings).format('YYYY-MM-DD'));
    } else {
      setToDate("")
    }

  }

  return (
    <>
      <CardToolbox>
        <UserCarrdTop>
          <PageHeader
            ghost
            title="Schedule Expert Interviews"
          />
        </UserCarrdTop>
      </CardToolbox>

      <Main>
        <Row>
          <Col xxl={24} lg={24} md={24} xs={24}>
            <Form name="schedule" form={form} onFinish={handleSubmit} layout='inline'>
              {/*<Col xxl={5} lg={5} md={12} xs={24} className='marginbtm-5'>
                <Form.Item name='filerCompanyList'
                  rules={[{ message: 'Please select Company!', required: false }]}>
                  <Select size="large" value={companySelectName} showSearch filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    } onChange={e => setcompanySelectName(e)} placeholder='Company'>
                    {filterCompanyList.map(item => {
                      return (<Option value={item.companyname}>{item.companyname}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col xxl={6} lg={6} md={12} xs={24} className='marginbtm-5'>
                <Form.Item name='filterSkillList' rules={[{ message: 'Please select Skills!', required: false }]}>
                  <Select value={skillSelectId} showSearch filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    } onChange={e => setskillSelectId(e)} size="large" placeholder='Skills'>
                    {filterSkillList.map(item => {
                      return (<Option value={item.id}>{item.type}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xxl={5} lg={5} md={12} xs={24} className='marginbtm-5'>
                <Form.Item name='filterExperienceList' rules={[{ message: 'Please select Experience!', required: false }]}>
                  <Select value={experienceSelect} showSearch onChange={e => setexperienceSelect(e)} size="large" placeholder='Experience'>
                    {filterExperienceList.map(item => {
                      return (<Option value={item.id}>{item.value}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
              {/* <Col xxl={8} lg={8} md={12} xs={24} className='marginbtm-5'>
                 <RangePicker
                
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      placeholder="Date of Birth"
      format="DD-MM-YYYY"
      onChange={onChangeDate}
    />
                 </Col> */}
              <Col xxl={5} lg={5} md={12} xs={24} className='marginbtm-5'>
                <Form.Item>
                  <DatePicker value={fromDate} disabledDate={(current) => {
                    let customDate = moment().format("DD-MM-YYYY");
                    return current && current < moment(customDate, "DD-MM-YYYY");
                  }} placeholder='Start Date' format="DD-MM-YYYY" onChange={e => setFromDate(e)} />
                  {dateErrorShowStart == true ?
                    <p className='text-error' >Please select Start Date.</p> : null}
                </Form.Item>
              </Col>
              <Col xxl={5} lg={5} md={12} xs={24} className='marginbtm-5'>
                <Form.Item>
                  <DatePicker value={toDate} disabledDate={(current) => {
                    return current && current < moment(fromDate, "DD-MM-YYYY");
                  }} placeholder='End Date' format="DD-MM-YYYY" onChange={e => setToDate(e)} />
                  {dateErrorShow == true ?
                    <p className='text-error' >Please select End Date.</p> : null}
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} md={12} xs={24} className='marginbtm-5 btn-alignment'>
                <Button type="primary" className='search-btn1' size="large" htmlType="submit">
                  Search
                </Button>
                <Button type='light' onClick={clearFilter} className='clear-btn' size="large">Clear</Button>
              </Col>

            </Form>
          </Col>
        </Row >

        <h3 className='heading'>Select Your Interviewer</h3>

        {
          isLoading == false ?
            <UsercardWrapper>
              <Row gutter={25}>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
                <Col xxl={8} md={8} sm={24} xs={24}>
                  <Cards headless>
                    <Skeleton avatar active />
                  </Cards>
                </Col>
              </Row>
            </UsercardWrapper> :

            <UsercardWrapper>
              <Row gutter={25}>
                {scheduleList.length ?
                  <>
                    {scheduleList.map(schedule => {
                      const { id } = schedule;
                      return (
                        <Col key={id} xxl={8} md={8} sm={24} xs={24}>
                          <UserCard>
                            <div className="card user-card theme-grid-3">
                              <Cards headless>
                                <div className="card__top">
                                  <div className="user-card__img">
                                    {schedule.profileImageId != null ?
                                      <Avatar size={64} src={siteUrl + "" + "unsecure/view/" + schedule.profileImageId} alt={`img${schedule.profileImageId}`} /> :
                                      <Avatar size={64} src={userImg} alt="" />}
                                  </div>
                                  <div className="user-card__info">
                                    <Heading className="card__name" as="h6">
                                      <Link className="name-text" to="#">
                                        {schedule.firstName} {schedule.lastName}
                                      </Link>
                                      <p className="card__designation">{schedule.interviewerInfo.companyName}</p>
                                      <span> <Rate allowHalf defaultValue={schedule.interviewerInfo.overAllRating} disabled /></span>
                                    </Heading>
                                  </div>
                                </div>
                                <div className="card__content">
                                  {schedule.interviewerInfo.longDescription != null ?
                                    <div className='longDescription'> {parse(schedule.interviewerInfo.longDescription)}</div> : null}
                                </div>
                                <div className="card__info myinfo">
                                  <div>
                                    <p className="info-line">
                                      <span>Amount</span>
                                    </p>
                                    <span className="success" style={{ background: 'none !important' }}>
                                      ₹ {schedule.interviewerInfo.amount}
                                    </span>
                                  </div>
                                  <div className="sch-btn">
                                    {
                                      schedule.soldOut === 'N' ? <Link to={{ pathname: "/interviewerprofile", state: { data: schedule, interviewerId: schedule.userId } }}>
                                        <Button type="primary">
                                          SCHEDULE
                                        </Button>
                                      </Link> : <Button disabled type="light"  >
                                        SOLD OUT
                                      </Button>}
                                  </div>


                                </div>
                              </Cards>
                            </div>
                          </UserCard>
                        </Col>


                      )
                    })
                    } </> :
                  <Col md={24}>
                    {/* <NotFoundWrapper>
                    <LottieComponent file={LottieFile.NoData} width={'25%'} />
                  </NotFoundWrapper> */}
                    {/* <div className='right-container'> */}
                    {/* <LottieComponent file={LottieFile.NoInterviews} width={'25%'} /> */}
                    <div className='no_data_interviewers'>
                    <img /*width="350" height="300"*/ alt="noDataImage" src={noDataImg} />
                    <h3 className='no_interviews_msg'>Uh!, It seem's all Interviewer's are Busy! Come Back After Sometime!</h3>
                    </div>
                    {/* </div> */}
                  </Col>}
              </Row>
            </UsercardWrapper>
        }
      </Main >
    </>
  );
};

export default Users;
