import Styled from 'styled-components';

const ProfileAuthorBox = Styled.div`

input{
    height: 40px;
}
 
   
    .ant-card-body{
        padding: 25px 0 25px !important;
        height: 394px !important;
    }
    .author-info{
        padding: 0 20px 20px;
        text-align: center;
        border-bottom: 1px solid #f9e8e8;
        .info{
               background-color: transparent;
        }
    }
    img.profile-img {
        width: 130px !important;
        height: 130px;
        border-radius: 50%;
        border: 1px solid #ddd;
        padding: 5px;
    }
    figure{
        position: relative;
        max-width: 120px;
        margin: 0 auto 18px;
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
            bottom: 9px;
            height: 40px;
            width: 40px;
            right: -2px;
            background: #15C872; /* #ff634d; Edtech ~ Theme Conversion By Jaga*/
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: ${({ theme }) => theme['primary-color']};
            } 
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }
    }
    figcaption{
        .info{
            h1{  
                color: red;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                margin-top: 2%;
            }},
            h2,
            h3,
            h4{
                font-size: 20px;
                font-weight : bold;
            },
            h5,
            h6{
                font-size: 16px;
                margin-bottom: 4px;
            }
            p{
                margin-bottom: 0;
                color: ${({ theme }) => theme['light-color']};
            }
        }
    }

    .settings-menmulist{
        padding: 20px 20px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 90%;
        .menu-list{
            font-size: 18px;
            font-weight: 400;
            display:flex;
            white-space: nowrap;
            margin-top:1%;
        }
        li{
            display:inline;
            a{
                color: rgba(0, 0, 0, 0.85);
                display: flex;
                flex-direction: column;                
                align-items: center;
                padding: 12px 20px;
                border-radius: 6px;
                color: ${({ theme }) => theme['light-color']};
                i,
                svg{
                    overflow: hidden;
                     width: 30px;
                     height: 30px;
                     color: #15C872; //* #FF634D; Edtech ~ Theme Conversion By Jaga*/
                     margin-bottom: 10px;
                },
                img{
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 1px;
                   
                }
                &.active{
                    font-weight: 500;
                    color: ${({ theme }) => theme['primary-color']};
                    background: ${({ theme }) => theme['primary-color']}05;
                }
            }
        }
    }
`;
export {
    ProfileAuthorBox
}