
import React, { useState, useEffect } from 'react';

import { Row, Col, Select, Form, Input, Button, message, card, Card, Spin, Rate, InputNumber, Checkbox } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { UsercardWrapperint } from '../style';
import RichTextEditor from 'react-rte';
import javaApiService from "../../../utils/javaApiService";


const CreateInterviewer = () => {
    const history = useHistory();
    const { Option } = Select;
    const jwt = localStorage.getItem('jwt');
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [level, setLevel] = useState("");
    const [amount, setAmount] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [experience, setExperience] = useState("");
    const [skill, setSkill] = useState([]);
    const [linkedin, setLinkedin] = useState("");
    const [overAllRating, setOverAllRating] = useState("");
    const [filterSkillList, setFilterSkillList] = useState([]);
    const [skillSelectValue, setskillSelectValue] = useState([]);
    const [interviewerSelectId, setinterviewerSelectId] = useState(null);
    const [pagesize, setPagesize] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [visible, setVisible] = useState(false);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [results, setResults] = useState([]);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [valueLongDesc, setvalueLongDesc] = useState(RichTextEditor.createEmptyValue());
    const [valueShortDesc, setvalueShortDesc] = useState(RichTextEditor.createEmptyValue());
    const [longDescErr, setlongDescErr] = useState(false);
    const [shortDescErr, setshortDescErr] = useState(false);
    const [ratingErr, setratingErr] = useState(false);
    const [approve, setapprove] = useState("N");

    const [uploadFiles, SetuploadFiles] = useState([]);
    const [image, setImage] = useState(null)
    let data = useLocation();
    let allInterviewerDetails = data.state.allInterviewerDetails;
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'], INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    const onChange = (date, dateString) => {

    }



    const onTextInput1 = value => {
        setvalueLongDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };
    const onTextInput2 = value => {
        setvalueShortDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };

    useEffect(() => {
        getFilterData();
    }, []);



    const getFilterData = () => {
        apiService(`signup/skilllist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setFilterSkillList([...result.data]);
                    setIsLoading(true);
                }
            },
            (error) => {

            });
    };


    const handlesubmit = () => {
        console.log("🎃🎃🎉🎉🧨🧨 UPDATE SUBMIT CLICKED...");
        if (validate(valueLongDesc, valueShortDesc, overAllRating)) {
            setIsLoading(false);
            submit();
        }
    }





    const submit = () => {
        skill.map(data => {
            skillSelectValue.push({
                id: data,
            });
        });
        let formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('contactNo', mobile);
        formData.append('userRoleId', 3);
        formData.append('interviewerIfoDto.level', level);
        formData.append('interviewerIfoDto.experience', experience);
        formData.append('interviewerIfoDto.companyName', companyName);
        // formData.append('interviewerIfoDto.userSkills',JSON.stringify(skillSelectValue));
        formData.append('interviewerIfoDto.amount', amount);
        formData.append('interviewerIfoDto.longDescription', valueLongDesc._cache.html);
        formData.append('interviewerIfoDto.shortDescription', valueShortDesc._cache.html);
        formData.append('interviewerIfoDto.linkedInUrl', linkedin);
        formData.append('interviewerIfoDto.overAllRating', overAllRating);
        formData.append('interviewerIfoDto.file', uploadFiles);
        formData.append('interviewerIfoDto.approve', approve);
        skillSelectValue.map((data, i) => {
            formData.append('interviewerIfoDto.userSkills[' + [i] + '].id', data.id)
        }
        );
        javaApiService(`secure/userInfo/addInterviewer`, 'post', formData, true, jwt,
            result => {
                if (result.data.SUCCESS == true) {
                    Notification.openNotificationSuccess("Interviewer Added successfully");
                    history.push('/admin/interviewer');
                } else {
                    setIsLoading(true);
                    setskillSelectValue([]);

                    Notification.openNotificationFaliure(result.data.MESSAGE)
                }
            },
            (error) => {

            });



    }



    const handleCancel = e => {
        setvalueShortDesc(RichTextEditor.createEmptyValue());
        setvalueLongDesc(RichTextEditor.createEmptyValue());
        setSkill([]);
        form.resetFields();
    }
    const handleChange = (value) => {
        setratingErr(false);
        setOverAllRating(value);
    };

    const validate = (longValue, shortValue, overAllRating) => {
        setlongDescErr(false);
        setshortDescErr(false);
        setratingErr(false);
        var valid = true;
        console.log("💚LongValue --> ", longValue);
        console.log("🧡ShortValue --> ", shortValue);
        if (longValue._cache.html == null || longValue._cache.html == "<p><br></p>") {
            setlongDescErr(true);
            valid = false;
        }
        if (shortValue._cache.html == null || shortValue._cache.html == "<p><br></p>") {
            setshortDescErr(true);
            valid = false;

        }
        if (overAllRating == null || overAllRating == "") {
            setratingErr(true);
            valid = false;
        }
        return valid;

    }

    function fileHandleChange(event) {
        console.log(event.target.files);
        SetuploadFiles(event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    }

    return (
        <>
            <PageHeader
                ghost
                title="Add Interviewer"
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/admin/interviewer" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <Main>
                    <UsercardWrapperint>
                        <Card>
                            <Form className='interviewer-form' form={form} name="interviewerdetails" onFinish={handlesubmit} layout="vertical">
                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="firstName"
                                            label="First Name"
                                            rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                            <Input placeholder="First Name" type='text'  value={firstName} onChange={e => setFirstName(e.target.value.replace(/^[^\d]+$/))} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="lastName"
                                            label="Last Name"
                                            rules={[{ message: 'Please enter your last name!', required: true },{ pattern: /^[^\d]+$/, message: 'Last name cannot contain numbers!' }]}>
                                            <Input placeholder="Last Name" type='text' value={lastName} onChange={e => setLastName(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}> <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }, {
                                            validator: (rule, value, callback) => {
                                                const isEmailAlreadyExists = allInterviewerDetails.some(item => item.email === value);
                                                if (isEmailAlreadyExists) {
                                                    callback('Email is already in use. Please use a different email.');
                                                } else {
                                                    callback();
                                                }
                                            }
                                        }]}>
                                        <Input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Item></Col>

                                </Row>

                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="mobile"
                                            label="Mobile"
                                            rules={[{
                                                type: "regexp",
                                                pattern: /^(?:\d*)$/,
                                                message: "Value should contain just number"
                                            },
                                            {
                                                min: 10,
                                                max: 10,
                                                message: "phone number must be 10 digits",
                                            },
                                            {
                                                message: 'Please enter your phone number!',
                                                required: true
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    const isMobileExists = allInterviewerDetails.some(item => item.contactno === value);
                                                    if (isMobileExists) {
                                                        callback('Mobile Number is already in use.');
                                                    } else {
                                                        // Validation succeeded
                                                        callback();
                                                    }

                                                }
                                            }]}>
                                            <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="companyName"
                                            label="Company"
                                            /* rules={[{ message: 'Please enter your company!', required: true }]} */>
                                            <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="experience"
                                            label="Experience"
                                            rules={[{
                                                message: 'Enter value 1 or greater than 1', type: 'number', min: 1
                                            }, { message: 'Please enter your experience!', required: true }]}>
                                            <InputNumber placeholder="Experience" value={experience} min="1" max="50"  onChange={e => {
                                      // setExperience(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setExperience(newValue);
                                      }
                                    }} type='number' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="level"
                                            label="Level"
                                            rules={[{ message: 'Please enter your level!', required: true }]}>
                                            <Input placeholder="Level" value={level} min="1" max="5"  onChange={e => {
                                    // setLevel(e.target.value);
                                    const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                    if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                    setLevel(newValue.toString());
                                    }
                                    }} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="amount"
                                            label="Amount"
                                            rules={[{
                                                message: 'Enter value 1 or greater than 1', type: 'number', min: 1
                                            }, { message: 'Please enter your amount!', required: true }]}>
                                            <InputNumber placeholder="Amount" value={amount} onChange={e => setAmount(e)} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="linkedin"
                                            label="LinkedIn"
                                            rules={[{ type: "url", message: 'Please enter your Linkedin profile!', required: true }]}>
                                            <Input placeholder="Linkedin" value={linkedin} onChange={e => setLinkedin(e.target.value)} type='text' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="graduation"
                                            label="Skills"
                                            rules={[{ message: 'Please select your skill!', required: true }]}>
                                            <Select
                                                mode="multiple"
                                                maxTagCount={1}
                                                showSearch={false}
                                                maxTagTextLength={17}
                                                style={{ width: '100%' }}
                                                placeholder="Select skill"
                                                value={skill} onChange={e => setSkill(e)}
                                            >
                                                {filterSkillList.map(item => {
                                                    return (<Option value={item.id}>{item.type}</Option>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="Overall Ratings"
                                            name="OverallRating"
                                            rules={[{ message: 'Please enter Rating!', required: true }]}
                                        >
                                            {/* <h4 className='rating'><span style={{ color: "red" }}>* </span>Overall Ratings :  */}
                                            <Rate onChange={handleChange} value={overAllRating} />
                                            {/* </h4> */}
                                            {/* {ratingErr === true ? <p className='text-error' >Please enter rating!</p> : null} */}
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    {image != null ?
                                        <Col span={7}>
                                            <Form.Item
                                                label="Company Logo"
                                            >
                                            </Form.Item>

                                            <div class="profile-pic">
                                                <label class="-label" for="file">
                                                    <span class="glyphicon glyphicon-camera"></span>
                                                    <span>Change Image</span>
                                                </label>
                                                <input id="file" type="file" accept="image/*" onChange={fileHandleChange} />
                                                <img src={image} id="output" width="200" />
                                            </div>
                                        </Col> : <Col span={7}>
                                            <Form.Item
                                                name="uploadFiles"
                                                label="Company Logo"
                                                /* rules={[{ message: 'Please upload company logo!', required: true }]} */>
                                                <Input accept="image/*" onChange={fileHandleChange} type='file' />
                                            </Form.Item>
                                        </Col>}

                                </Row>

                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            name="ld_edtech"
                                            label={<span><span style={{ color: 'red' }}>* </span><span>Long Description</span></span>}
                                            /* rules={[{ required: true, message: 'Please fill the long description!' }]} */>
                                            <RichTextEditor id="ld_edtech" toolbarConfig={toolbarConfig} value={valueLongDesc} onChange={onTextInput1} />
                                            {/* <Input value={valueLongDesc} type='text' /> */}
                                            {longDescErr === true ? <p className='text-error' >Please enter long description!</p> : null}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={11}>
                                        <Form.Item label={<span><span style={{ color: 'red' }}>* </span><span>Short Description</span></span>}
                                            name="sd_edtech"/*  rules={[{ message: 'Please fill the short description!' }]} */>
                                            <RichTextEditor id="sd_edtech" toolbarConfig={toolbarConfig} value={valueShortDesc} onChange={onTextInput2} />
                                            {shortDescErr === true ? <p className='text-error' >Please enter short description!</p> : null}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Item  >
                                        <Row>
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <Checkbox checked={approve === "Y" ? true : false} onChange={(event) => {
                                                    setapprove(event.target.checked ? "Y" : "N");
                                                }}></Checkbox>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: '20px' }}>
                                                <h4>Approve</h4>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Row>
                                <Col sm={23} xs={23}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button className="btn-signin mr-10" onClick={handleCancel} type="light" size="large">
                                            Clear
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Form>
                        </Card>
                    </UsercardWrapperint>
                </Main>
            }
        </>
    );
}

export default CreateInterviewer;