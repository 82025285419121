import React from 'react';

import './main-layout.scss';

import MainLayoutFunctional from './components/main-layout-functional';
import NotLogin from './components/layout';

const MainLayout = () => {
	var login = localStorage.getItem('login');
	// var login = localStorage.getItem('login');
	// console.log("gjhasgfjsdg" + login)

	// return (
	// 	login == "false" ?
	// 		<MainLayoutFunctional /> : <NotLogin />
	// )
	return <MainLayoutFunctional />;
};

export default MainLayout;
