import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal, Input } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const Configuration = () => {
    const history = useHistory();
    const { confirm } = Modal;

    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem('jwt');
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [results, setResults] = useState([]);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleEdit, setisModalVisibleEdit] = useState(false);
    const [form] = Form.useForm();
    const [configurationType, setConfigurationType] = useState([]);
    const [days, setDays] = useState("");
    const [percentage, setPercentage] = useState("");
    const { Option } = Select;
    const [configurationTypelist, setconfigurationTypelist] = useState([]);
    const [cancelListhideShow, setcancelListhideShow] = useState(false);
    const [resultsCancel, setResultsCancel] = useState([]);
    const [typeDefSelectValue, settypeDefSelectValue] = useState([]);
    const [rowSelectId, setrowSelectId] = useState("");
    const [typeDefSelectValueName, settypeDefSelectValueName] = useState([]);







    useEffect(() => {
        getRescheduleAllType();
        getPaymentTypes();
    }, []);

    const getRescheduleAllType = () => {
        apiService(`refund/all`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            days: data.days,
                            percentage: data.percentage,
                            paymentId: data.paymentType.id,
                            paymentTypeName: data.paymentType.name,


                        });
                    });
                    setTotalpages(result.data.totalelements)
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const getReschedule = (pageNumber, pageSize) => {
        apiService(`refund/type/2454`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            days: data.days,
                            percentage: data.percentage,
                            paymentId: data.paymentType.id,
                            paymentTypeName: data.paymentType.name,


                        });
                    });
                    setTotalpages(result.data.totalelements)
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };


    const getCancel = (pageNumber, pageSize) => {
        apiService(`refund/type/2455`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResultsCancel([]);
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            days: data.days,
                            percentage: data.percentage,


                        });
                    });
                    setTotalpages(result.data.totalelements)
                    setResultsCancel(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const getPaymentTypes = () => {
        apiService(`refund/alltype`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setconfigurationTypelist(result.data);
                }
            },
            (error) => {
            });
    };




    const dataSource = [];
    if (results.length)
        results.map(value => {
            const { id, days, percentage,paymentId,paymentTypeName } = value;
            var editDeleteBtn =  <Space>
            <Button onClick={() => {
                  handleTableEdit(value);
            }} type="primary" >Edit</Button>

             {/* <Button onClick={() => {
                showConfirm(value);
          }} type="primary" >Delete</Button> */}

            </Space>
            return dataSource.push({
                key: id,
                type: <span className="date-started">{paymentTypeName}</span>,
                days: <span className="date-started">{days}</span>,
                percentage: <span className="date-started">{percentage}</span>,
                action:editDeleteBtn,
            });
        });


    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'With In Days',
            dataIndex: 'days',
            key: 'days',
        },
        {
            title: 'Reduction Percentage',
            dataIndex: 'percentage',
            key: 'percentage',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          },

    ];


    const dataSourceCancel = [];
    if (resultsCancel.length)
        resultsCancel.map(value => {
            const { id, days, percentage } = value;
            var editDeleteBtn =  <Space>
            <Button onClick={() => {
                  handleTableEdit(value);
            }} type="primary" >Edit</Button> 
            <Button onClick={() => {
                showConfirm(value);
          }} type="primary" >Delete</Button>
            </Space>
            return dataSourceCancel.push({
                key: id,
                days: <span className="date-started">{days}</span>,
                percentage: <span className="date-started">{percentage}</span>,
                action:editDeleteBtn,
            });
        });


    const columnsCancel = [
        {
            title: 'Days',
            dataIndex: 'days',
            key: 'days',
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            key: 'percentage',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          },

    ];
    const handleTableEdit = (item) => {
        console.log(item);
        form.resetFields();
        onFill(item);

        setDays(item.days);
        setPercentage(item.percentage);
        setrowSelectId(item.id);
        settypeDefSelectValue(item.paymentId)
        setConfigurationType(item.paymentId)
        settypeDefSelectValueName(item.paymentTypeName)
        setisModalVisibleEdit({ isModalVisibleEdit: true });
    };
    function showConfirm(item) {
        confirm({
            title: 'Do you want to delete?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                handleTableDelete(item);
            },
            onCancel() {
            },
        });
    }
    const handleTableDelete = (item) => {
        apiService(`refund/delete/` + item.id, 'delete', '', false, jwt,
        result => {
            if (result.data.success == true) {
                Notification.openNotificationSuccess(result.data.message)
                if(cancelListhideShow == false){
                    getReschedule(pageDefaultValue, ps);
                }else{
                    getCancel(pageDefaultValue, ps);

                }
            } else {
                Notification.openNotificationFaliure(result.data.message)
            }
        },
        (error) => {
    
        });
    };
    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getReschedule(values, ps)
    };

    const showModal = () => {
        form.resetFields();
        setIsModalVisible({ isModalVisible: true });
    };
    const CancelList = () => {
        setIsLoading(false);
        setcancelListhideShow(true);
        getCancel();
    };
    const RescheduleList = () => {
        setIsLoading(false);
        setcancelListhideShow(false);
        getReschedule();
    };

    const handleCancel = () => {
        setisModalVisibleEdit(false);

    };
    const handleSubmit = () => {
        console.log(configurationType);
        console.log(days);
        console.log(percentage)
        setIsModalVisible(false);
        setisModalVisibleEdit(false);
        setIsLoading(false);
        if(rowSelectId == ""){
            apiService(`refund/percentage`, 'post', {
                "days": days,
                "percentage": percentage,
                "paymentTypeId": configurationType
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        Notification.openNotificationSuccess(result.data.message)
                        // if(cancelListhideShow == false){
                        //     getReschedule(pageDefaultValue, ps);
                        // }else{
                        //     getCancel(pageDefaultValue, ps);
    
                        // }
                        getRescheduleAllType();
                    } else {
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {
    
                });
        }else{
            apiService(`refund/percentage`, 'post', {
                "id":rowSelectId,
                "days": days,
                "percentage": percentage,
                "paymentTypeId": configurationType
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        form.resetFields();
                        Notification.openNotificationSuccess(result.data.message)
                        // if(cancelListhideShow == false){
                        //     getReschedule(pageDefaultValue, ps);
                        // }else{
                        //     getCancel(pageDefaultValue, ps);
    
                        // }
                        getRescheduleAllType();
                    } else {
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {
    
                });
        }
     
    };

    const onFill = (data) => {
        form.setFieldsValue({
          days: data.days,
          percentage: data.percentage,
        });
      };


    return (
        <>
            <PageHeader ghost title="Configuration" 
            // buttons={[

            //     <Space>
            //         {cancelListhideShow == false ?
            //             <Button key="1" onClick={showModal} type="primary" size="default">
            //                 Add Reschedule
            //             </Button> : <Button key="1" onClick={showModal} type="primary" size="default">
            //                 Add Cancel
            //             </Button>}
            //         {cancelListhideShow == true ?
            //             <Button
            //                 onClick={RescheduleList}
            //                 type="primary" >Reschedule List</Button> : <Button
            //                     onClick={CancelList}
            //                     type="primary" >Cancel List</Button>}
            //     </Space>
            // ]} 
            
            />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                             <div className='interviewee-container'>
                                    <Row gutter={25}>
                                        <Col xs={24}>
                                            <Cards title="Reschedule List">
                                                <ProjectList>
                                                    <div className="table-responsive">
                                                        <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    </div>
                                                </ProjectList>
                                            </Cards>
                                        </Col>
                                    </Row>

                                </div>
                            {/* {cancelListhideShow == false ?
                                <div className='interviewee-container'>
                                    <Row gutter={25}>
                                        <Col xs={24}>
                                            <Cards title="Reschedule List">
                                                <ProjectList>
                                                    <div className="table-responsive">
                                                        <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    </div>
                                                </ProjectList>
                                            </Cards>
                                        </Col>
                                    </Row>

                                </div>
                                :
                                 <div className='interviewee-container'>
                                    <Row gutter={25}>
                                        <Col xs={24}>
                                            <Cards title="Cancel List">
                                                <ProjectList>
                                                    <div className="table-responsive">
                                                        <Table dataSource={dataSourceCancel} columns={columnsCancel} pagination={false} />
                                                    </div>
                                                </ProjectList>
                                            </Cards>
                                        </Col>
                                    </Row>

                                </div>
                                } */}
                        </div>
                    }
                    <Modal title="Configuration"
                        visible={isModalVisible}
                        footer={null}
                        width={750}
                        onCancel={handleCancel}>
                        <Form name="add-configuration" form={form} onFinish={handleSubmit} layout="vertical">

                            <Row>

                                <Col xxl={7} lg={7} md={24} xs={24} className='paddingcln'>

                                    <Form.Item
                                        label="Configuration Type"
                                        name="configurationType"
                                        rules={[{ message: 'Please select Type!', required: true }]}>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={configurationType} onChange={e => setConfigurationType(e)}
                                            placeholder="Select Configuration Type"

                                        >


                                            {configurationTypelist.map(item => {
                                                return (<Option value={item.id}>{item.name}</Option>)
                                            })}


                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={1}></Col>
                                <Col xxl={7} lg={7} md={7} xs={24} className='paddingcln'>

                                    <Form.Item
                                        label="Before Days"
                                        name="days"
                                        rules={[{ message: 'Please enter before days!', required: true }]}>
                                        <Input  min="1" placeholder="Days" value={days} onChange={e => setDays(e.target.value)} type='number' />

                                    </Form.Item>
                                </Col>
                                <Col span={1}></Col>
                                <Col xxl={7} lg={7} md={7} xs={24} className='paddingcln'>
                                    <Form.Item
                                        name="percentage"
                                        label="Percentage Deduction"
                                        rules={[{ message: 'Please enter percentage deduction!', required: true }]}>
                                        <Input  min="1" placeholder="Percentage" value={percentage} onChange={e => setPercentage(e.target.value)} type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>

                    <Modal title={typeDefSelectValueName}
                        visible={isModalVisibleEdit}
                        footer={null}
                        // width={750}
                        onCancel={handleCancel}>
                        <Form name="add-configuration" form={form} onFinish={handleSubmit} layout="vertical">

                            <Row>

                                {/* <Col xxl={7} lg={7} md={24} xs={24} className='paddingcln'>

                                    <Form.Item
                                        label="Configuration Type"
                                        name="configurationType"
                                        initialValue={typeDefSelectValue}
                                        rules={[{ message: 'Please select Type!', required: true }]}>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={configurationType} onChange={e => setConfigurationType(e)}
                                            placeholder="Select Configuration Type"

                                        >


                                            {configurationTypelist.map(item => {
                                                return (<Option value={item.id}>{item.name}</Option>)
                                            })}


                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                {/* <Col span={1}></Col> */}
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>

                                    <Form.Item
                                        label="With In Days"
                                        name="days"
                                        // initialValue={days}
                                        rules={[{ message: 'Please enter days!', required: true }]}>
                                        <Input  min="1" placeholder="Days" value={days} onChange={e => setDays(e.target.value)} type='number' />

                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        name="percentage"
                                        label="Reduction Percentage"
                                        // initialValue={percentage}
                                        rules={[{ message: 'Please enter percentage!', required: true }]}>
                                        <Input  min="1" placeholder="Percentage" value={percentage} onChange={e => setPercentage(e.target.value)} type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>                                 
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>
                </div>
            </Main>
        </>




    );

}

export default Configuration;
