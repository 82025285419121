import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Modal, Pagination, Input } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import Notification from "../../../components/notification/notification";
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import Heading from '../../../components/heading/heading';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import parse from 'html-react-parser'
import siteUrl from "../../../api/apiUrls";
import { PrepTestDetailsWrapper } from '../style';
import ReactPlayer from 'react-player'
import './interviews.scss'

const Interviews = () => {
    const { confirm } = Modal;

    const jwt = localStorage.getItem('jwt');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userId = localStorage.getItem('userId');
    const [slots, setSlots] = useState([]);
    const [date, setDate] = useState("");
    const [statusId, setStatus] = useState([]);
    const [clickResult, setClickResult] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { Column, ColumnGroup } = Table;
    const [pagesize, setPagesize] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [statusList, setStatusList] = useState([]);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [results, setResults] = useState([]);
    const [isCompleted, setisCompleted] = useState(false);
    const [pnCompleted, setPnCompleted] = useState(1);
    const [psCompleted, setTotalPsCompleted] = useState(10);
    const [completedResults, setcompletedResults] = useState([]);
    const [totalpagesCompleted, setTotalpagesCompletd] = useState(0);
    const [pageDefaultValueCompleted, setPageDefaultValueCompletd] = useState(1);
    const [isModalVisibleFeedBack, setisModalVisibleFeedBack] = useState(false);
    const [state, setState] = useState({ searchText: '', searchedColumn: '' })
    const searchInput = useRef(null);
    const [scheduleRsult, setscheduleRsult] = useState("");
    const [scheduleVideos, setscheduleVideos] = useState([]);
    const [isModalVisibleVideos, setisModalVisibleVideos] = useState(false);
    const [videoPlayCheckId, setisvideoPlayCheckId] = useState("");

    const { Option } = Select;
    const showModal = () => {
        setIsModalVisible({ isModalVisible: true });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setisModalVisibleFeedBack(false);
        setisModalVisibleVideos(false);
        setisvideoPlayCheckId("");
    };
    const onChange = (date, dateString) => {
        setDate(moment(date).format('YYYY-MM-DD'));
    }


    const handleTableChange = (item) => {
        form.resetFields();
        setIsModalVisible({ isModalVisible: true });
        setClickResult(item);
        console.log(item)
    };
    const handleTableLinkCreation = (item) => {
        getLinkCreation(item);
    };

    useEffect(() => {
        getInterviews(pn, ps);
        getStatusList();
    }, []);

    const getInterviews = (pageNumber, pageSize) => {
        setisCompleted(false);
        apiService(`users/allinterviewlist?statusId=46,48,49&` + "pn=" + pageNumber + "&ps=" + pageSize, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    var datecheck = moment().format('YYYY-MM-DD');
                    var timeCheck = moment().format('HH:mm:ss');
                    result.data.content.map(data => {
                        if (datecheck == data.date) {
                            if (data.time >= timeCheck) {
                                resultPush.push({
                                    id: data.id,
                                    date: data.date,
                                    time: data.time,
                                    name: data.firstname + " " + data.lastname,
                                    intervieweeName: data.intervieweefirstname + " " + data.intervieweelastname,
                                    status: data.status,
                                    starturl: data.starturl,
                                    datetime: data.date + " " + data.time,
                                    intervieweeMobile: data.intervieweemobile
                                });
                            }
                        } else {
                            resultPush.push({
                                id: data.id,
                                date: data.date,
                                time: data.time,
                                name: data.firstname + " " + data.lastname,
                                status: data.status,
                                starturl: data.starturl,
                                datetime: data.date + " " + data.time,
                                intervieweeName: data.intervieweefirstname + " " + data.intervieweelastname,
                                intervieweeMobile: data.intervieweemobile
                            });
                        }
                    });
                    setPagesize(result.data.pagesize);
                    setTotalpages(result.data.totalelements)
                    resultPush.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const getInterviewsCompleted = (pageNumber, pageSize) => {
        setIsLoading(false);
        setisCompleted(true);
        apiService(`users/completedlist?` + "pn=" + pageNumber + "&ps=" + pageSize, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    result.data.content.map(data => {
                        resultPush.push({
                            id: data.id,
                            date: data.date,
                            time: data.time,
                            name: data.firstname + " " + data.lastname,
                            status: data.status,
                            starturl: data.starturl,
                            datetime: data.date + " " + data.time,
                            intervieweeName: data.intervieweefirstname + " " + data.intervieweelastname,
                            intervieweeMobile: data.intervieweemobile,
                            skillstoimprove: data.skillstoimprove,
                            rating: data.rating,
                            feedback: data.feedback,
                            recordings: data.recordings,
                        });
                    });
                    setTotalpagesCompletd(result.data.totalelements);
                    resultPush.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
                    setcompletedResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const getStatusList = () => {
        apiService(`users/statuslist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    result.data.map(data => {
                        if (data.name == "SYSTEM CHECK" || data.name == "PREP TEST") {
                            resultPush.push({
                                id: data.id,
                                name: data.name,
                            });
                            setStatusList(resultPush);
                        }

                    });
                }
            },
            (error) => {

            });
    };

    const getScheduleDetails = (scheduleId) => {
        apiService(`schedule/details/` + scheduleId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setscheduleRsult(result.data);
                    setisModalVisibleFeedBack({ isModalVisibleFeedBack: true });
                    setIsLoading(true)
                }
            },
            (error) => {

            });
    };

    const getVideoDetails = (path) => {
        apiService(`myinterview/stream?path=` + path, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    console.log(result.data);
                    // setisModalVisibleFeedBack({ isModalVisibleFeedBack: true });
                    setIsLoading(true)
                }
            },
            (error) => {

            });
    };

    const dataSource = [];
    if (results.length)
        results.map(value => {
            const { id, name, intervieweeName, intervieweeMobile, date, time, status, starturl } = value;
            var actionButton = <Space>
                {/* <Button onClick={() => {
                  handleTableChange(value);
              }} type="primary" >Status Change</Button> */}

                <Button onClick={() => {
                    showConfirm(value);
                }} type="primary" >Create Link</Button>
            </Space>
            if (starturl != null) {
                actionButton = <Space>
                    {/* <Button onClick={() => {
                    handleTableChange(value);
                }} type="primary" >Status Change</Button> */}

                    <Button disabled >Link Created</Button>
                </Space>
            }
            return dataSource.push({
                key: id,
                name: <span className="date-started">{name}</span>,
                intervieweeName: <span className="date-started">{intervieweeName}</span>,
                intervieweeMobile: <span className="date-started">{intervieweeMobile}</span>,
                date: <span className="date-started">{moment(date).format('DD-MM-YYYY')}</span>,
                time: <span className="date-finished">{moment(date + " " + time).format('hh:mm A')}</span>,
                status: <span className="date-started">{status === "PAYMENT COMPLETED" ? "PAID" : status}</span>,
                action: actionButton,
            });
        });

    const completedDataSource = [];

    if (completedResults.length)
        completedResults.map(value => {
            const { id, name, intervieweeName,intervieweeMobile, date, time, status, starturl } = value;
            var feedbackBtn = <Space><Button onClick={() => {
                feedbackPopOpen(value);
            }} type="primary"   >View Feedback</Button> {value.recordings.length !== 0 && <Button onClick={() => {
                recordingsOpen(value);
            }} type="primary"   >Video Link</Button>}</Space>
            return completedDataSource.push({
                key: id,
                name: <span className="date-started">{name}</span>,
                intervieweeName: <span className="date-started">{intervieweeName}</span>,
                intervieweeMobile: <span className="date-started">{intervieweeMobile}</span>,
                date: <span className="date-started">{moment(date).format('DD-MM-YYYY')}</span>,
                time: <span className="date-finished">{moment(date + " " + time).format('hh:mm A')}</span>,
                status: <span className="date-started">{status}</span>,
                action: feedbackBtn,
            });
        });

    const getLinkCreation = (data) => {
        apiService(`myinterview/meetinglink/` + data.id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    getInterviews(pageDefaultValue, ps);
                    Notification.openNotificationSuccess("Webex Link creation Successful!");
                }
            },
            (error) => {

            });
    };


    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getInterviews(values, ps)
    };



    const completedColumns = [
        {
            title: 'Interviewer Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Interviewee Name',
            dataIndex: 'intervieweeName',
            key: 'intervieweeName',
        },
        {
            title: 'Interviewee Mobile',
            dataIndex: 'intervieweeMobile',
            key: 'intervieweeMobile',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        // },
        {
            title: "Action",
            dataIndex: 'action',
            key: "action",
        }
    ];


    const handleSubmit = () => {
        setIsLoading(false);
        setIsModalVisible(false);
        apiService(`myinterview/statusupdate`, 'put', {
            "id": clickResult.id,
            "statusId": statusId
        }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getInterviews(pageDefaultValue, ps);
                } else {
                    getInterviews(pageDefaultValue, ps);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    }

    function showConfirm(item) {
        confirm({
            title: 'Do you want to create Webex url?',
            // title: 'Do you want to create zoom url?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                getLinkCreation(item);
            },
            onCancel() {
            },
        });
    }
    const feedbackPopOpen = (value) => {
        console.log(value)
        setIsLoading(false);
        setscheduleRsult(value);
        setisModalVisibleFeedBack({ isModalVisibleFeedBack: true });
        setIsLoading(true)
        // getScheduleDetails(value.id);
    }

    const recordingsOpen = (value) => {
        setscheduleVideos(value.recordings);
        setisModalVisibleVideos({ isModalVisibleVideos: true });
        console.log(value)
        // var url = siteUrl+"unsecure/download/"+item.files.id;
        // window.open(url, '_self');
    };
    const fileDownload = (item) => {
        // getVideoDetails(item.path);
        setisvideoPlayCheckId(item.id);
        console.log(item)
        // var url = siteUrl+"myinterview/stream?"+item.path;
        // window.open(url, '_self');
    };
    const fileClose = (item) => {       
        setisvideoPlayCheckId("");
     };
     
    const handlePaginationCompleted = (values) => {
        setIsLoading(false);
        setPageDefaultValueCompletd(values);
        getInterviewsCompleted(values, ps);
    };
    const getInterviewsCompletedClick = () => {
        setPageDefaultValueCompletd(1);
        setIsLoading(false);
        getInterviewsCompleted(pnCompleted, psCompleted);
    };
    const getInterviewsClick = () => {
        setPageDefaultValue(1);
        setIsLoading(false);
        getInterviews(pn, ps);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '' });
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },

    });


    const columns = [
        {
            title: 'Interviewer Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Interviewee Name',
            dataIndex: 'intervieweeName',
            key: 'intervieweeName',
        },
        {
            title: 'Interviewee Mobile',
            dataIndex: 'intervieweeMobile',
            key: 'intervieweeMobile',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: "Action",
            dataIndex: 'action',
            key: "action",
        }
    ];


    return (
        <>
            {!isCompleted ?
                <PageHeader ghost title="INTERVIEWS"
                    buttons={[
                        <Button key="1" type="primary" onClick={() => {
                            getInterviewsCompletedClick(pnCompleted, psCompleted)
                        }} size="default">
                            COMPLETED INTERVIEWS
                        </Button>,
                    ]} /> : <PageHeader ghost title="COMPLETED INTERVIEWS"
                        buttons={[
                            <Button key="1" type="primary" onClick={() => {
                                getInterviewsClick(pn, ps)
                            }} size="default">
                                INTERVIEWS
                            </Button>,
                        ]} />}

            <Main>
                <div className='interviewer_page slots_page interviws-page'>
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <>
                            <Row gutter={25}>
                                {!isCompleted ?
                                    <Col xs={24}>
                                        <Cards title="Interviews List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col> : <Col xs={24}>
                                        <Cards title="Interviews List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={completedDataSource} columns={completedColumns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValueCompleted} onChange={handlePaginationCompleted} total={totalpagesCompleted} />
                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col>}
                            </Row>
                        </>
                    }
                    <Modal title="Staus Change"
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form className='interviews-form' name="slot" form={form} onFinish={handleSubmit} layout="vertical">

                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="time"
                                        label="Select Status"
                                        rules={[{ message: 'Please select status!', required: true }]}>
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            value={statusId} onChange={e => setStatus(e)}
                                            placeholder="SELECT STATUS"
                                        >
                                            {statusList.map(item => {
                                                return (<Option value={item.id}>{item.name}</Option>)
                                            })}

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>
                </div>
                <Modal title={null}
                    visible={isModalVisibleFeedBack}
                    footer={null}
                    width={750}
                    onCancel={handleCancel}>

                    <Row className='feedback-interviwrer'>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <div className="interviewer_feedback">
                                <Row>
                                    <Col span={24}>
                                        <h3>Interviewer Feedback</h3>
                                    </Col>
                                </Row>
                                {scheduleRsult.skillstoimprove != null ?
                                    <>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Attitude</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <label for=''>{scheduleRsult.skillstoimprove.attitude}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Communication</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <label for=''>{scheduleRsult.skillstoimprove.communication}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Problem Solving</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <label for=''>{scheduleRsult.skillstoimprove.problemSolving}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Technical</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <label for=''>{scheduleRsult.skillstoimprove.technical}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Fitment To Role</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                {scheduleRsult.skillstoimprove.fitmentToRole}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Description</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <p className='feedback-interviwer'>{parse(scheduleRsult.skillstoimprove.description)}
                                                </p>

                                            </Col>
                                        </Row>
                                    </> : <p className='feedback-interviwer'>Interviewer still do not give feedback…!
                                    </p>}

                            </div>


                        </Col>
                        <Col span={1}></Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <div className="interviewer_feedback">
                                <Row>
                                    <Col span={24} >
                                        <h3 >Interviewee Feedback</h3>
                                    </Col>
                                </Row>
                                {scheduleRsult.feedback != null ?
                                    <>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Rating</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                {scheduleRsult.rating}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={9}>
                                                <label for=''>Description</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>
                                                <p className='feedback-interviwer'>{parse(scheduleRsult.feedback)}
                                                </p>

                                            </Col>
                                        </Row>

                                    </>
                                    : <p className='feedback-interviwer'>Interviewee still do not give feedback…!
                                    </p>}
                            </div>
                        </Col>

                    </Row>

                </Modal>
                <Modal title="Interviews Videos"
                    visible={isModalVisibleVideos}
                    footer={null}
                    width={750}
                    onCancel={handleCancel}>
                    <PrepTestDetailsWrapper>
                                <div className='interviews-video-container'>
                                    <Row gutter={25}>
                                        <Col xxl={24} lg={24} md={24} xs={24} className='paddingcln'>
                                            <Cards title={null}>
                                                <div className="file-list">
                                                    {scheduleVideos.length != 0 ?
                                                    <>
                                                {scheduleVideos.map((data, i) =>
                                                    <div className="file-list__single d-flex">
                                                       
                                                            <>
                                                                <div className="file-single-info d-flex">

                                                                    <div className="file-single__content">
                                                                    <span className="file-name">{++i}.  {data.fileName} - {moment(data.createdAt).format('DD-MM-YYYY hh:mm A')}</span>

                                                                    {/* <video width="250" height="200" controls controlsList="nodownload"><source src={siteUrl+"myinterview/stream?"+data.path} type="video/mp4"/></video> */}
                                                                 {videoPlayCheckId == data.id ?
                                                                    <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }}  playing  controls={true} url={siteUrl+"unsecure/zoom/streaming?path="+data.path} className='react-player' />
                                                                   :null}
                                                                    </div>

                                                                </div>
                                                                <div className="file-single-action">
                                                                    <span className="file-content-action">
                                                                    {videoPlayCheckId == "" ?
                                                                        <a onClick={() => { fileDownload(data) }}>Play</a>:null}
                                                                        {videoPlayCheckId == data.id ?
                                                                        <a onClick={() => { fileClose(data) }}>Close</a>
                                                                    :null}
                                                                    </span>
                                                                   

                                                                </div>
                                                            </>

                                                       
                                                    </div>
                                                     )

                                                    }
                                                    </>:<h3 className='no-interviews'>No Interviews Videos</h3>}
                                                </div>
                                            </Cards>
                                        </Col>
                                        </Row>
                                        </div>
                    </PrepTestDetailsWrapper>
                </Modal>
            </Main>


        </>

    );

}

export default Interviews;
