import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal, Upload, message, Input, } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined,PlusOutlined ,DeleteOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import javaApiService from "../../../utils/javaApiService";


const PrepTest = () => {
  const history = useHistory();
  const { confirm } = Modal;

  const [isLoading, setIsLoading] = useState(false);
  const jwt = localStorage.getItem('jwt');
  const [totalpages, setTotalpages] = useState(0);
  const [pageDefaultValue, setPageDefaultValue] = useState(1);
  const [results, setResults] = useState([]);
  const [pn, setPn] = useState(1);
  const [ps, setTotalPs] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [skillSelectId, setskillSelectId] = useState("");
  const [preptesturl, SetPrepTestUrl] = useState("");
  const [uploadFiles, SetuploadFiles] = useState([]);
  const [skillSelectIdTable, setskillSelectIdTable] = useState("");
  const [skillSelectNameTable, setskillSelectNameTable] = useState("");
  const { Option } = Select;
  const [filterUrlType, setUrlType] = useState("");
  const [visible, setVisible] = useState(false);
  const [inputList, setInputList] = useState([{ preptesturllink: ""}]);
  // const [Validation, setValidation] = useState(false);







  useEffect(() => {
    getSkillList();
    getpreptest();
  }, []);


  const getpreptest = () => {
    apiService(`preptest/alllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setResults([]);
          console.log(result.data)

          const resultPush = [];
          result.data.map(data => {
            var documentCheck = false;
            if (data.prepTestDoc.length > 0) {
              documentCheck = true;
            }
            resultPush.push({
              id: data.skills.id,
              skillName: data.skills.name,
              documentCheck: documentCheck,


            });
          });
          setResults(resultPush);
          console.log(resultPush)
          setIsLoading(true);
        }
      },
      (error) => {
      });
  };

  const getSkillList = () => {
    apiService(`signup/skilllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          // setResults([]);
          // console.log(result.data)

          // const resultPush = [];
          // result.data.map(data => {
          //     resultPush.push({
          //         id: data.id,
          //         skillName: data.name,
          //     });
          // });
          // setResults(resultPush);
          // setIsLoading(true);
          setFilterSkillList([...result.data]);
        }
      },
      (error) => {

      });
  };




  const dataSource = [];
  if (results.length)
    results.map(value => {
      const { id, skillName, documentCheck } = value;
      var editDeleteBtn = <Space>
        {documentCheck == false ?
          <Button onClick={() => {
            showModal(value);
          }} type="primary" >Add</Button> : null}
        {documentCheck == true ?
         <Space>
        <Button onClick={() => {
            showModal(value);
          }} type="primary" >Add</Button>    <Button onClick={() => {
            skillDocumentView(value);
          }} type="primary" >View</Button>
          </Space> : null}

      </Space>
      return dataSource.push({
        key: id,
        skillName: <span className="date-started">{skillName}</span>,
        action: editDeleteBtn,
      });
    });


  const columns = [
    {
      title: 'Skill',
      dataIndex: 'skillName',
      key: 'skillName',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

 

  const skillDocumentView = (value) => {
    console.log(value)
    history.push({ pathname: '/admin/perptestdetails', state: { preptestDetails: value } });
  };

  function showConfirm(item) {
    confirm({
      title: 'Do you want to delete?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setIsLoading(false);
        handleTableDelete(item);
      },
      onCancel() {
      },
    });
  }
  const handleTableDelete = (item) => {
    apiService(`refund/delete/` + item.id, 'delete', '', false, jwt,
      result => {
        if (result.data.success == true) {
          Notification.openNotificationSuccess(result.data.message)
          // if(cancelListhideShow == false){
          //     getReschedule(pageDefaultValue, ps);
          // }else{
          //     getCancel(pageDefaultValue, ps);

          // }
        } else {
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });
  };

  const showModal = (value) => {
    form.resetFields();
    console.log(value)
    setInputList([{ preptesturllink: ""}])
    setskillSelectIdTable(value.id);
    setskillSelectNameTable(value.skillName);
    setIsModalVisible({ isModalVisible: true });
    SetuploadFiles([]);
    // setValidation("");
    setUrlType("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUrlType("");
  };


  const handleSubmit = () => {
    setIsModalVisible(false);
    setIsLoading(false);
   let formData = new FormData();
   inputList.map((data, i) =>{
     if(data.preptesturllink != ""){
      formData.append('urls['+[i]+'].link', data.preptesturllink);
      formData.append('urls['+[i]+'].type', filterUrlType);
     }           
   }
    );

    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('documents['+[i]+'].file', uploadFiles[i])              
 }
    formData.append('id', skillSelectIdTable)
    javaApiService(`secure/preptest/add`, 'post', formData, true, jwt,
  result => {
      if (result.data.SUCCESS == true) {
          Notification.openNotificationSuccess(result.data.MESSAGE)
          getpreptest();
      } else {
          Notification.openNotificationFaliure(result.data.MESSAGE)
      }
  },
  (error) => {

  });

     
      
  }



  // const handleSubmit1 = () => {

  //       // setIsModalVisible(false);
  //       // setIsLoading(false);
  //       if( inputList[0].preptesturllink != "" || uploadFiles.length  != 0){
  //          setIsModalVisible(false);
  //          setIsLoading(false);
  //         let formData = new FormData();
  //         inputList.map((data, i) =>{
  //           if(data.preptesturllink != ""){
  //            formData.append('urls['+[i]+'].link', data.preptesturllink);
  //            formData.append('urls['+[i]+'].type', filterUrlType);
  //           }           
  //         }
  //          );
   
  //          for (var i = 0; i < uploadFiles.length; i++) {
  //            formData.append('documents['+[i]+'].file', uploadFiles[i])              
  //       }
  //          formData.append('id', skillSelectIdTable)
  //          javaApiService(`secure/preptest/add`, 'post', formData, true, jwt,
  //        result => {
  //            if (result.data.SUCCESS == true) {
  //                Notification.openNotificationSuccess(result.data.MESSAGE)
  //                getpreptest();
  //            } else {
  //                Notification.openNotificationFaliure(result.data.MESSAGE)
  //            }
  //        },
  //        (error) => {
   
  //        });
       
  //       }
      
  //       else{
  //         setValidation(true);
  //       }
      
  // };

  function fileHandleChange(event) {
    console.log(event.target.files);
    SetuploadFiles(event.target.files)
    // setValidation();
    // form.resetFields();
  }

  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const Showtest= (e) =>{
    setUrlType(e)
    if(e.length == ""){
    setVisible(false);
  }else{
      setVisible(true);
    }
  }

    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { preptesturllink: "" }]);
    };


  return (
    <>
      <PageHeader ghost title="Prep Test"
      //     buttons={[
      //   <Button key="1" onClick={showModal} type="primary" size="default">
      //     Add PrepTest
      //   </Button>
      // ]}
      />
      <Main>
        <div className="admin_interviewee">
          {isLoading == false ?
            <div className="spinner">
              <Spin />
            </div> :
            <div>
              <div className='interviewee-container'>
                <Row gutter={25}>
                  <Col xs={24}>
                    <Cards title="Prep Test List">
                      <ProjectList>
                        <div className="table-responsive">
                          <Table dataSource={dataSource} columns={columns} pagination={false} />
                        </div>
                      </ProjectList>
                    </Cards>
                  </Col>
                </Row>
              </div>
            </div>
          }
          <Modal title={skillSelectNameTable}
            visible={isModalVisible}
            footer={null}
            width={700}
            onCancel={handleCancel}>
            <Form name="add-configuration" form={form} onFinish={handleSubmit} layout="vertical">

                                <Row>
               
                                <Col xxl={9} lg={9} md={9} xs={24} className='paddingcln'>
                                <Form.Item 
                                 name='filterUrlType'
                                 label="URL Type"
                                 rules={[{ message: 'Please select url type!', required: true }]}>
                                 
                               <Select value={filterUrlType} onChange={e => Showtest(e)}  placeholder ='Select Url Type'> 
                               <Option value="YOUTUBE">Youtube URL</Option>
                               <Option value="REFERENCE">Reference URL</Option>
                               <Option value="DOCUMENT">Document</Option>
                               </Select>
                              </Form.Item>
                            </Col>
                            <Col span={1}></Col>
                           {filterUrlType === 'YOUTUBE' || filterUrlType === 'REFERENCE' ? 
                          <Col xxl={13} lg={13} md={13} xs={24} className='paddingcln'>
                <label className='url-label'>URL</label>

                {inputList.map((x, i) => {
        return (
          <>
                <Row className='url-head'>
                <Col xxl={16} lg={16} md={16} xs={16} className='paddingcln'>
                <Form.Item
                    name="uploadFiles"
                    rules={[{ message: 'Please enter url!', required: true }]}>
                    <Input placeholder="URL" name="preptesturllink"  value={x.preptesturllink} onChange={e => handleInputChange(e, i)} />
                    </Form.Item>
                    </Col>
                 
                    <Col span={1}></Col>
                    <Col xxl={7} lg={7} md={7} xs={17} className='add-multiple-input'>
                    <div className="btn-box">
                    {inputList.length !== 1 && 
                    
                    <Button
                      className="mr-10"
                      onClick={() => handleRemoveClick(i)}><DeleteOutlined /></Button>}
                    {inputList.length - 1 === i && <Button onClick={handleAddClick}><PlusOutlined /></Button>}
                  </div>
            </Col>
          </Row>
                    
        </>
                    );
                  })}
                </Col>
               :  filterUrlType === 'DOCUMENT' ?
                
                  
                <Col xxl={13} lg={13} md={13} xs={24} className='paddingcln'>
                <label className='url-label'>File</label>
                <Row className='url-head'>
                <Col xxl={24} lg={24} md={24} xs={24} className='paddingcln'>
                <Form.Item
                    name="uploadFiles"
                    rules={[{ message: 'Please upload files!', required: true }]}>
                  <Input multiple onChange={fileHandleChange} type='file' />
                  </Form.Item>
                </Col>
              </Row> 
              </Col> : null}

              
              </Row>
             

                {/* {Validation == true ? 
                 <text style={{color: "red"}}>Please select atleast  URL OR File</text> : null} */}
               
              <Row>

                <Col sm={24} xs={24}>
                  <div className="sDash_form-action mt-20 fl-right" >
                    <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                      Cancel
                    </Button>
                    <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>

          </Modal>
        </div>

      </Main>
    </>




  );

}

export default PrepTest;
