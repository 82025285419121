import {
    notification
} from 'antd'

const openNotificationSuccess = message => {
    notification.success({
        message: message,
        placement: 'bottomRight',
    });
};

const openNotificationFaliure = message => {
    notification.error({
        message: message,
        placement: 'bottomRight',
    });
};

const Notification = {
    openNotificationSuccess,
    openNotificationFaliure
};

export default Notification;