import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Spin, Modal } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './programmingLanguages-page.scss';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined, CheckCircleTwoTone, LinkOutlined } from '@ant-design/icons';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";



import lanImg from 'src/assets/imges/langnew.png';
const menus = [
    {
        icon: <ArrowRightOutlined />,
    }
];
const { Meta } = Card;

const ProgrammingLanguages = () => {
    const location = useLocation();
    // let yourself = location.state.yourself;
    const jwt = localStorage.getItem('jwt');
    const [programmingList, setProgrammingList] = useState([]);
    const [programmingListAdd, setProgrammingListAdd] = useState([]);
    const [selectItemId, setSelectItemId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectIdList, setSelectIdList] = useState([]);
    const programminglanguages = localStorage.getItem('programminglanguages');
    const [isModalVisible, setIsModalVisible] = useState(false);
    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");
    useEffect(() => {
        if (programminglanguages != null) {
            const programminglanguagesDetails = JSON.parse(programminglanguages);
            setSelectIdList(programminglanguagesDetails);
        }
        getProgrammingList();
    }, []);


    const getProgrammingList = () => {
        apiService(`signup/pllist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: false,
                        });
                    });
                    let programminglanguagesDetails = "";
                    if (programminglanguages != null) {
                        programminglanguagesDetails = JSON.parse(programminglanguages);
                        setSelectIdList(programminglanguagesDetails);
                    }
                    console.log(resultPush);
                    for (var i = 0; i < resultPush.length; i++) {
                        for (var k = 0; k < programminglanguagesDetails.length; k++) {
                            if (resultPush[i].id == programminglanguagesDetails[k].id) {
                                resultPush[i].check = true;
                                break;
                            }
                        }
                    }
                    setProgrammingList(resultPush);
                    setProgrammingListAdd(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const handleSubmit = () => {
        if (selectIdList.length > 0) {
            if (selectIdList[0].id === 404 && isModalVisible === false) {
                setIsModalVisible(true);
            }
            else {
                Notification.openNotificationSuccess("Details updated!")
                localStorage.setItem('programminglanguages', JSON.stringify(selectIdList));
                // history.push({ pathname: '/yourskill' });
                { userRoleId === '2' ? history.push({ pathname: '/yourskill/interviewee' }) : history.push({ pathname: '/yourskill/interviewer' }) }
            }
        } else {
            Notification.openNotificationFaliure("Pick one or more Programming languages that you are comfortable!")

        }
    };
    const openEdtech = () => {
        window.open('https://platform.testleaf.com');
    };

    const handleClick = (id, check) => {
        // console.log("ID ---> ",id, "CHECK --->", check);
        // console.log("Selected List State ---> ", selectIdList);
        // console.log("Before programmingList State ---> ", programmingList);
        // console.log("Before programmingListAdd State ---> ", programmingListAdd);
        if (!check) {
            if (id === 404) {
                setSelectIdList([{
                    id: id,
                }]);
            }
            else if (selectIdList.length != 0 && selectIdList[0].id === 404) {
                setSelectIdList([{
                    id: id,
                }]);
            }
            else {
                selectIdList.push({
                    id: id,
                });
            }
        } else {
            if (selectIdList.length > 0) {
                var index = selectIdList.indexOf(id)
                selectIdList.splice(index, 1);
            }
        }

        const resultPush = [];
        // if(selectIdList.length > 0){
        programmingList.map(data => {
            if (id === 404) {
                if (data.id === 404 && check === false) {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: true,
                    });
                }
                else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,
                    });
                }
            }
            else if ((id == data.id || data.check == true)) {
                if (data.id === 404) {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,
                    });
                }
                else if (id == data.id && check == true) {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,

                    });
                } else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: true,

                    });
                }
            } else {
                resultPush.push({
                    id: data.id,
                    name: data.name,
                    check: false,
                });
            }
        });
        // }
        // else {  
        //     programmingListAdd.map(data => {
        //         if (id == data.id || data.check == true) {
        //             if (check == false) {
        //                 resultPush.push({
        //                     id: data.id,
        //                     name: data.name,
        //                     check: true,

        //                 });
        //             } else {
        //                 resultPush.push({
        //                     id: data.id,
        //                     name: data.name,
        //                     check: false,

        //                 });
        //             }
        //         } else {
        //             resultPush.push({
        //                 id: data.id,
        //                 name: data.name,
        //                 check: false,
        //             });
        //         }
        //     });
        // }


        console.log("After programmingList State ---> ", resultPush);
        setProgrammingList(resultPush);
        console.log(selectIdList)

    }

    const history = useHistory();
    return (
        <div className="programming_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <div class='programming-container'>

                    <div class='left-container'>
                        <header>

                            <div class='card'>
                                <div class="header-name">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <h2>Programming Languages</h2>
                                        </Col>
                                    </Row>
                                </div>
                                <div class="header-name">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <h4>Pick one or more programming languages that you are comfortable </h4>
                                        </Col>
                                    </Row>
                                </div>
                                <div class='set'>
                                    <Row>
                                        {programmingList.map(item => {
                                            return (
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Card className={item.check == true ? 'ant-card-select' : ''} style={item.id !== 404 ? { width: 280, marginTop: 16, height: 60 } : { width: 560, marginTop: 16, height: 60 }} onClick={() => handleClick(item.id, item.check)}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} push={13}>
                                                                {item.check == true ?
                                                                    <i > <CheckCircleTwoTone twoToneColor="#52c41a" /></i>
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                        <Meta
                                                            description={item.name}
                                                        />
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        }
                                    </Row>
                                    <footer>
                                        <div class='footer_details'>
                                            <Link to={userRoleId === '2' ? "/yourself/interviewee" : "/yourself/interviewer"}> <Button type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button></Link>
                                            <Button onClick={handleSubmit} htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large">NEXT STEP </Button>
                                        </div>
                                    </footer>
                                </div>


                            </div>



                        </header>
                        {/* <footer>
                                    <div class=''>
                                        <Row >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Link to="/yourself"><button id='resend'>BACK   <i><ArrowLeftOutlined /></i></button></Link>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} push={1}>
                                                <button onClick={handleSubmit} id='next'>NEXT STEP   <i><ArrowRightOutlined /></i></button>
                                            </Col>
                                        </Row>
                                    </div>
                                </footer> */}
                    </div>
                    <div class='right-container'>
                        <h1>May be a good idea to have skill on more than one languages - Object oriented vs Functional </h1>
                        <img src={lanImg} />
                    </div>

                </div>
            }

            <Modal title="Visit EdTech Platform"
                visible={isModalVisible}
                footer={null}
                // width={550}
                onCancel={() => setIsModalVisible(false)}>
                <div>
                    <p>Visit Our Unique <a onClick={openEdtech} href="#">EdTech Platform</a> to learn Free Courses and Crack your Dream Jobs</p>
                </div>
                <div class='modalFoot'>
                    <Button onClick={openEdtech} htmlType="button" type="default" icon={<LinkOutlined />} size="large">Visit</Button>
                    <Button onClick={handleSubmit} htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large">Proceed to Next Step</Button>
                </div>
            </Modal>




        </div>
    );
};

export default ProgrammingLanguages;