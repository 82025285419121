import React from "react";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import './audiotest.scss';
import Notification from "../../../components/notification/notification";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioURL: null,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0
        }
      }
    };
  }
  handleAudioStop(data) {
    console.log(data);
    this.setState({ audioDetails: data });
    if(data.blob.size > 10000 &&  data.blob.type == "audio/*"){
        localStorage.setItem('audiotest', true);
        Notification.openNotificationSuccess("Audio test successful!");
    }else{
        Notification.openNotificationFaliure("Audio Check atleast 3 secs!");
        localStorage.setItem('audiotest', false);

    }

  }
  handleAudioCheck(data) {
    console.log(data);
    this.setState({ audioDetails: data });
    //console.log(data);
    console.log(data);
    console.log(data.blob.size);
    if(data.blob.size > 0 &&  data.blob.type == "audio/*"){
        localStorage.setItem('audiotest', true);
    }

  }
  handleAudioUpload(file) {
    console.log(file);
    console.log(file.blob.size);
  }
  handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    this.setState({ audioDetails: reset });
  }
  render() {
    return (
      <div className="App audio-test">
        <Recorder
          record={true}
          title={"Audio Test"}
          audioURL={this.state.audioDetails.url}
          showUIAudio
        //   showUIAudio={(data) => this.handleAudioCheck(data)}
          handleAudioStop={(data) => this.handleAudioStop(data)}
          handleAudioUpload={(data) => this.handleAudioUpload(data)}
          handleRest={() => this.handleRest()}
        />
      </div>
    );
  }
}
