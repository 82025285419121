import React, { lazy, useState, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Radio, Spin, Skeleton, Form, Select, Option, Modal, Rate } from 'antd';
import { Switch, NavLink, Route, useRouteMatch, Link, useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PaginationWrapper, NotFoundWrapper } from './Style';
import Heading from '../../../components/heading/heading';
import { ProductCard } from './Style';
import fullstack from '../../../../src/assets/imges/landscape_img.png';
import moment from 'moment';
import LottieComponent from '@sharedComponent/lottie-component';
import LottieFile from 'src/assets/lottie-files';
import { Button } from '../../../components/buttons/buttons';
import { UsercardWrapperNew } from '../schedule/style';
import './interviews-page.scss'
import apiService from "../../../utils/apiService";
import RichTextEditor from 'react-rte';
import Notification from "../../../components/notification/notification";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import siteUrl from "../../../api/apiUrls";
import noDataImg from 'src/assets/imges/No data.gif';

const Interviews = () => {
  const history = useHistory();
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };
  const { confirm } = Modal;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  const jwt = localStorage.getItem('jwt');
  const [completedInterviewsList, setCompletedInterviewsList] = useState([]);
  const [scheduledInterviewsList, setScheduledInterviewsList] = useState([]);
  const [interviewFilterDataTypes, setInterviewFilterDataTypes] = useState([]);
  const [interviewFilterDataCompany, setInterviewFilterDataCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);

  const [skillSelectId, setskillSelectId] = useState("");
  const [companySelectName, setcompanySelectName] = useState("");
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const searchData = useSelector(state => state.headerSearchData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackValue, setFeedBackValue] = useState(RichTextEditor.createEmptyValue());
  const [platformfeedbackValue, setPlatformFeedBackValue] = useState(RichTextEditor.createEmptyValue());
  const [overAllRating, setOverAllRating] = useState(0);
  const [selectScheduleId, setSelectScheduleId] = useState("");
  const [state, setState] = useState({
    notData: searchData,
    active: 'active',
  });
  const [pagesize, setPagesize] = useState(0);
  const [pagesizeCompleted, setPagesizeCompleted] = useState(0);
  const [totalpages, setTotalpages] = useState(0);
  const [pageDefaultValue, setPageDefaultValue] = useState(1);
  const [results, setResults] = useState([]);
  const [pageNumber, setPageNumber] = useState(2);
  const [pn, setPn] = useState(1);
  const [ps, setTotalPs] = useState(4);
  const [totalElements, setTotalElements] = useState(0);
  const [pnCompleted, setPnCompleted] = useState(1);
  const [psCompleted, setTotalPsCompleted] = useState(4);
  const [totalElementsComplted, setTotalElementsComplted] = useState(0);
  const { notData } = state;
  const userName = localStorage.getItem('name');
  const [joinNowTrue, setJoinNowTrue] = useState(false);
  const [platformFeedbackErr, setSetplatformFeedbackErr] = useState(false);
  const [ratingErr, setSetRatingErr] = useState(false);
  const [feedBackErr, setSetFeedbackErr] = useState(false);
  const [platformFeedbackHideShow, setSetplatformFeedbackHideShow] = useState(false);


  const handleSearch = searchText => {
    const data = searchData.filter(item => item.title.toUpperCase().startsWith(searchText.toUpperCase()));
    setState({
      ...state,
      notData: data,
    });
  };
  const handleSubmit = () => {
    setPn(1);
    setPnCompleted(1);
    setIsLoading(false);
    getMyInterviewsList(skillSelectId, companySelectName, 1, ps);
    getMyInterviewsListCompleted(skillSelectId, companySelectName, 1, psCompleted);

  }
  const clearFilter = () => {
    setcompanySelectName("");
    setskillSelectId("");
    setPn(1);
    setPnCompleted(1);
    setIsLoading(false);
    setIsLoadingCompleted(false)
    form.resetFields();
    getMyInterviewsList("", "", 1, ps);
    getMyInterviewsListCompleted("", "", 1, psCompleted);

  }


  function showConfirmPaynow(value) {
    confirm({
      title: 'Do you want to paynow?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        history.push({ pathname: '/confirmedinterview', state: { data: value, selectValue: value, assignedPaynow: "Y", interviewerId: value.userid } });

      },
      onCancel() {
      },
    });
  }




  useEffect(() => {
    getMyInterviewsList(skillSelectId, companySelectName, pn, ps);
    getMyInterviewsListCompleted(skillSelectId, companySelectName, pnCompleted, psCompleted);
    getFilterData();
    getFilterCompany();
  }, []);

  const getMyInterviewsList = (skillSelectId, companySelectName, pn, ps) => {
    let queryParam = "myinterviews/list?skillId=";

    if (skillSelectId !== "") {
      queryParam = queryParam + skillSelectId;
    }
    if (companySelectName != "") {
      queryParam = queryParam + `&${'companyName'}=${companySelectName}`;
    } else {
      queryParam = queryParam + `&${'companyName'}=`;
    }
    queryParam = queryParam + `&${'statusId=45,46,47,48,49&pn'}=${pn}&${'ps'}=${ps}`;
    // var apiUrl = "myinterviews/list?skillId=&companyName=&statusId=45,46,47,48,49&pn=" + pn + "&ps=" + ps;
    // console.log(apiUrl)
    // if (skillSelectId != "" && companySelectName != "") {
    //   apiUrl = "myinterviews/list?skillId=" + skillSelectId + "&companyName=" + companySelectName + "&statusId=45,46,47,48,49&pn=" + pn + "&ps=" + ps;
    // }
    apiService(queryParam, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setScheduledInterviewsList([...result.data.content]);
          setPagesize(result.data.totalpages);
          setTotalElements(result.data.totalelements)
          setIsLoading(true)
        }
      },
      (error) => {

      });
  };

  const getMyInterviewsListCompleted = (skillSelectId, companySelectName, pn, ps) => {
    let queryParam = "myinterviews/list?skillId=";
    if (skillSelectId !== "") {
      queryParam = queryParam + skillSelectId;
    }
    if (companySelectName != "") {
      queryParam = queryParam + `&${'companyName'}=${companySelectName}`;
    } else {
      queryParam = queryParam + `&${'companyName'}=`;
    }
    queryParam = queryParam + `&${'statusId=50&pn'}=${pn}&${'ps'}=${ps}`;
    // var apiUrl = "myinterviews/list?skillId=&companyName=&statusId=50&pn=" + pn + "&ps=" + ps;
    // if (skillSelectId != "" && companySelectName != "") {
    //   apiUrl = "myinterviews/list?skillId=" + skillSelectId + "&companyName=" + companySelectName + "&statusId=50&pn=" + pn + "&ps=" + ps;
    // }
    apiService(queryParam, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setCompletedInterviewsList([...result.data.content.sort((a,b)=> new Date(b.date+" "+b.time) - new Date(a.date+" "+a.time))]);
          setPagesizeCompleted(result.data.totalpages);
          setTotalElementsComplted(result.data.totalelements)
          setIsLoadingCompleted(true)
        }
      },
      (error) => {

      });
  };
  const getFilterData = () => {
    apiService(`signup/skilllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setFilterSkillList([...result.data]);
        }
      },
      (error) => {

      });
  };
  const getFilterCompany = () => {
    apiService(`users/companyname`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setFilterCompanyList([...result.data]);
        }
      },
      (error) => {

      });
  };
  const showModal = () => {
    setIsModalVisible({ isModalVisible: true });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onTextInput = value => {
    setFeedBackValue(value);
    setSetFeedbackErr(false);
    if (onChangehtml) {
      onChange(value.toString('html'));
    }
  };
  const onTextInput1 = value => {
    setSetplatformFeedbackErr(false);
    setPlatformFeedBackValue(value);
    if (onChangehtml) {
      onChange(value.toString('html'));
    }
  };
  const onChangehtml = () => {
  }
  const feedBackCheck = (value) => {
    // if(value.skillstoimprove != null){
    if (value.feedback != null) {
      history.push({ pathname: "/completedinterview", state: { data: value } });
    } else {
      getPlatformFeedbackVerify();
      setSelectScheduleId(value.id);
    }

    // }else{
    //   showConfirm()
    // }

  };
  const text1 = 'Wait for the feedback in interviwer'
  const text = <div>Hi {userName} </div>
  function showConfirm(item) {
    confirm({
      title: <div>{text} <br></br><>{text1}</></div>,
      icon: <ExclamationCircleOutlined />,
      onOk() {

      },
      onCancel() {
      },
    });
  }
  const handleChange = (value) => {
    setSetRatingErr(false);
    setOverAllRating(value);
  };

  const getPlatformFeedbackVerify = () => {
    var apiUrl = "myinterviews/verifyplatformfeedback";
    apiService(apiUrl, 'get', '', false, jwt,
      result => {
        if (result.data) {
          if (result.data.success == true) {
            setSetplatformFeedbackHideShow(false);
            showModal();
          } else {
            setSetplatformFeedbackHideShow({ platformFeedbackHideShow: true });
            showModal();

          }

        }
      },
      (error) => {

      });
  };
  const handleSubmitFeedback = () => {
    // console.log(feedbackValue._cache.html)
    // console.log(overAllRating)
    // console.log(selectScheduleId)
    // console.log(platformfeedbackValue._cache.html);
    if (platformfeedbackValue._cache.html == undefined) {
      if (validate(feedbackValue, overAllRating, platformfeedbackValue)) {
        setIsModalVisible(false);
        setIsLoadingCompleted(false)
        apiService(`myinterview/feedback`, 'put', {
          id: selectScheduleId, "feedBack": feedbackValue._cache.html,
          "rating": overAllRating,
        }, false, jwt,
          result => {
            if (result.data.message) {
              Notification.openNotificationSuccess(result.data.message)
              getMyInterviewsListCompleted("", "", pnCompleted, psCompleted);

            } else {
              setIsLoadingCompleted(true);
              Notification.openNotificationFaliure(result.data.message)
            }
          },
          (error) => {

          });
      }
    } else {
      if (validate(feedbackValue, overAllRating, platformfeedbackValue)) {
        setIsModalVisible(false);
        setIsLoadingCompleted(false)
        apiService(`myinterview/feedback`, 'put', {
          id: selectScheduleId, "feedBack": feedbackValue._cache.html,
          "rating": overAllRating, "platformFeedback": platformfeedbackValue._cache.html
        }, false, jwt,
          result => {
            if (result.data.message) {
              Notification.openNotificationSuccess(result.data.message)
              getMyInterviewsListCompleted("", "", pnCompleted, psCompleted);


            } else {
              setIsLoadingCompleted(true);
              Notification.openNotificationFaliure(result.data.message)
            }
          },
          (error) => {

          });
      }
    }




  }
  const validate = (feedbackValue, overAllRating, platformfeedbackValue) => {
    setSetplatformFeedbackErr(false);
    setSetFeedbackErr(false);
    setSetRatingErr(false);
    var valid = true;
    if (feedbackValue._cache.html == null || feedbackValue._cache.html == "<p><br></p>") {
      setSetFeedbackErr(true);
      valid = false;
    }
    if (platformFeedbackHideShow == false) {
      if (platformfeedbackValue._cache.html == null || platformfeedbackValue._cache.html == "<p><br></p>") {
        setSetplatformFeedbackErr(true);
        valid = false;
      }
    }

    if (overAllRating == 0 || overAllRating == "") {
      setSetRatingErr(true);
      valid = false;
    }

    return valid;

  }

  const paginationBackword = () => {
    let count = pn;
    count--;
    setPn(count)
    setIsLoading(false)
    getMyInterviewsList(skillSelectId, companySelectName, count, ps);
  };
  const paginationForword = () => {
    let count = pn;
    count++;
    setPn(count)
    setIsLoading(false)
    getMyInterviewsList(skillSelectId, companySelectName, count, ps);
  };
  const paginationBackwordCompleted = () => {
    let count = pnCompleted;
    count--;
    setPnCompleted(count)
    setIsLoadingCompleted(false)
    getMyInterviewsListCompleted(skillSelectId, companySelectName, count, psCompleted);
  };
  const paginationForwordCompleted = () => {
    let count = pnCompleted;
    count++;
    setPnCompleted(count)
    setIsLoadingCompleted(false)
    getMyInterviewsListCompleted(skillSelectId, companySelectName, count, psCompleted);
  };

  function CompareTime(date, time) {
    // moment(now).format("YYYY-MM-DD HH:mm:ss")
    // var currentDate= new Date("2022-03-19")  ; // format hh:mm
    let now = new Date();
    var currentDate = moment(now).format("YYYY-MM-DD")
    var scheduleDate = new Date(date);
    var dateCheck = endAfterStart(currentDate, scheduleDate);
    if (dateCheck == true) {
      Object.prototype.twoDigits = function () {
        return ("0" + this).slice(-2);
      }
      var minutesToAdd = 30;
      var sheduleDateAndTime = date + " " + time;
      var currentDateTime = new Date(sheduleDateAndTime);
      var time30Plus = new Date(currentDateTime.getTime() + minutesToAdd * 60000);
      var time30Min = new Date(currentDateTime.getTime() - minutesToAdd * 60000);
      var startTime = moment(time30Min).format("HH:mm");
      var endTime = moment(time30Plus).format("HH:mm");
      let timeOfDay = now.getHours().twoDigits() + ':' + now.getMinutes().twoDigits();
      if (startTime <= timeOfDay && timeOfDay <= endTime) {
        return true;
      } else {
        return false
      }
    }
  }
  function endAfterStart(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);
    return endDate.getTime() == startDate.getTime();
  }

  const onChange = (date, dateString) => {
  }

  const gotoSchedule = () => {
    history.push({ pathname: '/schedule' });
  };

  return (
    <>
      <PageHeader
        ghost
        title="INTERVIEWS"
      />
      <Main>
        <Row>
          <Col xxl={24} lg={24} md={24} xs={24}>
            <Form name="schedule" form={form} onFinish={handleSubmit} layout='inline'>
              {/* <Col xxl={8} lg={8} md={12} xs={24} className='marginbtm-5'>
                <Form.Item name='filerCompanyList'
                  rules={[{ message: 'Please select Company!', required: false }]}>
                  <Select size="large" value={companySelectName} showSearch  filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      } onChange={e => setcompanySelectName(e)} placeholder='SEARCH COMPANY'>
                    {filterCompanyList.map(item => {
                      return (<Option value={item.companyname}>{item.companyname}</Option>)
                    })}

                  </Select>
                </Form.Item>
              </Col> */}
              <Col xxl={8} lg={8} md={12} xs={24} className='marginbtm-5'>
                <Form.Item name='filterSkillList' rules={[{ message: 'Please select Skills!', required: false }]}>
                  <Select value={skillSelectId} showSearch filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    } onChange={e => setskillSelectId(e)} size="large" placeholder='SEARCH SKILLS'>
                    {filterSkillList.map(item => {
                      return (<Option value={item.id}>{item.type}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} md={12} xs={24} className='marginbtm-5 btn-alignment'>
                <Button type="primary" className='search-btn1' size="large" htmlType="submit">
                  Search
                </Button>
                <Button type='light' onClick={clearFilter} className='clear-btn' size="large">Clear</Button>
              </Col>
            </Form>
          </Col>
        </Row>



        <div class="header-name interviews-head">
          <div className='head-left'>
            <h3 className='heading '>Scheduled Interviews</h3>
          </div>
          {totalElements > 4 ?
            <div className='head-right'>
              {pn > 1 ?
                <i className='lefticon' onClick={paginationBackword}> <LeftOutlined /></i> : <i className='lefticon cu-notallowed'> <LeftOutlined /></i>}
              {pagesize > pn ?
                <i className='righticon' onClick={paginationForword}> <RightOutlined /></i> : <i className='righticon cu-notallowed' > <RightOutlined /></i>}
            </div>
            : null}
        </div>
        {isLoading == false ?
          <Row gutter={30}>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
          </Row> :
          <Row gutter={30}>
            {scheduledInterviewsList.length !== 0 ?
              <>
                {scheduledInterviewsList.map(interviewsList => {
                  console.log("🦧🦧🦧🦧 --> ", interviewsList);
                  const { id } = interviewsList;
                  if (interviewsList.skills != null) {
                    return (
                      <Col xxl={6} lg={6} xs={24}>
                        <ProductCard style={{ marginBottom: 30 }}>
                          <Link to={{ pathname: "/scheduledinterview", state: { data: interviewsList } }}>
                            <figure>
                              {interviewsList.companyfileid != null ? <img className='interview-img' src={siteUrl + "" + "unsecure/view/" + interviewsList.companyfileid} alt={`img${id}`} />
                                : <img className='interview-img' src={fullstack} alt={`img${id}`} />
                              }
                            </figure>
                            <figcaption>
                              <p className="product-single-price">
                                {/* <span className="product-single-price__new">{interviewsList.companyname} </span> */}
                                <span className="product-single-price__new">{interviewsList.firstname} {interviewsList.lastname} </span>
                              </p>
                              <h4 className='interview-skill'>{interviewsList.skills.type}</h4>
                              {/* <h4 className='justify-content-end  d-flex '>{moment(interviewsList.date).format('DD MMM')} {moment(interviewsList.date + " " +interviewsList.time).format('hh:mm A')}</h4> */}
                              <div class="header-name">
                                <div className='head-left'>
                                  {interviewsList.status == "PAYMENT COMPLETED" ?
                                    <span className='status-paid'>PAID</span> :
                                    interviewsList.status == "PREP TEST" ?
                                      <span className='status-test'>PREP TEST</span> :
                                      interviewsList.status == "INTERVIEWER ASSIGNED" ?
                                        <span className='status-assigned'>ASSIGNED</span> :
                                        interviewsList.status == "SYSTEM CHECK" ?
                                          <span className='payment-check'>SYSTEM CHECK</span> :
                                          interviewsList.status == "BEGIN INTERVIEW" ?
                                            <span className='payment-check'>BEGIN INTERVIEW</span> :
                                            joinNowTrue == true ? <span className='status-joinnow'>JOINNOW</span> : null}
                                </div>
                                <h4 >{moment(interviewsList.date).format('DD MMM')} {moment(interviewsList.date + " " + interviewsList.time).format('hh:mm A')}</h4>

                              </div>

                            </figcaption>
                          </Link>
                        </ProductCard>
                      </Col>
                    )
                  } else {
                    return (
                      <Col md={24}>
                        {/* <NotFoundWrapper>
                        <LottieComponent file={LottieFile.NoData} width={'25%'} />
                        <h3>There might be</h3>
                      </NotFoundWrapper> */}
                        <div className='no_data_interviews'>
                          <img /*width="300" height="250"*/ alt="noDataImage" src={noDataImg} />
                          {/* <LottieComponent file={LottieFile.NoInterviews} width={'25%'} /> */}
                          <h3 className='no_interviews_msg'>There might a glitch in your Schedule. Please contact admin.</h3>
                          {/* <Button type="primary" className='search-btn1' size="large" onClick={gotoSchedule}>
                        Schedule
                      </Button> */}
                        </div>
                      </Col>
                    )
                  }
                })
                }

              </> :
              <Col md={24}>
                {/* <NotFoundWrapper>
                  <LottieComponent file={LottieFile.NoData} width={'25%'} />
                  <h3>No Interviews Please schedule interviews</h3>
                </NotFoundWrapper> */}
                <div className='no_data_interviews'>
                  <img /*width="300" height="250"*/ alt="noDataImage" src={noDataImg} />
                  {/* <LottieComponent file={LottieFile.NoInterviews} width={'25%'} /> */}
                  <h3 className='no_interviews_msg'>There are no scheduled  interviews, please <a onClick={gotoSchedule}>schedule</a></h3>
                  {/* <Button type="primary" className='search-btn1' size="large" onClick={gotoSchedule}>
                  Schedule
                </Button> */}
                </div>
              </Col>}
          </Row>}


        <div class="header-name">
          <div className='head-left'>
            <h3 className='heading'>Completed Interviews</h3>
          </div>
          {totalElementsComplted > 4 ?
            <div className='head-right'>
              {pnCompleted > 1 ?
                <i aria-disabled className='lefticon' onClick={paginationBackwordCompleted}> <LeftOutlined /></i> : <i aria-disabled className='lefticon cu-notallowed'> <LeftOutlined /></i>}
              {pagesizeCompleted > pnCompleted ?
                <i className='righticon' onClick={paginationForwordCompleted}> <RightOutlined /></i> : <i className='righticon cu-notallowed'> <RightOutlined /></i>}
            </div> : null}
        </div>
        {isLoadingCompleted == false ?
          <Row gutter={30}>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
            <Col xxl={6} lg={6} xs={24} >
              <Cards headless>
                <Skeleton avatar active />
              </Cards>
            </Col>
          </Row> :

          <Row gutter={30} className='cumpletedIntervue'>
            {completedInterviewsList.length ?
              <>
                {completedInterviewsList.map(interviewsList => {
                  const { id } = interviewsList;
                  return (
                    <Col xxl={6} lg={6} xs={24} key={id}>
                      <ProductCard style={{ marginBottom: 30 }} className="cursor-pointer" onClick={() => { feedBackCheck(interviewsList) }}>
                        {/* <Link to={{ pathname: "/completedinterview", state: { data: interviewsList } }}> */}
                        <figure>
                          {interviewsList.companyfileid != null ? <img className='interview-img' src={siteUrl + "" + "unsecure/view/" + interviewsList.companyfileid} alt={`img${id}`} />
                            : <img className='interview-img' src={fullstack} alt={`img${id}`} />
                          }
                        </figure>
                        <figcaption>
                          <p className="product-single-price">
                            {/* <span className="product-single-price__new">{interviewsList.companyname} </span> */}
                            <span className="product-single-price__new">{interviewsList.firstname} {interviewsList.lastname} </span>
                          </p>
                          <h4 className='interview-skill'>{interviewsList.skills.type}</h4>
                          <h4 className='justify-content-end  d-flex '>{moment(interviewsList.date).format('DD MMM')} {moment(interviewsList.date + " " + interviewsList.time).format('hh:mm A')}</h4>

                        </figcaption>
                        {/* </Link> */}
                      </ProductCard>
                    </Col>
                  )
                })
                }
              </> :
              <Col md={24}>
                {/* <NotFoundWrapper>
                  <LottieComponent file={LottieFile.NoInterviews} width={'25%'} />
                </NotFoundWrapper> */}
                <div className='no_data_interviews_completed'>
                  {/* <LottieComponent file={LottieFile.NoInterviews} width={'25%'} /> */}
                  <img /*width="300" height="250"*/ alt="noDataImage" src={noDataImg} />
                </div>
              </Col>}
          </Row>
        }
        <Modal title="Feedback"
          visible={isModalVisible}
          footer={null}
          centered
          width={800}
          onCancel={handleCancel}>
          <Form className='richtext-feedback-interviewee' name="slot" form={filterForm} onFinish={handleSubmitFeedback} layout="vertical">
            {platformFeedbackHideShow == false ?
              <Row>
                <h3>Platform Feedback</h3>
                <Col span={24}>
                  <RichTextEditor placeholder='Enter your platform feedback' toolbarConfig={toolbarConfig} value={platformfeedbackValue} onChange={onTextInput1} />
                </Col>
                {platformFeedbackErr == true ?
                  <p className='text-error' >Please enter platform feedback!</p> : null}
              </Row> : null}
            <Row>
              <h3>Your Feedback about Interviewer</h3>
              <Col span={24}>
                <RichTextEditor placeholder='Enter your feedback' toolbarConfig={toolbarConfig} value={feedbackValue} onChange={onTextInput} />
              </Col>
              {feedBackErr == true ?
                <p className='text-error' >Please enter interviewer feedback!</p> : null}
              <Col span={24}>
                <h4 className='rating'>Over all Ratings :
                  <Rate onChange={handleChange} value={overAllRating} />
                </h4>
                {ratingErr == true ?
                  <p className='text-error' >Please select rating!</p> : null}
              </Col>
            </Row>
            <Row>
              <Col sm={24} xs={24}>
                <div className="sDash_form-action mt-20 fl-right" >
                  <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                    Cancel
                  </Button>
                  <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Interviews;
