import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './completedInterview-page.scss';
import 'antd/dist/antd.css';
import { Row, Col, Button, Input, Space, Card, Select, Rate, Avatar, Image, Steps, DatePicker, Modal, Form, Spin } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined, AudioOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Main,Main2 } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
// import fullstack from '../../../../src/assets/imges/c-full-stack.svg';
import moment from 'moment';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import RichTextEditor from 'react-rte';
import parse from 'html-react-parser'
import fullstack from '../../../../src/assets/imges/landscape_img.png';
import siteUrl from "../../../api/apiUrls";

const CompletedInterview = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    let data = useLocation();
    let interviewData = data.state.data;
    console.log(data)

    const { confirm } = Modal;
    const { Search } = Input;
    const { Meta } = Card;
    const { Option } = Select;
    const { Step } = Steps;
    const jwt = localStorage.getItem('jwt');
    const [reason, setReason] = useState("");
    const [suggestion, setSuggestion] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [assigendStatus, setassigendStatus] = useState(3);
    const [overAllRating, setOverAllRating] = useState("");
    const [feedBackEdit, setfeedBackEdit] = useState(false);
    const [feedbackValue, setFeedBackValue] = useState(RichTextEditor.createEmptyValue());

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    useEffect(() => {
        if (interviewData.status == "INTERVIEWER ASSIGNED") {
            setassigendStatus(1);
        } else if (interviewData.status == "PAYMENT COMPLETED") {
            setassigendStatus(2);
        } else if (interviewData.status == "SYSTEM CHECK") {
            setassigendStatus(3);
        } else if (interviewData.status == "PREP TEST") {
            setassigendStatus(4);
        } else if (interviewData.status == "BEGIN INTERVIEW") {
            setassigendStatus(5);
        }
        setSuggestion(interviewData.feedback)
        if (interviewData.feedback == null) {
            setfeedBackEdit(true)
        }
        setOverAllRating(interviewData.rating)
    }, []);
    const handleChange = (value) => {
        console.log(value)
        setOverAllRating(value);
    };




    const handleSubmit = () => {
        setIsLoading(false)
        console.log(interviewData.id)
        apiService(`myinterview/feedback`, 'put', {
            id: interviewData.id, "feedBack": feedbackValue._cache.html,
            "rating": overAllRating
        }, false, jwt,
            result => {
                if (result.data.message) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviews' });
                    setIsLoading(true);

                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };


    const feedbackEdit = () => {
        setfeedBackEdit(true)
    };
    const feedbackEditCancel = () => {
        setfeedBackEdit(false)
    };
    const onTextInput = value => {
        setFeedBackValue(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };
    const onChange = (value) => {
        console.log(value)
    }


    return (
        <>
            <PageHeader
                ghost
                title="COMPLETED INTERVIEW"
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/interviews" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            <Main2>
                {isLoading == false ?
                    <div className="spinner">
                        <Spin />
                    </div> :
                    <div className="completed_page_container p_10">
                        <div className='scheduledInterview-container'>
                            <div className='scheduledInterview-status'>
                                <Row>
                                    <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                    {interviewData.companyfileid != null ?<img  src={siteUrl+""+"unsecure/view/"+interviewData.companyfileid} alt=""/>
                            : <img alt="example" src={fullstack} />
                            }
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                        <div className="assigned-details">
                                            <h4>{interviewData.companyname}</h4>
                                            <h3>{interviewData.companyname} | {moment(interviewData.date).format('DD MMM')} {moment(interviewData.date + " " +interviewData.time).format('hh:mm A')}</h3>
                                            <h5>Interviewer {interviewData.firstname} {interviewData.lastname}  has been assigned</h5>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={15} lg={16} xl={16}>
                                        <div className='stpes-completed-large'>
                                            <Steps size="small" current="5">
                                                <Step description="INTERVIEWER ASSIGNED" />
                                                <Step description="PAYMENT COMPLETED" />
                                                <Step description="SYSTEM CHECK" />
                                                <Step description="PREP TEST" />
                                                <Step description="COMPLETED" />
                                            </Steps>
                                        </div>
                                        <div className='stpes-completed-small'>
                                            <Steps size="small" direction="vertical" current="5">
                                                <Step description="INTERVIEWER ASSIGNED" />
                                                <Step description="PAYMENT COMPLETED" />
                                                <Step description="SYSTEM CHECK" />
                                                <Step description="PREP TEST" />
                                                <Step description="COMPLETED" />
                                            </Steps>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </div>
                }
            </Main2>
            <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
           <Main2 className='feedback-head'>
            <div className="completed_page_container p_10">
                        <div className='scheduledInterview-container'>
                    <Form name="cancel" form={form} onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col span={24}>

                                <Row>
                                    <Col span={24}>
                                        <h2 className='heading'>Interviewer Feedback</h2>
                                    </Col>

                                </Row>
                                {interviewData.skillstoimprove != null ?

<>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Attitude</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={16}>
                                    <label for=''>{interviewData.skillstoimprove.attitude}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Communication</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={16}><label for=''>{interviewData.skillstoimprove.communication}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Problem Solving</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={16}>
                                    <label for=''>{interviewData.skillstoimprove.problemSolving}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Technical</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={16}>
                                    <label for=''>{interviewData.skillstoimprove.technical}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Fitment To Role</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={16}>{interviewData.skillstoimprove.fitmentToRole}

                                    </Col>
                                </Row>
                                <Row>
                                <Col xs={24} sm={24} md={12} lg={7} xl={7}>
                                        <label for=''>Description</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                        {interviewData.skillstoimprove.description != null ?
                                            <p className='feedback-interviwer'>{parse(interviewData.skillstoimprove.description)}
                                            </p>
                                            : <p className='feedback-interviwer'>Interviewer will be give your feedback...!
                                            </p>}
                                    </Col>
                                </Row>
</>:<>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Attitude</label>
                                    </Col>
                                    <Col span={17}>
                                    <label for=''></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Communication</label>
                                    </Col>
                                    <Col span={17}> <label for=''></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Problem Solving</label>
                                    </Col>
                                    <Col span={17}>
                                    <label for=''></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Technical</label>
                                    </Col>
                                    <Col span={17}>
                                    <label for=''></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Fitment To Role</label>
                                    </Col>
                                    <Col span={17}>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <label for=''>Description</label>
                                    </Col>
                                    <Col span={17}>
                                    <p className='feedback-interviwer'>Interviewer will be give your feedback...!
                                            </p>
                                    </Col>
                                </Row>
</>}
                            </Col>


                        </Row>
                    </Form>






                </div>
                </div>

            </Main2>
</Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Main2 className='feedback-head2'>
            <div className="completed_page_container p_10">
                        <div className='scheduledInterview-container'>
                    <Form name="cancel" form={form} onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col span={24} >
                                <Row>
                                    <Col span={12} >
                                        <h2 className='heading'>My Feedback</h2>
                                    </Col>
                                    <Col span={10} >
                                        {/* {feedBackEdit == false ?
                            <Button className='fl-right' onClick={feedbackEdit}   type='primary' size='default'>Edit
                                                        </Button>
                                                        :<Button className='fl-right' onClick={feedbackEditCancel}   type='primary' size='default'>Cancel
                                                        </Button>} */}
                                    </Col>
                                </Row>

                                {feedBackEdit == true ?
                                    <Form name="cancel" form={form} onFinish={handleSubmit} layout="vertical">
                                        {/* <Form.Item
                                           initialValue={suggestion}
                                                name="suggestion"
                                                rules={[{ message: 'Please enter your Feedback!', required: true }]}>
                                                <Input.TextArea placeholder='Enter your feedback' id='suggestion'
                                                    value={suggestion} onChange={e => setSuggestion(e.target.value)} />

                                            </Form.Item> */}

                                        <RichTextEditor toolbarConfig={toolbarConfig} value={feedbackValue} onChange={onTextInput} />
                                        <Form.Item >
                                            <h4 className='rating'>Over all Ratings :
                                                <Rate onChange={handleChange} value={overAllRating} />
                                            </h4>
                                        </Form.Item>
                                        <Form.Item >
                                            {/* {feedBackEdit == true ?   
                                            <Button className='fl-right ml-10' onClick={feedbackEditCancel}   type='primary' size='default'>Cancel
                                                        </Button>:null} */}
                                            <Button className='fl-right' htmlType="submit" type='primary' size='default'>Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    :
                                    <div>
                                        <p className='feedback-interviwer'>{parse(suggestion)}
                                        </p>
                                        <Rate disabled value={overAllRating} />
                                        <Row>


                                        </Row>
                                    </div>

                                }

                            </Col>
                        </Row>
                    </Form>






                </div>
                </div>

            </Main2>
           </Col>
       
            </Row>
         
        </>
    );

};

export default CompletedInterview;
