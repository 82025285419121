import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Checkbox, Spin, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './signup-page.scss';
import 'antd/dist/antd.css';

import signupImg from 'src/assets/imges/skilingo_sign_up.png';
import facebook from 'src/assets/imges/facebook.png';
import linkedIn from 'src/assets/imges/linkedIn.png';
import google from 'src/assets/imges/google.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
const { Title, Paragraph } = Typography;





const Signup = () => {
    const history = useHistory();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mobile, setMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isAgree, setIsAgree] = useState("");
    const [singupAllForm, setsingupAllForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const profilecompleted = localStorage.getItem('profilecompleted');
    const loginEmail = localStorage.getItem('loginEmail');
    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");

    useEffect(() => {
        console.log(profilecompleted);
        if (profilecompleted == "N") {
            setsingupAllForm({ singupAllForm: true });
            setEmail(loginEmail);
        }
        console.log(loginEmail);
        setTimeout(() => {
            setIsLoading(true);
        }, 1000);


        // window.addEventListener("keydown", function(e) {
        // if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
        //     e.preventDefault();
        // }
        // }, false);
    }, []);

    const preventDefault = (e) => {
        e.key === " " && e.preventDefault();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const ShowTermsNCondition = () => {
        setIsModalVisible({ isModalVisible: true });
    };

    const handleSubmitFirst = () => {
        setisBtnLoading(true);
        // apiService(`signup/email`, 'post', { contactNo: mobile, userRoleId: 2 }, false, '',
        //     result => {
        //         if (result.data.message == "Otp sent") {
        //             setisBtnLoading(false);
        //             Notification.openNotificationSuccess(result.data.message);
        //             setsingupAllForm({ singupAllForm: true });
        //         } else {
        //             setisBtnLoading(false);
        //             Notification.openNotificationFaliure(result.data.message)
        //         }
        //     },
        //     (error) => {

        //     });
        apiService(`signup/email`, 'post', { email: email, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data.success == true) {
                    setisBtnLoading(false);
                    Notification.openNotificationSuccess(result.data.message);
                    setsingupAllForm({ singupAllForm: true });
                } else {
                    setisBtnLoading(false);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
        // setisBtnLoading(false);
        // setsingupAllForm({ singupAllForm: true });

    };

    const handleSubmitSecond = () => {
        setisBtnLoading(true);
        if (profilecompleted != "N") {
            var apiUrl = "signup/verifyemailotp?email=" + email + "&otp=" + otp + "&userRoleId=" + userRoleId;
            apiService(apiUrl, 'get', "", false, '',
                result => {
                    if (result.data.success == true) {
                        Notification.openNotificationSuccess(result.data.message);
                        submitSignupDetails();
                    } else {
                        setisBtnLoading(false);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else {
            submitSignupDetails();
        }


    };

    const submitSignupDetails = () => {
        apiService(`signup/detailsviaemail`, 'put', { contactNo: mobile, firstName: firstName, lastName: lastName, email: email, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data.jwt != null) {
                    setisBtnLoading(false);
                    localStorage.setItem('jwt', result.data.jwt);
                    localStorage.setItem('userRole', userroleid.toUpperCase());
                    Notification.openNotificationSuccess(result.data.message);
                    // history.push('/yourself');
                    userRoleId === '2' ? history.push("/yourself/interviewee") : history.push("/yourself/interviewer")
                } else {
                    Notification.openNotificationSuccess(result.data.message)
                    setisBtnLoading(false);
                }
            },
            (error) => {

            });
    }

    const resendOtp = () => {
        setIsLoading(false);
        apiService(`signup/resendotp?email=` + email + "&userRoleId=" + userRoleId, 'get', '', false, '',
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message);
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationSuccess(result.data.message);
                }
            },
            (error) => {
                setIsLoading(false);
            });
    };

    return (
        <div className="login_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <div class='login-container'>
                    <div class='left-container'>
                        {singupAllForm == false ?
                            <Form name="signup1" form={form1} onFinish={handleSubmitFirst} layout="vertical">
                                <header>
                                    <div class="header-name-sign">
                                        <div className='head-left'>
                                            <h1 className='login-heading'>Sign up</h1>
                                        </div>
                                        <div className='head-right'>
                                            <h4>Already have an account ?  <Link to={userRoleId === '2' ? "/login/interviewee" : "/login/interviewer"}><span>Login</span></Link></h4>
                                        </div>
                                    </div>
                                    <div className='form-details'>
                                        {/* <div class='set'>
                                            <Form.Item
                                                name="mobile"
                                                rules={[{
                                                    type: "regexp",
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Value should contain just number"
                                                },
                                                {
                                                    min: 10,
                                                    max: 10,
                                                    message: "phone number must be 10 digits",
                                                },
                                                {
                                                    message: 'Please enter your phone number!',
                                                    required: true
                                                }]}>
                                                <input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                            </Form.Item>

                                        </div> */}
                                        <div class='set'>
                                            <Form.Item
                                                name="email"
                                                rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                <input onKeyDown={preventDefault} placeholder="Enter your email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                            </Form.Item>

                                        </div>

                                        <footer>
                                            <div class='login-footer'>
                                                {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large"> NEXT STEP </Button>}
                                                {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} loading={isBtnLoading} size="large"> NEXT STEP </Button>}
                                            </div>
                                        </footer>
                                    </div>
                                </header>
                            </Form>
                            :
                            <Form name="singup2" form={form2} onFinish={handleSubmitSecond} layout="vertical">
                                <header>
                                    <div class="header-name-sign">
                                        <div className='head-left'>
                                            <h1 className='login-heading'>Basic Details</h1>
                                        </div>
                                        {/* <div className='head-right'>
                                            <h4>Already have an account ?  <Link to="/login"><span>Login</span></Link></h4>
                                        </div> */}
                                    </div>

                                    <div className='form-details'>
                                        {
                                            profilecompleted != "N"
                                                ?
                                                <Form.Item style={{ marginBottom: 0 }}>
                                                    <Form.Item
                                                        name="email"
                                                        style={{ display: 'inline-block', width: 'calc(70% )' }}
                                                        initialValue={email}
                                                        rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                        <input disabled placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="otp"
                                                        rules={[{ message: 'Please enter your OTP!', required: true }]}
                                                        style={{ display: 'inline-block', width: 'calc(30% )', paddingLeft: 8 }}>
                                                        <input placeholder="EMAIL OTP" value={otp} onChange={e => setOtp(e.target.value)} type='number' />
                                                    </Form.Item>
                                                    <a className='resend_otp' onClick={resendOtp} >Resend OTP</a>
                                                </Form.Item>
                                                :
                                                <Form.Item
                                                    name="email"
                                                    initialValue={email}
                                                    rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                    <input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                                </Form.Item>
                                        }
                                        {/* {profilecompleted != "N" ?
                                        <a className='resend_otp' onClick={resendOtp} >Resend OTP</a>:null} */}

                                        {profilecompleted != "N" ?
                                            <Form.Item style={{ marginBottom: 0 }}>
                                                <Form.Item
                                                    name="mobile"
                                                    rules={[{
                                                        type: "regexp",
                                                        pattern: /^(?:\d*)$/,
                                                        message: "Value should contain just number"
                                                    },
                                                    {
                                                        min: 10,
                                                        max: 10,
                                                        message: "phone number must be 10 digits",
                                                    },
                                                    {
                                                        message: 'Please enter your phone number!',
                                                        required: true
                                                    }]}
                                                    style={{ display: 'inline-block', width: 'calc(100%)' }}
                                                >
                                                    <input id='mobile' onKeyDown={preventDefault} placeholder="Phone Number"
                                                        value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                </Form.Item>
                                            </Form.Item> : null}
                                        {profilecompleted == "N" ?
                                            <Form.Item style={{ marginBottom: 0 }}>
                                                <Form.Item
                                                    name="mobile"
                                                    rules={[{
                                                        type: "regexp",
                                                        pattern: /^(?:\d*)$/,
                                                        message: "Value should contain just number"
                                                    },
                                                    {
                                                        min: 10,
                                                        max: 10,
                                                        message: "phone number must be 10 digits",
                                                    },
                                                    {
                                                        message: 'Please enter your phone number!',
                                                        required: true
                                                    }]}
                                                    style={{ display: 'inline-block', width: 'calc(100%)' }}
                                                >
                                                    <input id='mobile' onKeyDown={preventDefault} placeholder="Phone Number"
                                                        value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                </Form.Item>
                                            </Form.Item> : null}

                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <Form.Item
                                                name="firstName"
                                                rules={[{ message: 'Please enter your first name!', required: true }]}
                                                style={{ display: 'inline-block', width: 'calc(50%)' }}
                                            >
                                                <input placeholder="First Name"
                                                    value={firstName}
                                                    onKeyDown={preventDefault}
                                                    onChange={e => setFirstName(e.target.value)} type='text' />
                                            </Form.Item>
                                            <Form.Item
                                                name="lastName"
                                                rules={[{ message: 'Please enter your last name!', required: true }]}
                                                style={{ display: 'inline-block', width: 'calc(50%)', paddingLeft: 8 }}
                                            >
                                                <input placeholder="Last Name"
                                                    onKeyDown={preventDefault}
                                                    value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
                                            </Form.Item>
                                        </Form.Item>
                                        <div>
                                            {/* <Form.Item
                                                name="email"
                                                rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                <input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                            </Form.Item> */}

                                        </div>
                                        <Form.Item
                                            name="agreement"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                                },
                                            ]}>
                                            <Row>
                                                <Col sm={2} md={2} lg={2} xl={2}>
                                                    <Checkbox />
                                                </Col>
                                                <Col sm={22} md={22} lg={22} xl={22}>
                                                    <p>
                                                        I agree to Skillingo's{' '}
                                                        <a role="button" onClick={ShowTermsNCondition}>
                                                            Terms of Services
                                                        </a>{' '}
                                                        and consent to Skillingo's Privacy Policy.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Form.Item>


                                        {/* <div>
                                            <Row>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Checkbox ></Checkbox>
                                                </Col>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                    <h4>I agree to Skillingo's <a class='href' href='/Welcome'>Terms's of Services</a> and consent to Skillingo's Privacy Policy</h4>
                                                </Col>
                                            </Row>
                                        </div> */}

                                        <footer>
                                            <div class='login-footer'>
                                                <Form.Item>
                                                    {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large"> NEXT STEP </Button>}
                                                    {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} loading={isBtnLoading} size="large"> NEXT STEP </Button>}
                                                </Form.Item>

                                            </div>
                                        </footer>
                                    </div>
                                </header>
                            </Form>

                        }
                        <Modal title="Terms and Conditions"
                            visible={isModalVisible}
                            footer={null}
                            centered
                            width={800}
                            onCancel={handleCancel}>
                            <Typography>
                                <Title level={3}>Terms and Conditions for Interview App</Title>
                                <Paragraph>
                                    <strong>1. Payment:</strong> All services provided by the interview app are subject to
                                    payment. Users are required to pay the specified amount for each interview session booked.
                                </Paragraph>

                                <Paragraph>
                                    <strong>2. Refund Policy:</strong> Refunds are not allowed once a payment has been made
                                    for an interview session. Users are encouraged to review their booking details carefully
                                    before confirming payment.
                                </Paragraph>

                                <Paragraph>
                                    <strong>3. Rescheduling:</strong> Users have the option to reschedule their interview
                                    session by notifying the app at least 24 hours in advance. Rescheduling within 24 hours of
                                    the scheduled session will incur a rescheduling fee equal to 20% of the original session
                                    cost.
                                </Paragraph>

                                <Paragraph>
                                    <strong>4. Cancellation:</strong> Users may cancel a scheduled interview session, but no
                                    refunds will be provided. The cancellation policy is in effect once the payment is made.
                                </Paragraph>

                                <Paragraph>
                                    <strong>5. Session Duration:</strong> The duration of each interview session is as
                                    specified during the booking process. Users are responsible for managing their time
                                    effectively within the allocated session duration.
                                </Paragraph>

                                <Paragraph>
                                    <strong>6. Technical Requirements:</strong> Users are responsible for ensuring they have
                                    the necessary technical requirements, including a stable internet connection and compatible
                                    devices, for a successful interview session.
                                </Paragraph>

                                <Paragraph>
                                    <strong>7. Conduct:</strong> Users are expected to conduct themselves professionally
                                    during the interview session. Any inappropriate behavior may result in termination of the
                                    session without a refund.
                                </Paragraph>

                                <Paragraph>
                                    <strong>8. Technical Issues:</strong> The interview app is not responsible for technical
                                    issues beyond its control, including but not limited to internet outages, hardware
                                    failures, or software glitches.
                                </Paragraph>

                                <Paragraph>
                                    <strong>9. Data Privacy:</strong> User data, including personal and interview-related
                                    information, will be treated with utmost confidentiality. The app will not share user data
                                    with third parties without explicit consent.
                                </Paragraph>

                                <Paragraph>
                                    <strong>10. Changes to Terms:</strong> The interview app reserves the right to modify
                                    these terms and conditions at any time. Users will be notified of any changes, and
                                    continued use of the app implies acceptance of the updated terms.
                                </Paragraph>

                                <Paragraph>
                                    <strong>11. Termination of Service:</strong> The interview app reserves the right to
                                    terminate its services to any user in the event of a violation of these terms and
                                    conditions.
                                </Paragraph>

                                <Paragraph>
                                    <strong>12. Governing Law:</strong> These terms and conditions are governed by and
                                    construed in accordance with the laws of [Your Jurisdiction].
                                </Paragraph>

                                <Paragraph>
                                    By using the interview app, users agree to abide by these terms and conditions. If you have
                                    any questions or concerns, please contact support@skilingo.com.
                                </Paragraph>
                            </Typography>
                        </Modal>
                    </div>
                    <div className='right-container'>
                        <h1>Join the Leap: Sign Up and Elevate Your Skills.</h1>
                        <img src={signupImg} />
                    </div>
                </div>
            }

        </div>
    );


}
export default Signup;
