import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './interviewProfile-page.scss';
import 'antd/dist/antd.css';
import { Row, Col, Button, Input, Space, Card, Select, Rate, Avatar, Image, Steps, Badge, Spin, Modal, Form } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined, AudioOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../../styled';
import userImg from '../../../../src/assets/imges/blank-profile-picture.jpg';
import apiService from "../../../utils/apiService";
import moment from 'moment';
import Notification from "../../../components/notification/notification";
import parse from 'html-react-parser'
import { ProfileAuthorBox } from './style';
import siteUrl from "../../../api/apiUrls";

const InterviewProfile = () => {
    const history = useHistory();
    const { confirm } = Modal;
    const [form] = Form.useForm();
    const { Option } = Select;

    let data = useLocation();
    let interViewerDetails = data.state.data;
    let reason = data.state.reason;
    let isPayment = data.state.isPayment;
    let reschedule = data.state.reschedule;
    let rescheduleBeforePayment = data.state.rescheduleBeforePayment;
    let sloatSelectValue = data.state.sloatSelectValue;
    let interviewerId = data.state.interviewerId;
    let cancel = data.state.cancel;
    const { Meta } = Card;
    const jwt = localStorage.getItem('jwt');
    const userId = localStorage.getItem('userId');

    // const [interViewerDetails, setInterViewerDetails] = useState("");
    const [slots, setSlots] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectItemId, setSelectItemId] = useState("");
    const [selectValue, setselectValue] = useState("");
    const [userDetails, setUserDetails] = useState("");
    const [intervieweeDetails, setintervieweeDetails] = useState("");
    const [userSkills, setUserSkills] = useState([]);
    const [skillSelectId, setskillSelectId] = useState("");
    const [skillSelectName, setskillSelectName] = useState("");
    const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [rescheduleDetails, setrescheduleDetails] = useState("");
    const [yourAmount, setyourAmount] = useState("");
    const [rescheduleAmount, setrescheduleAmount] = useState("");
    const rescheduleId = localStorage.getItem("rescheduleId");
    const rescheduleSuggestion = localStorage.getItem("rescheduleSuggestion");

    useEffect(() => {
        // localStorage.setItem('rescheduleInterviewerSlotID', rescheduleAmount);
        // localStorage.removeItem("rescheduleId");
        // localStorage.removeItem("rescheduleAmount");
        // localStorage.removeItem("slotId");
        localStorage.removeItem("refTransactionId");
        // showConfirmReschedule();
        getInterviewerDetails();
        getProfile();
        getTop3Feedback();
        getIntervieweeProfile();
    }, []);


    function showConfirm() {
        if (selectValue != "") {
            var skillName = null;
            userSkills.map(data => {
                if (data.skills.id == skillSelectId) {
                    skillName = data.skills.type;
                }
            });
            const text1 = 'Are you sure to confirm that the interview will schedule for' + " " + moment(selectValue.date + " " + selectValue.time).format('DD-MM-YYYY hh:mm A');
            const text = <div>Hi {userDetails.firstName}</div>
            const text3 = <div>Skill :   {skillName}</div>
            const DateTime = <div>{moment(selectValue.date + " " + selectValue.time).format('DD-MM-YYYY hh:mm A')}</div>

            if (intervieweeDetails.userResumeId === null) {
                confirm({
                    title: <div><>Notice : </>
                        <div className='mt-10'><>{"It looks like your resume hasn't been uploaded yet. To proceed with scheduling, please ensure your resume is uploaded in your profile"}</></div>
                        <div className='mt-10'><>{ }</></div></div>,
                    icon: <ExclamationCircleOutlined />,
                    okText: "Go to Profile",
                    onOk() {
                        history.push({ pathname: '/profile', });
                    },
                    onCancel() {
                    },
                });
            }
            else {
                confirm({
                    title: <div><>Interviewer : {userDetails.firstName} {userDetails.lastName}</>
                        <div className='mt-10'><>{text3}</></div>

                        <div className='mt-10'><>{text1}</></div></div>,
                    icon: <ExclamationCircleOutlined />,
                    okText: "Agree",
                    onOk() {
                        // setIsLoading(false);
                        if (cancel == "N") {
                            bookSloat();
                        } else if (reschedule == "Y") {
                            if (interViewerDetails.status == "PAYMENT COMPLETED" || interViewerDetails.status == "SYSTEM CHECK" || interViewerDetails.status == "PREP TEST") {
                                bookSloatAfterpaymnet();
                            } else {
                                bookSloatBeforepaymnet();
                            }
                        } else if (isPayment == "Y") {
                            bookSloatAfterpaymnet();
                        } else if (isPayment == "N") {
                            bookSloatBeforepaymnetSlot();
                        } else {
                            bookSloat();

                        }
                    },
                    onCancel() {
                    },
                });
            }
        } else {
            Notification.openNotificationFaliure("Please Select Any Available Slot!")
        }
    }

    function showConfirmReschedule(rAmount) {

        const text1 = 'Do you want to Rescedule?';
        const text = <div> <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                Paid Amount
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                ₹{yourAmount}
            </Col>
        </Row>
            <Row className='mt-10'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    Reschedule Charges
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    ₹{rAmount}
                </Col>
            </Row>
        </div>

        confirm({
            title: <div><>{text1}</>
                <div className='mt-10'><>{text}</></div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            // okText:"Agree",
            onOk() {
                rescheduleSlot();
            },
            onCancel() {
            },
        });

    }

    const showModal = (value) => {
        setIsModalVisible({ isModalVisible: true });
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getInterviewerDetails = () => {
        var userId = interViewerDetails.userId;
        if (reschedule == "Y") {
            userId = interViewerDetails.userid;
        } else if (cancel == "N" || isPayment == "N") {
            userId = interViewerDetails.id;
        }
        apiService(`myinterview/schedulelist/` + interviewerId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    var datecheck = moment().format('YYYY-MM-DD');
                    var timeCheck = moment().format('HH:mm:ss');
                    const resultPushSlotTime = [];
                    result.data.map(res => {
                        if (datecheck == res.date) {
                            if (res.time >= timeCheck) {
                                resultPushSlotTime.push({
                                    "id": res.id, "time": res.time, "date": res.date, "status": res.status, "datetime": res.date + " " + res.time,
                                }
                                );
                            }
                        } else {
                            resultPushSlotTime.push({
                                "id": res.id, "time": res.time, "date": res.date, "status": res.status, "datetime": res.date + " " + res.time,
                            }
                            );
                        }
                    });

                    resultPushSlotTime.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
                    setSlots(resultPushSlotTime);


                }
            },
            (error) => {

            });
    };


    const getProfile = (id) => {
        var userId = interViewerDetails.userId;
        if (reschedule == "Y") {
            userId = interViewerDetails.userid;
        } else if (cancel == "N" || isPayment == "N") {
            userId = interViewerDetails.id;
        }
        apiService(`users/details/` + interviewerId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setUserDetails(result.data);
                    setUserSkills(result.data.userSkills)
                    if (interViewerDetails.skills != null) {
                        setskillDefSelectValue(interViewerDetails.skills.name);
                        setskillSelectId(interViewerDetails.skills.id)
                        setskillSelectName(interViewerDetails.skills.name);
                    } else {
                        setskillDefSelectValue(result.data.userSkills[0].skills.id);
                        setskillSelectId(result.data.userSkills[0].skills.id)
                        setskillSelectName(result.data.userSkills[0].skills.name);
                    }

                    setIsLoading(true)

                }
            },
            (error) => {

            });
    };

    const getIntervieweeProfile = () => {
        let intervieweeId = localStorage.getItem('userId');
        console.log(intervieweeId);
        apiService(`users/details/` + intervieweeId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setintervieweeDetails(result.data);
                    // setIsLoading(true)
                }
            },
            (error) => {

            });
    };

    const getTop3Feedback = () => {
        var userId = interViewerDetails.userId;
        if (reschedule == "Y") {
            userId = interViewerDetails.userid;
        } else if (cancel == "N" || isPayment == "N") {
            userId = interViewerDetails.id;
        }
        apiService(`myinterview/topfeedback/` + interviewerId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setTestimonials(result.data);
                    // setIsLoading(true)

                }
            },
            (error) => {

            });
    };


    const bookSloat = () => {
        apiService(`schedule/pickinterviewer`, 'put', { id: selectItemId, "skillId": skillSelectId }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/confirmedinterview', state: { data: userDetails, selectValue: selectValue, interviewerId: userDetails.id, skillId: skillSelectId } });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    const bookSloatBeforepaymnet = () => {
        apiService(`schedule/beforepayment`, 'put', { id: interViewerDetails.id, "rescheduleId": selectItemId, "skillId": skillSelectId }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/confirmedinterview', state: { data: userDetails, selectValue: selectValue, interviewerId: userDetails.id } });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };


    const bookSloatBeforepaymnetSlot = () => {
        apiService(`schedule/beforepayment`, 'put', { id: sloatSelectValue.id, "rescheduleId": selectItemId, "skillId": skillSelectId }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/confirmedinterview', state: { data: userDetails, selectValue: selectValue, interviewerId: userDetails.id } });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };


    const bookSloatAfterpaymnet = () => {
        // var amount = 500;
        // setyourAmount(amount);
        // var rescheduleAmount = amount - 600;
        // setrescheduleAmount(rescheduleAmount);
        // // localStorage.setItem('rescheduleAmount', rescheduleAmount);
        // // localStorage.setItem('refTransactionId', resultData.data.id);
        // showConfirmReschedule(rescheduleAmount);  

        localStorage.setItem('slotId', selectItemId);
        var rescheduleSlotid = "";
        if (rescheduleId == null) {
            localStorage.setItem('rescheduleId', interViewerDetails.id);
            rescheduleSlotid = interViewerDetails.id;
        } else {
            rescheduleSlotid = rescheduleId;
        }
        apiService(`schedule/afterpayment`, 'put', {
            id: rescheduleSlotid, "rescheduleId": selectItemId,
            "reason": reason,
            "suggestion": rescheduleSuggestion
        }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    var resultData = result.data;
                    var amount = resultData.amount;
                    setyourAmount(amount);
                    var rescheduleAmount = amount - resultData.data.rescheduleBalanceAmount;
                    setrescheduleAmount(rescheduleAmount);
                    // localStorage.setItem('rescheduleAmount', rescheduleAmount);
                    localStorage.setItem('refTransactionId', resultData.data.id);
                    // showModal(rescheduleAmount);
                    // showConfirmReschedule(rescheduleAmount);
                    setrescheduleDetails(result.data);
                    rescheduleSlot()
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    const rescheduleSlot = () => {
        // var amountCheck = rescheduleAmount > 0;
        // if(rescheduleAmount == 0){
        //     zeroPayment();
        // }else if(amountCheck == false){
        //     refundPayment();
        // }else{
        //   history.push({ pathname: '/confirmedinterview', state: { data: userDetails, selectValue: selectValue,interviewerId:userDetails.id,skillId:skillSelectId} });
        // }
        history.push({ pathname: '/confirmedinterview', state: { data: interViewerDetails, selectValue: selectValue, interviewerId: userDetails.id, skillId: skillSelectId } });


    };

    const zeroPayment = () => {
        apiService(`payment/zero`, 'post', {
            "refTransactionId": rescheduleDetails.data.id,
            "amountToBePaid": rescheduleDetails.amount,
            "scheduleId": selectItemId,
            "discountAmount": rescheduleDetails.data.rescheduleBalanceAmount,
            "scheduleInterviewDto": {
                "id": interViewerDetails.id,
                "rescheduleId": selectItemId,
                "skillId": skillSelectId,
                "reason": reason,
                "suggestion": "test"
            }
        }, false, jwt,
            result => {
                if (result.data.SUCCESS == true) {
                    Notification.openNotificationSuccess(result.data.MESSAGE)
                    history.push({ pathname: '/interviews', });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.MESSAGE)
                }
            },
            (error) => {

            });
    };

    const refundPayment = () => {
        apiService(`payment/refund`, 'put', {
            "refTransactionId": 2737,
            "amountToBePaid": rescheduleDetails.amount,
            "scheduleId": selectItemId,
            "discountAmount": rescheduleDetails.data.rescheduleBalanceAmount,
            "scheduleInterviewDto": {
                "id": interViewerDetails.id,
                "rescheduleId": selectItemId,
                "skillId": skillSelectId,
                "reason": reason,
                "suggestion": "test"
            }
        }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviews', });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };


    const handleClickSloat = (value) => {
        setselectValue(value);
        localStorage.setItem('slotId', value.id);
        setSelectItemId(value.id);

    }
    const handleSubmit = () => {




    }

    return (

        <>
            <PageHeader
                ghost
                title="INTERVIEWER"
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/schedule" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <Main>
                    <div className="interviewProfile_page_container">

                        <div className='interviewProfile-container'>
                            <Row>
                                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                    <Row className='interviewProfile-head'>
                                        <Col xs={10} sm={10} md={5} lg={5} xl={5}>
                                            {userDetails.profileImageId != null ?
                                                <Avatar src={siteUrl + "" + "unsecure/view/" + userDetails.profileImageId} />
                                                :
                                                <Avatar src={userImg} />
                                            }
                                        </Col>
                                        <Col xs={1} sm={1} md={2} lg={1} xl={1}></Col>
                                        <Col xs={12} sm={12} md={15} lg={15} xl={15} className='user-detail'>
                                            <h2>{userDetails.firstName + " " + userDetails.lastName}</h2>
                                            <h4 className='linked-in'>{userDetails.interviewerInfo.linkedInUrl}</h4>
                                            <i><Rate disabled defaultValue={userDetails.interviewerInfo.overAllRating} /></i>
                                        </Col>
                                        {/* <Col span={3}></Col> */}
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} className='user-detail'>
                                            <Form name="profile-skill" form={form} onFinish={handleSubmit}>
                                                <Form.Item name='filerCompanyList' initialValue={skillDefSelectValue}
                                                    rules={[{ message: 'Please select Skills!', required: true }]}>
                                                    <Select size="large" defaultValue={skillDefSelectValue} value={skillSelectId} onChange={e => setskillSelectId(e)} placeholder='SEARCH SKILLS'>
                                                        {userSkills.map(item => {
                                                            return (<Option value={item.skills.id}>{item.skills.type}</Option>)
                                                        })}

                                                    </Select>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className='userinfo-head'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {userDetails.interviewerInfo.shortDescription != null ?
                                                <div className='profile_description'>  {parse(userDetails.interviewerInfo.shortDescription)}</div> : null}
                                        </Col>
                                    </Row>
                                    <Row className='userskill-head'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Row className='userskill-details'>
                                                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                    <h4>Level</h4>
                                                    <h3>{userDetails.interviewerInfo.level}</h3>
                                                </Col>
                                                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                    <h4>Experience</h4>
                                                    <h3>{userDetails.interviewerInfo.experience}</h3>
                                                </Col>
                                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                                    <h4>Skills</h4>
                                                    {userSkills.map(skill => {
                                                        return (
                                                            <h3 className='skill-name'>{skill.skills.type} {userSkills.length > 1 ? ',' : ''}</h3>
                                                        )
                                                    })}

                                                </Col>
                                                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                    <h4>Interviews</h4>
                                                    <h3>{userDetails.interviewerInfo.interviewedCount}</h3>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='userinfo-head'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {userDetails.interviewerInfo.longDescription != null ?
                                                <div className='profile_description'>  {parse(userDetails.interviewerInfo.longDescription)}</div> : null}
                                        </Col>
                                    </Row>
                                    <div className='relateduser-title'>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <h4>Testimonials</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <h2>Words from satisfied users</h2>
                                            </Col>
                                        </Row>
                                    </div>
                                    {testimonials.length ?
                                        <Row className='relateduser-head'>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Row className='relateduser-details'>
                                                    {testimonials.map(item => {
                                                        return (
                                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                <Card hoverable className='card-box'>
                                                                    <div class='card-header'>
                                                                        <Row>
                                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='card-rate'>
                                                                                <i><Rate disabled defaultValue={item.rating} /></i>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <Meta
                                                                        description={parse(item.feedback)}
                                                                    />
                                                                    <footer className='card-footer'>
                                                                        <Row >
                                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                                <Avatar src={userImg} />
                                                                            </Col>
                                                                            <Col xs={17} sm={17} md={17} lg={17} xl={17} >
                                                                                <h4>{item.firstname} {item.lastname}</h4>
                                                                                <h5>Develpoer</h5>
                                                                            </Col>
                                                                        </Row>
                                                                    </footer>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row> :
                                        <Row className='relateduser-head'>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Row className='relateduser-details'>
                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                        <Card hoverable className='card-box'>
                                                            <div class='card-header'>
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='card-rate'>
                                                                        <i><Rate disabled defaultValue={5} /></i>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <Meta
                                                                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                                                            />
                                                            <footer className='card-footer'>
                                                                <Row >
                                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                        <Avatar src={userImg} />
                                                                    </Col>
                                                                    <Col xs={17} sm={17} md={17} lg={17} xl={17} >
                                                                        <h4>Salai</h4>
                                                                        <h5>Develpoer</h5>
                                                                    </Col>
                                                                </Row>
                                                            </footer>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                                        <Card hoverable className='card-box'>
                                                            <div class='card-header'>
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='card-rate'>
                                                                        <i><Rate disabled defaultValue={5} /></i>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <Meta
                                                                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                                                            />
                                                            <footer className='card-footer'>
                                                                <Row >
                                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                        <Avatar src={userImg} />
                                                                    </Col>
                                                                    <Col xs={17} sm={17} md={17} lg={17} xl={17} >
                                                                        <h4>Muthudevi</h4>
                                                                        <h5>Develpoer</h5>
                                                                    </Col>
                                                                </Row>
                                                            </footer>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                        <Card hoverable className='card-box'>
                                                            <div class='card-header'>
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='card-rate'>
                                                                        <i><Rate disabled defaultValue={5} /></i>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <Meta
                                                                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                                                            />
                                                            <footer className='card-footer'>
                                                                <Row >
                                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                        <Avatar src={userImg} />
                                                                    </Col>
                                                                    <Col xs={17} sm={17} md={17} lg={17} xl={17} >
                                                                        <h4>Bowya Karthikeyan</h4>
                                                                        <h5>Developer</h5>
                                                                    </Col>
                                                                </Row>
                                                            </footer>
                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>}
                                </Col>
                                <Col xs={24} sm={24} md={5} lg={5} xl={5} push={1} className='right-container'>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Link to={{ pathname: "/schedule", }}>
                                                <button id='similar' >SEE SIMILAR PROFILES   <i className='icon-hide-sm'><ArrowRightOutlined /></i></button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        {slots.length > 0 ?
                                            <Button onClick={showConfirm} id='booknow' >Book now   <i className='icon-hide-sm'><ArrowRightOutlined /></i></Button> :
                                            <Button disabled id='booknow' >Book now   <i><ArrowRightOutlined /></i></Button>}
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div className='available-conatiner'>
                                            <h4 className='sloats'>Available Slots<Badge
                                                className="site-badge-count-109"
                                                count={slots.length}
                                            /></h4>
                                            {slots.length ?
                                                <>
                                                    {slots.map(item => {
                                                        return (
                                                            <div className={item.id == selectItemId ? 'sloat-select' : 'available-head'} onClick={() => handleClickSloat(item)}>
                                                                <div className='available-slots'>
                                                                    <h3>{moment(item.date).format('DD MMM YYYY')} </h3>
                                                                    <h4>{moment(item.date + " " + item.time).format('hh:mm A')} IST</h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </> : <div className='available-head'>
                                                    <div className='available-slots'>
                                                        <h3>No  Available Slots</h3>
                                                    </div>
                                                </div>}

                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                        <Modal title="Reschedule Details"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={handleCancel}>
                            {/* <Row>
                                    <Col span={9}>
                                        <label for=''>You Paid Amout</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={14}>
                                    <label for=''>{yourAmount}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={9}>
                                        <label for=''>Reschedule Amount</label>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={14}>
                                       <label for=''>{rescheduleAmount}</label>
                                    </Col>
                                </Row> */}
                            <ProfileAuthorBox>
                                <div className="author-info">
                                    <figure>
                                        <nav className="settings-menmulist">
                                            <ul>
                                                <div className='menu-list'>

                                                    <li>

                                                        <label>Paid Amount</label> <br />
                                                        <label>  ₹{yourAmount}</label>

                                                    </li>
                                                    <Col span={3}></Col>
                                                    <li>
                                                        <label> Reschedule Charges</label><br />
                                                        <label>  ₹{rescheduleAmount}</label>


                                                    </li>

                                                </div>

                                            </ul>
                                        </nav>
                                    </figure>
                                </div>

                                <figcaption>
                                    <div className="info">

                                        <Row>
                                            <Col span={24}>
                                                <h1>Do you want to Rescedule?</h1>
                                            </Col>
                                        </Row>
                                    </div>
                                </figcaption>
                            </ProfileAuthorBox>
                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button onClick={rescheduleSlot} className="btn-signin" type="primary" size="large">
                                            OK
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        </Modal>
                    </div>

                </Main>
            }
        </>
    );

};

export default InterviewProfile;
