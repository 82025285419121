import React from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import LottieComponent from '@sharedComponent/lottie-component';
import LottieFile from 'src/assets/lottie-files';
import './otp-page.scss';
import 'antd/dist/antd.css';
import { Form, Input, Radio } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';

import otpImg from 'src/assets/imges/otppage.png';
const menus = [
    {
        icon: <ArrowRightOutlined />,
    }
];

const Otp = () => {

    const history = useHistory();
    return (
        <div className="otp_page_container p_10">
            <div class='otp-container'>
                <Row>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <div class='left-container'>
                            <header>
                                <div class="header-name">
                                    <Row>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <h1>Verification </h1>
                                        </Col>
                                    </Row>
                                </div>
                                <div class="header-name">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <h4>We've sent you a 6 digit verification code to this phone number </h4>
                                        </Col>
                                    </Row>
                                </div>
                                <div class='set'>
                                    <Row>
                                        <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                                            <label for=''>Phone</label>
                                            <input id='' placeholder="Phone" type='text' />
                                        </Col>
                                    </Row>
                                </div>
                                <div class='set otp-header'>
                                    <Row>
                                        <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                                            <OtpInput
                                                value={1}
                                                // onChange={this.handleChange}
                                                numInputs={6}
                                                inputStyle='inputStyle'
                                                separator={<span>-</span>}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </header>
                            <footer>
                                <div class=''>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div ><h4 >Resend Otp</h4></div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} push={1}>
                                            <div >
                                                <Link to="/YourSelf"><button id='next'>Verify OTP   <ArrowRightOutlined /></button></Link></div>
                                        </Col>
                                    </Row>
                                </div>
                            </footer>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <div class='right-container'>
                            <img src={otpImg} />
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    );
};

export default Otp;