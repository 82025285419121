import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './confirmedInterview-page.scss';
import 'antd/dist/antd.css';
import { Row, Col, Button, Input, Space, Card, Select, Rate, Avatar, Image, Modal, Spin, Form } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined, MailFilled, CheckCircleTwoTone, RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Main } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import userImg from '../../../../src/assets/imges/blank-profile-picture.jpg';
import apiService from "../../../utils/apiService";
import moment from 'moment';
import Notification from "../../../components/notification/notification";
import { OrderSummary } from '../profile/style';
import siteUrl from "../../../api/apiUrls";
const ConfirmedInterview = () => {
    const history = useHistory();
    const { confirm } = Modal;
    const location = useLocation();
    const jwt = localStorage.getItem('jwt');
    const userId = localStorage.getItem('userId');
    const localData = localStorage.getItem("loginData");
    const loginData = JSON.parse(localData);
    let interViewerDetails = location.state.data;
    let interviewerId = location.state.interviewerId;
    let skillId = location.state.skillId;
    const rescheduleAmount = localStorage.getItem("rescheduleAmount");
    const slotId = localStorage.getItem("slotId");
    const rescheduleId = localStorage.getItem("rescheduleId");
    const refTransactionId = localStorage.getItem("refTransactionId");
    const rescheduleReason = localStorage.getItem("rescheduleReason");
    const rescheduleSuggestion = localStorage.getItem("rescheduleSuggestion");
    const refundAmount = localStorage.getItem("refundAmount");

    console.log(interviewerId);
    console.log(skillId);
    console.log(rescheduleAmount)
    console.log(refTransactionId);
    console.log(rescheduleId);
    let sloatSelectValue = location.state.selectValue;
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState("");
    const [slotAmount, setslotAmount] = useState(0);
    const [payAmount, setpayAmount] = useState(0);
    const [payAmountDef, setpayAmountDef] = useState(0);
    const [isCouponApplied, setisCouponApplied] = useState(false);
    const [intervieweruserDetails, setInterviewerUserDetails] = useState("");
    const [form] = Form.useForm();
    const [title, setTitle] = useState("");
    const [couponResult, setcouponResult] = useState("");
    const [gstCal, setgstCal] = useState(0);
    const [slotTotalAmont, setslotTotalAmont] = useState(0);
    const [promoDisAmont, setpromoDisAmont] = useState(0);
    console.log(interViewerDetails);
    console.log(sloatSelectValue);

    const { Search } = Input;
    const { Meta } = Card;
    const { Option } = Select;

    function showConfirm() {
        confirm({
            title: 'Do you want to paynow?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                payNow();
            },
            onCancel() {
            },
        });
    }

    function cancelInterviewCall() {
        confirm({
            title: 'Do you want to cancel?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                cancelInterview();
            },
            onCancel() {
            },
        });
    }

    function showConfirmRe() {
        confirm({
            title: 'Do you want to reschedule?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                var isPayment = "N";
                if (rescheduleAmount != null) {
                    isPayment = "Y"
                }
                history.push({ pathname: '/interviewerprofile', state: { data: interViewerDetails, isPayment: isPayment, sloatSelectValue: sloatSelectValue, interviewerId: interviewerId } });
            },
            onCancel() {
            },
        });
    }

    useEffect(() => {
        // setTitle("CONFIRMED INTERVIEW" + "   |  " + interViewerDetails.interviewerInfo.companyName + "   |   " + moment(sloatSelectValue.date).format('DD MMM YYYY') + "    |   " + moment(sloatSelectValue.date + " " +sloatSelectValue.time).format('hh:mm A') + "  IST")
        window.addEventListener("keydown", function (e) {
            if (["Enter"].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        }, false);
        getUserDetails();
        getIntervieweeUserDetails();
    }, []);



    const getUserDetails = () => {
        // setIsLoading(false);
        apiService(`users/details/` + userId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    console.log(result.data)
                    setUserDetails(result.data)
                    // setIsLoading(true);
                }
            },
            (error) => {

            });
    };


    const getIntervieweeUserDetails = () => {
        // setIsLoading(false);
        apiService(`users/details/` + interviewerId, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    console.log(result.data)
                    // setTitle("CONFIRMED INTERVIEW" + "   |  " + result.data.interviewerInfo.companyName + "   |   " + moment(sloatSelectValue.date).format('DD MMM YYYY') + "    |   " + moment(sloatSelectValue.date + " " + sloatSelectValue.time).format('hh:mm A') + "  IST");
                    setTitle("CONFIRMED INTERVIEW" + "   |   " + moment(sloatSelectValue.date).format('DD MMM YYYY') + "    |   " + moment(sloatSelectValue.date + " " + sloatSelectValue.time).format('hh:mm A') + "  IST");
                    if (rescheduleAmount == null) {
                        setslotAmount(result.data.interviewerInfo.amount);
                        // setpayAmountDef(result.data.interviewerInfo.amount);
                        let amt = (result.data.interviewerInfo.amount * (18 / 100));
                        setgstCal(amt);
                        var totalAmountCal = result.data.interviewerInfo.amount + amt;
                        setpayAmount(totalAmountCal);
                        setpayAmountDef(totalAmountCal);
                        setslotTotalAmont(totalAmountCal);
                    } else {
                        setslotAmount(rescheduleAmount);
                        let amt = (rescheduleAmount * (18 / 100));
                        setgstCal(amt.toFixed(0));
                        var totalAmountCal = parseInt(rescheduleAmount) + amt;
                        setpayAmount(totalAmountCal);
                        setpayAmountDef(totalAmountCal);
                        setslotTotalAmont(totalAmountCal);
                        // setpayAmount(rescheduleAmount);
                        // setpayAmountDef(rescheduleAmount);
                    }
                    setInterviewerUserDetails(result.data)
                    setIsLoading(true);
                }
            },
            (error) => {

            });
    };


    const bookingConfirmed = () => {
        setIsLoading(false);
        apiService(`schedule/bookingconfirmed`, 'put', { id: slotId, "skillId": skillId }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviews' });
                    setIsLoading(true);

                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    const cancelInterview = () => {
        setIsLoading(false);
        apiService(`myinterviews/cancel`, 'put', { id: slotId, }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviewerprofile', state: { data: interViewerDetails, cancel: "N", interviewerId: interviewerId } });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };





    const payNow = () => {
        if (payAmount == 0) {
            zeroPayment();

        } else {

            // // var amount = parseFloat(interViewerDetails.interviewerInfo.amount) * 100;
            // var amount = parseFloat(interViewerDetails.interviewerInfo.amount ? interViewerDetails.interviewerInfo.amount : 0) * 100
            // Notification.openNotificationFaliure(amount)
            apiService(`payment/create`, 'post', { scheduleId: slotId, amount: payAmount, "receipt": "Receipt no" + " - " + slotId + " - " + moment().format('DDMMYYYY') }, false, jwt,
                result => {
                    if (result) {
                        setIsLoading(true);
                        console.log(result)
                        payForOrder(result.data)
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        }

    };


    const payForOrder = (data) => {
        console.log(data)
        const options = {
            "key": data.secretKey,
            "amount": data.amount,
            "name": "Skilngo Interviewee Pay",
            "description": "Skilngo Interviewee for scheduleId : " + slotId,
            "order_id": data.razorpayOrderId,
            "enabled": {
                "escape": false
            },
            "prefill": {
                "name": userDetails.firstName + userDetails.lastName,
                "email": userDetails.email,
                "contact": userDetails.contactNo,
            },
            "notes": {
                "Company": "Skilngo",
                "Payment": "schedule Payment",
                // "Order Id": data.quotationId,
                // "Customer Name": data.name,
                // "Email": data.email,
                // "Contact No": data.contactno,
                // "Amount": data.amount
            },
            "theme": {
                "color": "#dc3545"
            }
        };

        options.handler = ((response, error) => {
            console.log(response)
            options.response = response;
            // bookingConfirmed();
            paymentSuccessCall(response);

        });

        // options['modal.ondismiss'] = this.paymentFailureCall.bind(this);

        // console.log(options);
        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            paymentFailedCall(response.error)
            console.log("Payment Failed");
        });
        rzp1.open();
    };

    const paymentSuccessCall = (data) => {
        setIsLoading(false);
        if (rescheduleId == null) {
            apiService(`payment/update`, 'put', {
                "orderId": data.razorpay_order_id,
                "paymentId": data.razorpay_payment_id,
                "scheduleId": parseInt(slotId),
                "signature": data.razorpay_signature,
                "coupon": couponResult.id,
            }, false, jwt,
                result => {
                    if (result.data.SUCCESS == true) {
                        Notification.openNotificationSuccess(result.data.MESSAGE);
                        localStorage.removeItem("rescheduleId");
                        localStorage.removeItem("rescheduleAmount");
                        localStorage.removeItem("slotId");
                        localStorage.removeItem("refTransactionId");
                        localStorage.removeItem("rescheduleReason");
                        history.push({ pathname: '/interviews', state: { data: interViewerDetails, cancel: "N", interviewerId: interviewerId } });
                        setIsLoading(true);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.MESSAGE)
                    }
                },
                (error) => {

                });
        } else {

            apiService(`payment/update`, 'put', {
                "orderId": data.razorpay_order_id,
                "paymentId": data.razorpay_payment_id,
                "scheduleId": parseInt(slotId),
                "signature": data.razorpay_signature,
                "refTransactionId": parseInt(refTransactionId),
                "refundAmount": parseInt(refundAmount),
                "coupon": couponResult.id,
                "scheduleInterviewDto": {
                    "rescheduleId": parseInt(rescheduleId),
                    "skillId": skillId,
                    "reason": rescheduleReason,
                    "suggestion": rescheduleSuggestion,

                }
            }, false, jwt,
                result => {
                    if (result.data.SUCCESS == true) {
                        localStorage.removeItem("rescheduleId");
                        localStorage.removeItem("rescheduleAmount");
                        localStorage.removeItem("slotId");
                        localStorage.removeItem("refTransactionId");
                        localStorage.removeItem("rescheduleReason");
                        localStorage.removeItem("rescheduleSuggestion");
                        localStorage.removeItem("refundAmount");
                        Notification.openNotificationSuccess(result.data.MESSAGE)
                        history.push({ pathname: '/interviews', state: { data: interViewerDetails, cancel: "N", interviewerId: interviewerId } });
                        setIsLoading(true);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.MESSAGE)
                    }
                },
                (error) => {

                });

        }

    };

    const paymentFailedCall = (data) => {
        console.log(data);
        setIsLoading(false);
        apiService(`/payment/failed`, 'put', {
            "orderId": data.metadata.order_id,
            "scheduleId": parseInt(slotId),
        }, false, jwt,
            result => {
                if (result.data.SUCCESS == true) {
                    Notification.openNotificationSuccess(result.data.MESSAGE);
                    localStorage.removeItem("rescheduleId");
                    localStorage.removeItem("rescheduleAmount");
                    localStorage.removeItem("slotId");
                    localStorage.removeItem("refTransactionId");
                    localStorage.removeItem("rescheduleReason");
                    localStorage.removeItem("rescheduleSuggestion");

                    history.push({ pathname: '/interviews', state: { data: interViewerDetails, cancel: "N", interviewerId: interviewerId } });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.MESSAGE)
                }
            },
            (error) => {

            });
    }

    const submitPromo = values => {
        console.log(values)
        apiService(`coupon/verify?name=` + values.promoCode, 'get', '', false, jwt,
            result => {
                if (result.data.SUCCESS == true) {
                    console.log(result.data.DATA);
                    setisCouponApplied(true);
                    // showConfirmAfterPayment(result.data.DATA);
                    setcouponResult(result.data.DATA);
                    if (result.data.DATA.couponType.type != "VALUE") {
                        let amt = (payAmount * (result.data.DATA.value / 100));
                        setpromoDisAmont(amt);
                        var disAmount = payAmount - amt;
                        setpayAmount(disAmount)

                    } else {
                        var amt = payAmount - result.data.DATA.value;
                        setpromoDisAmont(result.data.DATA.value);
                        if (amt >= 0) {
                            setpayAmount(amt)
                        } else {
                            setpayAmount(0)
                        }

                    }
                } else {
                    Notification.openNotificationFaliure(result.data.MESSAGE)
                }
            },
            (error) => {
            });
    };



    function showConfirmAfterPayment(data) {
        console.log(data)
        var name = data.name;
        var value = data.value;
        var type = data.couponType.type;
        const text1 = 'Coupon Details';
        const text = <div> <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                Coupon Name
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                {name}
            </Col>
        </Row>
            <Row className='mt-10'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    Value
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    {type == "VALUE" ?
                        <> ₹{value}</>
                        : <> {value}%</>}
                </Col>
            </Row>
        </div>
        confirm({
            title: <div><>{text1}</>
                <div className='mt-10'><>{text}</></div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                setcouponResult(data);
                if (type != "VALUE") {
                    let amt = (payAmount * (value / 100));
                    var disAmount = payAmount - amt;
                    setpayAmount(disAmount)

                } else {
                    var amt = payAmount - value;
                    if (amt >= 0) {
                        setpayAmount(amt)
                    } else {
                        setpayAmount(0)
                    }

                }
                console.log(amt);

            },
            onCancel() {
            },
        });
    }


    const zeroPayment = () => {
        if (rescheduleId == null) {
            apiService(`payment/zero`, 'post', {
                "scheduleId": parseInt(slotId),
                "coupon": couponResult.id,

            }, false, jwt,
                result => {
                    if (result.data.SUCCESS == true) {
                        localStorage.removeItem("rescheduleId");
                        localStorage.removeItem("rescheduleAmount");
                        localStorage.removeItem("slotId");
                        localStorage.removeItem("refTransactionId");
                        localStorage.removeItem("rescheduleReason");
                        localStorage.removeItem("rescheduleSuggestion");
                        Notification.openNotificationSuccess(result.data.MESSAGE)
                        history.push({ pathname: '/interviews', });
                        setIsLoading(true);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.MESSAGE)
                    }
                },
                (error) => {

                });
        } else {

            apiService(`payment/zero`, 'post', {
                "coupon": couponResult.id,
                "refTransactionId": parseInt(refTransactionId),
                "scheduleId": parseInt(slotId),
                "scheduleInterviewDto": {
                    "id": parseInt(rescheduleId),
                    "rescheduleId": parseInt(slotId),
                    "skillId": skillId,
                    "reason": rescheduleReason,
                    "suggestion": rescheduleSuggestion
                }
            }, false, jwt,
                result => {
                    if (result.data.SUCCESS == true) {
                        localStorage.removeItem("rescheduleId");
                        localStorage.removeItem("rescheduleAmount");
                        localStorage.removeItem("slotId");
                        localStorage.removeItem("refTransactionId");
                        localStorage.removeItem("rescheduleReason");
                        localStorage.removeItem("rescheduleSuggestion");
                        Notification.openNotificationSuccess(result.data.MESSAGE)
                        history.push({ pathname: '/interviews', });
                        setIsLoading(true);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.MESSAGE)
                    }
                },
                (error) => {

                });

        }
    };


    const handleCancel = e => {
        setisCouponApplied(false);
        form.resetFields()
        setcouponResult("");
        setpayAmount(payAmountDef);
    }






    return (
        <>
            <PageHeader
                ghost
                title={title}
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/schedule" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            {/* <Main> */}
            <div className="confirmedInterview_page_container p_10">
                {isLoading == false ?
                    <div className="spinner">
                        <Spin />
                    </div> :
                    <div className='schedule-container'>
                        <div className='schedule-head'>
                            {/* <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h2>Confirmed interview | {interViewerDetails.interviewerInfo.companyname} | {moment(sloatSelectValue.date).format('DD MMM YYYY')} {sloatSelectValue.time} IST</h2>
                            </Col>
                        </Row> */}
                            <div className='schedule-card'>
                                <Row>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <div className='card-box'>
                                            <div className='card-head'>
                                                <div className='card-header'>
                                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                    <h1>Booking Confirmed</h1>
                                                </div>
                                            </div>
                                            <div className='user-details'>
                                                <div className='card-header'>
                                                    {intervieweruserDetails.profileImageId != null ?
                                                        <Avatar src={siteUrl + "" + "unsecure/view/" + intervieweruserDetails.profileImageId} /> : <Avatar src={userImg} />}
                                                    <h4>Congratulations. Your interview with {interViewerDetails.firstName} {interViewerDetails.lastName} has been confirmed.</h4>
                                                </div>
                                            </div>
                                            <div className='card-footer'>
                                                <div className='footer-details'>
                                                    <div class="header-name">
                                                        <div className='head-left date-time '>
                                                            <h3> {moment(sloatSelectValue.date).format('DD MMM YYYY')}</h3>
                                                            <h4>{moment(sloatSelectValue.date + " " + sloatSelectValue.time).format('hh:mm A')}  IST</h4>
                                                        </div>
                                                        <div className='head-right'>
                                                            <Button onClick={showConfirmRe} className='res-btn'>
                                                                RESCHEDULE	<i><ArrowRightOutlined /></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/* <Row>
                                                        <Col xs={24} sm={24} md={22} lg={22} xl={22} className='date-time'>
                                                            <div>
                                                                <h3> {moment(sloatSelectValue.date).format('DD MMM YYYY')}</h3>
                                                                <h4>{sloatSelectValue.time} IST</h4>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>

                                                            <Button onClick={showConfirmRe} className='res-btn'>
                                                                RESCHEDULE	<i><ArrowRightOutlined /></i>
                                                            </Button>
                                                        </Col>
                                                    </Row> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='card-box-help'>
                                            <div className='card-box-service'>
                                                <div className='card-head'>
                                                    <div className='card-header'>
                                                        <Row>
                                                            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                                                <h3>Service Checklist</h3>
                                                                <h4>See what all you should know or do before the interview begins.
                                                                </h4>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2} lg={2} xl={2} className='right-arrow'>
                                                                <i><RightOutlined /></i>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='card-box-help'>
                                            <div className='card-box-service'>
                                                <div className='card-head help-head'>
                                                    <div className='card-header'>
                                                        <Row>
                                                            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                                                <h3>Need our help?</h3>
                                                                <h4>Email us in case you need assistance in our service.</h4>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className='right-arrow ph-number'>
                                                                <Button disabled className='ph-btn'>
                                                                    support@skilingo.com<i><MailFilled /></i>&nbsp;
                                                                </Button>
                                                            </Col>

                                                        </Row>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} push={2}>
                                        <div className='card-box-payment'>
                                            <div className='card-head'>
                                                <div className='card-header'>
                                                    <h3>Payment Summary</h3>
                                                </div>
                                            </div>
                                            <div className='user-details'>
                                                <div className='card-header'>
                                                    <Row className='rate-row'>
                                                        <Col xs={16} sm={16} md={20} lg={20} xl={10}>
                                                            <h4>Total</h4>
                                                        </Col>
                                                        <Col xs={8} sm={8} md={4} lg={4} xl={4} push={6}>
                                                            <h4 className='rupess'>Rs {slotAmount}</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row className='rate-row'>
                                                        <Col xs={16} sm={16} md={20} lg={20} xl={10}>
                                                            <h4>GST(18%)</h4>
                                                        </Col>
                                                        <Col xs={8} sm={8} md={4} lg={4} xl={4} push={6}>
                                                            <h4 className='rupess'>Rs {gstCal}</h4>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row className='rate-row'>
                                                        <Col xs={16} sm={16} md={20} lg={20} xl={10}>
                                                            <h4>Credits</h4>
                                                        </Col>
                                                        <Col xs={8} sm={8} md={4} lg={4} xl={4} push={6}>
                                                            <h4 className='rupess'>Rs 0</h4>
                                                        </Col>
                                                    </Row> */}
                                                    {isCouponApplied == true ?
                                                        <Row className='rate-row'>
                                                            <Col xs={16} sm={16} md={20} lg={20} xl={10}>
                                                                <h4>Promo Value</h4>
                                                            </Col>
                                                            <Col xs={8} sm={8} md={4} lg={4} xl={4} push={6}>
                                                                <h4 className='rupess'>Rs {slotTotalAmont.toFixed(0)} - {promoDisAmont.toFixed(0)} </h4>
                                                            </Col>
                                                        </Row> : null}
                                                </div>
                                            </div>
                                            <div className='card-footer'>
                                                <div className='footer-details'>
                                                    <Row>
                                                        <Col xs={16} sm={16} md={20} lg={20} xl={10}>
                                                            <h3>Amount to pay</h3>
                                                        </Col>
                                                        <Col xs={8} sm={8} md={4} lg={4} xl={4} push={6}>
                                                            <h3 className='total-rup'>Rs {payAmount.toFixed(0)}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pay-btn-head'>
                                                            <Button onClick={showConfirm} className='pay-btn'>
                                                                Pay Now
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='can-btn-head'>
                                                            <a onClick={cancelInterviewCall}>CANCEL</a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-box-payment card-box-promocode'>
                                            <div className='card-head'>
                                                <div className='card-header'>
                                                    <h3>Promo Code</h3>
                                                </div>
                                            </div>
                                            <OrderSummary>
                                                <Form form={form} name="promo" onFinish={submitPromo}>

                                                    <div className="promo-apply-form">
                                                        <Form.Item name="promoCode" rules={[{ message: 'Please enter promo code!', required: true }]}>
                                                            <Input placeholder="Promo Code" />
                                                        </Form.Item>
                                                        <div className='promocode-btn'>
                                                            {isCouponApplied == false ?
                                                                <Button htmlType="submit" size="default" type="primary" outlined>
                                                                    Apply
                                                                </Button> : null}
                                                            &nbsp; &nbsp;
                                                            <Button size="default" onClick={handleCancel} className='clear-btn' type="light">
                                                                Cancel
                                                            </Button>
                                                            {/* <Button htmlType="submit" size="default" type="primary" outlined>
                                                                Apply
                                                            </Button> */}
                                                        </div>

                                                    </div>
                                                </Form>

                                            </OrderSummary>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>

                    </div>
                }
            </div>
            {/* </Main> */}
        </>
    );
};

export default ConfirmedInterview;
