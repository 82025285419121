import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './profile-page.scss';
import 'antd/dist/antd.css';
import './profile.scss';

import { Row, Col, Input, Space, Card, Select, Rate, Avatar, Image, Checkbox, Form, Spin, Upload, DatePicker } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useRouteMatch } from "react-router-dom";
import { UploadOutlined, ArrowRightOutlined, PhoneFilled, CheckCircleTwoTone, RightOutlined, EditOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { SettingWrapper, ChangePasswordWrapper } from './style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import userImg from 'src/assets/imges/user.png';
import apiService from "../../../utils/apiService";
import apiJavaService from "../../../utils/javaApiService";
import Notification from "../../../components/notification/notification";
import Heading from '../../../components/heading/heading';
import { BasicFormWrapper, TagInput } from '../../styled';
import { ProfileAuthorBox } from './style';
import moment from 'moment';
import siteUrl from "../../../api/apiUrls";

const Profile = () => {
  const { path } = useRouteMatch();

  const jwt = localStorage.getItem('jwt');
  const userId = localStorage.getItem('userId');
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const { Search } = Input;
  const [form] = Form.useForm();
  const { Meta } = Card;
  const { Option } = Select;
  const [profileDetails, setProfileDetails] = useState("");
  const [mobile, setMobile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [resumePath, setResumePath] = useState("");
  const [resumeFile, setResumeFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [currentpassword, setCPassword] = useState("");
  const [newpassword, setNPassword] = useState("");
  const [editcardShow, seteditcardShow] = useState(1);
  const [oldPassword, setoldPassword] = useState("");
  const [NewPassword, SetNewPassword] = useState("");
  const [RepeatPW, setRepea] = useState("");
  const [filterprglan, setPrglan] = useState([""]);
  const [filterSkill, setSkill] = useState([""]);
  const [filterArea, setArea] = useState([""]);
  const [state, setState] = useState({
    name: 'clayton',
    values: null,
  });
  const [cgpa, setcgpa] = useState("");
  const [graduation, setgraduation] = useState([]);
  const [Specialization, setspecialization] = useState("");
  const [year, setyear] = useState([]);
  const [experience, setexperience] = useState([]);
  const [college, setcollege] = useState("");
  const [organization, setorganization] = useState("");
  const [isStudent, setIsStudent] = useState("");
  const config = {
    rules: [{ type: 'object', required: true, message: 'Please select your graduation Year!' }],

  };
  const [interestAreaList, setInterestAreaList] = useState([]);
  const [programmingList, setProgrammingList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [interestAreaSelectItemId, setinterestAreaSelectItemId] = useState("");
  const [programmingListSelectItemId, setprogrammingListSelectItemId] = useState("");
  const [skillListSelectItemId, setskillListSelectItemId] = useState("");
  const [educationalInfo, seteducationalInfo] = useState("");
  const [userDomain, setuserDomain] = useState("");
  const [userLanguage, setuserLanguage] = useState("");
  const [userSkills, setuserSkills] = useState("");
  const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
  const [langDefSelectValue, setlangDefSelectValue] = useState([]);
  const [interestDefSelectValue, setInterestDefSelectValue] = useState([]);
  const [image, setImage] = useState("/static/media/blank-profile-picture.298c21cb.jpg");
  const [passwordSet, setPasswordSet] = useState("N");

  useEffect(() => {
    getProfile();
    getResume();
    getInterestAreaList();
    getProgrammingList();
    getSkillList();
  }, []);

  const getInterestAreaList = () => {
    apiService(`signup/favlist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setInterestAreaList([...result.data]);
        }
      },
      (error) => {
      });
  };



  const deleteResume = () => {
    apiService(`users/deleteresume`, 'put', {
      "id": userId
  }, false, jwt,
      result => {
        if (result.data) {
          Notification.openNotificationSuccess(result.data.message);
        }
      },
      (error) => {

      });
};

  const getProgrammingList = () => {
    apiService(`signup/pllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setProgrammingList([...result.data]);
          // setIsLoading(true);
        }
      },
      (error) => {
      });
  };

  const getSkillList = () => {
    apiService(`signup/skilllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setSkillList([...result.data]);
          // setIsLoading(true);
        }
      },
      (error) => {

      });
  };

  const getProfile = () => {
    apiService(`users/details/${userId}`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          const dataSourceSkill = [];
          const dataSourceLang = [];
          const dataSourceIntr = [];
          console.log(result.data)
          if (result.data.profileImageId != null) {
            setImage(siteUrl + "" + "unsecure/view/" + result.data.profileImageId);
          }
          if (result.data.userResumeId != null) {
            console.log("💹💹💹 --> ", siteUrl + "" + "unsecure/view/" + result.data.userResumeId);
            setResumePath(siteUrl + "" + "unsecure/view/" + result.data.userResumeId);
          }
          setProfileDetails(result.data);
          setFirstName(result.data.firstName);
          setLastName(result.data.lastName);
          setEmail(result.data.email);
          setMobile(result.data.contactNo);
          seteducationalInfo(result.data.educationalInfo);
          setuserDomain(result.data.userDomain);
          setuserLanguage(result.data.userLanguage);
          setuserSkills(result.data.userSkills);
          setspecialization(result.data.educationalInfo.specialization);
          setyear(result.data.educationalInfo.graduationYear);
          setgraduation(result.data.educationalInfo.degree);
          setIsStudent(result.data.educationalInfo.isStudent)
          if (result.data.educationalInfo.isStudent == "Y") {
            setcgpa(result.data.educationalInfo.collegeInfo.cgpa);
            setcollege(result.data.educationalInfo.collegeInfo.collegeName);
          } else {
            setexperience(result.data.educationalInfo.professionalInfo.experience);
            setorganization(result.data.educationalInfo.professionalInfo.companyName);
          }

          result.data.userSkills.map(data => {
            dataSourceSkill.push(
              data.skills.id,
            );
          });
          result.data.userDomain.map(data => {
            dataSourceIntr.push(
              data.domain.id,
            );
          });
          result.data.userLanguage.map(data => {
            dataSourceLang.push(
              data.language.id,
            );
          });
          setskillDefSelectValue(dataSourceSkill);
          setlangDefSelectValue(dataSourceLang);
          setInterestDefSelectValue(dataSourceIntr)
          setprogrammingListSelectItemId(dataSourceLang)
          setinterestAreaSelectItemId(dataSourceIntr)
          setskillListSelectItemId(dataSourceSkill)
          setPasswordSet(result.data.passwordSet);
          setIsLoading(true)
        }
      },
      (error) => {

      });
  };
  const getResume = () => {
    apiService(`users/resume/${userId}`, 'get', '', false, jwt,
      result => {
        console.log("✔🔙 Resume GET --> ", result);
        if (result.data.filePath !== null) {
          setResumeFile([{ uid: result.data.id, name: result.data.name, type: result.data.fileType, url: siteUrl + "unsecure/download/" + result.data.id }]);
        }
      },
      (error) => {

      });
  };

  const   handleSubmitProfile = () => {
    setIsLoading(false);
    // let formData = new FormData();
    // formData.append('firstName', firstName)
    // formData.append('lastName', lastName)
    // formData.append('email', email)
    // formData.append('contactNo', mobile)
    apiService(`users/updateinterviewee`, 'put', {
      id: parseInt(userId),
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "contactNo": mobile,
      "userRoleId": "2"
    }, false, jwt,
      result => {
        if (result.data) {
          getProfile();
          Notification.openNotificationSuccess(result.data.message);
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message);
        }
      },
      (error) => {

      });

  };

  const handleSubmitPassword = () => {
    setIsLoading(false);
    apiService(`users/updatepassword`, 'put', { password: oldPassword, newPassword: NewPassword }, false, jwt,
      result => {
        if (result.data.message) {
          Notification.openNotificationSuccess(result.data.message)
          form1.resetFields();
          setIsLoading(true);

        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });

  };

  const handleCreatePassword = () => {
    setIsLoading(false);
    apiService(`login/email/setpassword`, 'post', { password: RepeatPW, token: jwt, userRoleId: '2','email':email }, false, '',
      result => {
        if (result.data.success == true) {
          Notification.openNotificationSuccess(result.data.message)
          form1.resetFields();
          setIsLoading(true);
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });

  };

  const handleSubmitSkill = () => {
    setIsLoading(false);
    const dataSourceSkill = [];
    const dataSourceLang = [];
    const dataSourceIntr = [];
    interestAreaSelectItemId.map(data => {
      dataSourceIntr.push({
        id: data,
      });
    });
    programmingListSelectItemId.map(data => {
      dataSourceLang.push({
        id: data,
      });
    });
    skillListSelectItemId.map(data => {
      dataSourceSkill.push({
        id: data,
      });
    });
    apiService(`users/updateinterviewee`, 'put',
      {
        id: parseInt(userId),
        "educationalInfoDto": {
          "isStudent": isStudent,
          "degree": graduation,
          "specialization": Specialization,
          "graduationYear": year,
          "collegeInfoDto": {
            "cgpa": cgpa,
            "collegeName": college
          },
          "userLang":
            dataSourceLang
          ,
          "userSkills":
            dataSourceSkill,

          "userDomain":
            dataSourceIntr,
        }
      }, false, jwt,
      result => {
        if (result.data.message) {
          Notification.openNotificationSuccess(result.data.message)
          // form.resetFields();    
          setprogrammingListSelectItemId([]);
          setskillListSelectItemId([]);
          setinterestAreaSelectItemId([]);
          getProfile();
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });
  };

  const checkPassword = (rule, value, callback) => {
    if (value && value !== NewPassword) {
      callback("The passwords don't match");
    } else if (oldPassword != "") {
      if (oldPassword == RepeatPW) {
        callback("You are entered old password");
      } else {
        callback();
      }
    }
    else {
      callback();
    }
  };

  const handleCancel = e => {
    e.preventDefault();
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    setFirstName(profileDetails.firstName);
    setLastName(profileDetails.lastName);
    setEmail(profileDetails.email);
    setMobile(profileDetails.contactNo);
    seteducationalInfo(profileDetails.educationalInfo);
    setuserDomain(profileDetails.userDomain);
    setuserLanguage(profileDetails.userLanguage);
    setuserSkills(profileDetails.userSkills);
    setspecialization(profileDetails.educationalInfo.specialization);
    setyear(profileDetails.educationalInfo.graduationYear);
    setgraduation(profileDetails.educationalInfo.degree);
    setIsStudent(profileDetails.educationalInfo.isStudent)
    if (profileDetails.educationalInfo.isStudent == "Y") {
      setcgpa(profileDetails.educationalInfo.collegeInfo.cgpa);
      setcollege(profileDetails.educationalInfo.collegeInfo.collegeName);
    } else {
      setexperience(profileDetails.educationalInfo.professionalInfo.experience);
      setorganization(profileDetails.educationalInfo.professionalInfo.companyName);
    }
  };

  const formChange1 = () => {
    seteditcardShow(1)
  };

  const formChange2 = () => {
    seteditcardShow(2)
  };

  const formChange3 = () => {
    seteditcardShow(3)
  };

  const handleSubmit = () => {
    console.warn("Testing")
    const skills = {
      filterArea: filterArea, filterSkill: filterSkill, filterprglan: filterprglan
    }
    console.log({ form });
    console.log({ skills });
  };

  const onChange = (date, dateString) => {
    console.log(dateString);
    setyear(dateString)
  }

  const handleupload = (e) => {
    setIsLoading(false);
    let allowedFilesMime = [
      "image/png",
      "image/jpeg",
      "image/jpg"
    ];
    var file = e;
    const filetypeValidated = allowedFilesMime.includes(file.type);
    if (filetypeValidated) {
      if (file.size <= 1048576) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        apiJavaService(`secure/userController/updateProfile`, 'put', formData, true, jwt,
          result => {
            if (result.data.SUCCESS === true) {
              Notification.openNotificationSuccess(result.data.MESSAGE)
              getProfile();
            } else {
              setIsLoading(true);
              Notification.openNotificationFaliure(result.data.MESSAGE)
            }
          },
          (error) => {

          });
      }
      else {
        setIsLoading(true);
        Notification.openNotificationFaliure("Image size exceeds limit. Max size: 1 MB.");
      }
    } else {
      setIsLoading(true);
      Notification.openNotificationFaliure('Image file format not supported!');
    }
  };
  const fileDownload = (id) => {
    console.log("File ID --> ", id);
    var url = siteUrl + "unsecure/download/" + id;
    window.open(url, '_self');
  };
  const resumeUpload = (info) => {
    let allowedFilesMime = [
      "application/pdf",
      "text/plain",
      "application/rtf",
      "applicion/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/html",
      "application/vnd.oasis.opendocument.text"
    ];
    console.log("🦆🦆🦆 ", info);
    const filetypeValidated = allowedFilesMime.includes(info.type);
    if (filetypeValidated) {
      if (info.size <= 1048576) {
        setResumeFile([info]);
        let formData = new FormData();
        formData.append('resume', info);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        apiJavaService(`secure/userController/updateResume`, 'put', formData, true, jwt,
          result => {
            if (result.data.SUCCESS === true) {
              // ${e.file.name}
              Notification.openNotificationSuccess(`Resume uploaded successfully`);
            } else {
              // ${e.file.name}
              Notification.openNotificationFaliure(`File upload failed.`);
            }
          },
          (error) => {

          });
      }
      else {
        Notification.openNotificationFaliure("File size exceeds limit. Max size: 1 MB.");
      }
    } else {
      Notification.openNotificationFaliure('File type not supported!');
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <>
      <PageHeader
        ghost
        title="Profile"
      />
      {isLoading == false ?
        <div className="spinner">
          <Spin />
        </div> :
        <Main className='profile-page'>
          <Row gutter={25}>
            <Col xxl={6} lg={8} md={10} xs={24}>
              <ProfileAuthorBox>
                <Cards headless>
                  <div className="author-info">
                    <figure>
                      <img className='profile-img' src={image} alt="" />
                      <Upload accept="image/*" listType="picture" beforeUpload={handleupload}
                        maxCount={1}>
                        <Link to="#">
                          <FeatherIcon icon="camera" size={16} />
                        </Link>
                      </Upload>
                    </figure>
                    <figcaption>
                      <div className="info">
                        <Heading as="h4">{profileDetails.firstName}</Heading>
                        <p>{profileDetails.email}</p>
                      </div>
                    </figcaption>
                  </div>
                  <nav className="settings-menmulist">
                    <ul>
                      <div className='menu-list'>
                        <li onClick={formChange1} >
                          <a className='profilelink'>
                            <FeatherIcon icon="user" size={14} />
                            Edit Profile
                          </a>
                        </li>

                        <li onClick={formChange2} >
                          <a >
                            <FeatherIcon icon="settings" size={14} />
                            Edit Skills
                          </a>
                        </li>
                        <li onClick={formChange3} >
                          <a >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-key"
                            >
                              <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                            </svg>
                            {(passwordSet === "N" || passwordSet === null) ?
                              'Create Password'
                              : 'Change Password'}
                          </a>
                        </li>
                      </div>
                      {/* <li>
              <NavLink to={`${path}/social`}>
                <FeatherIcon icon="users" size={14} />
                Social Profile
              </NavLink>
            </li>
            <li>
              <NavLink to={`${path}/notification`}>
                <FeatherIcon icon="bell" size={14} />
                Notification
              </NavLink>
            </li> */}
                    </ul>
                  </nav>
                </Cards>
              </ProfileAuthorBox>
            </Col>
            {editcardShow == 1 ?
              <Col xxl={18} lg={16} md={14} xs={24}>
                <SettingWrapper>
                  <Cards
                    title={
                      <div className="setting-card-title">
                        <Heading as="h4">Edit Profile</Heading>
                        <span>Set up your personal information</span>
                      </div>
                    }
                  >
                    <Row justify="center">
                      <Col xl={20} lg={20} xs={24}>


                        <BasicFormWrapper>
                          <Form name="editprofile" form={form1} onFinish={handleSubmitProfile}>
                            <Row className='form-alig'>
                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="firstName"
                                  label="First Name"
                                  initialValue={profileDetails.firstName}
                                  rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                  <Input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
                                </Form.Item>

                              </Col>

                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="lastName"
                                  label="Last Name"
                                  initialValue={profileDetails.lastName}
                                  rules={[{ message: 'Please enter your last name!', required: true },{ pattern: /^[^\d]+$/, message: 'Last name cannot contain numbers!' }]}>
                                  <Input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row className='form-alig'>
                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="email"
                                  label="Email"
                                  initialValue={profileDetails.email}
                                >
                                  <Input disabled placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                </Form.Item>
                              </Col>

                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="mobile"
                                  label="Mobile"
                                  initialValue={profileDetails.contactNo}
                                >
                                  <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row className='form-alig'>
                              <Col xxl={24} lg={24} md={24} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="resume"
                                  label="Resume"
                                  initialValue={profileDetails.email}
                                >
                                  <div className='gap'></div>
                                  <Upload
                                    maxCount={1}
                                    fileList={resumeFile}
                                    listType="text"
                                    accept="file/*,application/*"
                                    multiple={false}
                                    beforeUpload={resumeUpload}
                                    onRemove={() => { deleteResume(); setResumeFile([]); }}
                                    onDownload={fileDownload}
                                    name="resumeuploader"
                                    customRequest={dummyRequest}
                                    progress={{
                                      strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                      },
                                      strokeWidth: 3,
                                      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
                                    }}>
                                    <Button className="rsmeupldbtn" icon={<UploadOutlined />}>Click to Upload</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="setting-form-actions">
                              <Button size="default" htmlType="submit" type="primary">
                                Update Profile
                              </Button>
                              &nbsp; &nbsp;
                              <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </BasicFormWrapper>
                      </Col>

                    </Row>
                  </Cards>
                </SettingWrapper>
              </Col> : editcardShow == 3 ?
                (passwordSet === 'N' || passwordSet === null) ?
                  <Col xxl={18} lg={16} md={14} xs={24}>
                    <SettingWrapper>
                      <Cards
                        title={
                          <div className="setting-card-title">
                            <Heading as="h4">Password Settings</Heading>
                            <span>Create your account password</span>
                          </div>
                        }
                      >
                        <Row justify="center">
                          <Col xl={20} lg={20} xs={24}>
                            <BasicFormWrapper>
                              <Form name="editProfile" form={form1} onFinish={handleCreatePassword}>
                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Password"
                                      name="Password"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter password!', required: true }]}>
                                      <Input.Password placeholder='Password' value={NewPassword} onChange={e => SetNewPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>

                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Confirm Password"
                                      name="RepeatPw"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter confirm password!', required: true }, {
                                        validator: (rule, value, callback) => {
                                          if (value && value !== NewPassword) {
                                            callback("The passwords don't match");
                                          } else {
                                            callback();
                                          }
                                        }
                                      }]}>
                                      <Input.Password placeholder='Confirm Password' value={RepeatPW} onChange={e => setRepea(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <div className="setting-form-actions">
                                  <Button size="default" htmlType="submit" type="primary">
                                    Save
                                  </Button>
                                  &nbsp; &nbsp;
                                  <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                    Cancel
                                  </Button>
                                </div>
                              </Form>
                            </BasicFormWrapper>
                          </Col>

                        </Row>
                      </Cards>
                    </SettingWrapper>
                  </Col> : <Col xxl={18} lg={16} md={14} xs={24}>
                    <SettingWrapper>
                      <Cards
                        title={
                          <div className="setting-card-title">
                            <Heading as="h4">Password Settings</Heading>
                            <span>Change or reset your account password</span>
                          </div>
                        }
                      >
                        <Row justify="center">
                          <Col xl={20} lg={20} xs={24}>
                            <BasicFormWrapper>
                              <Form name="editProfile" form={form1} onFinish={handleSubmitPassword}>
                                <Row className='form-alig'>
                                  <Col xxl={24} lg={24} md={24} xs={24} className='paddingcln'>
                                    <Form.Item
                                      name="oldPassword"
                                      label="Old Password"
                                      rules={[{ message: 'Please enter old password!', required: true }]}>
                                      <Input.Password placeholder='Old Password'
                                        value={oldPassword} onChange={e => setoldPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="New Password"
                                      name="NewPassword"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter new password!', required: true }]}>
                                      <Input.Password placeholder='New Password' value={NewPassword} onChange={e => SetNewPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>

                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Re-Type New Password"
                                      name="RepeatPw"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Re-type new password!', required: true }, { validator: checkPassword }]}>
                                      <Input.Password placeholder='Re-type Password' value={RepeatPW} onChange={e => setRepea(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <div className="setting-form-actions">
                                  <Button size="default" htmlType="submit" type="primary">
                                    Save
                                  </Button>
                                  &nbsp; &nbsp;
                                  <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                    Cancel
                                  </Button>
                                </div>
                              </Form>
                            </BasicFormWrapper>
                          </Col>

                        </Row>
                      </Cards>
                    </SettingWrapper>
                  </Col> :
                <Col xxl={18} lg={16} md={14} xs={24}>
                  <SettingWrapper>
                    <Cards
                      title={
                        <div className="setting-card-title">
                          <Heading as="h4">Skill Settings</Heading>
                          <span>SkillEdit</span>
                        </div>
                      }
                    >
                      <Row justify="center">
                        <Col xl={20} lg={20} xs={24}>


                          <BasicFormWrapper>

                            <Form name="editProfile" form={form3} onFinish={handleSubmitSkill}>
                              <Row className='form-alig'>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item label="Graduation"
                                    name="graduation"
                                    initialValue={educationalInfo.degree}
                                    rules={[{ message: 'Please select your graduation!', required: true }]}>
                                    <Select
                                      placeholder="Select Graduation Degree"
                                      value={graduation} onChange={e => setgraduation(e)}
                                      defaultValue={educationalInfo.degree}

                                    >
                                      <Option value="BE">BE</Option>
                                      <Option value="BTECH">BTECH</Option>
                                      <Option value="BCA">BCA</Option>
                                      <Option value="BSC">BSC</Option>
                                      <Option value="MCA">MCA</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="specialization"
                                    label="Specialization"
                                    initialValue={Specialization}
                                    rules={[{ message: 'Please enter your specialization!', required: true }]}>
                                    <Input id='specialization' placeholder="Specialization (Eg: CSE, ECE, EEE)" type='text'
                                      value={Specialization} onChange={e => setspecialization(e.target.value)} />
                                  </Form.Item>
                                </Col>
                              </Row>
                              {isStudent == "Y" ?
                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      name="college"
                                      label="Your College"
                                      initialValue={educationalInfo.collegeInfo.collegeName}
                                      rules={[{ message: 'Please enter your College!', required: true }]}>
                                      <Input id='college' placeholder="Your College" type='text'
                                        value={college} onChange={e => setcollege(e.target.value)} />
                                    </Form.Item>
                                  </Col>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      name="cgpa"
                                      label="CGPA / Percentage"
                                      initialValue={educationalInfo.collegeInfo.cgpa}
                                      rules={[{ message: 'Please enter your CGPA!', required: true }]}>
                                      <Input placeholder="CGPA / Percentage" type='number' id='cgpa'
                                        value={cgpa} onChange={e => setcgpa(e.target.value)} />
                                    </Form.Item>
                                  </Col>
                                </Row> :

                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item initialValue={experience}
                                      name="experience"
                                      label="Your IT Experience"
                                      rules={[{ message: 'Please select your IT Experience!', required: true }]}>
                                      <Select defaultValue={experience}
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select Your IT experience"

                                        value={experience} onChange={e => setexperience(e)}
                                      >
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      name="organization"
                                      label="Organization"
                                      initialValue={educationalInfo.professionalInfo.companyName}
                                      rules={[{ message: 'Please enter your organization!', required: true }]}>
                                      <Input placeholder="Your Present Organization" type='text'
                                        value={organization} onChange={e => setorganization(e.target.value)} />
                                    </Form.Item>
                                  </Col>
                                </Row>}
                              <Row className='form-alig'>


                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>

                                  <Form.Item label="Graduation Year" initialValue={moment(educationalInfo.graduationYear + "-01-01")} name="year" {...config}
                                  >
                                    <DatePicker format="YYYY" defaultValue={moment(educationalInfo.graduationYear + "-01-01")} value={year} onChange={onChange} picker="year" disabledDate={(current) => {
                                      // Disable dates that are after the current year
                                      return current && current.year() > new Date().getFullYear() + 1;
                                    }
                                    } />
                                  </Form.Item>
                                </Col>


                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item initialValue={interestDefSelectValue} name='interestAreaSelectItemId' label="Interested Area" rules={[{ message: 'Select Interested Area!', required: true }]}>
                                    <Select mode="multiple" maxTagTextLength={17} showSearch={false} maxTagCount={1} defaultValue={interestDefSelectValue} value={interestAreaSelectItemId} onChange={e => setinterestAreaSelectItemId(e)} placeholder='Select Interestarea'>
                                      {interestAreaList.map(item => {
                                        return (<Option value={item.id}>{item.type}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className='form-alig'>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item initialValue={langDefSelectValue} name='programmingListSelectItemId' label="Programming Languages" rules={[{ message: 'Please select Programming Languages!', required: true }]}>
                                    <Select mode="multiple" maxTagTextLength={17} showSearch={false} maxTagCount={1} defaultValue={langDefSelectValue} value={programmingListSelectItemId} onChange={e => setprogrammingListSelectItemId(e)} placeholder='Select Programminglanguages'>
                                      {programmingList.map(item => {
                                        return (<Option value={item.id}>{item.name}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item initialValue={skillDefSelectValue}
                                    name='skillListSelectItemId' label="Skills" rules={[{ message: 'Please select Skill!', required: true }]}>
                                    <Select mode="multiple" maxTagTextLength={17} showSearch={false} maxTagCount={1} defaultValue={skillDefSelectValue} value={skillListSelectItemId} onChange={e => setskillListSelectItemId(e)} placeholder='Select Skill'>
                                      {skillList.map(item => {
                                        return (<Option value={item.id}>{item.type}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>

                              </Row>
                              <div className="setting-form-actions">
                                <Button size="default" htmlType="submit" type="primary">
                                  Update
                                </Button>
                                &nbsp; &nbsp;
                                <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                  Cancel
                                </Button>
                              </div>
                            </Form>



                          </BasicFormWrapper>
                        </Col>

                      </Row>
                    </Cards>
                  </SettingWrapper>
                </Col>
            }
          </Row>
        </Main>
      }
    </>
    // <div className="profile_page_container p_10">
    //     {isLoading == false ?
    //         <div className="spinner">
    //             <Spin />
    //         </div> : <div className='profile-container'>
    //             <Form name="profile" form={form1} onFinish={handleSubmit} layout="vertical">
    //                 <Row>
    //                     <Col xs={24} sm={24} md={19} lg={19} xl={19}>
    //                         <h2>My Profile</h2>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={5} lg={5} xl={5} push={1}>
    //                         <div className='btn-div'>
    //                             <Row>
    //                                 <Col xs={12} sm={12} md={10} lg={10} xl={10}>
    //                                     <button id='cancel' >CANCEL</button>
    //                                 </Col>
    //                                 <Col xs={12} sm={12} md={14} lg={14} xl={14} pull={2}>
    //                                     <button id='save' >SAVE CHANGES</button>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>

    //                 <Row>
    //                     <Col xs={24} sm={24} md={20} lg={20} xl={20}>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={6} lg={6} xl={6}>
    //                                     <label for=''>First Name</label>
    //                                     <Form.Item
    //                                         name="firstName"
    //                                         initialValue={profileDetails.firstName}
    //                                         rules={[{ message: 'Please enter your first name!', required: true }]}>
    //                                         <input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
    //                                     </Form.Item>

    //                                 </Col>
    //                                 <Col xs={24} sm={24} md={6} lg={6} xl={6} push={2}>
    //                                     <label for=''>Last Name</label>
    //                                     <Form.Item
    //                                         name="lastName"
    //                                         initialValue={profileDetails.lastName}
    //                                         rules={[{ message: 'Please enter your last name!', required: true }]}>
    //                                         <input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Email ID</label>
    //                                     <Form.Item
    //                                         name="email"
    //                                         initialValue={profileDetails.email}
    //                                         rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
    //                                         <input disabled placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Phone Number</label>
    //                                     <Form.Item
    //                                         name="mobile"
    //                                         initialValue={profileDetails.contactNo}
    //                                         rules={[{
    //                                             type: "regexp",
    //                                             pattern: /^(?:\d*)$/,
    //                                             message: "Value should contain just number"
    //                                         },
    //                                         {
    //                                             min: 10,
    //                                             max: 10,
    //                                             message: "phone number must be 10 digits",
    //                                         },
    //                                         {
    //                                             message: 'Please enter your phone number!',
    //                                             required: true
    //                                         }]}>
    //                                         <input disabled id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={4} lg={4} xl={4} pull={5}>
    //                         <div className='card-header'>
    //                             <Avatar src={userImg} />
    //                             <i><EditOutlined /></i>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             </Form>


    //             <Form name="profile" form={form2} onFinish={handleSubmitPassword} layout="vertical">
    //                 <div className='btn-div'>
    //                     <Row>
    //                         <Col xs={24} sm={24} md={8} lg={8} xl={8}>
    //                             <h3>Do you want change the Password?</h3>
    //                         </Col>
    //                         <Col xs={24} sm={24} md={4} lg={4} xl={4}>
    //                             <button id='change' >Change Password</button>
    //                         </Col>
    //                     </Row>
    //                 </div>

    //                 <Row>
    //                     <Col xs={24} sm={24} md={20} lg={20} xl={20}>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Current Password</label>
    //                                     <Form.Item
    //                                         name="currentpassword"
    //                                         rules={[{ message: 'Please enter your current password!', required: true }]}>
    //                                         <input id='currentpassword' placeholder="Current Password" value={currentpassword} onChange={e => setCPassword(e.target.value)} type='password' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                         <Row>
    //                             <Col xs={24} sm={23} md={20} lg={20} xl={20}>
    //                                 <label for=''>New Password</label>
    //                                 <Form.Item
    //                                     name="password"
    //                                     rules={[{ message: 'Please enter your password!', required: true }]}>
    //                                     <input id='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} type='password' />
    //                                 </Form.Item>
    //                             </Col>
    //                         </Row>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={10} lg={10} xl={10} pull={2}>
    //                         <div class=''>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    //                                     <label for=''>Confirm Password</label>
    //                                     <Form.Item
    //                                         name="newpassword"
    //                                         rules={[{ message: 'Please enter your confirm password!', required: true }, { validator: checkPassword }]}>
    //                                         <input id='newpassword' placeholder="Confirm Password" value={newpassword} onChange={e => setNPassword(e.target.value)} type='password' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>

    //                 <Row className='profile-footer'>
    //                     <Col xs={1} sm={1} md={1} lg={1} xl={1}>
    //                         <Checkbox ></Checkbox>
    //                     </Col>
    //                     <Col xs={22} sm={22} md={22} lg={23} xl={22}>
    //                         <h4>Option in to receive communications related new skills or interview in Skillingo</h4>
    //                     </Col>
    //                 </Row>
    //             </Form>


    //         </div>
    //     }
    // </div>
  );
};

export default Profile;
