import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Modal, Input, DatePicker , AutoComplete } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const Coupon = () => {
    const history = useHistory();
    const { confirm } = Modal;
    const [pagesize, setPagesize] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem('jwt');
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [results, setResults] = useState([]);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleEdit, setisModalVisibleEdit] = useState(false);
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    const [configurationType, setConfigurationType] = useState([]);
    const [days, setDays] = useState("");
    const [percentage, setPercentage] = useState("");
    const { Option } = Select;
    const [configurationTypelist, setconfigurationTypelist] = useState([]);
    const [cancelListhideShow, setcancelListhideShow] = useState(false);
    const [resultsCancel, setResultsCancel] = useState([]);
    const [typeDefSelectValue, settypeDefSelectValue] = useState([]);
    const [rowSelectId, setrowSelectId] = useState("");
    const [typeDefSelectValueName, settypeDefSelectValueName] = useState([]);
    const [couponType, setCouponType] = useState([]);
    const [categoryType, setCategoryType] = useState([]);
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [couponTypeList, setcouponTypeList] = useState([]);
    const [categoryTypeList, setCategoryTypeList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [expiryDate, setexpiryDate] = useState("");
    const [couponTypeDefSelectValue, setcouponTypeDefSelectValue] = useState("");
    const [couponResult, setcouponResult] = useState("");




    useEffect(() => {
        getCouponList(pn, ps);
        getCouponTypes();
        getCouponCategory();
        getEmailList();
    }, []);


    const getCouponTypes = () => {
        apiService(`coupon/type`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setcouponTypeList(result.data);
                }
            },
            (error) => {
            });
    };


    // GET COUPON CATEGORY 
    const getCouponCategory = () => {
        apiService(`coupon/type`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setCategoryTypeList(result.data);
                }
            },
            (error) => {
            });
    };

    // GET Email List 
    const getEmailList = () => {
        let result = {
            data: [
                "manigandan@gmail.com",
                "jagadish.baskaran@gmail.com",
                "sunil@testleaf.com",
                "babu@testleaf.com",
                "bowya.karthi@qeagle.com",
                "hari@testleaf.com"
            ]
        }
        setEmailList(result.data);
        // apiService(`coupon/type`, 'get', '', false, jwt,
        //     result => {
        //         if (result.data) {
        //             setCategoryTypeList(result.data);
        //         }
        //     },
        //     (error) => {
        //     });
    };
    const onEmailSearch = (searchText) => {
        let filteredList = emailList.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
        setEmailOptions(
            !searchText ? [] : filteredList.map(item => {
                return {
                    value: item
                }
            })
        );
      };
      const onEmailSelect = (data) => {
          console.log('onSelect', data);
          
      };
      const onEmailChange = (data) => {
        setValue(data);
      };
    
    const getCouponList = (pageNumber, pageSize) => {
        apiService(`coupon/alllist?` + "pn=" + pageNumber + "&ps=" + pageSize, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.content.map(data => {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            expiryDate: data.expiryDate,
                            couponvalue: data.value,
                            couponTypeId: data.couponType.id,
                            couponTypeName: data.couponType.type,
                        });
                    });
                    setTotalpages(result.data.totalElements);
                    setPagesize(result.data.pagesize);
                    setResults(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };
    const dataSource = [];
    if (results.length)
        results.map(value => {
            const { id, name, couponvalue,couponTypeName,expiryDate } = value;
            var editDeleteBtn =  <Space>
            <Button onClick={() => {
                  handleTableEdit(value);
            }} type="primary" >Edit</Button>

             <Button onClick={() => {
                showConfirm(value);
          }} type="primary" >Delete</Button>

            </Space>
            return dataSource.push({
                key: id,
                name: <span className="date-started">{name}</span>,
                couponTypeName: <span className="date-started">{couponTypeName}</span>,
                couponvalue: <span className="date-started">{couponvalue}</span>,
                expiryDate: <span className="date-finished">{moment(expiryDate).format('DD-MM-YYYY')}</span>,

                action:editDeleteBtn,
            });
        });
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'couponTypeName',
            key: 'couponTypeName',
        },
        {
            title: 'Value',
            dataIndex: 'couponvalue',
            key: 'couponvalue',
        }, {
            title: 'ExpiryDate',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },

    ];
    function showConfirm(item) {
        confirm({
            title: 'Do you want to delete?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                handleTableDelete(item);
            },
            onCancel() {
            },
        });
    }
    const handleTableDelete = (item) => {
        apiService(`coupon/delete/` + item.id, 'delete', '', false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getCouponList(pageDefaultValue, ps);

                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };
    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getCouponList(values, ps)
    };
    const showModal = () => {
        setrowSelectId("");
        form.resetFields();
        setIsModalVisible({ isModalVisible: true });
    };
    const handleTableEdit = (item) => {
        setName("");
        setValue("");
        setexpiryDate("");
        setConfigurationType("");
        setcouponTypeDefSelectValue("");
        onFill(item);
        setcouponResult(item);   
        setName(item.name);
        setValue(item.couponvalue);
        setexpiryDate(item.expiryDate);
        setConfigurationType(item.couponTypeId);
        setcouponTypeDefSelectValue(item.couponTypeId);
        setCouponType(item.couponTypeId);
        setrowSelectId(item.id);
        setisModalVisibleEdit({ isModalVisibleEdit: true });


    };
    const handleCancel = () => {
        form1.resetFields();
        form.resetFields();
        setIsModalVisible(false);
        setisModalVisibleEdit(false);

    };
    const onFill = (data) => {
        form1.setFieldsValue({
          name: data.name,
          value: data.couponvalue,
          expiryDate: moment(data.expiryDate),
          couponTypeDefSelectValue:data.couponTypeId
        });
      };
    const handleSubmit = () => {
        setIsModalVisible(false);
        setisModalVisibleEdit(false);
        setIsLoading(false);
        if (rowSelectId == "") {
            apiService(`coupon/add`, 'post', {
                "name":name,
                "value":value,
                "typeId":couponType,
                "expiryDate":expiryDate
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        Notification.openNotificationSuccess(result.data.message)
                         getCouponList(pageDefaultValue, ps);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else {
            apiService(`coupon/add`, 'post', {
                "id": rowSelectId,
                "name":name,
                "value":value,
                "typeId":couponType,
                "expiryDate":expiryDate
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        form1.resetFields();
                        Notification.openNotificationSuccess(result.data.message)
                        getCouponList(pageDefaultValue, ps);
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        }

    };
    const onChange = (date, dateString) => {
        setexpiryDate(moment(date).format('YYYY-MM-DD'));
    }


    return (
        <>
            <PageHeader ghost title="Coupon"
                buttons={[

                    <Button key="1" onClick={showModal} type="primary" size="default">
                        Add Coupon
                    </Button>
                    //      <Button key="1"  type="primary" size="default">
                    //      Add Coupon
                    //  </Button> 
                ]}

            />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                            <div className='interviewee-container'>
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards title="Coupon List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />

                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                    <Modal title="Add Coupon"
                        visible={isModalVisible}
                        footer={null}
                        // width={550}
                        onCancel={handleCancel}>
                        <Form name="add-configuration" form={form} onFinish={handleSubmit} layout="vertical">

                            <Row>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ message: 'Please enter name!', required: true }]}>
                                        <Input placeholder="Name" value={name} onChange={e => setName(e.target.value)} type='text' />

                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        label="Coupon Type"
                                        name="couponType"
                                        rules={[{ message: 'Please select type!', required: true }]}>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={couponType} onChange={e => setCouponType(e)}
                                            placeholder="Select Coupon Type"
                                        >

                                            {couponTypeList.map(item => {
                                                return (<Option value={item.id}>{item.type}</Option>)
                                            })}


                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        name="value"
                                        label="Value"
                                        rules={[{ message: 'Please enter value!', required: true }]}>
                                        <Input min="1" max={couponType == 4614 ? "100" :null} placeholder="Value" value={value} onChange={e => setValue(e.target.value)} type='number' />
                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item name="expiryDate"
                                        label="ExpiryDate"
                                        rules={[{ message: 'Please select date!', required: true }]} >
                                        <DatePicker  disabledDate={(current) => {
                                            let customDate = moment().format("DD-MM-YYYY");
                                            return current && current < moment(customDate, "DD-MM-YYYY");
                                        }} onChange={onChange} format="DD-MM-YYYY" />

                                    </Form.Item>
                                </Col>
                            </Row>
                            
                            {/* New Row */}
                            <Row>
                            <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                <Form.Item
                                    label="Category"
                                    name="category"
                                    rules={[{ message: 'Please Select Category!', required: true }]} >
                                    <Select
                                        style={{ width: '100%' }}
                                            value={categoryType} onChange={e => setCategoryType(e)}
                                        placeholder="Select Category" >
                                        {categoryTypeList.map(item => {
                                            return (<Option value={item.id}>{item.type}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                {categoryType === 4615 && <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ message: 'Please select email!', required: true }]}>
                                    <AutoComplete
                                        value={value}
                                        options={emailOptions}
                                        style={{
                                        width: 200,
                                        }}
                                        onSelect={onEmailSelect}
                                        onSearch={onEmailSearch}
                                        onChange={onEmailChange}
                                        placeholder="Select Email"
                                    />
                                </Form.Item>}
                            </Col>
                        </Row>


                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>

                    <Modal title="View Coupon"
                        visible={isModalVisibleEdit}
                        footer={null}
                        // width={750}
                        onCancel={handleCancel}>
                        <Form name="add-configuration" form={form1} onFinish={handleSubmit} layout="vertical">
                            <Row>

                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ message: 'Please enter name!', required: true }]}>
                                        <Input id='name' placeholder="Name" value={name} onChange={e => setName(e.target.value)} type='text' />
                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>

                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>

                                    <Form.Item
                                        label="Coupon Type"
                                        name="couponTypeDefSelectValue"
                                        rules={[{ message: 'Please select type!', required: true }]}>
                                        <Select
                                            style={{ width: '100%' }}
                                            defaultValue={couponTypeDefSelectValue}
                                            value={couponType} onChange={e => setCouponType(e)}
                                            placeholder="Select Coupon Type"
                                        >
                                            {couponTypeList.map(item => {
                                                return (<Option value={item.id}>{item.type}</Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item
                                        name="value"
                                        label="Value"
                                        rules={[{ message: 'Please enter value!', required: true }]}>
                                        <Input min="1" max={couponType == 4614 ? "100" :null} placeholder="Value" value={value} onChange={e => setValue(e.target.value)} type='number' />
                                    </Form.Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col xxl={11} lg={11} md={11} xs={24} className='paddingcln'>
                                    <Form.Item name="expiryDate"
                                        label="ExpiryDate"
                                        rules={[{ message: 'Please select date!', required: true }]} >
                                        <DatePicker disabled defaultValue={moment(expiryDate)} disabledDate={(current) => {
                                            let customDate = moment().format("DD-MM-YYYY");
                                            return current && current < moment(customDate, "DD-MM-YYYY");
                                        }} onChange={onChange} format="DD-MM-YYYY" />

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={24} xs={24}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>
                </div>
            </Main>
        </>
    );

}

export default Coupon;
