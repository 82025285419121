import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Spin } from 'antd';
import './setPassword-page.scss';
import 'antd/dist/antd.css';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import loginImg from 'src/assets/imges/loginnew1.png';


import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";


const SetPaasword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    let data = useLocation();
    let paramString = data.search.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [userRoleId, setuserRoleId] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);

    useEffect(() => {
            for (let pair of queryString.entries()) {
                if(pair[0] == "token"){
                    setToken( pair[1])
                } else if(pair[0] == "email") {
                    setEmail( pair[1])
                } else {
                    setuserRoleId(pair[1])
                }
               
            }
            setTimeout(() => {          
                setIsLoading(true);
            }, 100);
    }, []);


    const handleSubmit = () => {
        setisBtnLoading(true);
        console.log(password);
        console.log(confirmPassword);
        console.log(token);
        console.log(email)
        apiService(`login/email/setpassword`, 'post', { password: confirmPassword, token: token, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data.success == true) {
                    history.push({ pathname: '/login'});
                    Notification.openNotificationSuccess(result.data.message)
                } else {
                    setisBtnLoading(false);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    const checkPassword = (rule, value, callback) => {
        if (value && value !== password) {
            callback("The passwords don't match");
        } else {
            callback();
        }
    };




    return (
        <div className="login_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <div class='login-container'>
                    <div class='left-container'>

                        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                            <header>
                                <div class="header-name">
                                    <div className='head-left'>
                                        <h1 className='login-heading'>Set Password</h1>
                                    </div>
                                    <div className='head-right'>
                                        <h4>or <Link to="/login"><span>Login</span></Link></h4>
                                    </div>
                                </div>
                                <div className='form-details'>
                                    <div>
                                        {/* <label >Email</label> */}
                                        <Form.Item
                                            name="password"
                                            rules={[{ message: 'Please enter your password!', required: true }]}>
                                            <Input.Password id='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} type='password' />
                                        </Form.Item>

                                    </div>
                                    <div>
                                        {/* <label for=''>Password</label> */}
                                        <Form.Item
                                            name="confirmPassword"
                                            rules={[{ message: 'Please enter your confirm password!', required: true }, { validator: checkPassword }]}>
                                             <Input.Password id='confirmPassword' placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} type='password' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </header>
                            <footer>
                                <div class='login-footer'>
                                    <Form.Item>
                                        {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large">SUBMIT </Button>}
                                        {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} loading={isBtnLoading} size="large">SUBMIT </Button>}
                                    </Form.Item>
                                </div>
                            </footer>
                        </Form>
                    </div>
                    <div className='right-container'>
                        <h1>Reset Your Path: Click Here to Recover Your Password.</h1>
                        <img src={loginImg} />
                    </div>
                </div>
            }
        </div>
    );
};

export default SetPaasword;
