import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import apiService from "../../../utils/apiService";
import apiService from "../utils/apiService.js";
import Welcome from '@pages/welcome/welcome-page';
import Signup from '@pages/interviewee/signup/signup-page';
import SignIn from '@pages/interviewee/signIn/signIn-page';
import Otp from '@pages/interviewee/otp/otp-page';
import YourSelf from '@pages/interviewee/yourself/yourself-page';
import YourSkill from '@pages/interviewee/yourskill/yourskill-page';
import ProgrammingLanguages from '@pages/interviewee/programmingLanguages/programmingLanguages-page';
import InterestArea from '@pages/interviewee/interestArea/interestArea-page';


// import Dashboard from '@pages/interviewee/dashboard/dashboard-page';
import Interviews from '@pages/interviewee/interviews/interviews-page';
import ScheduledInterview from '@pages/interviewee/scheduledInterview/scheduledInterview-page';
import Schedule from '@pages/interviewee/schedule/schedule-page';
import ReScheduledInterview from '@pages/interviewee/rescheduleInterview/rescheduleInterview-page';
import CancelInterview from '@pages/interviewee/cancelInterview/cancelInterview-page';
import ConfirmedInterview from '@pages/interviewee/confirmedInterview/confirmedInterview-page';
import InterviewProfile from '@pages/interviewee/interviewProfile/interviewProfile-page';
import CompletedInterview from '@pages/interviewee/completedInterview/completedInterview-page';
import AudioTest from '@pages/interviewee/audiotest/audiotest';

import Profile from '@pages/interviewee/profile/profile-page';
import Login from '@pages/login/login-page';
// import Logout from '@pages/interviewee/logout/logout';
import Logout from '@pages/logout/logout';
import setPassword from '@pages/interviewee/setPassword/setPassword-page';
import accountVerify from '@pages/interviewee/setPassword/setPassword-page';

import Interviewer from '@pages/interviewer/my interviewer/myinterviewer';
import Slots from '@pages/interviewer/mySlots/mySlots';
import InterviewerProfile from '@pages/interviewer/profile/profile-page';
import Feedback from '@pages/interviewer/myfeedback/myfeedback';

import Dashboard from '@pages/admin/dashboard/dashboard';
import Interviewee from '@pages/admin/interviewee/interviewee-details';
import interviewer from '@pages/admin/interviewer/interviewer-details';
import Allinterviews from '@pages/admin/interviews/interviews';
import IntervieweeDetails from '@pages/admin/interviewee-details/interviewee-details';
import AdminProfile from '@pages/admin/profile/profile-page';
import CreateInterviewer from '@pages/admin/interviewer/createInterviewer';
import EditInterviewer from '@pages/admin/interviewer/editInterviewer';
import Transaction from '@pages/admin/transaction/transaction';
import Configuration from '@pages/admin/configuration/configuration';
import PrepTest from '@pages/admin/preptest/preptest';
import PrepTestDetails from '@pages/admin/preptest/perptestDetails';
import Coupon from '@pages/admin/coupon/coupon';
// import Coupon from '@pages/admin/coupon/coupon_local';

const HomeRoutes = () => {
	var login = localStorage.getItem('login');
	var userRole = localStorage.getItem('userRole');
	if (login == "true") {
		if (userRole == "INTERVIEWEE") {
			return (
				<Switch>

					<Route path="/schedule" component={Schedule} />
					<Route path="/scheduledinterview" component={ScheduledInterview} />
					<Route path="/rescheduledinterview" component={ReScheduledInterview} />
					<Route path="/cancelinterview" component={CancelInterview} />
					<Route path="/confirmedinterview" component={ConfirmedInterview} />
					<Route path="/interviewerprofile" component={InterviewProfile} />
					<Route path="/completedinterview" component={CompletedInterview} />
					<Route path="/interviews" component={Interviews} />
					<Route path="/profile" component={Profile} />
					<Route path="/audioTest" component={AudioTest} />
					<Route path="/logout" component={Logout} />

					<Route path="/" render={() => <Redirect to="/interviews" />} />
				</Switch>
			);
		} else if (userRole == "INTERVIEWER") {
			const jwt = localStorage.getItem('jwt');
			const userId = localStorage.getItem("userId");
			const localData = localStorage.getItem("loginData");
			const loginData = (localData === undefined || localData === null) ? "" : JSON.parse(localData);
			// if (loginData.approve === 'N' || loginData.approve === null || loginData.approve === undefined) {
				apiService(`myinterview/getInterviewerDetails/${userId}`, 'get', '', false, jwt,
					result => {
						// console.log("🎃🎃🎃I'm Interrviewer --> ", result);
						if (result['data'] === '') {
							loginData['approve'] = 'N';
						} else {
							loginData['approve'] = result['data']['approve'];
						}
						// console.log("🎃localStorage loginData --> ", loginData);
						localStorage.setItem("loginData", JSON.stringify(loginData));
					},
					(err) => {
						console.error(err);
						throw err;
					});
			// }
			return (
				<Switch>
					<Route path="/interviewer/interviews" component={Interviewer} />
					<Route path="/interviewer/myslots" component={Slots} />
					<Route path="/interviewer/profile" component={InterviewerProfile} />
					<Route path="/interviewer/feedback" component={Feedback} />
					<Route path="/logout" component={Logout} />
					<Route path="/" render={() => <Redirect to="/interviewer/interviews" />} />
				</Switch>
			);

		} else {
			return (
				<Switch>
					<Route path="/admin/dashboard" component={Dashboard} />
					<Route path="/admin/interviewee" component={Interviewee} />
					<Route path="/admin/interviews" component={Allinterviews} />
					<Route path="/admin/interviewee-details" component={IntervieweeDetails} />
					<Route path="/admin/interviewer" component={interviewer} />
					<Route path="/admin/profile" component={AdminProfile} />
					<Route path="/admin/addinterviewer" component={CreateInterviewer} />
					<Route path="/admin/editinterviewer" component={EditInterviewer} />
					<Route path="/admin/transaction" component={Transaction} />
					<Route path="/admin/configuration" component={Configuration} />
					<Route path="/admin/preptest" component={PrepTest} />
					<Route path="/admin/perptestdetails" component={PrepTestDetails} />
					<Route path="/admin/coupon" component={Coupon} />
					<Route path="/logout" component={Logout} />
					<Route path="/" render={() => <Redirect to="/admin/dashboard" />} />
				</Switch>
			);
		}



	}
	else {
		return (
			<Switch>
				<Route path="/signin" component={SignIn} />
				<Route path="/signup/:userroleid" component={Signup} />{/* <Route path="/signup" component={Signup} /> */}
				<Route path="/otp" component={Otp} />
				<Route path="/yourself/:userroleid" component={YourSelf} />{/* <Route path="/yourself" component={YourSelf} /> */}
				<Route path="/yourskill/:userroleid" component={YourSkill} />
				<Route path="/programminglanguages/:userroleid" component={ProgrammingLanguages} />
				<Route path="/interestarea/:userroleid" component={InterestArea} />
				<Route path="/login/:userroleid" component={Login} /> {/* <Route path="/login" component={Login} /> */}
				<Route path="/edtechlogin/:edtechemail/:edtechotp" component={Login} />
				<Route path="/logout" component={Logout} />
				<Route path="/setpassword" component={setPassword} />
				<Route path="/accountverify" component={accountVerify} />
				<Route path="/" component={Welcome} />
			</Switch>
		);

	}
};

export { HomeRoutes };
