import './myinterviewer.scss';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Modal, Input, Rate, Popover, Pagination, InputNumber, Tooltip } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { ExclamationCircleOutlined, DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import parse from 'html-react-parser'
import RichTextEditor from 'react-rte';
import siteUrl from "../../../api/apiUrls";

const Interviewer = () => {
  const { confirm } = Modal;
  const jwt = localStorage.getItem('jwt');
  const userId = localStorage.getItem('userId');
  const [results, setResults] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilePreviewModalVisible, setIsFilePreviewModalVisible] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [slotSelectValue, setslotSelectValue] = useState("");
  const [form] = Form.useForm();
  const { Column, ColumnGroup } = Table;
  const [joinNowTrue, setJoinNowTrue] = useState(false);
  const [todayDate, setTodayDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [completedResults, setcompletedResults] = useState([]);
  const [isCompleted, setisCompleted] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [hovered, sethovered] = useState(false);
  const [interviewSelectId, setinterviewSelectId] = useState("");
  const [isModalVisibleFeedBack, setisModalVisibleFeedBack] = useState(false);
  const [isModalVisibleFeedBacknInterviewee, setisModalVisibleFeedBackInterviewee] = useState(false);
  const [visibleIntervieweeDetails, setVisibleIntervieweeDetails] = useState(false);
  const [dateCheckStartEnd, setdateCheckStartEnd] = useState(false);
  const [feedbackValue, setFeedBackValue] = useState(RichTextEditor.createEmptyValue());
  const [contentFeedback, setcontentFeedback] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [skillSelectValue, setskillSelectValue] = useState([]);
  const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
  const [CompanyName, setCompanyName] = useState("");
  const [attitude, setAttitude] = useState("");
  const [communication, setCommunication] = useState("");
  const [problemSolving, setProblemSolving] = useState("");
  const [technical, setTechnical] = useState("");
  const [fitmentRole, setFitmentRole] = useState([]);
  const [pagesize, setPagesize] = useState(0);
  const [totalpages, setTotalpages] = useState(0);
  const [pageDefaultValue, setPageDefaultValue] = useState(1);
  const [pn, setPn] = useState(1);
  const [ps, setTotalPs] = useState(10);
  const [totalpagesCompleted, setTotalpagesCompletd] = useState(0);
  const [pageDefaultValueCompleted, setPageDefaultValueCompletd] = useState(1);
  const [pnCompleted, setPnCompleted] = useState(1);
  const [psCompleted, setTotalPsCompleted] = useState(10);
  const [isCompletedBefore, setisCompletedBefore] = useState(false);
  const [attitudeValue, setAttitudeValue] = useState("");
  const [communicationValue, setCommunicationValue] = useState("");
  const [problemSolvingValue, setProblemSolvingValue] = useState("");
  const [technicalValue, setTechnicalValue] = useState("");
  const [fitmentRoleValue, setFitmentRoleValue] = useState("");
  const [descriptionValue, setdescriptionValue] = useState("");
  const [intervieweeFeedbackValue, setintervieweeFeedbackValue] = useState("");
  const [feedbackValueErr, setfeedbackValueErr] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };
  const onTextInput = value => {
    setFeedBackValue(value);
    if (onChange) {
      onChange(value.toString('html'));
    }
  };
  const { Option } = Select;
  const showModal = () => {
    setIsModalVisible({ isModalVisible: true });
  };
  const showFilePreviewModal = () => {
    setIsFilePreviewModalVisible({ isFilePreviewModalVisible: true });
  };
  const handleOk = () => {
    setIsLoading(false);
    submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setisModalVisibleFeedBack(false)
    setisModalVisibleFeedBackInterviewee(false);
    setIsFilePreviewModalVisible(false);
  };
  const handleDownload = (id) => {
    console.log("File ID --> ", id);
    var url = siteUrl + "unsecure/download/" + id;
    window.open(url, '_self');
  };
  const onChange = (date, dateString) => {
    setDate(moment(date).format('YYYY-MM-DD'));
  }
  const hide = () => {
    sethovered(false);
    setclicked(false);
  };
  const handleHoverChange = visible => {
    sethovered(visible);
    setclicked(false);
  };
  const handleClickChange = visible => {
    sethovered(false);
    setclicked(visible);
  };
  useEffect(() => {
    getInterviewee(pn, ps);
  }, []);
  const getInterviewee = (pageNumber, pageSize) => {
    var apiUrl = "myinterview/alllist/?statusId=48,45" + "&pn=" + pageNumber + "&ps=" + pageSize;
    apiService(apiUrl, 'get', '', false, jwt,
      result => {
        console.log("result");
        console.log(result.data);
        if (result.data) {
          const resultPush = [];
          result.data.content.map(data => {
            resultPush.push({
              key: data.id,
              date: data.date,
              name: data.firstname + " " + data.lastname,
              resumeid: data.resumeId,
              time: data.time,
              status: data.status,
              starturl: data.starturl,
              datetime: data.date + " " + data.time,
              isjoined: data.isjoined,
            });
          });
          resultPush.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          setResults(resultPush);
          setIsLoading(true);
        }
      },
      (error) => {
        console.log("myinterview/alllist Error --> ", error);
        setIsLoading(true);
      });
  };
  const getMyInterviews = (pageNumber, pageSize) => {
    setisCompleted(false);
    setIsLoading(false);
    var apiUrl = "myinterview/alllist/?statusId=48,45" + "&pn=" + pageNumber + "&ps=" + pageSize;
    apiService(apiUrl, 'get', '', false, jwt,
      result => {
        if (result.data) {
          const resultPush = [];
          result.data.content.map(data => {
            resultPush.push({
              key: data.id,
              date: data.date,
              name: data.firstname + " " + data.lastname,
              resumeid: data.resumeId,
              time: data.time,
              status: data.status,
              starturl: data.starturl,
              isjoined: data.isjoined,
              datetime: data.date + " " + data.time,
            });
          });
          resultPush.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          setTotalpages(result.data.totalelements)
          setResults(resultPush);
          setIsLoading(true);
        }
      },
      (error) => {
      });
  };
  const getIntervieweeCompleted = (pageNumber, pageSize) => {
    setIsLoading(false);
    setisCompleted(true);
    var apiUrl = "myinterview/completed?" + "pn=" + pageNumber + "&ps=" + pageSize;
    apiService(apiUrl, 'get', '', false, jwt,
      result => {
        if (result.data) {
          const resultPush = [];
          result.data.content.map(data => {
            resultPush.push({
              key: data.id,
              date: data.date,
              name: data.firstname + " " + data.lastname,
              resumeid: data.resumeId,
              time: data.time,
              status: data.status,
              starturl: data.starturl,
              feedback: data.feedback,
              rating: data.rating,
              skillstoimprove: data.skillstoimprove,
              datetime: data.date + " " + data.time,

            });
          });
          resultPush.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          setTotalpagesCompletd(result.data.totalelements)
          setcompletedResults(resultPush);
          setIsLoading(true);
        }
      },
      (error) => {
      });
  };

  const getResume = (resumeid) => {
    //       return <div>
    //   <Tooltip title="Download Resume">
    //     <DownloadOutlined
    //       style={{ fontSize: '24px' }}
    //       onMouseOver={() => setShowPreview(true)}
    //       onMouseOut={() => setShowPreview(false)}
    //       onClick={()=>{
    //         const link = document.createElement('a');
    //         link.href = siteUrl + "unsecure/download/" + resumeid;
    //         link.setAttribute('download', 'resume.pdf');
    //         document.body.appendChild(link);
    //         link.click();
    //       }}
    //     />
    //   </Tooltip>
    //   {showPreview && (
    //     <iframe
    //       src={siteUrl + "unsecure/view/" + resumeid}
    //       title="Resume Preview"
    //       width="100%"
    //       height="500px"
    //       style={{ border: '1px solid #ccc', marginTop: '10px' }}
    //     />
    //   )}
    // </div>
    return <>
      <Button disabled={resumeid === null} type="ghost" /*onClick={showFilePreviewModal}*/ onClick={() => { handleDownload(resumeid) }} icon={<FilePdfOutlined />}>
        Download
      </Button>
      <Modal
        title="File Preview"
        visible={isFilePreviewModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="download" type="primary" onClick={() => {
            handleDownload(resumeid)
          }}>
            Download
          </Button>,
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <p>{siteUrl + "unsecure/view/" + resumeid}</p>
        <iframe src={siteUrl + "unsecure/view/" + resumeid} width="100%" height="500px" title="File Preview" />
      </Modal>
    </>
  };

  const submit = () => {
    apiService(`myinterview/schedule`, 'post', {
      "scheduleDto": [
        {
          "scheduleDate": date,
          "scheduleTime": time
        },
      ]
    }, false, jwt,
      result => {
        if (result.data.message) {
          Notification.openNotificationSuccess(result.data.message)
          setIsModalVisible(false);
          setIsLoading(true);
        } else {
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });
  }
  const handleTableChange = (item) => {
    setIsLoading(false);
    Notification.openNotificationSuccess("Please Be Patient.. Redirecting to Webex");
    apiService(`myinterview/join`, 'put', {
      "id": item.key,
    }, false, jwt,
      result => {
        console.log("🔴🟡🟢 --> ", JSON.stringify(result, null, 2));
        if (result.data.success == true) {
          // Notification.openNotificationSuccess(result.data.message);
          setIsLoading(true);
          openZoom(result.data.starturl);
        } else {
          Notification.openNotificationFaliure(result.data.message);
        }
      },
      (error) => {

      });

    // if (item.starturl != null) {
    //   zoomMeetJoined(item);
    //   openZoom(item.starturl);
    // } else {
    //   Notification.openNotificationFaliure("Contact admin...!Not created webex url")
    // }
  };
  const openZoom = (url) => {
    window.open(url, 'popUpWindow', 'height=500,width=500,left=400,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')

  };
  const zoomMeetJoined = (item) => {
    apiService(`myinterview/join`, 'put', {
      "id": item.key,
    }, false, jwt,
      result => {
        if (result.data.success == true) {
          Notification.openNotificationSuccess(result.data.message);
        } else {
          Notification.openNotificationFaliure(result.data.message);
        }
      },
      (error) => {

      });
  };
  function showConfirm(item, check) {
    form.resetFields();
    setDescription("")
    if (check == "Y") {
      setisCompletedBefore({ isCompletedBefore: true })
    }
    setslotSelectValue(item);
    confirm({
      title: 'Do you want to complete this interview?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setIsModalVisible({ isModalVisible: true });
      },
      onCancel() {
      },
    });
  }
  const handleSubmit = () => {
    if (validate(feedbackValue)) {
      setIsLoading(false);
      setIsModalVisible(false);
      apiService(`myinterview/skillstoimprove`, 'put', {
        id: slotSelectValue.key,
        "attitude": attitude,
        "communication": communication,
        "problemSolving": problemSolving,
        "technical": technical,
        "fitmentToRole": fitmentRole,
        "description": feedbackValue._cache.html
      }, false, jwt,
        result => {
          if (result.data.success == true) {
            Notification.openNotificationSuccess(result.data.message);
            if (isCompletedBefore == false) {
              getInterviewee(pageDefaultValue, ps);
            } else {
              getIntervieweeCompleted(pageDefaultValueCompleted, psCompleted);
            }
          } else {
            setIsLoading(true);
            Notification.openNotificationFaliure(result.data.message)
          }
        },
        (error) => {

        });
    }
  }
  const validate = (setfeedbackValue) => {
    setfeedbackValueErr(false);
    var valid = true;
    if (setfeedbackValue._cache.html == null || setfeedbackValue._cache.html == "<p><br></p>") {
      setfeedbackValueErr(true);
      valid = false;
    }
    return valid;
  }
  function CompareTime(date, time) {
    // moment(now).format("YYYY-MM-DD HH:mm:ss")

    // var currentDate= new Date("2022-03-31")  ; // format hh:mm
    let now = new Date();
    var currentDate = moment(now).format("YYYY-MM-DD")
    var scheduleDate = new Date(date);
    var dateCheck = endAfterStart(currentDate, scheduleDate);
    // setdateCheckStartEnd(dateCheck);
    if (dateCheck == true) {
      Object.prototype.twoDigits = function () {
        return ("0" + this).slice(-2);
      }
      var minutesToAdd = 10;
      var sheduleDateAndTime = date + " " + time;
      var currentDateTime = new Date(sheduleDateAndTime);
      var time30Plus = new Date(currentDateTime.getTime() + minutesToAdd * 60000);
      var time30Min = new Date(currentDateTime.getTime() - minutesToAdd * 60000);
      var startTime = moment(time30Min).format("HH:mm");
      var endTime = moment(time30Plus).format("HH:mm");
      let timeOfDay = now.getHours().twoDigits() + ':' + now.getMinutes().twoDigits();
      if (startTime <= timeOfDay && timeOfDay <= endTime) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  function CompareTimeCompleted(date, time) {
    // moment(now).format("YYYY-MM-DD HH:mm:ss")

    // var currentDate= new Date("2022-03-31")   ; // format hh:mm
    let now = new Date();
    var currentDate = moment(now).format("YYYY-MM-DD")
    var scheduleDate = new Date(date);
    var dateCheck = endAfterStartCheck(currentDate, scheduleDate);
    // setdateCheckStartEnd(dateCheck);
    if (dateCheck == true) {
      Object.prototype.twoDigits = function () {
        return ("0" + this).slice(-2);
      }
      var minutesToAdd = 30;
      var sheduleDateAndTime = date + " " + time;
      var currentDateTime = new Date(sheduleDateAndTime);
      var time30Plus = new Date(currentDateTime.getTime() + minutesToAdd * 60000);
      var time30Min = new Date(currentDateTime.getTime() - minutesToAdd * 60000);
      var startTime = moment(time30Min).format("HH:mm");
      var endTime = moment(time30Plus).format("HH:mm");
      let timeOfDay = now.getHours().twoDigits() + ':' + now.getMinutes().twoDigits();
      if (endTime <= timeOfDay) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  function CompareTimeInterviewsCompletedGreater(date, time) {
    let now = new Date();
    var currentDate = moment(now).format("YYYY-MM-DD")
    var scheduleDate = new Date(date);
    var dateCheck = endAfterStartCompletedInterviewsGreater(currentDate, scheduleDate);
    if (dateCheck == true) {

      return true;

    } else {
      return false
    }
  }
  function endAfterStartCompletedInterviewsGreater(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);
    return endDate.getTime() < startDate.getTime();
  }
  function endAfterStart(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);

    return endDate.getTime() == startDate.getTime();
  }
  function endAfterStartCheck(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);

    return endDate.getTime() == startDate.getTime();
  }

  const dataSource = [];

  if (results.length) {
    results.map(value => {
      const { id, name, date, time, starturl, status, resumeid, isjoined } = value;
      var joinNowBtn = <Space><Button disabled   >JOINNOW</Button></Space>
      var date1 = new Date(todayDate);
      var todayTime = moment().format("HH:mm:ss");
      var timeCheck = CompareTime(date, time);
      var timeCheckCompleted = CompareTimeCompleted(date, time);
      var timeCheckCompletedgreaterDate = CompareTimeInterviewsCompletedGreater(date, time);
      var date2 = new Date(value.date);
      if (timeCheck == true) {
        joinNowBtn =
          <Space>
            <Button onClick={() => {
              handleTableChange(value);
            }} type="primary" >JOINNOW</Button>
            <Button disabled={isjoined === "Y" ? false : true} onClick={() => {
              showConfirm(value, "N");
            }} type={isjoined === "Y" ? "primary" : "light"} >COMPLETED</Button>
          </Space>
      } 
      else if ((timeCheckCompleted == true || timeCheckCompletedgreaterDate == true) && isjoined === "Y") {
        joinNowBtn =
          <Space>
            <Button onClick={() => {
              showConfirm(value, "N");
            }} type="primary" >COMPLETED</Button>
          </Space>


      } else {
        joinNowBtn =
          <Space>
            <Button disabled type="light" >JOINNOW</Button>
            <Button disabled type="light" >COMPLETED</Button>
          </Space>
      }

      return dataSource.push({
        key: id,
        name: <span className="date-started" onClick={() => {
          intervieweeDeatilsOpen(value);
        }}>{name}</span>,
        date: <span className="date-started">{moment(date).format('DD-MM-YYYY')}</span>,
        time: <span className="date-finished">{moment(date + " " + time).format('hh:mm A')}</span>,
        status: <span className="date-finished">{status}</span>,
        resume: getResume(resumeid),
        action: joinNowBtn,
      });
    });
  }
  const completedDataSource = [];
  if (completedResults.length) {
    completedResults.map(value => {
      const { id, name, date, time, starturl, status, feedback, rating, skillstoimprove, resumeid } = value;
      var feedbackByInterviwee = "";
      var feedbackByInterviwer = "";
      var feedbackBtn = <Space><Button onClick={() => {
        feedbackPopOpen(value);
      }} type="primary"   >View</Button></Space>
      if (skillstoimprove == null) {
        feedbackBtn = <Button onClick={() => {
          showConfirm(value, "Y");
        }} type="primary" >Feedback</Button>
      }
      return completedDataSource.push({
        key: id,
        name: <span className="date-started" onClick={() => {
          intervieweeDeatilsOpen(value);
        }}>{name}</span>,
        date: <span className="date-started">{moment(date).format('DD-MM-YYYY')}</span>,
        time: <span className="date-finished">{moment(date + " " + time).format('hh:mm A')}</span>,
        feedback: feedbackByInterviwer
        ,
        feedbackByInterviwer: feedbackByInterviwee
        ,
        rating: <span className="date-finished"><Rate disabled value={rating} /></span>,
        resume: getResume(resumeid),
        action: feedbackBtn,
      });
    });
  }
  const feedbackPopOpen = (value) => {
    setAttitudeValue(value.skillstoimprove.attitude);
    setCommunicationValue(value.skillstoimprove.communication);
    setdescriptionValue(value.skillstoimprove.description);
    setFitmentRoleValue(value.skillstoimprove.fitmentToRole);
    setTechnicalValue(value.skillstoimprove.technical);
    setProblemSolvingValue(value.skillstoimprove.problemSolving);
    setintervieweeFeedbackValue(value.feedback)
    setisModalVisibleFeedBack({ isModalVisibleFeedBack: true });
  }
  const intervieweeDeatilsOpen = (value) => {
    // setVisibleIntervieweeDetails({ setVisibleIntervieweeDetails: true });
  }
  const completedColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    // {
    //   title: 'Feedback',
    //   dataIndex: 'feedback',
    //   key: 'feedback',
    // },
    // {
    //   title: 'Feedback By Interviewer',
    //   dataIndex: 'feedbackByInterviwer',
    //   key: 'feedbackByInterviwer',
    // },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Resume',
      dataIndex: 'resume',
      key: 'resume',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    }
  ];
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Resume',
      dataIndex: 'resume',
      key: 'resume',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];
  const handlePagination = (values) => {
    setIsLoading(false);
    setPageDefaultValue(values)
    getInterviewee(values, ps)
  };
  const handlePaginationCompleted = (values) => {
    setIsLoading(false);
    setPageDefaultValueCompletd(values)
    getIntervieweeCompleted(values, ps)
  };
  return (
    <>
      {!isCompleted ?
        <PageHeader ghost title="MY INTERVIEWS"
          buttons={[
            <Button key="1" type="primary" onClick={() => {
              getIntervieweeCompleted(pnCompleted, psCompleted)
            }} size="default">
              COMPLETED INTERVIEWS
            </Button>,
          ]} /> : <PageHeader ghost title="MY INTERVIEWS"
            buttons={[
              <Button key="1" type="primary" onClick={() => {
                getMyInterviews(pn, ps)
              }} size="default">
                MY INTERVIEWS
              </Button>,
            ]} />}
      <Main>
        <div className='interviewer_page'>
          {isLoading == false ?
            <div className="spinner">
              <Spin />
            </div> :
            <>
              <Row gutter={25}>
                {!isCompleted ?
                  <Col xs={24}>
                    <Cards title="Interviews List">
                      <ProjectList>
                        <div className="table-responsive">
                          <Table dataSource={dataSource} columns={columns} pagination={false} />
                          <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                        </div>
                      </ProjectList>
                    </Cards>
                  </Col>
                  : <Col xs={24}>
                    <Cards title="Completed List">
                      <ProjectList>
                        <div className="table-responsive">
                          <Table dataSource={completedDataSource} columns={completedColumns} pagination={false} />
                          <Pagination hideOnSinglePage={true} current={pageDefaultValueCompleted} onChange={handlePaginationCompleted} total={totalpagesCompleted} />
                        </div>
                      </ProjectList>
                    </Cards>
                  </Col>}
              </Row>
            </>
          }
        </div>
        <Modal title="Completed Interview"
          visible={isModalVisible}
          footer={null}
          centered
          width={750}
          onCancel={handleCancel}>
          <Form className='richtext-feedback interviewer-form' name="slot" form={form} onFinish={handleSubmit} layout="vertical">
            <Row>
              <Col span={7}>
                <Form.Item
                  name="attitude"
                  label="Attitude (1/5)"
                  rules={[{
                    message: 'Enter value between 1 to 5', type: 'number', min: 1,
                    max: 5
                  }, { message: 'Please enter your Attitude!', required: true }]}>
                  <InputNumber placeholder="Attitude" type='number' value={attitude} onChange={e => setAttitude(e)} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  name="communication"
                  label="Communication (1/5)"
                  rules={[{
                    message: 'Enter value between 1 to 5', type: 'number', min: 1,
                    max: 5
                  }, { message: 'Please enter your Communication!', required: true }]}>
                  <InputNumber placeholder="Communication" type='number' value={communication} onChange={e => setCommunication(e)} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}> <Form.Item
                name="problemSolving"
                label="Problem Solving (1/5)"
                rules={[{
                  message: 'Enter value between 1 to 5', type: 'number', min: 1,
                  max: 5
                }, { message: 'Please enter your Problem Solving!', required: true }]}>
                <InputNumber placeholder="Problem Solving" type='number' value={problemSolving} onChange={e => setProblemSolving(e)} />
              </Form.Item></Col>

            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="technical "
                  label="Technical (1/5)"
                  rules={[{
                    message: 'Enter value between 1 to 5', type: 'number', min: 1,
                    max: 5
                  }, { message: 'Please enter your Technical!', required: true }]}>
                  <InputNumber placeholder="Technical" type='number' value={attitude} onChange={e => setTechnical(e)} />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item
                  name="fitmentRole"
                  label="Fitment to the Role"
                  rules={[{ message: 'Please select your FitmentRole!', required: true }]}>
                  <Select
                    style={{ width: '100% ' }}
                    placeholder="Select FitmentRole"
                    value={fitmentRole} onChange={e => setFitmentRole(e)}
                  >
                    <Option value="Eligible">Eligible </Option>
                    <Option value="Not Eligible">Not Eligible</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <RichTextEditor placeholder='Enter your feedback' toolbarConfig={toolbarConfig} value={feedbackValue} onChange={onTextInput} />
                {feedbackValueErr == true ?
                  <p className='text-error' >Please your feedback!</p> : null}

              </Col>
            </Row>
            <Row>
              <Col sm={24} xs={24}>
                <div className="sDash_form-action mt-20 fl-right" >
                  <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                    Cancel
                  </Button>
                  <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

        </Modal>
        <Modal title={null}
          visible={isModalVisibleFeedBack}
          footer={null}
          width={750}
          hea
          onCancel={handleCancel}>

          <Row className='feedback-interviwrer'>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <div className="interviewer_feedback">
                <Row>
                  <Col span={24}>
                    <h3>My Feedback</h3>
                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Attitude</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    <label for=''>{attitudeValue}</label>
                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Communication</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    <label for=''>{communicationValue}</label>
                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Problem Solving</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    <label for=''>{problemSolvingValue}</label>
                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Technical</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    <label for=''>{technicalValue}</label>
                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Fitment To Role</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    {fitmentRoleValue}

                  </Col>
                </Row>
                <Row>
                  <Col span={9}>
                    <label for=''>Description</label>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={14}>
                    <p className='feedback-interviwer'>{parse(descriptionValue)}
                    </p>

                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <div className="interviewer_feedback">
                <Row>
                  <Col span={24} >
                    <h3 >Interviewee Feedback</h3>
                  </Col>
                </Row>
                <Row>

                  <Col span={14}>
                    {intervieweeFeedbackValue != null ?
                      <p className='feedback-interviwer'>{parse(intervieweeFeedbackValue)}
                      </p> : <p className='feedback-interviwer'>Interviewee will be giving your feedback…!
                      </p>}

                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Details"
          centered
          visible={visibleIntervieweeDetails}
          onClick={() => setVisibleIntervieweeDetails(false)}
          onCancel={() => setVisibleIntervieweeDetails(false)}
          onOk={handleOk}
          width={850}
          okText="Save"
          footer={null}
        >
          <div className='modalmain'>
            <Form className='interviewer-form' form={form} name="nest-messages" layout="vertical">
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                  >
                    <text>{firstName}</text>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"

                  >
                    <text>{lastName}</text>

                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="email"
                    label="Email"

                  >
                    <text>{email}</text>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="ContactNo"
                    label="Mobile"

                  >
                    <text>{contactNo}</text>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="degree"
                    label="Degree"

                  >
                    <text>BE</text>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="specification"
                    label="specification"

                  >
                    <text>CSE</text>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="year"
                    label="Year"

                  >
                    <text>2018</text>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="cgpa"
                    label="CGPA"

                  >
                    <text>65</text>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="college"
                    label="College"

                  >
                    <text>GCE Thanajavur</text>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="experiance"
                    label="Experiance"

                  >
                    <text>3.5yr</text>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="CompanyName"
                    label="Organization"

                  >
                    <text>{CompanyName}</text>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="skillDefSelectValue"
                    label="Skill"
                  >
                    <text>{skillDefSelectValue}</text>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

      </Main>
    </>
  );
}

export default Interviewer;
