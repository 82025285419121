import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './welcome-page.scss';
import welcomelogo from 'src/assets/imges/welcomepage.png'
import welcomeimg from 'src/assets/imges/welcomepage1.png'
// import welcomerate from 'src/assets/imges/welcome-rating.png'
import welcomefb from 'src/assets/imges/welcome-facebook.png'
import welcomegogle from 'src/assets/imges/welcome-google.png'
import welcomeslack from 'src/assets/imges/welcome-slack.png'
import welcomemicro from 'src/assets/imges/welcome-microsoft.png'
import welcomedashboard from 'src/assets/imges/welcome-dashboard.jpg'
import profileimage1 from 'src/assets/imges/card-image1.jpg'
import profileimage2 from 'src/assets/imges/card-image2.jpg'
import profileimage3 from 'src/assets/imges/card-image3.jpg'
import pro from 'src/assets/imges/welcome-pro1.jpg'
import semicolon from 'src/assets/imges/semi-colon.PNG'
import { Col, Row } from 'antd';
import { Button, Card, Badge, Layout, Dropdown, Menu, Space, Tooltip } from 'antd';

const Welcome = () => {
	const history = useHistory();
	const handleclick = () => {
		history.push({ pathname: '/login/interviewee' });
	};


	useEffect(() => {
		localStorage.clear();
	}, []);


	return (
		<Layout>
			<div className='welcome-page'>
				<Row>
					<Col xs={9} sm={8} md={12} lg={12} xl={12} span={10}>
						<div className='logodiv' > <img className='welcome-logo' src={welcomelogo} alt="logo" /></div>
					</Col>
					<Col xs={8} sm={6} md={10} lg={10} xl={12} span={10} id='signIn-btn-col'>
						{/* <Form.Item name='filterSkillList' rules={[{ message: 'Please select Skills!', required: false }]}> */}
						<Dropdown action={['click']} overlay={<Menu>
							<Menu.Item onClick={() => { history.push('/login/interviewee'); }} key="1">Interviewee</Menu.Item>
							<Menu.Item onClick={() => { history.push('/login/interviewer'); }} key="2">Interviewer</Menu.Item>
							{/* <Menu.Item onClick={() => { history.push('/login/admin'); }} key="3">Admin</Menu.Item> */}
						</Menu>} placement="bottomRight" >
							<Button className="signIn-btn" type="primary">
								Sign In
							</Button>
						</Dropdown>
						{/* </Form.Item> */}
					</Col>
				</Row>


				<div className='homebanner'>
					<Row>

						<Col xs={24} sm={24} md={12} lg={8} xl={8} span={12} className="homebanner_left">
							<h1 className='welcome-text'>Crack your <span>next interview</span> <span>with confidence.</span></h1>
							<p>A simplified mock interview platform with 1:1 real  experienced interviewers of your choice.</p>
							<Button className="welcome-btn" type="primary" onClick={handleclick}>Attend Free Interview</Button>

						</Col>

						<Col xs={24} sm={24} md={12} lg={12} xl={12} span={12} className="homebanner_right">
							<img className='welcome-img' src={welcomeimg} />
						</Col>
					</Row>
				</div>


				<div className="section2">
					<Row className='align-item-center'>
						<Col span={6} >
							<Card className='cardstyle' style={{ height: 280, width: 300, }}>
								<div>
									<h1>4.9</h1>
									<img className='img-align' src="https://media.swipepages.com/2020/2/trustpilot-rating.svg" alt="" />

									<h2>Trust Pilot score</h2>
									<h4>810 Reviews</h4>
								</div>
							</Card>

						</Col>

						<Col span={18} >
							<div className="trustdiv">
								<h5 className='trusttitle'>Trusted by <span>5,000+</span> happy tech team members since 2020</h5>
								<Col className='logodiv1' xs={6} sm={6} md={24} lg={24} xl={24} span={18}>
									<img className='image-align' src={welcomegogle} />
									<img className='image-align' src={welcomeslack} />
									<img className='image-align' src={welcomemicro} />
									<img className='image-align' src={welcomefb} />
								</Col>
							</div>

						</Col>
					</Row>
				</div>

				<div className='section3'>
					<div className="title1div">
						<h3 className="title1">What you get from Skilingo?</h3>
						<p className="titlepara">The features that help you to crack your next interview guaranteed.</p>
					</div>
					<div className='firstrow'>
						<Row className='sub-row'>
							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card className='subb-card' >
									<Row  >
										<Col span={4} className='icon_right_div' >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="m PC SD YE"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="rgba(37,124,255,1)"></path></svg>
										</Col>
										<Col span={20} >
											<div className='logopara'>
												<h2>Choose Your Interviewer</h2>
												<p>A interviewer from one of chosen companies you are interested.</p>
											</div>

										</Col>
									</Row>


								</Card>
							</Col>

							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card className='subb-card'>
									<Row>
										<Col span={4} className='icon_right_div' >
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="m PC SD YE"><title>chat</title><path d="M5.8 12.2v-6.2h-3.8c-1.1 0-2 0.9-2 2v6c0 1.1 0.9 2 2 2h1v3l3-3h5c1.1 0 2-0.9 2-2v-1.82c-0.064 0.014-0.132 0.021-0.2 0.021l-7-0.001zM18 1h-9c-1.1 0-2 0.9-2 2v8h7l3 3v-3h1c1.1 0 2-0.899 2-2v-6c0-1.1-0.9-2-2-2z" fill="rgba(37,124,255,1)"></path></svg>
										</Col>
										<Col span={20} >
											<div className='logopara'>
												<h2>60 Minutes Interview</h2>
												<p>Best-in-class interviews with a simulation for you to improve your interview skills</p></div>
										</Col>

									</Row>


								</Card></Col>

							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card className='subb-card'>
									<Row>
										<Col span={4} className='icon_right_div' >
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="m PC SD YE"><title>funnel</title><path d="M10 1c-4.908 0-8 1.512-8 3.001v2c0 0.918 6 6 6 6v6c-0.001 0.684 1 1 2 1s2.001-0.316 2-1v-6c0 0 6-5.082 6-6v-2c0-1.489-3.092-3.001-8-3.001zM10 6.123c-3.591-0.001-6.138-1.333-6.138-1.831-0.002-0.495 2.548-1.831 6.138-1.829 3.59-0.002 6.14 1.334 6.138 1.828 0 0.499-2.547 1.831-6.138 1.832z" fill="rgba(37,124,255,1)"></path></svg>
										</Col>
										<Col span={20} >
											<div className='logopara' ><h2>Actionable Feedback</h2 >
												<p>Dedicated feedback time post the mock interview to get critical takeways</p>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>

						</Row>
					</div>

					{/* 2nd row */}
					<div className='secondrow'>
						<Row className='sub-row'>
							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card className='subb-card'>
									<Row >
										<Col span={4} className='icon_right_div' > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="m PC SD YE"><path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 16.171875 21 L 14.171875 19 L 5 19 L 5 9 L 19 9 L 18.998047 14.171875 L 21 16.171875 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 7 L 5 7 L 5 5 z M 7 11 L 7 13 L 9 13 L 9 11 L 7 11 z M 11 11 L 11 13 L 17 13 L 17 11 L 11 11 z M 7 15 L 7 17 L 9 17 L 9 15 L 7 15 z M 11 15 L 11 17 L 13 17 L 13 15 L 11 15 z M 15 15 L 15 17 L 20.146484 22.146484 L 22.146484 20.146484 L 17 15 L 15 15 z M 22.853516 20.853516 L 20.853516 22.853516 L 21.853516 23.853516 C 22.048516 24.048516 22.365547 24.048516 22.560547 23.853516 L 23.853516 22.560547 C 24.048516 22.364547 24.048516 22.048516 23.853516 21.853516 L 22.853516 20.853516 z" fill="rgba(37,124,255,1)"></path></svg> </Col>
										<Col span={20}>
											<div className='logopara' >
												<h2>Access to Questions</h2>
												<p>Stop searching for questions  on the internet and let the interviewer ask them directly</p>
											</div>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}><Card className='subb-card'>
								<Row>
									<Col span={4} className='icon_right_div' > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="m PC SD YE"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" fill="rgba(37,124,255,1)"></path></svg> </Col>
									<Col span={20}><div className='logopara' ><h2>Interview Prep Guide</h2>

										<p>Detailed 30 to 90 days actionable interview preparation guide.</p></div>

									</Col>
								</Row>


							</Card>
							</Col>

							<Col span={8} className="plr15" xs={24} sm={24} md={12} lg={8} xl={8}><Card className='subb-card'>
								<Row>
									<Col span={4} className='icon_right_div' > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="m PC SD YE"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" fill="rgba(37,124,255,1)"></path></svg> </Col>
									<Col span={20} >
										<div className='logopara'>
											<h2>Job Opportunities</h2>

											<p>Access to Job opportunities by your interest, skill and ratings </p>
										</div>
									</Col>
								</Row>


							</Card>
							</Col>

						</Row>
					</div>
					<div className='buttonint'>
						<Button className='btn-int' type='primary' onClick={handleclick}> <b>Start Your First Interview</b></Button>
					</div>

				</div>



				{/* section4 */}
				<div className='section4'>

					<Row>
						<Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
							<img src={pro} />
							<h1 className='text-align3'>Way of working</h1>
							<p>Here are 5 simple steps, how skilingo works for you !</p>
							<ul >

								<li className='ul-align'>1. Sign up and choose your skill(s) of interview</li>

								<li className='ul-align'>2. Choose your interviewer of choice </li>

								<li className='ul-align'>3. Schedule the appointment and Pay</li>

								<li className='ul-align'>4. Attend Interview live on the Platform</li>

								<li className='ul-align'>5. Get Feedback & Revise</li>
							</ul>

						</Col>
						<Col span={12} >
							<img className='dash-image' src={welcomedashboard} />
						</Col>
					</Row>
				</div>

				{/* section4 */}

				{/* section5 */}
				<div className='section5'>
					<div className="site-card-wrapper">
						<Row gutter={0}>
							<Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card style={{ height: 450, width: 400 }} bordered={false} className='profile-card'>
									<ul>
										<li > <img className='semi-colon' src={semicolon} alt="" /></li>
										<li> <img className='profile-img' src={profileimage1} /> </li>

										<li>
											<p className='para-align'>
												“We were excel files and sharing notes earlier. Moving to Sales Close helped our sales process become more efficient.”
											</p>
										</li>
										<li className='margin-left'>
											<h1>Jofra Archer</h1>
											<p>ASB Dealers</p>

										</li>
									</ul>
								</Card>
							</Col>
							<Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card style={{ height: 450, width: 400 }} bordered={false} className='profile-card'>
									<ul>
										<li > <img className='semi-colon' src={semicolon} alt="" /></li>
										<li> <img className='profile-img' src={profileimage2} /> </li>

										<li>
											<p className='para-align'>
												“It’s simplicity simply blows away the competition. Onboarding new sales team members is a breeze”
											</p>
										</li>
										<li className='margin-left'>
											<h1>Maria D Souza</h1>
											<p>Auto Fasteners Ltd</p>
										</li>
									</ul>
								</Card>
							</Col>
							<Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
								<Card style={{ height: 450, width: 400 }} bordered={false} className='profile-card'>
									<ul>
										<li > <img className='semi-colon' src={semicolon} alt="" /></li>
										<li> <img className='profile-img' src={profileimage3} /> </li>

										<li>
											<p className='para-align'>
												“The smart lead scoring and predictive calling feature has helped us increase our conversions by 200%”
											</p>
										</li>
										<li className='margin-left'>
											<h1>Mike Danton</h1>
											<p>Kirk Pipes Ltd</p>

										</li>
									</ul>
								</Card>
							</Col>
						</Row>
					</div>


					<div className='end-card'>
						<Card className='end-card1'>
							<h3>READY TO TAKE ACTION?</h3>
							<h1>Crack your next interview.</h1>
							<h2>We designed mock interview process that can help  you to identify pain
								points in the interview process. Together, we shaped the new standard.</h2>

							<Button className='btn-align' onClick={handleclick}>Start Today</Button>
						</Card>

					</div>
				</div>
				{/* section5 */}
			</div>
		</Layout>
	)
}

export default Welcome
