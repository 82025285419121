import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Spin, Checkbox } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './yourskill-page.scss';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined,ArrowLeftOutlined, UserOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";



import otpImg from 'src/assets/imges/yourskillnew.png';
const menus = [
    {
        icon: <ArrowRightOutlined />,
    }
];




const { Meta } = Card;

const YourSkill = () => {
    const history = useHistory();
    const location = useLocation();
    const userSkills = localStorage.getItem('userSkills');
    // let yourself = location.state.yourself;
    // let programminglanguages = location.state.programminglanguages;
    // console.log(yourself);
    // console.log(programminglanguages);
    const jwt = localStorage.getItem('jwt');
    const dispatch = useDispatch();
    const [skillList, setSkillList] = useState([]);
    const [skillListAdd, setSkillListAdd] = useState([]);
    const [selectItemId, setSelectItemId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectIdList, setSelectIdList] = useState([]);
    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");



    useEffect(() => {
        if (userSkills != null) {
            const userkillDetails = JSON.parse(userSkills);
            console.log(userkillDetails)
            setSelectIdList(userkillDetails);
        }

        getSkillList();
    }, []);


    const getSkillList = () => {
        apiService(`signup/skilllist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    let userkillDetails =""
                    if (userSkills != null) {
                     userkillDetails = JSON.parse(userSkills);
                    console.log(userkillDetails)
                    }
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            name: data.type,
                            check: false,
                        });
                    });

                    console.log(resultPush);
                    for (var i = 0; i < resultPush.length; i++) {
                        for (var k = 0; k < userkillDetails.length; k++) {
                            if (resultPush[i].id == userkillDetails[k].id) {
                                resultPush[i].check = true;
                                break;
                            }
                        }
                    }
                    console.log(resultPush);
                    setSkillListAdd(resultPush);
                    setSkillList(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {

            });
    };


    const handleSubmit = () => {
        if (selectIdList.length > 0) {
            Notification.openNotificationSuccess("Details updated!")
            localStorage.setItem('userSkills', JSON.stringify(selectIdList));
            // history.push({ pathname: '/interestarea' });
            userRoleId === '2' ? history.push({ pathname: '/interestarea/interviewee' }) : history.push({ pathname: '/interestarea/interviewer' }) 
        } else {
            Notification.openNotificationFaliure("Pick one more skills that you possess!")
        }

    };

    const handleClick = (id, check) => {
        if (!check) {
            selectIdList.push({
                id: id,
            });
        } else {
            if (selectIdList.length > 0) {
                var index = selectIdList.indexOf(id)
                selectIdList.splice(index, 1);
            }
        }

        const resultPush = [];
        if (selectIdList.length > 0) {
            skillList.map(data => {
                if ((id == data.id || data.check == true)) {
                    if (id == data.id && check == true) {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: false,

                        });
                    } else {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: true,

                        });
                    }
                } else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,

                    });
                }

            });
        } else {
            skillListAdd.map(data => {
                if (id == data.id || data.check == true) {
                    if (check == false) {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: true,

                        });
                    } else {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: false,

                        });
                    }

                } else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,

                    });
                }
            });

        }



        setSkillList(resultPush);
        console.log(selectIdList)

    }

    return (
        <div className="yourskill_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <div class='yourskill-container'>
                    <div class='left-container'>
                        <header>
                            <div class="header-name">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <h2>Your Skills / Expertise </h2>
                                    </Col>
                                </Row>
                            </div>
                            <div class="header-name">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <h4>Pick one or more below skills that you posses</h4>
                                    </Col>
                                </Row>
                            </div>
                            <div class='card'>

                                {/* <Row>
                                    {skillList.map(item => {
                                        return (
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Card className={item.check == true ? 'ant-card-select' : ''} onClick={() => handleClick(item.id, item.check)}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} push={15}>
                                                            {item.check == true ?
                                                                <i > <CheckCircleTwoTone twoToneColor="#52c41a" /></i>
                                                                : null}
                                                        </Col>
                                                    </Row>
                                                    <Meta
                                                        description={item.name}
                                                    />
                                                </Card>
                                            </Col>
                                        )
                                    })
                                    }
                                </Row> */}
                                {/* <Row gutter={[16,0]}>
                                    {skillList.map((item) => (
                                        <Col key={item.id} xs={24} sm={12} md={8} lg={8} xl={8}>
                                        <Card
                                            className={item.check ? 'ant-card-select' : ''}
                                            onClick={() => handleClick(item.id, item.check)}
                                        >
                                            {item.check && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                            <Meta description={item.name} style={{"marginTop":'-15px'}}/>
                                        </Card>
                                        </Col>
                                    ))}
                                </Row> */}
                                <div className="skills-list">
                                    {skillList.map((item) => (
                                    <div
                                        key={item.id}
                                        className={`skill-box ${item.check ? 'selected' : ''}`}
                                        onClick={() => handleClick(item.id, item.check)}
                                    >
                                        <span className="skill-text">{item.name}</span>
                                        {item.check && <span className="tick">✔</span>}
                                    </div>
                                    ))}
                                </div>
                                <footer>
                                    <div class='footer_details'>
                                        <Link to={userRoleId === '2' ? "/programminglanguages/interviewee" : "/programminglanguages/interviewer"}> <Button type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button></Link>
                                        <Button onClick={handleSubmit} htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large">NEXT STEP </Button>
                                    </div>
                                </footer>
                            </div>

                        </header>
                        {/* <footer>
                            <div class=''>
                                <Row >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Link to="/programminglanguages"><button id='resend'>BACK   <i><ArrowLeftOutlined /></i></button></Link>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <button id='next' onClick={handleSubmit}>NEXT STEP   <i><ArrowRightOutlined /></i></button>
                                    </Col>
                                </Row>
                            </div>
                        </footer> */}
                    </div>
                    <div class='right-container'>
                        <h1>A degree can just earn the job, but t cannot help to grow further without the skill</h1>
                        <img src={otpImg} />
                    </div>
                </div>
            }
        </div>
    );
};

export default YourSkill;