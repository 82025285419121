import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './scheduledInterview-page.scss';
import 'antd/dist/antd.css';
import { Row, Col, Button, Radio, Input, Space, Card, Select, Rate, Avatar, Image, Steps, Modal, Form, Spin, Tabs, Typography } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRightOutlined, AudioOutlined, ExclamationCircleOutlined, CameraOutlined } from '@ant-design/icons';
import { Main, Main2 } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import fullstack from '../../../../src/assets/imges/landscape_img.png';
import apiService from "../../../utils/apiService";
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import Notification from "../../../components/notification/notification";
import ReactPlayer from 'react-player'
import AudioTest from "../audiotest/audiotest";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PrepTestDetailsWrapper } from '../../admin/style';
import siteUrl from "../../../api/apiUrls";

const { Title, Paragraph } = Typography;


const ScheduledInterview = () => {
    let data = useLocation();
    const history = useHistory();
    const jwt = localStorage.getItem('jwt');
    const audiotest = localStorage.getItem("audiotest");
    console.log(audiotest)
    let interviewrData = data.state.data;
    console.log(interviewrData)
    const { confirm } = Modal;

    const { Search } = Input;
    const { Meta } = Card;
    const { Option } = Select;
    const { Step } = Steps;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [similarList, setSimilarList] = useState([]);
    const [paymetStatus, setPaymetStatus] = useState("");
    const [joinNowTrue, setJoinNowTrue] = useState(false);
    const [todayDate, setTodayDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [assigendStatus, setassigendStatus] = useState(3);
    const [title, setTitle] = useState("");
    const [consent, setConsent] = useState("Y");
    const [isLoading, setIsLoading] = useState(false);
    const [paynowHideShow, setPaynowHideShow] = useState(false);
    const [noShowbtn, setnoShowbtn] = useState(false);
    const { TabPane } = Tabs;
    const [audioCheck, setaudioCheck] = useState(false);
    const [cameraCheck, setcameraCheck] = useState(false);
    const [scheduleRsult, setscheduleRsult] = useState("");
    const [preptestCheck, setpreptestCheck] = useState(false);
    const [results, setResults] = useState([]);
    const [yourAmount, setyourAmount] = useState(0);
    const [rescheduleAmount, setrescheduleAmount] = useState("");
    const [cancelAmount, setcancelAmount] = useState("");
    const [reschedulePercentage, setreschedulePercentage] = useState("");
    const [cancelAmountPercentage, setcancelAmountPercentage] = useState("");
    const [interviewsCompleted, setinterviewsCompleted] = useState(false);
    const [preptestDocAndOtherUrl, setpreptestDocAndOtherUrl] = useState("");
    const [youtubeFound, setYoutubeFound] = useState(false);

    useEffect(() => {
        localStorage.removeItem("audiotest");
        localStorage.removeItem("rescheduleAmount");
        localStorage.removeItem("refundAmount");
        localStorage.removeItem("rescheduleId");
        localStorage.removeItem("slotId");
        localStorage.removeItem("refTransactionId");
        localStorage.removeItem("rescheduleReason");
        getInterviewDetails(interviewrData.id);
        getRescheduleAllType();
        // setTitle("SCHEDULE INTERVIEW" + "   |  " + interviewrData.companyname + "   |   " + moment(interviewrData.date).format('DD MMM') + "    |   " + moment(interviewrData.date + " " + interviewrData.time).format('hh:mm A'))
        // var date1 = new Date(todayDate);
        // var date2 = new Date(interviewrData.date);
        // console.log(date1)
        // console.log(date2)
        // var timeCheck = CompareTime(interviewrData.date, interviewrData.time);
        // if (timeCheck == true) {
        //     setJoinNowTrue(true);
        // }
        // var currentDate = moment().format('YYYY-MM-DD');
        // var sheduleDate = moment(interviewrData.date).subtract(1, 'd').format('YYYY-MM-DD');
        // var dateCheckNoShowBtn = CompareTimeNoShowBtn(currentDate, sheduleDate);
        // var completedInterviewsTime = CompareTimeInterviewsCompleted(interviewrData.date, interviewrData.time);
        // console.log(completedInterviewsTime)
        // var completedInterviewsTimeCurrentDataGreater = CompareTimeInterviewsCompletedGreater(interviewrData.date, interviewrData.time);

        // if (dateCheckNoShowBtn == true) {
        //     setnoShowbtn(true);
        // }
        // if (completedInterviewsTime == true || completedInterviewsTimeCurrentDataGreater == true) {
        //     setinterviewsCompleted(true);
        // }

    }, []);

    const openAudioTest = () => {
        history.push({ pathname: '/audiotest' });
    }
    const handleConsent = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const openVideoTest = () => {

    }
    const handleVideoTest = () => {
        console.log("test");
        setpreptestCheck(true);
    }

    const getRescheduleAllType = (pageNumber, pageSize) => {
        apiService(`refund/all`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    result.data.map(data => {
                        if (data.paymentType.name == "RESCHEDULE") {
                            setreschedulePercentage(data.percentage)

                        } else if (data.paymentType.name == "CANCELED") {
                            setcancelAmountPercentage(data.percentage)
                        }
                    });
                    console.log(result.data);
                }
            },
            (error) => {
            });
    };


    const submitAudiVideoCheck = () => {
        var audiotesting = localStorage.getItem("audiotest");
        console.log(audiotesting);
        if (audiotesting == "true" && cameraCheck == true) {
            setIsLoading(false);
            apiService(`myinterview/statusupdate`, 'put', {
                "id": interviewrData.id,
                "statusId": 49
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        getInterviewDetails(interviewrData.id);
                        Notification.openNotificationSuccess(result.data.message)
                    } else {
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else {
            if (audiotesting != "true") {
                Notification.openNotificationFaliure("Audio Check Failure...Go to recorded and audio testing!")
            } else if (cameraCheck == false) {
                Notification.openNotificationFaliure("Camera Check Failure...Go to click picture!")

            }
        }


    }

    const submitPreptest = () => {
        console.log("YOUTUBE Condition ---------->>>>>> ", preptestCheck);
        console.log("YOUTUBE Condition 2 ---------->>>>>> ", youtubeFound);
        if (preptestCheck === true || youtubeFound === false) {
            setIsLoading(false);
            apiService(`myinterview/statusupdate`, 'put', {
                "id": interviewrData.id,
                "statusId": 48
            }, false, jwt,
                result => {
                    if (result.data.success == true) {

                        apiService(`myinterview/meetinglink/` + interviewrData.id, 'get', '', false, jwt,
                            result => {
                                if (result.data) {
                                    // getInterviews(pageDefaultValue, ps);
                                    getInterviewDetails(interviewrData.id);
                                    Notification.openNotificationSuccess(result.data.message)
                                }
                            },
                            (error) => {

                            });
                    } else {
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else {
            Notification.openNotificationFaliure("Prep Test video watch first!")
        }
    }

    const submitBeginInterview = () => {
        setIsLoading(false);
        apiService(`myinterview/statusupdate`, 'put', {
            "id": interviewrData.id,
            "statusId": 45,
            "videoConsent": consent
        }, false, jwt,
            result => {
                if (result.data.success == true) {
                    getInterviewDetails(interviewrData.id);
                    Notification.openNotificationSuccess(result.data.message)
                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });


    }


    const getInterviewDetails = (scheduleId) => {
        console.log("🐸🐸", "I'm INSIDE THE API CALL");
        apiService(`schedule/details/` + scheduleId, 'get', '', false, jwt,
            result => {
                console.log("🐸🐸🐸🐸🐸🐸", result);
                if (result.data) {
                    // setTitle("SCHEDULED INTERVIEW" + "  |  " + interviewrData.companyname + "   |   " + moment(result.data.date).format('DD MMM') + "    |   " + moment(result.data.date + " " + result.data.time).format('hh:mm A'));
                    setTitle("SCHEDULED INTERVIEW" + "   |   " + moment(result.data.date).format('DD MMM') + "    |   " + moment(result.data.date + " " + result.data.time).format('hh:mm A'));
                    setscheduleRsult(result.data);
                    if (result.data.transaction.discountAmount == null) {
                        setyourAmount(result.data.transaction.amountPaid + 0);
                    } else {
                        setyourAmount(result.data.transaction.amountPaid + result.data.transaction.discountAmount);
                    }
                    if (result.data.status === "INTERVIEWER ASSIGNED") {
                        setassigendStatus(1);
                        setPaynowHideShow(true);
                        setIsLoading(true)
                    } else if (result.data.status === "PAYMENT COMPLETED") {
                        setassigendStatus(2);
                        setIsLoading(true)
                    } else if (result.data.status === "SYSTEM CHECK") {
                        setassigendStatus(3);
                        getpreptest(interviewrData.skills.id);
                    } else if (result.data.status === "PREP TEST") {
                        setassigendStatus(4);
                        setIsLoading(true)
                    } else if (result.data.status === "BEGIN INTERVIEW") {
                        setassigendStatus(5);
                        setIsLoading(true)
                    }
                    var date1 = new Date(todayDate);
                    var date2 = new Date(result.data.date);
                    console.log(date1)
                    console.log(date2)
                    var timeCheck = CompareTime(result.data.date, result.data.time);
                    if (timeCheck == true && result.data.isjoined === "Y") {
                        setJoinNowTrue(true);
                    }
                    var currentDate = moment().format('YYYY-MM-DD');
                    var sheduleDate = moment(result.data.date).subtract(1, 'days').format('YYYY-MM-DD');
                    var dateCheckNoShowBtn = CompareTimeNoShowBtn(currentDate, sheduleDate);
                    var completedInterviewsTime = CompareTimeInterviewsCompleted(result.data.date, result.data.time);
                    console.log(" completedInterviewsTime ", completedInterviewsTime);
                    var completedInterviewsTimeCurrentDataGreater = CompareTimeInterviewsCompletedGreater(result.data.date, result.data.time);
                    if (dateCheckNoShowBtn == true) {
                        setnoShowbtn(true);
                    }
                    console.log("completedInterviewsTimeCurrentDataGreater --> ", completedInterviewsTimeCurrentDataGreater);
                    if (completedInterviewsTime === true || completedInterviewsTimeCurrentDataGreater === true) {
                        setinterviewsCompleted(true);
                    }
                    // setIsLoading(true)
                    console.log("💦💦 DATE CHECK NO SHOW BUTTON --> ", dateCheckNoShowBtn);
                    console.log("💫💨NO SHOW BUTTON --> ", noShowbtn);
                    console.log("💨💦 timeCheck --> ", timeCheck);
                }
            },
            (error) => {
                console.error("🤬🤬", error);
            });
    };

    const getpreptest = (id) => {
        apiService(`preptest/skill/` + id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setResults([]);
                    const resultPush = [];
                    result.data.prepTestLink.map(res => {
                        if (res.type != "YOUTUBE") {
                            resultPush.push({
                                id: res.id,
                                url: res.url,
                                type: "Others",

                            });
                        }
                        else {
                            if (res.type !== null && res.type.toUpperCase() === 'YOUTUBE') {
                                setYoutubeFound(true);
                            }
                        }
                    });
                    result.data.prepTestDoc.map(res => {
                        resultPush.push({
                            id: res.files.id,
                            name: res.files.name,
                            type: "Documents"
                        });
                    })
                    console.log(resultPush)
                    setpreptestDocAndOtherUrl(resultPush);
                    setResults(result.data);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    function CompareTime(date, time) {
        // moment(now).format("YYYY-MM-DD HH:mm:ss")
        // var currentDate= new Date("2022-03-19")  ; // format hh:mm
        let now = new Date();
        var currentDate = moment(now).format("YYYY-MM-DD")
        // console.log(currentDate)
        console.log("date -->", date);
        var scheduleDate = new Date(date);
        var dateCheck = endAfterStart(currentDate, scheduleDate);
        if (dateCheck === true) {
            Object.prototype.twoDigits = function () {
                return ("0" + this).slice(-2);
            }
            var minutesToAdd = 10;
            var sheduleDateAndTime = date + " " + time;
            var currentDateTime = new Date(sheduleDateAndTime);
            var time30Plus = new Date(currentDateTime.getTime() + minutesToAdd * 60000);
            var time30Min = new Date(currentDateTime.getTime() - minutesToAdd * 60000);
            var startTime = moment(time30Min).format("HH:mm");
            var endTime = moment(time30Plus).format("HH:mm");
            let timeOfDay = now.getHours().twoDigits() + ':' + now.getMinutes().twoDigits();
            console.log("timeOfDay --> ", timeOfDay);
            console.log(startTime);
            console.log(endTime);
            if (startTime <= timeOfDay && timeOfDay <= endTime) {
                return true;
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function CompareTimeInterviewsCompleted(date, time) {
        // moment(now).format("YYYY-MM-DD HH:mm:ss")
        // var currentDate= new Date("2022-03-19")  ; // format hh:mm
        let now = new Date();
        var currentDate = moment(now).format("YYYY-MM-DD")
        // console.log(currentDate)
        // console.log(date)
        var scheduleDate = new Date(date);
        var dateCheck = endAfterStartCompletedInterviews(currentDate, scheduleDate);
        if (dateCheck == true) {
            Object.prototype.twoDigits = function () {
                return ("0" + this).slice(-2);
            }
            var minutesToAdd = 30;
            var sheduleDateAndTime = date + " " + time;
            var currentDateTime = new Date(sheduleDateAndTime);
            var time30Plus = new Date(currentDateTime.getTime() + minutesToAdd * 60000);
            var time30Min = new Date(currentDateTime.getTime() - minutesToAdd * 60000);
            var startTime = moment(time30Min).format("HH:mm");
            var endTime = moment(time30Plus).format("HH:mm");
            let timeOfDay = now.getHours().twoDigits() + ':' + now.getMinutes().twoDigits();
            // console.log(timeOfDay);
            // console.log(startTime);
            // console.log(endTime);
            if (endTime <= timeOfDay) {
                return true;
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function CompareTimeInterviewsCompletedGreater(date, time) {
        let now = new Date();
        var currentDate = moment(now).format("YYYY-MM-DD")
        var scheduleDate = new Date(date);
        var dateCheck = endAfterStartCompletedInterviewsGreater(currentDate, scheduleDate);
        if (dateCheck == true) {

            return true;

        } else {
            return false
        }
    }

    function endAfterStartCompletedInterviewsGreater(start, end) {
        var startDate = new Date(start);
        var endDate = new Date(end);
        return endDate.getTime() < startDate.getTime();
    }

    function endAfterStartCompletedInterviews(start, end) {
        var startDate = new Date(start);
        var endDate = new Date(end);
        return endDate.getTime() <= startDate.getTime();
    }

    function endAfterStart(start, end) {
        var startDate = new Date(start);
        var endDate = new Date(end);
        return endDate.getTime() == startDate.getTime();
    }

    function CompareTimeNoShowBtn(start, end) {
        var startDate = new Date(start);
        var endDate = new Date(end);
        console.log("START --> ", start, "::: ", startDate.getTime());
        console.log("END --> ", end, "::: ", endDate.getTime());
        return endDate.getTime() <= startDate.getTime();
    }

    const getAllStatus = () => {
        apiService(`users/statuslist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    console.log(result.data)
                }
            },
            (error) => {

            });
    };

    const openZoom = () => {
        var url = scheduleRsult.joiurl;
        if (url != null) {
            submitBeginInterview();
            setIsModalVisible(false);
            window.open(url, 'popUpWindow', 'height=500,width=500,left=400,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        } else {
            setIsModalVisible(false);
            Notification.openNotificationFaliure("Contact admin...Not created Webex url")
        }
        // window.parent.location.href = "https://us05web.zoom.us/s/83800033458?zak=eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6InFjdkg3ZGhfVHBhWkFYM01vdG43Y1EiLCJpc3MiOiJ3ZWIiLCJzayI6IjAiLCJzdHkiOjEsIndjZCI6InVzMDUiLCJjbHQiOjAsIm1udW0iOiI4MzgwMDAzMzQ1OCIsImV4cCI6MTY0NTYzMTU1MiwiaWF0IjoxNjQ1NjI0MzUyLCJhaWQiOiJleFJ4ZTJ5YlM1V0RVNlN5VVgzY253IiwiY2lkIjoiIn0.-TuGqW__sSFndtYjofCQsLZDgkowWLKgiR3EoR5OCkU";
    };

    function showConfirmAfterPayment() {
        console.log(scheduleRsult)
        let amt = (yourAmount * (cancelAmountPercentage / 100));
        let refundAmount = yourAmount - amt;
        localStorage.setItem('refundAmount', refundAmount.toFixed(0));
        const text1 = 'Do you want to cancel interview ?';
        const text = <div> <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                Paid Amount
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                ₹{yourAmount}
            </Col>
        </Row>
            <Row className='mt-10'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    Cancel Charges
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    ₹{amt.toFixed(0)}
                </Col>
            </Row>
            <Row className='mt-10'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    Refund Amount
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    ₹{refundAmount.toFixed(0)}
                </Col>
            </Row>
        </div>
        confirm({
            title: <div><>{text1}</>
                <div className='mt-10'><>{text}</></div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                history.push({ pathname: '/cancelinterview', state: { data: interviewrData, interviewerId: interviewrData.userid, interviewStatus: scheduleRsult.status } });

            },
            onCancel() {
            },
        });
    }

    function showConfirmCancel() {
        confirm({
            title: 'Do you want to interview cancel?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                cancelInterview();
            },
            onCancel() {
            },
        });
    }

    const cancelInterview = () => {
        setIsLoading(false);
        apiService(`myinterviews/cancel`, 'put', { id: interviewrData.id, }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviews' });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    function showConfirmNoShow() {
        const text1 = 'Payment will not be refunded if the interview can be cancelled.';
        const text = <div className='mt-10'>
            Do you confirm to no show?
        </div>
        confirm({
            title: <div><>{text1}</>
                <div className='mt-10'><>{text}</></div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                noShowInterview();
            },
            onCancel() {
            },
        });
    }

    const noShowInterview = () => {
        setIsLoading(false);
        apiService(`myinterviews/noshow`, 'put', { id: interviewrData.id, }, false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    history.push({ pathname: '/interviews' });
                    setIsLoading(true);
                } else {
                    setIsLoading(true);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    function showConfirmRe() {
        let amt = (yourAmount * (reschedulePercentage / 100));
        var refundAmt = yourAmount - amt;
        localStorage.setItem('rescheduleAmount', amt.toFixed(0));
        localStorage.setItem('refundAmount', refundAmt.toFixed(0));

        const text1 = 'Do you want to reschedule interview?';
        const text = <div> <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                Paid Amount
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                ₹{yourAmount}
            </Col>
        </Row>
            <Row className='mt-10'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    Reschedule Charges
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    ₹{amt.toFixed(0)}
                </Col>
            </Row>
        </div>
        confirm({
            title: <div><>{text1}</>
                <div className='mt-10'><>{text}</></div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                history.push({ pathname: '/rescheduledinterview', state: { data: interviewrData, interviewerId: interviewrData.userid, interviewStatus: scheduleRsult.status } });

            },
            onCancel() {
            },
        });
    }

    function showConfirmPaynow() {
        localStorage.setItem('slotId', interviewrData.id);
        confirm({
            title: 'Do you want to paynow?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                history.push({ pathname: '/confirmedinterview', state: { data: interviewrData, selectValue: interviewrData, assignedPaynow: "Y", interviewerId: interviewrData.userid } });

            },
            onCancel() {
            },
        });
    }

    function callback(key) {
        console.log(key);
    }

    function handleTakePhoto(dataUri) {
        if (dataUri != null) {
            Notification.openNotificationSuccess("Camera test successful!")
            setcameraCheck(true);
        } else {
            Notification.openNotificationSuccess("camera test failed!")
        }
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    const fileUrlOpen = (item) => {
        var url = item.url;
        window.open(url);
    };

    const fileDownload = (item) => {
        var url = siteUrl + "unsecure/download/" + item.id;
        window.open(url, '_self');
    };

    const fileOpenUrl = (item) => {
        var url = item.url;
        window.open(url);
    };

    return (
        <>
            <PageHeader
                ghost
                title={title}
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/interviewee/interviews" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <>
                    <Main>

                        <div className="scheduledInterview_page_container p_10">
                            <div className='scheduledInterview-container'>
                                <div className='scheduledInterview-title'>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {/* <h2>ScheduledInterview | {interviewrData.companyname} | {moment(interviewrData.date).format('DD MMM')} {interviewrData.time}</h2> */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className='scheduledInterview-status'>
                                    <Row>
                                        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                            {interviewrData.companyfileid != null ? <img src={siteUrl + "" + "unsecure/view/" + interviewrData.companyfileid} alt="" />
                                                : <img alt="example" src={fullstack} />
                                            }

                                        </Col>
                                        <Col xs={24} sm={24} md={2} lg={2} xl={2} push={1}>
                                            <div className="assigned-details">
                                                <h4>{interviewrData.companyname}</h4>
                                                {/* <h2>{interviewrData.level}</h2> */}
                                                <h5>Interviewer {interviewrData.firstname} {interviewrData.lastname} has been assigned</h5>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={13} lg={13} xl={13} push={1}>
                                            <div className='stpes-completed-large'>
                                                <Steps size="small" current={assigendStatus}>
                                                    <Step description="INTERVIEWER ASSIGNED" />
                                                    <Step description="PAYMENT COMPLETED" />
                                                    <Step description="SYSTEM CHECK" />
                                                    <Step description="PREP TEST" />
                                                    <Step description="BEGIN INTERVIEW" />
                                                </Steps>
                                            </div>
                                            <div className='stpes-completed-small'>
                                                <Steps size="small" direction="vertical" current={assigendStatus}>
                                                    <Step description="INTERVIEWER ASSIGNED" />
                                                    <Step description="PAYMENT COMPLETED" />
                                                    <Step description="SYSTEM CHECK" />
                                                    <Step description="PREP TEST" />
                                                    <Step description="BEGIN INTERVIEW" />
                                                </Steps>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={3} lg={3} xl={3} push={1}>
                                            {paynowHideShow == false ?
                                                <div className='btn-div'>
                                                    {joinNowTrue == false ?
                                                        <>
                                                            {noShowbtn === false || (scheduleRsult.isjoined === "N" && interviewsCompleted === true) ?
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                                        <Button type="primary" id='resend' onClick={showConfirmRe}>Reschedule <i><ArrowRightOutlined /></i></Button>
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                                        <div ><Button type='light' onClick={showConfirmAfterPayment} id='cancel' >Cancel <i><ArrowRightOutlined /></i></Button></div>
                                                                    </Col>
                                                                </Row> :
                                                                <Row>
                                                                    {interviewsCompleted == false ?
                                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="no-show-btn">
                                                                            <Button id='resend' onClick={showConfirmNoShow}>NO SHOW<i><ArrowRightOutlined /></i></Button>
                                                                        </Col>
                                                                        : null}
                                                                </Row>
                                                            }
                                                        </>
                                                        :
                                                        <Row>
                                                            <Col xs={12} sm={12} md={24} lg={24} xl={24} className="no-show-btn">
                                                                <Button type='primary' id='joinnow-btn' size='large' onClick={handleConsent}>Join Now<i><ArrowRightOutlined /></i></Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            Download and Install Webex for better Interview process!</Col>
                                                    </Row>

                                                </div>
                                                :
                                                <div className='btn-div'>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                            <button id='resend' onClick={showConfirmPaynow}>Pay now<i><ArrowRightOutlined /></i></button>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                            <div ><button onClick={showConfirmCancel} id='cancel' >Cancel<i><ArrowRightOutlined /></i></button></div>
                                                        </Col>

                                                    </Row>
                                                </div>}
                                        </Col>
                                    </Row>
                                </div>



                            </div>
                        </div>
                    </Main>
                    {scheduleRsult.status == "SYSTEM CHECK" || scheduleRsult.status == "PAYMENT COMPLETED" ?
                        <Main2 className='systemcheck-container'>
                            <div className="scheduledInterview_page_container p_10">
                                <div className='scheduledInterview-container'>
                                    <div className='scheduledInterview-title similar-interviews'>
                                        {scheduleRsult.status == "PAYMENT COMPLETED" ?
                                            <>
                                                <div class="header-name preptest-header">
                                                    <div className='head-left  '>
                                                        <h3>SYSTEM CHECK</h3>
                                                    </div>
                                                    <div className='head-right'>
                                                        <Button type='primary' size='large' onClick={submitAudiVideoCheck}>COMPLETE SYSTEM CHECK </Button>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                        {/* <h1>Audio Test</h1> */}
                                                        <AudioTest />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={9} lg={9} xl={9} className="camera-captrue">
                                                        {/* <h1>Audio Test</h1> */}
                                                        <Camera
                                                            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                                        />
                                                    </Col>
                                                    {/* <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <Button type='primary'  className='audio-check' size='default' onClick={openVideoTest}>Camera Test <i className='audio-icon'><CameraOutlined /></i></Button>
                                </Col> */}
                                                    {/* <Col xs={24} sm={24} md={6} lg={6} xl={6} className="submit-btn-align-audio">
                                                            <Button type='primary' size='large' onClick={submitAudiVideoCheck}>SUBMIT </Button>
                                                        </Col> */}
                                                </Row>
                                            </>

                                            : null}
                                        {
                                            scheduleRsult.status == "SYSTEM CHECK" ?
                                                <>
                                                    <div className='similar-interviews'>
                                                        <div class="header-name preptest-header">
                                                            <div className='head-left  '>
                                                                <h3>PREP TEST</h3>
                                                            </div>
                                                            <div className='head-right'>
                                                                <Button type='primary' size='large' onClick={submitPreptest}>COMPLETE PREPTEST </Button>
                                                            </div>
                                                        </div>
                                                        <Row>

                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                <h3>Prep Test Videos</h3>
                                                                {results.prepTestLink.map((data, i) =>
                                                                    <>
                                                                        {data.type == "YOUTUBE" ?
                                                                            <Card className='video-card'>
                                                                                {/* <Row>
                                                                            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                                                <div>
                                                                                    <h3>TCS</h3>
                                                                                </div>
                                                                            </Col>
                                                                        </Row> */}
                                                                                <ReactPlayer onPlay={handleVideoTest} controls url={data.url}
                                                                                    className='react-player' />
                                                                                {/* <div className='video-rate'>
                                                                            <div class="header-name">
                                                                                <div className='head-left date-time '>
                                                                                    <i><Rate disabled defaultValue={5} /></i>
                                                                                </div>
                                                                                <div className='head-right'>
                                                                                    <h3>TEST</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                            </Card> : null}
                                                                    </>


                                                                )

                                                                }
                                                            </Col>


                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                {/* <Button type='primary' size='large' onClick={submitPreptest}>SUBMIT </Button> */}
                                                                <h3>Prep Test Documents And Url</h3>
                                                                <div className="prep-details">
                                                                    <Cards headless >
                                                                        <div className="file-list">
                                                                            <div className="file-list__single">
                                                                                {preptestDocAndOtherUrl.map((data, i) =>
                                                                                    <>
                                                                                        {
                                                                                            data.type == "Documents" ?
                                                                                                <div class="prepheader-name">
                                                                                                    <span className="file-name">{++i + ". "}</span>&nbsp;
                                                                                                    <div className='prephead-left'>
                                                                                                        <span className="file-name">{data.name}</span>
                                                                                                    </div>
                                                                                                    <div className='prephead-right'>
                                                                                                        <span className="file-content-action">
                                                                                                            {/* <a onClick={() => { fileUrlOpen(data) }}>View</a> */}
                                                                                                        </span>
                                                                                                        <span className="file-content-action ml-10">
                                                                                                            <a onClick={() => { fileDownload(data) }}>Download</a>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div class="prepheader-name">
                                                                                                    <span className="file-name">{++i + ". "}</span>&nbsp;
                                                                                                    <div className='prephead-left'>
                                                                                                        <span className="file-name">{data.url}</span>
                                                                                                    </div>
                                                                                                    <div className='prephead-right'>
                                                                                                        <span className="file-content-action">
                                                                                                            {/* <a onClick={() => { fileUrlOpen(data) }}>View</a> */}
                                                                                                        </span>
                                                                                                        <span className="file-content-action ml-10">
                                                                                                            <a onClick={() => { fileOpenUrl(data) }}>View</a>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                        }
                                                                                    </>

                                                                                )

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Cards>
                                                                </div>


                                                            </Col>

                                                        </Row>
                                                    </div>

                                                </> : null}
                                    </div>
                                </div>
                            </div>
                        </Main2> : null}
                    <Modal title="Consent to Share your Interview Video"
                        visible={isModalVisible}
                        footer={null}
                        centered
                        width={800}
                        onCancel={handleCancel}
                    >
                        <Typography>
                            <Paragraph>
                                Before proceeding with the mock interview, we'd like to confirm your preference regarding sharing the video. Do you consent to share the recorded interview video ?
                            </Paragraph>
                        </Typography>
                        <Radio.Group onChange={(e) => { setConsent(e.target.value); console.log(consent); }} value={consent}>
                            <Radio value="Y" style={{ marginBottom: '10px', display: 'block' }}>Publicly (for educational or promotional purposes)</Radio>
                            <Radio value="N" style={{ marginBottom: '10px', display: 'block' }}>Privately  (only with the interviewing party and designated personnel for assessment purposes)</Radio>
                        </Radio.Group>
                        <Form>
                            <Form.Item>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                                    <Button onClick={openZoom} type="primary" size="large">
                                        Knock the Interview Door!
                                    </Button>
                                    <Button type="light" onClick={handleCancel} size="large">
                                        Let me think!
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Modal>
                </>

            }
        </>
    );

};

export default ScheduledInterview;
