import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { CommentOutlined, SafetyCertificateOutlined } from '@ant-design/icons';

import ImageComponent from '@sharedComponent/image-component';
import { HomeRoutes } from 'src/routes';

const { Header, Content, Footer, Sider } = Layout;


const menus = [

    // {
    // 	name: 'Interviewer',
    // 	icon: <span class="material-icons">people </span>,
    // 	route: '/admin/interviewee',
    // },
    // {
    // 	name: 'Interviwee',
    // 	icon: <span class="material-icons">people </span>,
    // 	route: '/admin/interviewer',
    // },

    // {
    // 	name: 'Dashboard',
    // 	icon: <span class="material-icons">dashboard</span>,
    // 	route: '/Dashboard',
    // },

    {
        name: 'My Interviews',
        icon: <span class="material-icons">assignment_ind</span>,
        route: '/interviews',
    },
    {
        name: 'Schedule',
        icon: <span class="material-icons">list_alt</span>,
        route: '/schedule',
    },
    {
        name: 'My Profile',
        icon: <span class="material-icons">person</span>,
        route: '/profile',
    },
    {
        name: 'Logout',
        icon: <span class="material-icons">logout</span>,
        route: '/logout',
    },
];


const handleClick = (name) => {
    console.log(name)
}


const NotLogin = () => {
    var login = localStorage.getItem('login');
    // const [login, setCount] = useState(login);
    console.log(login)



    return (
        <Layout style={{ height: '100%' }}>
            {/* <ImageComponent style={{ width: '100%', marginTop: 15 }} /> */}
            <Layout>
                {/* <Header className="site-layout-sub-header-background" style={{ padding: 0 }} /> */}
                <Content style={{ margin: '24px 16px 0', height: '100%' }}>
                    <div className="site-layout-background" style={{ borderRadius: 5, overflow: 'hidden', minHeight: '100%' }}>
                        <HomeRoutes />
                    </div>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}	>human managed </Footer> */}
            </Layout>
        </Layout>
    );
};

export default NotLogin;
