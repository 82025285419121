import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Select, DatePicker, Spin } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import LottieComponent from '@sharedComponent/lottie-component';
import LottieFile from 'src/assets/lottie-files';
import './yourself-page.scss';
import 'antd/dist/antd.css';
import { Form, Input, Radio, InputNumber } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined, DesktopOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import moment from 'moment';


import otpImg from 'src/assets/imges/yourselfnew.png';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
const menus = [
    {
        icon: <ArrowRightOutlined />,
    }
];
const { Meta } = Card;
const { Option } = Select;

const config = {
    rules: [{ type: 'object', required: true, message: 'Please select your graduation Year!' }],

};

const YourSelf = () => {
    const history = useHistory();
    const jwt = localStorage.getItem('jwt');
    const [form] = Form.useForm();
    const [detailsForm, setDetailsForm] = useState(false);
    const [formShowStudent, setFormShowStudent] = useState(false);
    const [selectItemId, setSelectItemId] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [cgpa, setcgpa] = useState();
    const [graduation, setgraduation] = useState([]);
    const [customGraduation, setCustomGraduation] = useState('');
    const [showCustomGraduationInput, setShowCustomGraduationInput] = useState(false);
    const [customSpecialization, setCustomSpecialization] = useState('');
    const [showCustomSpecializationInput, setShowCustomSpecializationInput] = useState(false);
    const [Specialization, setspecialization] = useState([]);
    const [year, setyear] = useState("");
    const [defyear, setDefyear] = useState("");
    const [experience, setexperience] = useState([]);
    const [college, setcollege] = useState("");
    const [organization, setorganization] = useState("");
    const [isStudent, setIsStudent] = useState("");
    const yearFormat = "YYYY";
    const yourselfData = localStorage.getItem('yourself');
    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");


    useEffect(() => {
        if (yourselfData != null) {
            const yourselfDetails = JSON.parse(yourselfData);
            console.log(yourselfDetails)
            if (yourselfDetails.isStudent == "Y") {
                setSelectItemId(1)
                setgraduation(yourselfDetails.degree);
                setspecialization(yourselfDetails.specialization);
                setDefyear(yourselfDetails.graduationYear);
                setyear(yourselfDetails.graduationYear);
                setcgpa(yourselfDetails.collegeInfoDto.cgpa);
                setcollege(yourselfDetails.collegeInfoDto.collegeName);
                setexperience([]);
                setorganization("");
                setDetailsForm(true);
                setFormShowStudent(false);
                setIsStudent("Y")
            } else {
                setSelectItemId(2);
                setgraduation(yourselfDetails.degree);
                setspecialization(yourselfDetails.specialization);
                setDefyear(yourselfDetails.graduationYear);
                setyear(yourselfDetails.graduationYear);
                setexperience(yourselfDetails.professionalInfoDto.experience);
                setorganization(yourselfDetails.professionalInfoDto.companyName);
                setcgpa();
                setcollege("");
                setDetailsForm(true);
                setFormShowStudent(true);
                setIsStudent("N")
            }

        } else {
            if (userRoleId === "3") {
                setcgpa();
                setcollege("");
                setSelectItemId(2);
                setDetailsForm(true);
                setFormShowStudent(true);
                setIsStudent("N")
            }
        }

        setTimeout(() => {
            setIsLoading(true);
        }, 100);

    }, []);


    const handleSubmit = () => {
        const yourself = {
            isStudent: isStudent,
            degree: graduation === "Others" ? customGraduation : graduation,
            specialization: Specialization === "Others" ? customSpecialization : Specialization,
            graduationYear: year,
            collegeInfoDto: {
                cgpa: cgpa, collegeName: college,
            },
            professionalInfoDto: {
                experience: experience, companyName: organization,
            }
        }
        console.log(yourself)
        Notification.openNotificationSuccess("Details updated!")
        localStorage.setItem('yourself', JSON.stringify(yourself));
        // history.push({ pathname: '/programminglanguages' });
        userRoleId === '2' ? history.push("/programminglanguages/interviewee") : history.push("/programminglanguages/interviewer")
    };

    const handleClick1 = (value) => {
        form.resetFields();
        setSelectItemId("1");
        // setDetailsForm(true);
        // setFormShowStudent(false);
        setIsStudent("Y")
        // this.setState({ detailsForm: value, formShowStudent: index });

    }
    const handleClick2 = (value) => {
        form.resetFields();
        setSelectItemId("2");
        // setDetailsForm(true);
        // setFormShowStudent(true);
        setIsStudent("N")
    }
    const handleClickNext = (value) => {
        if (yourselfData == null) {

            setSelectItemId("1")
            setgraduation([]);
            setspecialization([]);
            setcgpa("");
            setcollege("");
            setexperience([]);
            setorganization("");
        }
        if (selectItemId == 1) {
            form.resetFields();
            setSelectItemId("1");
            setDetailsForm(true);
            setFormShowStudent(false);
            setIsStudent("Y")
        }
        else if (selectItemId == 2) {
            form.resetFields();
            setSelectItemId("2");
            setDetailsForm(true);
            setFormShowStudent(true);
            setIsStudent("N")
        }
        // else {
        //     Notification.openNotificationFaliure("Pick one of these that describes yourself now!")
        // }
        console.log(selectItemId)

    }
    const handleClickBack = (value) => {
        setDetailsForm(false);

    };
    const cgpaValidator = ({ target }) => {
        let cgpaTempValue = target.value;
        if (cgpaTempValue.includes('.')) {
            setcgpa(Number(Number(cgpaTempValue).toFixed(2)));
        }
        else {
            setcgpa(cgpaTempValue);
        }
    };
    const onChange = (date, dateString) => {
        console.log(dateString);
        setyear(dateString)
    }
    return (
        <div className="yourself_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <div class='yourself-container'>

                    {detailsForm == false ?
                        <div class='left-container'>
                            <header>
                                <div class="header-name">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <h2>Tell us about yourself </h2>
                                        </Col>
                                    </Row>
                                </div>
                                <div class="header-name">
                                    <Row class="header-name">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {/* <h4>Pick one of these that describes yourself now </h4> */}
                                            <h4>Click Next to fill your details</h4>
                                        </Col>
                                    </Row>
                                </div>

                                <div class='card'>
                                    <Card className={"1" == selectItemId ? 'ant-card-select' : ''} onClick={() => handleClick1(1)} style={{ width: 210, marginTop: 16, height: 115 }}>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} push={13}>
                                                {"1" == selectItemId ?
                                                    <i > <CheckCircleTwoTone twoToneColor="#52c41a" /></i>
                                                    : null}
                                            </Col>
                                        </Row>
                                        <div class='card-header'>
                                            <div>
                                                <i> <UserOutlined /></i>
                                            </div>
                                        </div>
                                        <Meta
                                            description="Student / Fresher - Out of College"
                                        />
                                    </Card>
                                    <Card className={"2" == selectItemId ? 'ant-card-select' : ''} class="antd-card-pro" onClick={() => handleClick2(false)} style={{ width: 210, marginTop: 16, height: 115, marginLeft: 50 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} push={13}>
                                            {"2" == selectItemId ?
                                                <i > <CheckCircleTwoTone twoToneColor="#52c41a" /></i>
                                                : null}
                                        </Col>
                                        <div class='card-header'>
                                            <div>
                                                <i> <DesktopOutlined /></i>
                                            </div>
                                        </div>
                                        <Meta
                                            description="Software Professional"
                                        />
                                    </Card>

                                </div>
                                <footer>
                                    <div class='login-footer'>
                                        <Button id="forOnlyStudent" onClick={handleClickNext} type="primary" icon={<ArrowRightOutlined />} size="large">NEXT STEP </Button>
                                    </div>
                                </footer>
                            </header>
                        </div>
                        :
                        <div class='left-container'>
                            <Form name="yourself" form={form} onFinish={handleSubmit} layout="vertical">
                                <header>
                                    <div >
                                        <Row>
                                            {selectItemId == 1 ?
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <h1>Student Details</h1>
                                                </Col> : <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <h1>Professional Details</h1>
                                                </Col>}
                                        </Row>
                                    </div>
                                </header>
                                <div class='form card-input'>
                                    <Form.Item
                                        name="graduation"
                                        initialValue={graduation}
                                        rules={[{ message: 'Please select your graduation!', required: true }]}>
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Select Graduation Degree"
                                            defaultValue={graduation}
                                            value={graduation} onChange={e => {
                                                setgraduation(e);
                                                e === "Others" ? setShowCustomGraduationInput(true) : setShowCustomGraduationInput(false);
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value="BE">BE</Option>
                                            <Option value="BTECH">BTECH</Option>
                                            <Option value="BCA">BCA</Option>
                                            <Option value="BSC">BSC</Option>
                                            <Option value="MCA">MCA</Option>
                                            <Option value="Others">Others</Option>
                                        </Select>
                                    </Form.Item>
                                    {showCustomGraduationInput && <Form.Item
                                        name="customgraduation"
                                        initialValue={customGraduation}
                                        rules={[{ message: 'Please enter your graduation!', required: true }]}>
                                        <input id='customgraduation' placeholder="Graduation Degree" type='text'
                                            value={customGraduation} onChange={e => setCustomGraduation(e.target.value)} />
                                    </Form.Item>}
                                    <Form.Item
                                        name="specialization"
                                        initialValue={Specialization}
                                        rules={[{ message: 'Please select your specialization!', required: true }]}>
                                        <Select
                                            showSearch
                                            style={{ width: '100% ' }}
                                            placeholder="Select Specialization"
                                            optionFilterProp="children"
                                            defaultValue={Specialization}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            value={Specialization} onChange={e => {
                                                setspecialization(e);
                                                e === "Others" ? setShowCustomSpecializationInput(true) : setShowCustomSpecializationInput(false);
                                            }}
                                        >
                                            <Option value="CSE">CSE</Option>
                                            <Option value="ECE">ECE</Option>
                                            <Option value="IT">IT</Option>
                                            <Option value="EEE">EEE</Option>
                                            <Option value="Others">Others</Option>
                                        </Select>
                                    </Form.Item>
                                    {showCustomSpecializationInput && <Form.Item
                                        name="customspecialization"
                                        initialValue={customSpecialization}
                                        rules={[{ message: 'Please enter your Specialization!', required: true }]}>
                                        <input id='customspecialization' placeholder="Specialization (Eg: CSE, EEE, IT)" type='text'
                                            value={customSpecialization} onChange={e => setCustomSpecialization(e.target.value)} />
                                    </Form.Item>}
                                </div>
                                <div class='form'>
                                    {defyear == "" ?
                                        <Form.Item name="year" {...config} style={{ display: 'inline-block', width: 'calc(50%)' }}
                                        >
                                            <DatePicker value={year} disabledDate={(current) => {
                                                let customDate = moment().add(1, 'Y').format("DD-MM-YYYY");
                                                return current && current > moment(customDate, "DD-MM-YYYY");
                                            }} onChange={onChange} picker="year" />
                                        </Form.Item> : <Form.Item name="year" style={{ display: 'inline-block', width: 'calc(50%)' }}
                                        >
                                            <DatePicker defaultValue={moment(defyear, yearFormat)} value={year} disabledDate={(current) => {
                                                let customDate = moment().add(1, 'Y').format("DD-MM-YYYY");
                                                return current && current > moment(customDate, "DD-MM-YYYY");
                                            }} onChange={onChange} picker="year" />
                                        </Form.Item>
                                    }
                                    {formShowStudent == false ?
                                        <Form.Item style={{ display: 'inline-block', width: 'calc(50%)', paddingLeft: 8, marginBottom: 0 }}>
                                            <Form.Item
                                                name="cgpa"
                                                initialValue={cgpa}
                                                rules={[{ message: 'Please enter your CGPA!', required: true }, {
                                                    // validator: (rule, value, callback) => {
                                                    //     if (/^\d+$/.test(value)) {
                                                    //         const numValue = parseInt(value, 10);
                                                    //         if (numValue >= 1 && numValue <= 100) {
                                                    //             setcgpa(value);
                                                    //             callback();
                                                    //         } else {
                                                    //             callback('Enter a number between 1 to 100');
                                                    //         }
                                                    //     }
                                                    // }
                                                }]}>
                                                <input placeholder="CGPA" type='number' step="0.01" id='cgpa'
                                                    min="1" max="10"
                                                    value={Number(cgpa)} onChange={e=>setcgpa(e.target.value)} />
                                                {/* <input placeholder="CGPA / Percentage" type='number' step="0.01" id='cgpa'
                                                        min="1" max="100"
                                                        value={Number(cgpa)} onChange={e => setcgpa(e.target.value)} /> */}
                                            </Form.Item>
                                        </Form.Item> :
                                        <Form.Item style={{ display: 'inline-block', width: 'calc(50%)', paddingLeft: 8, marginBottom: 0 }}>
                                            <Form.Item
                                                name="experience"
                                                initialValue={experience}
                                                rules={[{ message: 'Please select your IT Experience!', required: true }]}>
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    defaultValue={experience}
                                                    placeholder="Select Your IT experience"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    value={experience} onChange={e => setexperience(e)}
                                                >
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>
                                                    <Option value="4">4</Option>
                                                    <Option value="5">5</Option>
                                                    <Option value="6">6</Option>
                                                    <Option value="7">7</Option>
                                                    <Option value="8">8</Option>
                                                    <Option value="9">9</Option>
                                                    <Option value="10">10</Option>
                                                    <Option value="11">11</Option>
                                                    <Option value="12">12</Option>
                                                    <Option value="13">13</Option>
                                                    <Option value="14">14</Option>
                                                    <Option value="15">15</Option>
                                                    <Option value="16">16</Option>
                                                    <Option value="17">17</Option>
                                                    <Option value="18">18</Option>
                                                    <Option value="19">19</Option>
                                                    <Option value="20">20</Option>
                                                </Select>
                                            </Form.Item>
                                        </Form.Item>
                                    }
                                </div>
                                <div class='form card-input'>
                                    {formShowStudent == false ?
                                        <div>
                                            <Form.Item
                                                name="college"
                                                initialValue={college}
                                                rules={[{ message: 'Please enter your College!', required: true }]}>
                                                <input id='college' placeholder="Your College" type='text'
                                                    value={college} onChange={e => setcollege(e.target.value)} />
                                            </Form.Item>
                                        </div> :
                                        <div>
                                            <Form.Item
                                                name="organization"
                                                initialValue={organization}
                                                /* rules={[{ message: 'Please enter your organization!', required: true }]} */>
                                                <input id='organization' placeholder="Your Present Organization" type='text'
                                                    value={organization} onChange={e => setorganization(e.target.value)} />
                                            </Form.Item>
                                        </div>
                                    }
                                </div>
                                <footer>
                                    <div class='footer_details'>
                                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <button onClick={() => handleClickBack(false)} id='resend'>BACK   <i><ArrowRightOutlined /></i></button>
                                            </Col> */}
                                        <Button onClick={() => handleClickBack(false)} type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button>
                                        <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} size="large">NEXT STEP </Button>
                                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <button id='next'>NEXT STEP   <i><ArrowRightOutlined /></i></button>
                                            </Col> */}
                                    </div>
                                </footer>
                            </Form>
                        </div>}
                    <div class='right-container'>
                        <h1>Our platform helps you boost your confidence</h1>
                        <img src={otpImg} alt="RightPanel_Image" />
                    </div>

                </div>
            }

        </div>
    );
}

export default YourSelf;