import React, { useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Avatar, Button, Modal } from "antd";
import {
  Link,
  useLocation,
  NavLink,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { CommentOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import TopMenu from "./TopMenu";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ImageComponent from "@sharedComponent/image-component";
import { HomeRoutes } from "src/routes";
import { useStateValue } from "../../../reducers/provider";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Div,
  SmallScreenAuthInfo,
  SmallScreenSearch,
  TopMenuSearch,
} from "./style";
import MenueItems from "./MenueItems";
import { Scrollbars } from "react-custom-scrollbars";
import { ThemeProvider } from "styled-components";
import interviewsImg from "../../../../src/assets/imges/job-interview.png";
import scheduleImg from "../../../../src/assets/imges/waiting-list.png";
import logout from "../../../../src/assets/imges/exit.png";
import userImg from "../../../../src/assets/imges/user-img.png";
import dashboardImg from "../../../../src/assets/imges/dashboard.png";
import userGropuImg from "../../../../src/assets/imges/user-group.png";
//   import interviewerImg from '../../../../src/assets/imges/user-group.png';
import transaction from "../../../../src/assets/imges/transaction.png";
import configuration from "../../../../src/assets/imges/configuration.png";
import exam from "../../../../src/assets/imges/exam.png";
import couponImg from "../../../../src/assets/imges/coupon.png";
import interviewerImg from "../../../../src/assets/imges/interview.png";

const { darkTheme } = require("../../../config/theme/themeVariables");

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;
const intervieweeMenus = [
  {
    name: "My Interviews",
    icon: <span class="material-icons">assignment_ind</span>,
    route: "/interviews",
  },
  {
    name: "Schedule",
    icon: <span class="material-icons">list_alt</span>,
    route: "/schedule",
  },
  {
    name: "My Profile",
    icon: <span class="material-icons">person</span>,
    route: "/profile",
  },
  {
    name: "Logout",
    icon: <span class="material-icons">logout</span>,
    route: "/logout",
  },
];

const footerMenus = [
  {
    name: "Search",
    icon: <span class="material-icons">search</span>,
  },
  {
    name: "Notification",
    icon: <span class="material-icons">notifications</span>,
  },
  {
    name: "Support",
    icon: <span class="material-icons">sms</span>,
  },
];

const adminMenus = [
  {
    name: "Dashboard",
    icon: <span class="material-icons">dashboard</span>,
    route: "/admin/dashboard",
  },
  {
    name: "Interviewee",
    icon: <span class="material-icons">group</span>,
    route: "/admin/interviewee",
  },
  {
    name: "Interviewer",
    icon: <span class="material-icons">supervised_user_circle</span>,
    route: "/admin/interviewer",
  },
  {
    name: "Interviews",
    icon: <span class="material-icons">list_alt</span>,
    route: "/admin/interviews",
  },
  {
    name: "My Profile",
    icon: <span class="material-icons">person</span>,
    route: "/admin/profile",
  },
  {
    name: "Logout",
    icon: <span class="material-icons">logout</span>,
    route: "/logout",
  },
];

const interviewerMenus = [
  {
    name: "My Interviews",
    icon: <span class="material-icons">assignment_ind</span>,
    route: "/interviewer/interviews",
  },
  {
    name: "My Slots",
    icon: <span class="material-icons">list_alt</span>,
    route: "/interviewer/myslots",
  },

  {
    name: "My Profile",
    icon: <span class="material-icons">person</span>,
    route: "/interviewer/profile",
  },
  {
    name: "Logout",
    icon: <span class="material-icons">logout</span>,
    route: "/logout",
  },
];

const handleClick = (name) => {
  console.log(name);
};

const MainLayoutPresentational = () => {
  var login = localStorage.getItem("login");
  var userRole = localStorage.getItem("userRole");
  const history = useHistory();
  const { confirm } = Modal;

  let location = useLocation();
  const { path } = useRouteMatch();
  const onCollapse = (data) => {
    console.log(data);
    setCollapsed(data);
  };

  const [collapsed, setCollapsed] = useState(false);
  const [topMenu, settopMenu] = useState(false);
  const [ChangeLayoutMode, setChangeLayoutMode] = useState(false);
  const [rtl, setrtl] = useState(false);
  const [changeRtl, setchangeRtl] = useState(false);
  const [changeLayout, setchangeLayout] = useState(false);
  const [changeMenuMode, setchangeMenuMode] = useState(false);
  const [toggleCollapsed, settoggleCollapsed] = useState(false);

  // const { ChangeLayoutMode, rtl, changeRtl, changeLayout, changeMenuMode } = this.props;

  const left = !rtl ? "left" : "right";
  const darkMode = ChangeLayoutMode;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    toggleCollapsedMobile();
  }, []);
  const updateDimensions = () => {
	  console.log("window.innerWidth --> " , window.innerWidth);
    if (window.innerWidth >= 990 && window.innerWidth <= 1200) {
      setCollapsed(true);
    }
  };
  const toggleCollapsedOpen = () => {
    setCollapsed(false);
    //   settopMenu(false );
  };
  const toggleCollapsedClose = () => {
    setCollapsed(true);
    //   settopMenu(true);
  };
  const toggleCollapsedCloseMenu = () => {
    if (window.innerWidth <= 990) {
      setCollapsed(true);
    }
  };

  const toggleCollapsedMobile = () => {
    console.log("MOBILE COLLAPSE WIDTH --> ", window.innerWidth);
    if (window.innerWidth <= 990) {
      console.log("DidMyMobileJob  --> ", window.innerWidth);
      console.log("DidMyMobileJob  --> ", collapsed);
      setCollapsed(true);
    }
  };

  const footerStyle = {
    padding: "20px 30px 18px",
    color: "rgba(0, 0, 0, 0.65)",
    fontSize: "14px",
    background: "rgba(255, 255, 255, .90)",
    width: "100%",
    boxShadow: "0 -5px 10px rgba(146,153,184, 0.05)",
  };

  const SideBarStyle = {
    // margin: '63px 0 0 0',
    padding: "15px 15px 55px 15px",
    overflowY: "auto",
    height: "100vh",
    position: "fixed",
    [left]: 0,
    zIndex: 998,
  };

  const renderView = ({ style }) => {
    const customStyle = {
      marginRight: "auto",
      [rtl ? "marginLeft" : "marginRight"]: "-17px",
    };
    return <div style={{ ...style, ...customStyle }} />;
  };

  const renderThumbVertical = ({ style }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#ffffff16",
      [left]: "2px",
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: "absolute",
      width: "6px",
      transition: "opacity 200ms ease 0s",
      opacity: 0,
      [rtl ? "left" : "right"]: "2px",
      bottom: "2px",
      top: "2px",
      borderRadius: "3px",
    };
    return <div style={thumbStyle} />;
  };

  const renderThumbHorizontal = ({ style }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#ffffff16",
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const onRtlChange = () => {
    const html = document.querySelector("html");
    html.setAttribute("dir", "rtl");
    changeRtl(true);
  };

  const onLtrChange = () => {
    const html = document.querySelector("html");
    html.setAttribute("dir", "ltr");
    changeRtl(false);
  };

  const modeChangeDark = () => {
    changeLayout(true);
  };

  const modeChangeLight = () => {
    changeLayout(false);
  };

  const modeChangeTopNav = () => {
    changeMenuMode(true);
  };

  const modeChangeSideNav = () => {
    changeMenuMode(false);
  };

  const onEventChange = {
    onRtlChange,
    onLtrChange,
    modeChangeDark,
    modeChangeLight,
    modeChangeTopNav,
    modeChangeSideNav,
  };

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const logoutConfirm = () => {
    confirm({
      title: "Do you want to logout?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        history.push({ pathname: "/logout" });
      },
      onCancel() {},
    });
  };

  return (
    <Div darkMode={darkMode}>
      <Layout className="layout">
        {login == "true" ? (
          <Header
            style={{
              position: "fixed",
              width: "100%",
              top: 0,
              [!rtl ? "left" : "right"]: 0,
            }}
          >
            <Row>
              <Col
                lg={10}
                sm={10}
                xs={10}
                className="align-center-v navbar-brand"
              >
                <>
                  {collapsed ? (
                    <Button
                      className="sidenav-btn"
                      type="link"
                      onClick={toggleCollapsedOpen}
                    >
                      <img
                        className="head-menu"
                        src={require(`../../../static/img/icon/${
                          collapsed ? "right.svg" : "left.svg"
                        }`)}
                        alt="menu"
                      />
                    </Button>
                  ) : (
                    <Button
                      className="sidenav-btn"
                      type="link"
                      onClick={toggleCollapsedClose}
                    >
                      <img
                        src={require(`../../../static/img/icon/${
                          collapsed ? "right.svg" : "left.svg"
                        }`)}
                        alt="menu"
                      />
                    </Button>
                  )}
                </>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h3 className="header-name">SKILINGO</h3>
              </Col>
            </Row>
          </Header>
        ) : null}
        {login == "true" ? (
          <Layout>
            {!topMenu || window.innerWidth <= 991 ? (
              <ThemeProvider theme={darkTheme}>
                <Sider width={200} style={SideBarStyle} collapsed={collapsed}>
                  <Scrollbars
                    className="custom-scrollbar"
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    renderThumbHorizontal={renderThumbHorizontal}
                    renderThumbVertical={renderThumbVertical}
                    renderView={renderView}
                    renderTrackVertical={renderTrackVertical}
                  >
                    <div className="sidenav-head">
                      {collapsed ? (
                        <Button
                          className="sidenav-btn"
                          type="link"
                          onClick={toggleCollapsedOpen}
                        >
                          <img
                            className="head-menu"
                            src={require(`../../../static/img/icon/${
                              collapsed ? "right.svg" : "left.svg"
                            }`)}
                            alt="menu"
                          />
                        </Button>
                      ) : (
                        <Button
                          className="sidenav-btn"
                          type="link"
                          onClick={toggleCollapsedClose}
                        >
                          <img
                            src={require(`../../../static/img/icon/${
                              collapsed ? "right.svg" : "left.svg"
                            }`)}
                            alt="menu"
                          />
                        </Button>
                      )}

                      {collapsed ? null : (
                        <h3 className="header-name">SKILINGO</h3>
                      )}
                    </div>
                    {userRole == "INTERVIEWEE" ? (
                      <Menu
                        theme="dark"
                        onClick={toggleCollapsedCloseMenu}
                        selectedKeys={mainPathSplit}
                        mode="inline"
                      >
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="interviews"
                              >
                                <img
                                  className="nav-img"
                                  src={interviewsImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="interviews"
                        >
                          <NavLink to="interviews">My Interviews</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink className="menuItem-iocn" to="/schedule">
                                <img src={scheduleImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="schedule"
                        >
                          <NavLink onClick={toggleCollapsed} to="/schedule">
                            My Schedule
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink className="menuItem-iocn" to="/profile">
                                <img src={userImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="profile"
                        >
                          <NavLink onClick={toggleCollapsed} to="/profile">
                            My Profile
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <a className="menuItem-iocn" onClick={logoutConfirm} >
                                <img src={logout} alt="" />
                              </a>
                            )
                          }
                          key="logout"
                        >
                          <a onClick={logoutConfirm}>Logout</a>
                        </Menu.Item>
                      </Menu>
                    ) : userRole == "INTERVIEWER" ? (
                      <Menu
                        onClick={toggleCollapsedCloseMenu}
                        className="custom_menu"
                        theme="dark"
                        mode="inline"
                        selectedKeys={location.pathname}
                      >
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/interviewer/interviews"
                              >
                                <img
                                  className="nav-img"
                                  src={interviewsImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="interviews"
                        >
                          <NavLink to="/interviewer/interviews">
                            My Interviews
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/interviewer/myslots"
                              >
                                <img src={scheduleImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="slots"
                        >
                          <NavLink to="/interviewer/myslots">My Slots</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/interviewer/profile"
                              >
                                <img src={userImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="profile"
                        >
                          <NavLink to="/interviewer/profile">My Profile</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <a
                                className="menuItem-iocn"
                                onClick={logoutConfirm}
                              >
                                <img src={logout} alt="" />
                              </a>
                            )
                          }
                          key="logout"
                        >
                          <a onClick={logoutConfirm}>Logout</a>
                        </Menu.Item>
                      </Menu>
                    ) : (
                      <Menu
                        onClick={toggleCollapsedCloseMenu}
                        className="custom_menu"
                        theme="dark"
                        mode="inline"
                        selectedKeys={location.pathname}
                      >
						
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/dashboard"
                              >
                                <img
                                  className="nav-img"
                                  src={dashboardImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="dashboard"
                        >
                          <NavLink to="/admin/dashboard">Dashboard</NavLink>
                        </Menu.Item>

                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/interviewee"
                              >
                                <img src={userGropuImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="interviewee"
                        >
                          <NavLink to="/admin/interviewee">Interviewee</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/interviewer"
                              >
                                <img
                                  className="nav-img"
                                  src={interviewerImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="interviewer"
                        >
                          <NavLink to="/admin/interviewer">Interviewer</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/interviews"
                              >
                                <img
                                  className="nav-img"
                                  src={interviewsImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="interviews"
                        >
                          <NavLink to="/admin/interviews">Interviews</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/transaction"
                              >
                                <img
                                  className="nav-img"
                                  src={transaction}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="transaction"
                        >
                          <NavLink to="/admin/transaction">Transaction</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/configuration"
                              >
                                <img
                                  className="nav-img"
                                  src={configuration}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="configuration"
                        >
                          <NavLink to="/admin/configuration">
                            Configuration
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/coupon"
                              >
                                <img
                                  className="nav-img"
                                  src={couponImg}
                                  alt=""
                                />
                              </NavLink>
                            )
                          }
                          key="coupon"
                        >
                          <NavLink to="/admin/coupon">Coupon</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/preptest"
                              >
                                <img className="nav-img" src={exam} alt="" />
                              </NavLink>
                            )
                          }
                          key="preptest"
                        >
                          <NavLink to="/admin/preptest">Prep Test</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <NavLink
                                className="menuItem-iocn"
                                to="/admin/profile"
                              >
                                <img src={userImg} alt="" />
                              </NavLink>
                            )
                          }
                          key="profile"
                        >
                          <NavLink to="/admin/profile">Profile</NavLink>
                        </Menu.Item>
                        <Menu.Item
                          icon={
                            !topMenu && (
                              <a
                                className="menuItem-iocn"
                                onClick={logoutConfirm}
                              >
                                <img src={logout} alt="" />
                              </a>
                            )
                          }
                          key="logout"
                        >
                          <a onClick={logoutConfirm}>Logout</a>
                        </Menu.Item>
                      </Menu>
                    )}
                  </Scrollbars>
                </Sider>
              </ThemeProvider>
            ) : null}
            <Layout className="atbd-main-layout">
              <Content>
                <Content style={{ margin: "24px 16px 0", height: "100%" }}>
                  <div
                    className="site-layout-background"
                    style={{
                      borderRadius: 5,
                      overflow: "hidden",
                      minHeight: "100%",
                    }}
                  >
                    <HomeRoutes />
                  </div>
                </Content>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <Layout>
            <Content style={{ margin: "24px 16px 0", height: "100%" }}>
              <div
                className="site-layout-background"
                style={{
                  borderRadius: 5,
                  overflow: "hidden",
                  minHeight: "100%",
                }}
              >
                <HomeRoutes />
              </div>
            </Content>
          </Layout>
        )}
      </Layout>
    </Div>
  );
};

export default MainLayoutPresentational;

// <Layout style={{ height: '100%' }}>
    // 	{login == "true" ?
    // 		<Sider
    // 			// breakpoint="lg"
    // 			collapsedWidth="0"
    // 			collapsible collapsed={collapsed} onCollapse={onCollapse}>
    // 			<Link to="/" className='dashboad-header'>
    // 				<h2>SKILLINGO</h2>
    // 			</Link>
    // 			{userRole == "INTERVIEWEE" ?
    // 				<Menu className="custom_menu" theme="dark" mode="inline" selectedKeys={[location.pathname]}>
    // 					{intervieweeMenus.map((menu, i) => (
    // 						<Menu.Item key={menu.route} icon={menu.icon} >
    // 							<Link to={menu.route}>{menu.name}</Link>
    // 						</Menu.Item>
    // 					))}
    // 					{/* <footer>

    // 						<header >
    // 							<div class="avatar">
    // 								<Row className='interviewProfile-head'>
    // 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
    // 										<Avatar src={userImg} />
    // 									</Col>
    // 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
    // 										<h3>Rajesh</h3>
    // 									</Col>
    // 								</Row>
    // 							</div>
    // 						</header>
    // 					</footer> */}

    // 				</Menu> : userRole == "INTERVIEWER" ? <Menu className="custom_menu" theme="dark" mode="inline" selectedKeys={[location.pathname]}>
    // 					{interviewerMenus.map((menu, i) => (
    // 						<Menu.Item key={menu.route} icon={menu.icon} >
    // 							<Link to={menu.route}>{menu.name}</Link>
    // 						</Menu.Item>
    // 					))}
    // 					{/* <footer className='interviewer-footer'>
    // 						<header >
    // 							<div class="avatar">
    // 								<Row className='interviewProfile-head'>
    // 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
    // 										<Avatar src={userImg} />
    // 									</Col>
    // 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
    // 										<h3>Rajesh</h3>
    // 									</Col>
    // 								</Row>
    // 							</div>
    // 						</header>
    // 					</footer> */}
    // 				</Menu> : <Menu className="custom_menu" theme="dark" mode="inline"
    // 					selectedKeys={[location.pathname]}>
    // 					{adminMenus.map((menu, i) => (
    // 						<Menu.Item key={menu.route} icon={menu.icon} >
    // 							<Link to={menu.route}>{menu.name}</Link>
    // 						</Menu.Item>
    // 					))}
    // 					{/* <footer className='admin-footer'>
    // 						<header >
    // 							<div class="avatar">
    // 								<Row className='interviewProfile-head'>
    // 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
    // 										<Avatar src={userImg} />
    // 									</Col>
    // 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
    // 										<h3>Rajesh</h3>
    // 									</Col>
    // 								</Row>
    // 							</div>
    // 						</header>
    // 					</footer> */}
    // 				</Menu>}
    // 		</Sider> : null}
    // 	{/* <ImageComponent style={{ width: '100%', marginTop: 15 }} /> */}
    // 	<Layout>
    // 		{/* <Header className="site-layout-sub-header-background" style={{ padding: 0 }} /> */}
    // 		<Content style={{ margin: '24px 16px 0', height: '100%' }}>
    // 			<div className="site-layout-background" style={{ borderRadius: 5, overflow: 'hidden', minHeight: '100%' }}>
    // 				<HomeRoutes />
    // 			</div>
    // 		</Content>
    // 		{/* <Footer style={{ textAlign: 'center' }}	>human managed </Footer> */}
    // 	</Layout>
    // </Layout>