import './interviewee-details.scss';
import React, { useState, useEffect } from 'react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Row, Col, Select, Form, Input, Button, message, card, Card, Spin,Rate } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { UsercardWrapperint,ProfileAuthorBoxnew } from '../style';
//import { ProfileAuthorBox } from '../profile/style'
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import siteUrl from "../../../api/apiUrls";
const IntervieweeDetails = () => {
    const [form] = Form.useForm();
    let data= useLocation();
    let Interviewee = data.state.Interviewee
    console.log(Interviewee);
    const jwt = localStorage.getItem('jwt');
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [email, setEmail] = useState("");
    const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
    const [visible, setVisible] = useState(false);
    const [skillSelectValue, setskillSelectValue] = useState([]);
    const [educationalInfo, seteducationalInfo] = useState("");
    const [interestAreaList, setInterestAreaList] = useState([]);
    const [programmingList, setProgrammingList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [intervieweeDetails, setIntervieweeDetails] = useState("");
    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        setskillSelectValue([]);
        apiService(`users/details/` + Interviewee.id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const dataSource = [];
                    setIntervieweeDetails(result.data)
                    console.log(result.data)
                    setFirstName(result.data.firstName);
                    setLastName(result.data.lastName);
                    setContactNo(result.data.contactNo);
                    setEmail(result.data.email);                
                    setVisible(true)
                    setskillDefSelectValue(dataSource)
                    seteducationalInfo(result.data.educationalInfo)
                    setSkillList(result.data.userSkills);
                    setProgrammingList(result.data.userLanguage);
                    setInterestAreaList(result.data.userDomain)
                    setIsLoading(true)

                }
            },
            (error) => {

            });
    };
 
   
    return (
      <>
      <PageHeader
                ghost
                title="Details"
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/admin/interviewee" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
  {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <Main>
                    <UsercardWrapperint>
                    <ProfileAuthorBoxnew>
             <Cards headless>
        <div className="author-info">
          <figure>
          {intervieweeDetails.profileImageId == null ?
          <img className='profile-img' src={require('../../../../src/assets/imges/blank-profile-picture.jpg')} alt="" />:
          <img className='profile-img' src={siteUrl+""+"unsecure/view/"+intervieweeDetails.profileImageId} />}
          </figure>
        </div>
        <figcaption>
        <div className="info">
                        <Form className='interviewer-form' form={form}  name="Interviewee" layout="vertical">
                            <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                name="firstName"
                                                label = "First Name"
                                                
                                               >
                                            {/* <text>{firstName}</text> */}
                                              
                                            <text>{firstName}</text>
                                              
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        <Form.Item
                                                name="lastName"
                                                label = "Last Name"
                                                
                                               >
                                            {/* <text>{lastName}</text> */}
                                            <text>{lastName}</text>
                                              
                                            </Form.Item>
                                        </Col>
                                        
                                        <Col span={8}>
                                        <Form.Item
                                                name="email"
                                                label = "Email"
                                                
                                               >
                                            {/* <text>{email}</text> */}
                                            <text>{email}</text>
                                            </Form.Item>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                name="ContactNo"
                                                label = "Mobile"
                                                
                                               >
                                            {/* <text>{contactNo}</text> */}
                                            <text>{contactNo}</text>
                                              
                                            </Form.Item>
                                        </Col>
                                        
                                    
                                       
                                        <Col span={8}>
                                        <Form.Item
                                                name="degree"
                                                label = "Degree"
                                                
                                               >
                                            <text>{educationalInfo.degree}</text>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        <Form.Item
                                                name="Specialization"
                                                label = "Specialization"
                                                
                                               >
                                            <text>{educationalInfo.specialization}</text>
                                            </Form.Item>
                                        </Col>
                                        </Row>
                                        {educationalInfo.isStudent == "Y" ?
                                            <Row>
                           
                                        <Col span={8}>
                                        <Form.Item
                                                name="year"
                                                label = "Year"
                                                
                                               >
                                            <text>{educationalInfo.graduationYear}</text>
                                            </Form.Item>
                                        </Col>
                                       
                                       <>
                                       <Col span={8}>
                                        <Form.Item
                                                name="cgpa"
                                                label = "CGPA"
                                                
                                               >
                                            <text>{educationalInfo.collegeInfo.cgpa}</text>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        <Form.Item
                                                name="college"
                                                label = "College"
                                                
                                               >
                                            <text>{educationalInfo.collegeInfo.collegeName}</text>
                                            </Form.Item>
                                        </Col>
                                       </>
                                     
                                        </Row>:null}
                                        <Row>
                                        {educationalInfo.isStudent == "Y" ? null :
                                      <>
                                       <Col span={8}>
                                        <Form.Item
                                                name="year"
                                                label = "Year"
                                                
                                               >
                                            <text>{educationalInfo.graduationYear}</text>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        <Form.Item
                                                name="experiance"
                                                label = "Experiance"
                                                
                                               >
                                                   {educationalInfo.professionalInfo != null ?
                                            <text>{educationalInfo.professionalInfo.experience}</text>:null}
                                            </Form.Item>
                                        </Col>
                                      
                                       <Col span={8}>
                                       <Form.Item
                                                name="CompanyName"
                                                label = "Organization"
                                                
                                               >
                                                    {educationalInfo.professionalInfo != null ?
                                            <text>{educationalInfo.professionalInfo.companyName}</text>:null}
                                            </Form.Item>
                                       </Col></>}
                                       </Row>
                                       <Row className='skill-head'>
                                       <Col span={8}>
                                        <Form.Item
                                                name="ProgrammingLanguages"
                                                label = "ProgrammingLanguages"
                                                
                                               >
                                               { programmingList.map(lang => {
                    return (
                        <text className='interviewee-skills'>{lang.language.name} </text>
                    )}  )}
                                           
                                            </Form.Item>
                                        </Col>
                                       <Col span={8}>
                                        <Form.Item
                                                name="skillDefSelectValue"
                                                label = "Skill"
                                                
                                               >
                                               { skillList.map(skill => {
                    return (
                        <text className='interviewee-skills'>{skill.skills.name} </text>
                    )}  )}
                                           
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                        <Form.Item
                                                name="InterestArea"
                                                label = "InterestArea"
                                                
                                               >
                                               { interestAreaList.map(area => {
                    return (
                        <text className='interviewee-skills'>{area.domain.name}</text>
                    )}  )}
                                           
                                            </Form.Item>
                                        </Col>
                                        </Row>

                                       

                                </Form>
                                </div>
                        
        </figcaption>
      </Cards>
      </ProfileAuthorBoxnew>
                     
                    </UsercardWrapperint>
                </Main>
}
      </>
    );

}

export default IntervieweeDetails;
