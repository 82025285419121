
import React, { useState, useEffect } from 'react';

import { Row, Col, Select, Form, Input, Button, message, card, Card, Spin, Rate, InputNumber, Checkbox } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { UsercardWrapperint } from '../style';

import RichTextEditor from 'react-rte';
import javaApiService from "../../../utils/javaApiService";
import FeatherIcon from 'feather-icons-react';
import siteUrl from "../../../api/apiUrls";


const EditInterviewer = () => {
    const history = useHistory();
    let data = useLocation();
    let interViewerDetails = data.state.interviwerDetails;
    let allInterviewerDetails = data.state.allInterviewerDetails;
    const { Option } = Select;
    const jwt = localStorage.getItem('jwt');
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [level, setLevel] = useState("");
    const [amount, setAmount] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [experience, setExperience] = useState("");
    const [skill, setSkill] = useState([]);
    const [filterSkillList, setFilterSkillList] = useState([]);
    const [skillSelectValue, setskillSelectValue] = useState([]);
    const [interviewerSelectId, setinterviewerSelectId] = useState(null);
    const [pagesize, setPagesize] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [visible, setVisible] = useState(false);
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const [results, setResults] = useState([]);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [valueLongDesc, setvalueLongDesc] = useState(RichTextEditor.createEmptyValue());
    const [valueShortDesc, setvalueShortDesc] = useState(RichTextEditor.createEmptyValue());
    const [overAllRating, setOverAllRating] = useState(0);
    const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
    const [linkedin, setLinkedin] = useState("");
    const [longDescErr, setlongDescErr] = useState(false);
    const [shortDescErr, setshortDescErr] = useState(false);
    const [uploadFiles, SetuploadFiles] = useState([]);
    const [image, setImage] = useState(null)
    const [ratingErr, setratingErr] = useState(false);
    const [approve, setapprove] = useState("N");


    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'], INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };


    const onChange = (date, dateString) => {

    }



    const onTextInput1 = value => {
        setvalueLongDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };
    const onTextInput2 = value => {
        setvalueShortDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };

    useEffect(() => {
        getFilterData();
        getProfile();
    }, []);

    const getProfile = () => {
        setskillSelectValue([]);
        setskillDefSelectValue([]);
        apiService(`users/details/` + interViewerDetails.id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const dataSource = [];
                    if (result.data.interviewerInfo?.companyFileId != null) {
                        setImage(siteUrl + "" + "unsecure/view/" + result.data.interviewerInfo.companyFileId)
                    }
                    setFirstName(result.data.firstName);
                    setLastName(result.data.lastName);
                    setEmail(result.data.email);
                    setMobile(result.data.contactNo);
                    if (result.data.interviewerInfo != null) {
                        setCompanyName(result.data.interviewerInfo.companyName);
                        setExperience(result.data.interviewerInfo.experience);
                        setLevel(result.data.interviewerInfo.level);
                        setAmount(result.data.interviewerInfo.amount);
                        setOverAllRating(result.data.interviewerInfo.overAllRating);
                        setLinkedin(result.data.interviewerInfo.linkedInUrl)
                        setapprove(result.data.interviewerInfo.approve)
                        setvalueLongDesc(RichTextEditor.createValueFromString(result.data.interviewerInfo.longDescription, "html"));
                        setvalueShortDesc(RichTextEditor.createValueFromString(result.data.interviewerInfo.shortDescription, "html"));
                    }
                    result.data.userSkills.map(data => {
                        dataSource.push(
                            data.skills.id,
                        );
                    });
                    console.log(dataSource)
                    setskillDefSelectValue(dataSource)
                    setSkill(dataSource)
                    setIsLoading(true)

                }
            },
            (error) => {

            });
    };
    const handleChange = (value) => {
        setratingErr(false);
        setOverAllRating(value);
    };



    const getFilterData = () => {
        apiService(`signup/skilllist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setFilterSkillList([...result.data]);
                    // setIsLoading(true);
                }
            },
            (error) => {

            });
    };


    const handlesubmit = () => {
        if (validate(valueLongDesc, valueShortDesc, overAllRating)) {
            setIsLoading(false);
            submit();
        }

    }





    const submit = () => {
        const dataSourceSkill = [];
        skill.map(data => {
            dataSourceSkill.push({
                id: data,
            });
        });

        let formData = new FormData();
        formData.append('id', interViewerDetails.id);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('contactNo', mobile);
        formData.append('userRoleId', 3);
        formData.append('interviewerIfoDto.level', level);
        formData.append('interviewerIfoDto.experience', experience);
        formData.append('interviewerIfoDto.companyName', companyName);
        // formData.append('interviewerIfoDto.userSkills',JSON.stringify(dataSourceSkill));
        formData.append('interviewerIfoDto.amount', amount);
        formData.append('interviewerIfoDto.longDescription', valueLongDesc._cache.html);
        formData.append('interviewerIfoDto.shortDescription', valueShortDesc._cache.html);
        formData.append('interviewerIfoDto.linkedInUrl', linkedin);
        if (uploadFiles != "") {
            formData.append('interviewerIfoDto.file', uploadFiles);

        }
        formData.append('interviewerIfoDto.overAllRating', overAllRating);
        dataSourceSkill.map((data, i) => {
            formData.append('interviewerIfoDto.userSkills[' + [i] + '].id', data.id)
        }
        );
        formData.append('interviewerIfoDto.approve', approve);

        // formData.append('interviewerIfoDto.file',uploadFiles); 

        // {
        //     "id": interViewerDetails.id,
        //     "firstName": firstName,
        //     "lastName": lastName,
        //     "email": email,
        //     "contactNo": mobile,
        //     "userRoleId": 3,
        //     "interviewerIfoDto": {
        //         "level": level,
        //         "experience": experience,
        //         "companyName": companyName,
        //         "userSkills": dataSourceSkill,
        //         "amount": amount,
        //         "longDescription": valueLongDesc._cache.html,
        //         "shortDescription": valueShortDesc._cache.html,
        //         "linkedInUrl":linkedin,
        //         "overAllRating":overAllRating
        //     }
        // }
        javaApiService(`secure/userInfo/addInterviewer`, 'post', formData, true, jwt,
            result => {
                if (result.data.SUCCESS == true) {
                    Notification.openNotificationSuccess("Interviewer updated successfully");
                    history.push('/admin/interviewer');
                } else {
                    setIsLoading(true);
                    setskillSelectValue([]);
                    Notification.openNotificationFaliure(result.data.MESSAGE)
                }
            },
            (error) => {

            });



    }

    function fileHandleChange(event) {
        console.log(event.target.files);
        SetuploadFiles(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0])
        )
    }

    const validate = (longValue, shortValue, overAllRating) => {
        setlongDescErr(false);
        setshortDescErr(false);
        setratingErr(false);
        var valid = true;
        if (longValue._cache.html == null || longValue._cache.html == "<p><br></p>") {
            setlongDescErr(true);
            valid = false;
        }
        if (shortValue._cache.html == null || shortValue._cache.html == "<p><br></p>") {
            setshortDescErr(true);
            valid = false;
        }
        if (overAllRating == null || overAllRating == 0) {
            setratingErr(true);
            valid = false;

        }
        return valid;
    }

    const handleCancel = e => {
        history.push('/admin/interviewer');
    }

    return (
        <>
            <PageHeader
                ghost
                title="Update Interviewer"
                buttons={[
                    <div key="1" className="page-header-actions">
                        <Link to={{ pathname: "/admin/interviewer" }}>
                            <Button className="go-back-btn" size="large" key="4" type="primary">
                                Go Back
                            </Button>
                        </Link>
                    </div>,
                ]}
            />
            {isLoading == false ?
                <div className="spinner">
                    <Spin />
                </div> :
                <Main>
                    <UsercardWrapperint>
                        <Card>
                            <Form className='interviewer-form edit-interviewer-form' form={form} name="interviewerdetails" onFinish={handlesubmit} onFinishFailed={(errorInfo) => {
                                console.log('Failed:', errorInfo);
                            }} layout="vertical">

                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="firstName"
                                            label="First Name"
                                            initialValue={firstName}
                                            rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                            <Input placeholder="First Name" type='text' value={firstName} onChange={e => setFirstName(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="lastName"
                                            label="Last Name"
                                            initialValue={lastName}
                                            rules={[{ message: 'Please enter your last name!', required: true }]}>
                                            <Input placeholder="Last Name" type='text' value={lastName} onChange={e => setLastName(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}> <Form.Item
                                        name="email"
                                        label="Email"
                                        initialValue={email}
                                        rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (value !== interViewerDetails.email) {
                                                    const isEmailAlreadyExists = allInterviewerDetails.some(item => item.email === value);
                                                    if (isEmailAlreadyExists) {
                                                        callback('Email is already in use. Please use a different email.');
                                                    } else {
                                                        callback();
                                                    }
                                                } else {
                                                    callback();
                                                }
                                            }
                                        }
                                        ]}>
                                        <Input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Item></Col>

                                </Row>

                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="mobile"
                                            label="Mobile"
                                            initialValue={mobile}
                                            rules={[{
                                                type: "regexp",
                                                pattern: /^(?:\d*)$/,
                                                message: "Value should contain just number"
                                            },
                                            {
                                                min: 10,
                                                max: 10,
                                                message: "phone number must be 10 digits",
                                            },
                                            {
                                                message: 'Please enter your phone number!',
                                                required: true
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value !== interViewerDetails.contactno) {
                                                        const isMobileExists = allInterviewerDetails.some(item => item.contactno === value);
                                                        if (isMobileExists) {
                                                            callback('Mobile Number is already in use.');
                                                        } else {
                                                            // Validation succeeded
                                                            callback();
                                                        }
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }]}>
                                            <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="companyName"
                                            label="Company"
                                            initialValue={companyName}
                                            /* rules={[{ message: 'Please enter your company!', required: true }]} */>
                                            <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="experience"
                                            label="Experience"
                                            initialValue={experience}
                                            rules={[{
                                                message: 'Enter value 1 or greater than 1', type: 'number', min: 1
                                            }, { message: 'Please enter your experience!', required: true }]}>
                                            <InputNumber placeholder="Experience" value={experience} min="1" max="50"  onChange={e => {
                                      // setExperience(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setExperience(newValue);
                                      }
                                    }} type='number' />
                                        </Form.Item>
                                    </Col>



                                </Row>

                                <Row>
                                    <Col span={7}>
                                        <Form.Item
                                            name="level"
                                            label="Level"
                                            initialValue={level}
                                            rules={[{ message: 'Please enter your level!', required: true }]}>
                                            <Input placeholder="Level" value={level} min="1" max="5"  onChange={e => {
                                    // setLevel(e.target.value);
                                    const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                    if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                    setLevel(newValue.toString());
                                    }
                                    }} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="amount"
                                            label="Amount"
                                            initialValue={amount}
                                            rules={[{
                                                message: 'Enter value 1 or greater than 1', type: 'number', min: 1
                                            }, { message: 'Please enter your amount!', required: true }]}>
                                            <InputNumber placeholder="Amount" value={amount} onChange={e => setAmount(e)} type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="linkedin"
                                            label="LinkedIn"
                                            initialValue={linkedin}
                                            rules={[{ type: "url", message: 'Please enter your Linkedin profile!', required: true }]}>
                                            <Input placeholder="Linkedin" value={linkedin} onChange={e => setLinkedin(e.target.value)} type='text' />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={7}>
                                        <Form.Item initialValue={skillDefSelectValue}
                                            name="graduation"
                                            label="Skills"
                                            rules={[{ message: 'Please select your skill!', required: true }]}>
                                            <Select
                                                mode="multiple"
                                                defaultValue={skillDefSelectValue}
                                                maxTagCount={1}
                                                showSearch={false}
                                                maxTagTextLength={17}
                                                style={{ width: '100%' }}
                                                placeholder="Select skill"
                                                value={skill} onChange={e => setSkill(e)}
                                            >
                                                {filterSkillList.map(item => {
                                                    return (<Option value={item.id}>{item.type}</Option>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="Overall Ratings"
                                            name="OverallRating"
                                            rules={[{ required: true, message: 'Please enter Rating!' }]}>
                                            {/* <h4 className='rating'>Over all Ratings */}
                                            <Rate onChange={handleChange} value={overAllRating} defaultValue={overAllRating} />
                                            {/* </h4> */}
                                            {/* {ratingErr == true ?
                  <p className='text-error' >Please enter rating!</p> : null} */}
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    {image != null ?
                                        <Col span={7}>
                                            <Form.Item
                                                label="Company Logo"
                                            >

                                            </Form.Item>

                                            <div class="profile-pic">
                                                <label class="-label" for="file">
                                                    <span class="glyphicon glyphicon-camera"></span>
                                                    <span>Change Image</span>
                                                </label>
                                                <input id="file" type="file" accept="image/*" onChange={fileHandleChange} />
                                                <img src={image} id="output" width="200" />
                                            </div>
                                        </Col> : <Col span={7}>
                                            <Form.Item
                                                name="uploadFiles"
                                                label="Company Logo"
                                                /* rules={[{ message: 'Please upload company logo!', required: true }]} */>

                                                <Input accept="image/*" onChange={fileHandleChange} type='file' />
                                            </Form.Item>
                                        </Col>}

                                </Row>

                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            label={<span><span style={{ color: 'red' }}>* </span><span>Long Description</span></span>}
                                            rules={[{ message: 'Please select your skill!', required: true }]}>

                                            <RichTextEditor id="ld_edit" toolbarConfig={toolbarConfig} value={valueLongDesc} onChange={onTextInput1} />
                                        </Form.Item>
                                        {longDescErr == true ?
                                            <p className='text-error' >Please enter long description!</p> : null}
                                    </Col>

                                    <Col span={2}></Col>
                                    <Col span={11}>
                                        <Form.Item label={<span><span style={{ color: 'red' }}>* </span><span>Short Description</span></span>} rules={[{ message: 'Please fill the short description!' }]}>
                                            <RichTextEditor toolbarConfig={toolbarConfig} value={valueShortDesc} onChange={onTextInput2} />
                                        </Form.Item>
                                        {shortDescErr == true ?
                                            <p className='text-error' >Please enter short description!</p> : null}
                                    </Col>

                                </Row>
                                <Row>
                                    <Form.Item  >
                                        <Row>
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <Checkbox checked={approve === "Y" ? true : false} onChange={(event) => {
                                                    setapprove(event.target.checked ? "Y" : "N");
                                                }}></Checkbox>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: '20px' }}>
                                                <h4>Approve</h4>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Row>
                                <Col sm={23} xs={23}>
                                    <div className="sDash_form-action mt-20 fl-right" >
                                        <Button className="btn-signin mr-10" onClick={handleCancel} type="light" size="large">
                                            Cancel
                                        </Button>
                                        <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                            Update
                                        </Button>
                                    </div>
                                </Col>
                            </Form>
                        </Card>
                    </UsercardWrapperint>
                </Main>
            }
        </>
    );
}

export default EditInterviewer;