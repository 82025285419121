// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';
// import './index.css';
// import 'antd/dist/antd.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import StateProvider from './reducers/provider';
// import {reducer, initialState} from './reducers/loginReducer';


// //test
// ReactDOM.render(
// 	<React.StrictMode>
// 		 <StateProvider reducer={reducer} initialState={initialState}>
// 			<BrowserRouter>
// 				<App />
// 			</BrowserRouter>
// 		</StateProvider>
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();



import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import StateProvider from './reducers/provider';
import {reducer, initialState} from './reducers/loginReducer';
import './index.css';
import 'antd/dist/antd.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import myStore from './store/createStore';
const store = myStore();

//test
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
		<StateProvider reducer={reducer} initialState={initialState}>
			<BrowserRouter>
			<App />
			</BrowserRouter>
		</StateProvider>
	
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// import React from 'react';
// import WebApp from './App';
// import StateProvider from './reducers/provider';
// import {reducer, initialState} from './reducers/loginReducer';
// export default function App() {
//   return (
//     <StateProvider reducer={reducer} initialState={initialState}>
//       <WebApp />
//     </StateProvider>
//   );
// }
