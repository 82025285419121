import './interviewer-details.scss';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Select, Spin, Form, Pagination, Input, Modal, } from 'antd';
import { Table, Tag, Space, Button } from 'antd';
import { ProjectPagination, ProjectListTitle, ProjectListAssignees, ProjectList } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import apiService from "../../../utils/apiService";
import Notification from "../../../components/notification/notification";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { InputNumber, } from 'antd';
import { Rate } from 'antd';
import RichTextEditor from 'react-rte';
import parse from 'html-react-parser'

const Interviewee = () => {
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 24,
        },
    };
    const history = useHistory();
    const { confirm } = Modal;
    const jwt = localStorage.getItem('jwt');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userId = localStorage.getItem('userId');
    const [slots, setSlots] = useState([]);
    const [date, setDate] = useState("");
    const [time, setTime] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { Column, ColumnGroup } = Table;
    // const [dataSource, setdataSource] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [interviewedCount, setInterviewedCount] = useState("");
    const [level, setLevel] = useState("");
    const [amount, setAmount] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [experience, setExperience] = useState("");
    const [description, setDescription] = useState("");
    const [overAllRating, setOverAllRating] = useState("");
    const [pagesize, setPagesize] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [pageDefaultValue, setPageDefaultValue] = useState(1);
    const [skill, setSkill] = useState([]);
    const [filterSkillList, setFilterSkillList] = useState([]);
    const [interviewerSelectId, setinterviewerSelectId] = useState(null);
    const [skillSelectValue, setskillSelectValue] = useState([]);
    const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
    const [results, setResults] = useState([]);
    const [titleModal, settitleModal] = useState("Add Interviewer");
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setvisibleEdit] = useState(false);
    const [valueLongDesc, setvalueLongDesc] = useState(RichTextEditor.createEmptyValue());
    const [valueShortDesc, setvalueShortDesc] = useState(RichTextEditor.createEmptyValue());
    const [valueLongDescDef, setvalueLongDescDef] = useState("");
    const [valueShortDescDef, setvalueShortDescDef] = useState("");
    const [pn, setPn] = useState(1);
    const [ps, setTotalPs] = useState(10);
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'], INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };




    const { Option } = Select;

    const onChange = (date, dateString) => {
        setDate(moment(date).format('YYYY-MM-DD'));
    }



    const onTextInput1 = value => {
        setvalueLongDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };
    const onTextInput2 = value => {
        setvalueShortDesc(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };

    const onTextInput3 = value => {
        setvalueLongDescDef(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };
    const onTextInput4 = value => {
        setvalueShortDescDef(value);
        if (onChange) {
            onChange(value.toString('html'));
        }
    };

    useEffect(() => {
        getInterviewer(pn, ps);
        getFilterData();
    }, []);


    const getInterviewer = (pageNumber, pageSize) => {
        apiService(`users/userslist/3?` + "pn=" + pageNumber + "&ps=" + pageSize, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    result.data.content.map(data => {
                        resultPush.push({
                            id: data.id,
                            email: data.email,
                            name: data.firstname + " " + data.lastname,
                            contactno: data.contactno,
                            
                        });
                    });
                    setPagesize(result.data.pagesize);
                    setTotalpages(result.data.totalelements)
                    setResults(resultPush);
                    setIsLoading(true);

                }
            },
            (error) => {
            });
    };



    const dataSource = [];

    if (results.length)
        results.map(value => {
            const { id, name, email, contactno } = value;
            var editDeleteBtn = <Space>
                <Button onClick={() => {
                    handleTableEdit(value);
                }} type="primary" >Edit</Button> <Button onClick={() => {
                    showConfirm(value);
                }} type="primary" >Delete</Button>
            </Space>
            return dataSource.push({
                key: id,
                name: <span className="date-started">{name}</span>,
                email: <span className="date-started">{email}</span>,
                contactno: <span className="date-finished">{contactno}</span>,
                action: editDeleteBtn,
            });
        });


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'contactno',
            key: 'contactno',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
    ];

    const getFilterData = () => {
        apiService(`signup/skilllist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    setFilterSkillList([...result.data]);
                }
            },
            (error) => {

            });
    };



    const handleSubmitSecond = (values) => {
        submit();
    };



    const handleOk = () => {
        submit();
    };

    const handlePagination = (values) => {
        setIsLoading(false);
        setPageDefaultValue(values)
        getInterviewer(values, ps)
    };

    const showModal = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobile("");

        setCompanyName('');
        setExperience("");
        setLevel("");
        setAmount("");
        setInterviewedCount("");
        setDescription("");
        setOverAllRating("");
        setskillDefSelectValue([]);
        setvalueShortDesc(RichTextEditor.createEmptyValue());
        setvalueLongDesc(RichTextEditor.createEmptyValue());
        setinterviewerSelectId(null)
        settitleModal("Add Interviwer")
        setskillSelectValue([]);
        form.resetFields();
        setVisible(true)


    };







    const submit = () => {
        skill.map(data => {
            skillSelectValue.push({
                id: data,
            });
        });
        setIsLoading(false);
        if (interviewerSelectId == null) {
            // let formData = new FormData();
            // formData.append('firstName', firstName)
            // formData.append('lastName', lastName)
            // formData.append('email', email)
            // formData.append('contactNo', mobile)
            // formData.append('interviewedCount', interviewedCount)
            // formData.append('level', level)
            // formData.append('amount', amount)
            // formData.append('companyName', companyName)
            // formData.append('experience', experience)
            // formData.append('description', description)
            // formData.append('overAllRating', overAllRating)
            console.log(valueLongDesc)
            console.log(valueLongDesc._cache.html)
            apiService(`users/addinterviewer`, 'post', {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "contactNo": mobile,
                "userRoleId": "3",
                "interviewerIfoDto": {
                    "description": description,
                    "longDescription": valueLongDesc._cache.html,
                    "shortDescription": valueShortDesc._cache.html,
                    "level": level,
                    "experience": experience,
                    "companyName": companyName,
                    "userSkills": skillSelectValue,
                    "amount": amount
                }
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        setVisible(false)
                        getInterviewer(pageDefaultValue, ps)
                        Notification.openNotificationSuccess("Interviewer Added successfully");
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else {
            apiService(`users/addinterviewer`, 'post', {
                "id": interviewerSelectId,
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "contactNo": mobile,
                "userRoleId": "3",
                "interviewerIfoDto": {
                    "description": description,
                    "longDescription": valueLongDescDef._cache.html,
                    "shortDescription": valueShortDescDef._cache.html,
                    "level": level,
                    "experience": experience,
                    "companyName": companyName,
                    "userSkills": skillSelectValue,
                    "amount": amount
                }
            }, false, jwt,
                result => {
                    if (result.data.success == true) {
                        setvisibleEdit(false)
                        getInterviewer(pageDefaultValue, ps)
                        Notification.openNotificationSuccess("Interviewer updated successfully");
                    } else {
                        setIsLoading(true);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        }


    }
    const style = { width: 300, padding: '8px 0', };


    const handleChange = (value) => {
        console.log(value)
        setOverAllRating(value);
    };

    const handleTableEdit = (item) => {
        history.push({ pathname: '/admin/editinterviewer', state: { interviwerDetails: item, allInterviewerDetails: results } });
    };
    const handleTableDelete = (item) => {
        apiService(`users/delete/` + item.id, 'delete', '', false, jwt,
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message)
                    getInterviewer(pageDefaultValue, ps);
                } else {
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };
    const getProfile = (id) => {
        setskillSelectValue([]);
        setskillDefSelectValue([]);
        apiService(`users/details/` + id, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const dataSource = [];
                    setFirstName(result.data.firstName);
                    setLastName(result.data.lastName);
                    setEmail(result.data.email);
                    setMobile(result.data.contactNo);

                    setCompanyName(result.data.interviewerInfo.companyName);
                    setExperience(result.data.interviewerInfo.experience);
                    setLevel(result.data.interviewerInfo.level);
                    setAmount(result.data.interviewerInfo.amount);
                    setInterviewedCount(result.data.interviewerInfo.interviewedCount);
                    setDescription(result.data.interviewerInfo.description);
                    setOverAllRating(result.data.interviewerInfo.overAllRating);
                    setvalueLongDescDef(RichTextEditor.createValueFromString(result.data.interviewerInfo.longDescription, "html"));
                    setvalueShortDescDef(RichTextEditor.createValueFromString(result.data.interviewerInfo.shortDescription, "html"));
                    result.data.userSkills.map(data => {
                        dataSource.push(
                            data.skills.id,
                        );
                    });
                    console.log(dataSource)
                    setskillDefSelectValue(dataSource)
                    setvisibleEdit(true)
                    setIsLoading(true)

                }
            },
            (error) => {

            });
    };

    const handleCancel = () => {
        setVisible(false);
        setvisibleEdit(false)
    };

    function showConfirm(item) {
        confirm({
            title: 'Do you want to delete?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setIsLoading(false);
                handleTableDelete(item);
            },
            onCancel() {
            },
        });
    }

    return (
        <>
            <PageHeader ghost title="INTERVIEWER" buttons={[
                <Link to={{ pathname: "/admin/addinterviewer", state: { allInterviewerDetails: results } }}>
                    <Button key="1" type="primary" size="default">
                        Add Interviewer
                    </Button>
                </Link>,
            ]} />
            <Main>
                <div className="admin_interviewee">
                    {isLoading == false ?
                        <div className="spinner">
                            <Spin />
                        </div> :
                        <div>
                            <div className='interviewee-container'>
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards title="Interviewer List">
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                                                    <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />
                                                </div>
                                            </ProjectList>
                                        </Cards>
                                    </Col>
                                </Row>
                                <Modal
                                    title={titleModal}
                                    centered
                                    visible={visible}
                                    onClick={() => setVisible(false)}
                                    onCancel={() => setVisible(false)}
                                    onOk={handleOk}
                                    width={850}
                                    okText="Save"
                                    footer={null}

                                >

                                    <div className='modalmain'>

                                        <Form className='interviewer-form'  {...layout} name="nest-messages" form={form} onFinish={handleSubmitSecond} layout="vertical">

                                            <Row>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="firstName"
                                                        initialValue={firstName}
                                                        rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                                        <Input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="lastName"
                                                        initialValue={lastName}
                                                        rules={[{ message: 'Please enter your last name!', required: true }]}>
                                                        <Input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}> <Form.Item
                                                    name="email"
                                                    initialValue={email}
                                                    rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                    <Input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                                </Form.Item></Col>
                                            </Row>

                                            <Row>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="mobile"
                                                        initialValue={mobile}
                                                        rules={[{
                                                            type: "regexp",
                                                            pattern: /^(?:\d*)$/,
                                                            message: "Value should contain just number"
                                                        },
                                                        {
                                                            min: 10,
                                                            max: 10,
                                                            message: "phone number must be 10 digits",
                                                        },
                                                        {
                                                            message: 'Please enter your phone number!',
                                                            required: true
                                                        }]}>
                                                        <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="companyName"
                                                        initialValue={companyName}
                                                        rules={[{ message: 'Please enter your company!', required: true }]}>
                                                        <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="experience"
                                                        initialValue={experience}
                                                        rules={[{ message: 'Please enter your experience!', required: true }]}>
                                                        <Input placeholder="Experience" value={experience} min="1" max="50"  onChange={e => {
                                      // setExperience(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setExperience(newValue);
                                      }
                                    }} type='number' />
                                                    </Form.Item>
                                                </Col>



                                            </Row>

                                            <Row>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="level"
                                                        initialValue={level}
                                                        rules={[{ message: 'Please enter your level!', required: true }]}>
                                                        <Input placeholder="Level" value={level} min="1" max="5"  onChange={e => {
                                    // setLevel(e.target.value);
                                    const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                    if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                    setLevel(newValue.toString());
                                    }
                                    }} type='number' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="amount"
                                                        initialValue={amount}
                                                        rules={[{ message: 'Please enter your amount!', required: true }]}>
                                                        <Input placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="graduation"
                                                        rules={[{ message: 'Please select your skill!', required: true }]}>
                                                        <Select
                                                            mode="multiple"
                                                            maxTagCount='responsive'
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select skill"
                                                            optionFilterProp="children"
                                                            value={skill} onChange={e => setSkill(e)}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {filterSkillList.map(item => {
                                                                return (<Option value={item.id}>{item.name}</Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>


                                            </Row>

                                            <Row>

                                                <Col span={11}>
                                                    <label>Long Description</label>
                                                    <RichTextEditor toolbarConfig={toolbarConfig} value={valueLongDesc} onChange={onTextInput1} />

                                                </Col>
                                                <Col span={2}></Col>
                                                <Col span={11}>
                                                    <label>Short Description</label>
                                                    <RichTextEditor toolbarConfig={toolbarConfig} value={valueShortDesc} onChange={onTextInput2} />

                                                </Col>
                                            </Row>
                                            <Col sm={24} xs={24}>
                                                <div className="sDash_form-action mt-20 fl-right" >
                                                    <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                                        Cancel
                                                    </Button>
                                                    <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                                        Save
                                                    </Button>
                                                </div>
                                            </Col>




                                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>

                                            </Form.Item>
                                        </Form>

                                    </div>




                                </Modal>
                                <Modal
                                    title={titleModal}
                                    centered
                                    visible={visibleEdit}
                                    onClick={() => setvisibleEdit(false)}
                                    onCancel={() => setvisibleEdit(false)}
                                    onOk={handleOk}
                                    width={850}
                                    okText="Save"
                                    footer={null}

                                >

                                    <div className='modalmain'>

                                        <Form className='interviewer-form'  {...layout} name="nest-messages" form={form2} onFinish={handleSubmitSecond} layout="vertical">

                                            <Row>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="firstName"
                                                        initialValue={firstName}
                                                        rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                                        <Input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="lastName"
                                                        initialValue={lastName}
                                                        rules={[{ message: 'Please enter your last name!', required: true }]}>
                                                        <Input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}> <Form.Item
                                                    name="email"
                                                    initialValue={email}
                                                    rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                                    <Input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                                </Form.Item></Col>
                                            </Row>

                                            <Row>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="mobile"
                                                        initialValue={mobile}
                                                        rules={[{
                                                            type: "regexp",
                                                            pattern: /^(?:\d*)$/,
                                                            message: "Value should contain just number"
                                                        },
                                                        {
                                                            min: 10,
                                                            max: 10,
                                                            message: "phone number must be 10 digits",
                                                        },
                                                        {
                                                            message: 'Please enter your phone number!',
                                                            required: true
                                                        }]}>
                                                        <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="companyName"
                                                        initialValue={companyName}
                                                        rules={[{ message: 'Please enter your company!', required: true }]}>
                                                        <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="experience"
                                                        initialValue={experience}
                                                        rules={[{ message: 'Please enter your experience!', required: true }]}>
                                                        <Input placeholder="Experience" value={experience} min="1" max="50"  onChange={e => {
                                      // setExperience(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setExperience(newValue.toString());
                                      }
                                    }} type='number' />
                                                    </Form.Item>
                                                </Col>



                                            </Row>

                                            <Row>

                                                <Col span={7}>
                                                    <Form.Item
                                                        name="level"
                                                        initialValue={level}
                                                        rules={[{ message: 'Please enter your level!', required: true }]}>
                                                        <Input placeholder="Level" value={level} onChange={e => setLevel(e.target.value)} type='text' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="amount"
                                                        initialValue={amount}
                                                        rules={[{ message: 'Please enter your amount!', required: true }]}>
                                                        <Input placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)} type='numner' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        name="graduation"
                                                        rules={[{ message: 'Please select your skill!', required: true }]}>
                                                        <Select
                                                            mode="multiple"
                                                            defaultValue={skillDefSelectValue}
                                                            maxTagCount='responsive'
                                                            style={{ width: '100%' }}
                                                            placeholder="Select skill"
                                                            value={skill} onChange={e => setSkill(e)}
                                                        >
                                                            {filterSkillList.map(item => {
                                                                return (<Option value={item.id}>{item.name}</Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>



                                            </Row>

                                            <Row>

                                                <Col span={11}>
                                                    <label>Long Description</label>
                                                    <RichTextEditor toolbarConfig={toolbarConfig} value={valueLongDescDef} onChange={onTextInput3} />

                                                </Col>
                                                <Col span={2}></Col>
                                                <Col span={11}>
                                                    <label>Short Description</label>
                                                    <RichTextEditor toolbarConfig={toolbarConfig} value={valueShortDescDef} onChange={onTextInput4} />

                                                </Col>
                                            </Row>
                                            <Col sm={24} xs={24}>
                                                <div className="sDash_form-action mt-20 fl-right" >
                                                    <Button onClick={handleCancel} className="btn-signin mr-10" type="light" size="large">
                                                        Cancel
                                                    </Button>
                                                    <Button htmlType="submit" className="btn-signin" type="primary" size="large">
                                                        Save
                                                    </Button>
                                                </div>
                                            </Col>




                                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>

                                            </Form.Item>
                                        </Form>

                                    </div>




                                </Modal>
                            </div>
                        </div>
                    }
                </div>
            </Main>
        </>
        // <div className='intervieweradd_page'>
        //     {isLoading == false ?
        //         <div className="spinner">
        //             <Spin />
        //         </div> :
        //         <div>
        //             <div className='btn'>
        //                 <Button type="primary" onClick={setVisible}>Add Interviewer</Button>

        //             </div>
        //             <div className="interviewer_page_container p_10">

        //                 <Modal
        //                     title="Add Interviewer"
        //                     centered
        //                     visible={visible}
        //                     onClick={() => setVisible(false)}
        //                     onCancel={() => setVisible(false)}
        //                     onOk={handleOk}
        //                     width={700}
        //                     okText="Save"
        //                     bodyStyle={{ height: 500 }}

        //                 >

        //                     <div className='modalmain'>

        //                         <Form  {...layout} name="nest-messages"  form={form} onFinish={handleSubmitSecond} layout="vertical">

        //                             <Row>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="firstName"
        //                                         initialValue={firstName}
        //                                         rules={[{ message: 'Please enter your first name!', required: true }]}>
        //                                         <Input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
        //                                     </Form.Item>
        //                                 </Col>
        //                                 <Col span={2}></Col>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="lastName"
        //                                         initialValue={lastName}
        //                                         rules={[{ message: 'Please enter your last name!', required: true }]}>
        //                                         <Input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
        //                                     </Form.Item>
        //                                 </Col>
        //                             </Row>

        //                             <Row>
        //                                 <Col span={11}> <Form.Item
        //                                     name="email"
        //                                     initialValue={email}
        //                                     rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
        //                                     <Input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
        //                                 </Form.Item></Col>
        //                                 <Col span={2}></Col>

        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="mobile"
        //                                         initialValue={mobile}
        //                                         rules={[{
        //                                             type: "regexp",
        //                                             pattern: /^(?:\d*)$/,
        //                                             message: "Value should contain just number"
        //                                         },
        //                                         {
        //                                             min: 10,
        //                                             max: 10,
        //                                             message: "phone number must be 10 digits",
        //                                         },
        //                                         {
        //                                             message: 'Please enter your phone number!',
        //                                             required: true
        //                                         }]}>
        //                                         <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
        //                                     </Form.Item>
        //                                 </Col>
        //                             </Row>
        //                             <Row>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="companyName"
        //                                         initialValue={companyName}
        //                                         rules={[{ message: 'Please enter your company!', required: true }]}>
        //                                         <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
        //                                     </Form.Item>
        //                                 </Col>
        //                                 <Col span={2}></Col>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="experience"
        //                                         initialValue={experience}
        //                                         rules={[{ message: 'Please enter your experience!', required: true }]}>
        //                                         <Input placeholder="Experience" value={experience} onChange={e => setExperience(e.target.value)} type='text' />
        //                                     </Form.Item>
        //                                 </Col>

        //                             </Row>
        //                             <Row>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="interviewedCount"
        //                                         initialValue={interviewedCount}
        //                                         rules={[{ message: 'Please enter your interview count!', required: true }]}>
        //                                         <Input placeholder="Interview Count" value={interviewedCount} onChange={e => setInterviewedCount(e.target.value)} type='number' />
        //                                     </Form.Item>
        //                                 </Col>
        //                                 <Col span={2}></Col>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="level"
        //                                         initialValue={level}
        //                                         rules={[{ message: 'Please enter your level!', required: true }]}>
        //                                         <Input placeholder="Level" value={level} onChange={e => setLevel(e.target.value)} type='text' />
        //                                     </Form.Item>
        //                                 </Col>
        //                             </Row>
        //                             <Row>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="amount"
        //                                         initialValue={amount}
        //                                         rules={[{ message: 'Please enter your amount!', required: true }]}>
        //                                         <Input placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)} type='numner' />
        //                                     </Form.Item>
        //                                 </Col>
        //                                 <Col span={2}></Col>
        //                                 <Col span={11}>
        //                                     <h4 className='rating'>Over all Ratings :
        //                                         <Rate onChange={handleChange} value={overAllRating} />
        //                                     </h4>
        //                                 </Col>
        //                             </Row>
        //                             <Row>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="graduation"
        //                                         rules={[{ message: 'Please select your Specialization!', required: true }]}>
        //                                         <Select
        //                                             mode="multiple"
        //                                             defaultValue={skillDefSelectValue}
        //                                             showSearch
        //                                             style={{ width: '100%' }}
        //                                             placeholder="Select skill"
        //                                             optionFilterProp="children"
        //                                             value={skill} onChange={e => setSkill(e)}
        //                                             filterOption={(input, option) =>
        //                                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        //                                             }
        //                                             filterSort={(optionA, optionB) =>
        //                                                 optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        //                                             }
        //                                         >
        //                                             {filterSkillList.map(item => {
        //                                                 return (<Option value={item.id}>{item.name}</Option>)
        //                                             })}
        //                                         </Select>
        //                                     </Form.Item>
        //                                 </Col>
        //                                 <Col span={2}></Col>
        //                                 <Col span={11}>
        //                                     <Form.Item
        //                                         name="description"
        //                                         initialValue={description}
        //                                         rules={[{ message: 'Please enter your description!', required: true }]}>
        //                                         <Input.TextArea placeholder="Description" value={description} onChange={e => setDescription(e.target.value)} type='text' />

        //                                     </Form.Item>
        //                                 </Col>
        //                             </Row>




        //                             <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>

        //                             </Form.Item>
        //                         </Form>

        //                     </div>




        //                 </Modal>

        //                 <div className='interviewer-container'>
        //                     <div className="App">
        //                     </div>
        //                     <div>
        //                         <Table dataSource={dataSource} columns={columns} pagination={false} />
        //                         <Pagination hideOnSinglePage={true} current={pageDefaultValue} onChange={handlePagination} total={totalpages} />

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     }
        // </div>
    );

}

export default Interviewee;
