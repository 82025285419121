import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './profile-page.scss';
import 'antd/dist/antd.css';
import './profile.scss';

import { Row, Col, Input, Space, Card, Select, Rate, Avatar, Image, Checkbox, Form, Spin, Upload } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useRouteMatch } from "react-router-dom";
import { ArrowRightOutlined, PhoneFilled, CheckCircleTwoTone, RightOutlined, EditOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { SettingWrapper, ChangePasswordWrapper } from './style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import userImg from 'src/assets/imges/user.png';
import apiService from "../../../utils/apiService";
import apiJavaService from "../../../utils/javaApiService";
import Notification from "../../../components/notification/notification";
import Heading from '../../../components/heading/heading';
import { BasicFormWrapper, TagInput } from '../../styled';
import { ProfileAuthorBox } from './style';
import siteUrl from "../../../api/apiUrls";

const Profile = () => {
  const { path } = useRouteMatch();

  const jwt = localStorage.getItem('jwt');
  const userId = localStorage.getItem('userId');
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const { Search } = Input;
  const [form] = Form.useForm();
  const { Meta } = Card;
  const { Option } = Select;
  const [profileDetails, setProfileDetails] = useState("");
  const [mobile, setMobile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [currentpassword, setCPassword] = useState("");
  const [newpassword, setNPassword] = useState("");
  const [editcardShow, seteditcardShow] = useState(1);
  const [oldPassword, setoldPassword] = useState("");
  const [NewPassword, SetNewPassword] = useState("");
  const [RepeatPW, setRepea] = useState("");
  const [interviewedCount, setInterviewedCount] = useState("");
  const [level, setLevel] = useState("");
  const [amount, setAmount] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [experience, setExperience] = useState("");
  const [description, setDescription] = useState("");
  const [overAllRating, setOverAllRating] = useState("");
  const [skill, setSkill] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [skillDefSelectValue, setskillDefSelectValue] = useState([]);
  const [filterSkillList, setFilterSkillList] = useState([]);
  const [valueLongDesc, setvalueLongDesc] = useState("");
  const [valueShortDesc, setvalueShortDesc] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState(false);
  const [interviewerInfo, setinterviewerInfo] = useState("");
  const [image, setImage] = useState("/static/media/blank-profile-picture.298c21cb.jpg")
  const [isLoadingApi, setisLoadingApi] = useState(false);
  const [passwordSet, setPasswordSet] = useState("N");


  useEffect(() => {
    getProfile();
    getFilterData();
  }, []);


  const getProfile = () => {
    apiService(`users/details/${userId}`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          const dataSource = [];
          console.log(result.data)
          if (result.data.profileImageId != null) {
            setImage(siteUrl + "" + "unsecure/view/" + result.data.profileImageId)
          }
          setProfileDetails(result.data);
          setFirstName(result.data.firstName);
          setLastName(result.data.lastName);
          setEmail(result.data.email);
          setMobile(result.data.contactNo);
          setinterviewerInfo(result.data.interviewerInfo);
          setCompanyName(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.companyName);
          setExperience(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.experience);
          setLevel(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.level);
          setAmount(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.amount);
          setInterviewedCount(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.interviewedCount);
          setDescription(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.description);
          setOverAllRating(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.overAllRating);
          setLinkedin(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.linkedInUrl);
          setvalueLongDesc(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.longDescription);
          setvalueShortDesc(result.data.interviewerInfo === null ? "" : result.data.interviewerInfo.shortDescription);
          result.data.userSkills.map(data => {
            dataSource.push(
              data.skills.id,
            );
          });
          console.log(dataSource)
          setskillDefSelectValue(dataSource)
          setSkill(dataSource)
          setPasswordSet(result.data.passwordSet);
          setIsLoading(true)
        }
      },
      (error) => {

      });
  };

  const getFilterData = () => {
    apiService(`signup/skilllist`, 'get', '', false, jwt,
      result => {
        if (result.data) {
          setFilterSkillList([...result.data]);
          // setIsLoading(true);
        }
      },
      (error) => {

      });
  };

  const handleCreatePassword = () => {
    setIsLoading(false);
    apiService(`login/email/setpassword`, 'post', { password: RepeatPW, token: jwt, userRoleId: '3', 'email': email }, false, '',
      result => {
        if (result.data.success == true) {
          Notification.openNotificationSuccess(result.data.message)
          form1.resetFields();
          setIsLoading(true);
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });

  };

  const handleSubmitProfile = () => {
    setIsLoading(false);
    // let formData = new FormData();
    // formData.append('firstName', firstName)
    // formData.append('lastName', lastName)
    // formData.append('email', email)
    // formData.append('contactNo', mobile)
    apiService(`users/addinterviewer`, 'post', {
      id: parseInt(userId),
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "contactNo": mobile
    }, false, jwt,
      result => {
        if (result.data.success == true) {
          getProfile();
          Notification.openNotificationSuccess(result.data.message);
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });

  };


  const handleSubmitPassword = () => {
    // if(validate(oldPassword,RepeatPW)){
    setIsLoading(false);
    apiService(`users/updatepassword`, 'put', { password: oldPassword, newPassword: NewPassword }, false, jwt,
      result => {
        if (result.data.success == true) {
          Notification.openNotificationSuccess(result.data.message)
          form1.resetFields();
          setIsLoading(true);

        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });
    // }

  };
  const handleSubmitSkill = () => {
    setIsLoading(false);
    const dataSourceSkill = [];
    skill.map(data => {
      dataSourceSkill.push({
        id: data,
      });
    });
    apiService(`users/addinterviewer`, 'post', {
      id: parseInt(userId),
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "contactNo": mobile,
      "interviewerIfoDto": {
        "level": level,
        "experience": experience,
        "companyName": companyName,
        "amount": amount,
        "linkedInUrl": linkedin,
        "userSkills": dataSourceSkill,
        "longDescription": valueLongDesc,
        "shortDescription": valueShortDesc,
        "linkedInUrl": linkedin,
        "overAllRating": overAllRating
      }
    }, false, jwt,
      result => {
        if (result.data.success == true) {
          getProfile();
          Notification.openNotificationSuccess(result.data.message);
        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.message)
        }
      },
      (error) => {

      });

  };


  const checkPassword = (rule, value, callback) => {
    if (value && value !== NewPassword) {
      callback("The passwords don't match");
    } else if (oldPassword != "") {
      if (oldPassword == RepeatPW) {
        callback("You are entered old password");
      }
      else {
        callback();
      }
    }
    else {
      callback();
    }
  };

  const validate = (oldPassword, RepeatPW) => {
    var valid = true;
    if (oldPassword == RepeatPW) {
      Notification.openNotificationFaliure("You are entered old password");
      valid = false;
    }
    return valid;

  }

  const handleCancel = e => {
    e.preventDefault();
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    setFirstName(profileDetails.firstName);
    setLastName(profileDetails.lastName);
    setEmail(profileDetails.email);
    setMobile(profileDetails.contactNo);
    setCompanyName(profileDetails.interviewerInfo?.companyName);
    setExperience(profileDetails.interviewerInfo?.experience);
    setLevel(profileDetails.interviewerInfo?.level);
    setAmount(profileDetails.interviewerInfo?.amount);
    setInterviewedCount(profileDetails.interviewerInfo?.interviewedCount);
    setDescription(profileDetails.interviewerInfo?.description);
    setOverAllRating(profileDetails.interviewerInfo?.overAllRating);
    setLinkedin(profileDetails.interviewerInfo?.linkedInUrl);
    setvalueLongDesc(profileDetails.interviewerInfo?.longDescription);
    setvalueShortDesc(profileDetails.interviewerInfo?.shortDescription);
  };

  const formChange1 = () => {
    seteditcardShow(1)

  };

  const formChange2 = () => {
    seteditcardShow(2)

  };

  const formChange3 = () => {
    seteditcardShow(3)

  };
  const handleChange = (value) => {
    console.log(value)
    setOverAllRating(value);
  };

  const handleupload = (e) => {
    var file = e;
    setIsLoading(false);
    let formData = new FormData();
    formData.append('file', file);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    apiJavaService(`secure/userController/updateProfile`, 'put', formData, true, jwt,
      result => {
        if (result.data.SUCCESS == true) {
          Notification.openNotificationSuccess(result.data.MESSAGE)
          getProfile();

        } else {
          setIsLoading(true);
          Notification.openNotificationFaliure(result.data.MESSAGE)
        }
      },
      (error) => {

      });
  }



  return (
    <>
      <PageHeader
        ghost
        title="Profile"
      />
      {isLoading == false ?
        <div className="spinner">
          <Spin />
        </div> :
        <Main className='profile-page'>
          <Row gutter={25}>
            <Col xxl={6} lg={8} md={10} xs={24}>
              <ProfileAuthorBox>
                <Cards headless>
                  <div className="author-info">
                    <figure>
                      <img className='profile-img' src={image} alt="" />

                      <Upload accept="image/*" listType="picture" beforeUpload={handleupload}
                        maxCount={1}>
                        <Link to="#">
                          <FeatherIcon icon="camera" size={16} />
                        </Link>
                      </Upload>
                    </figure>
                    <figcaption>
                      <div className="info">
                        <Heading as="h4">{profileDetails.firstName}</Heading>
                        <p>{profileDetails.email}</p>
                      </div>
                    </figcaption>
                  </div>
                  <nav className="settings-menmulist">
                    <ul>
                      <div className='menu-list'>
                        <li onClick={formChange1} >
                          <a className='profilelink'>
                            <FeatherIcon icon="user" size={14} />
                            Edit Profile
                          </a>
                        </li>

                        <li onClick={formChange2} >
                          <a >
                            <FeatherIcon icon="settings" size={14} />
                            Personal Info
                          </a>
                        </li>
                        <li onClick={formChange3} >
                          <a >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-key"
                            >
                              <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                            </svg>
                            {(passwordSet === "N" || passwordSet === null) ?
                              'Create Password'
                              : 'Change Password'}
                          </a>
                        </li>
                      </div>
                      {/* <li>
              <NavLink to={`${path}/social`}>
                <FeatherIcon icon="users" size={14} />
                Social Profile
              </NavLink>
            </li>
            <li>
              <NavLink to={`${path}/notification`}>
                <FeatherIcon icon="bell" size={14} />
                Notification
              </NavLink>
            </li> */}
                    </ul>
                  </nav>
                </Cards>
              </ProfileAuthorBox>
            </Col>
            {editcardShow == 1 ?
              <Col xxl={18} lg={16} md={14} xs={24}>
                <SettingWrapper>
                  <Cards
                    title={
                      <div className="setting-card-title">
                        <Heading as="h4">Edit Profile</Heading>
                        <span>Set Up Your Personal Information</span>
                      </div>
                    }
                  >
                    <Row justify="center">
                      <Col xl={20} lg={20} xs={24}>


                        <BasicFormWrapper>
                          <Form name="editprofile" className='edit-profile' form={form1} onFinish={handleSubmitProfile}>
                            <Row className='form-alig'>
                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="firstName"
                                  label="First Name"
                                  initialValue={profileDetails.firstName}
                                  rules={[{ message: 'Please enter your first name!', required: true },{ pattern: /^[^\d]+$/, message: 'First name cannot contain numbers!' }]}>
                                  <Input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
                                </Form.Item>

                              </Col>

                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="lastName"
                                  label="Last Name"
                                  initialValue={profileDetails.lastName}
                                  rules={[{ message: 'Please enter your last name!', required: true },{ pattern: /^[^\d]+$/, message: 'Last name cannot contain numbers!' }]}>
                                  <Input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row className='form-alig'>
                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="email"
                                  label="Email"
                                  initialValue={profileDetails.email}
                                >
                                  <Input disabled placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
                                </Form.Item>
                              </Col>

                              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                <Form.Item
                                  name="mobile"
                                  label="Mobile"
                                  initialValue={profileDetails.contactNo}
                                  rules={[{
                                    type: "regexp",
                                    pattern: /^(?:\d*)$/,
                                    message: "Value should contain just number"
                                  },
                                  {
                                    min: 10,
                                    max: 10,
                                    message: "phone number must be 10 digits",
                                  },
                                  {
                                    message: 'Please enter your phone number!',
                                    required: true
                                  }]}
                                >
                                  <Input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="setting-form-actions">
                              <Button size="default" htmlType="submit" type="primary">
                                Update Profile
                              </Button>
                              &nbsp; &nbsp;
                              <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </BasicFormWrapper>
                      </Col>

                    </Row>
                  </Cards>
                </SettingWrapper>
              </Col> : editcardShow == 3 ?
                (passwordSet === 'N' || passwordSet === null) ?
                  <Col xxl={18} lg={16} md={14} xs={24}>
                    <SettingWrapper>
                      <Cards
                        title={
                          <div className="setting-card-title">
                            <Heading as="h4">Password Settings</Heading>
                            <span>Create your account password</span>
                          </div>
                        }
                      >
                        <Row justify="center">
                          <Col xl={20} lg={20} xs={24}>
                            <BasicFormWrapper>
                              <Form name="editProfile" form={form1} onFinish={handleCreatePassword}>
                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Password"
                                      name="Password"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter password!', required: true }]}>
                                      <Input.Password placeholder='Password' value={NewPassword} onChange={e => SetNewPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>

                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Confirm Password"
                                      name="RepeatPw"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter confirm password!', required: true }, {
                                        validator: (rule, value, callback) => {
                                          if (value && value !== NewPassword) {
                                            callback("The passwords don't match");
                                          } else {
                                            callback();
                                          }
                                        }
                                      }]}>
                                      <Input.Password placeholder='Confirm Password' value={RepeatPW} onChange={e => setRepea(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <div className="setting-form-actions">
                                  <Button size="default" htmlType="submit" type="primary">
                                    Save
                                  </Button>
                                  &nbsp; &nbsp;
                                  <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                    Cancel
                                  </Button>
                                </div>
                              </Form>
                            </BasicFormWrapper>
                          </Col>

                        </Row>
                      </Cards>
                    </SettingWrapper>
                  </Col> :
                  <Col xxl={18} lg={16} md={14} xs={24}>
                    <SettingWrapper>
                      <Cards
                        title={
                          <div className="setting-card-title">
                            <Heading as="h4">Password Settings</Heading>
                            <span>Change or reset your account password</span>
                          </div>
                        }
                      >
                        <Row justify="center">
                          <Col xl={20} lg={20} xs={24}>


                            <BasicFormWrapper>

                              <Form name="editProfile" form={form1} onFinish={handleSubmitPassword}>
                                <Row className='form-alig'>
                                  <Col xxl={24} lg={24} md={24} xs={24} className='paddingcln'>
                                    <Form.Item
                                      name="oldPassword"
                                      label="Old Password"
                                      rules={[{ message: 'Please enter old password!', required: true }]}>
                                      <Input.Password placeholder='Old password'
                                        value={oldPassword} onChange={e => setoldPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row className='form-alig'>
                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="New Password"
                                      name="NewPassword"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Please enter new password!', required: true }]}>
                                      <Input.Password placeholder='New password' value={NewPassword} onChange={e => SetNewPassword(e.target.value)} type='password' />
                                    </Form.Item>
                                  </Col>

                                  <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                    <Form.Item
                                      label="Re-Type New Password"
                                      name="RepeatPw"
                                      // initialValue={profileDetails.firstName}
                                      rules={[{ message: 'Re-type passWord!', required: true }, { validator: checkPassword }]}>
                                      <Input.Password placeholder='Re-type password' value={RepeatPW} onChange={e => setRepea(e.target.value)} type='password' />
                                    </Form.Item>
                                    {/* {oldPasswordErr == false ?
                  <p className='text-error' >You are entered old password!</p> : null} */}
                                  </Col>
                                </Row>
                                <div className="setting-form-actions">
                                  <Button size="default" htmlType="submit" type="primary">
                                    Save
                                  </Button>
                                  &nbsp; &nbsp;
                                  <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                    Cancel
                                  </Button>
                                </div>
                              </Form>



                            </BasicFormWrapper>
                          </Col>

                        </Row>
                      </Cards>
                    </SettingWrapper>
                  </Col> :
                <Col xxl={18} lg={16} md={14} xs={24}>
                  <SettingWrapper>
                    <Cards
                      title={
                        <div className="setting-card-title">
                          <Heading as="h4">Personal Info</Heading>
                          <span>Change Personal Info</span>
                        </div>
                      }
                    >
                      <Row justify="center">
                        <Col xl={20} lg={20} xs={24}>
                          <BasicFormWrapper>
                            <Form name="editprofile" form={form3} onFinish={handleSubmitSkill}>
                              <Row className='form-alig'>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    initialValue={companyName}
                                    /* rules={[{ message: 'Please enter your company!', required: true }]} */>
                                    <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
                                  </Form.Item>
                                </Col>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="experience"
                                    initialValue={experience}
                                    label="Experience"
                                    rules={[{ message: 'Please enter your experience!', required: true }]}>
                                    <Input placeholder="Experience" value={experience} min="1" max="50"  onChange={e => {
                                      // setExperience(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setExperience(newValue);
                                      }
                                    }} type='number' />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className='form-alig'>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="level"
                                    initialValue={level}
                                    label="Level"
                                    rules={[{ message: 'Please enter your level!', required: true }]}>
                                    <Input placeholder="Level" min="1" max="5" value={level} onChange={e => {
                                      // setLevel(e.target.value);
                                      const newValue = parseInt(e === undefined || e === null || e === "" ? 0 : e.target.value, 10);
                                      if (!isNaN(newValue) && newValue >= 1 && newValue <= 50) {
                                        setLevel(newValue.toString());
                                      }
                                    }} type='number' />
                                  </Form.Item>
                                </Col>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="amount"
                                    label="Amount"
                                    initialValue={amount}
                                    rules={[{ message: 'Please enter your amount!', required: true }]}>
                                    <Input placeholder="Amount" min="1" value={amount} onChange={e => setAmount(e.target.value)} type='number' />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row className='form-alig'>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                                  <Form.Item
                                    name="linkedin"
                                    label="LinkedIn"
                                    initialValue={linkedin}
                                    rules={[{ type: "url", message: 'Please enter your Linkedin profile!', required: true }]}>
                                    <Input placeholder="Linkedin" value={linkedin} onChange={e => setLinkedin(e.target.value)} type='text' />
                                  </Form.Item>
                                </Col>
                                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>

                                  <Form.Item initialValue={skillDefSelectValue}
                                    name="graduation"
                                    label="Skills"
                                    rules={[{ message: 'Please select your skill!', required: true }]}>
                                    <Select
                                      mode="multiple"
                                      defaultValue={skillDefSelectValue}
                                      maxTagCount={1}
                                      showSearch={false}
                                      maxTagTextLength={17}
                                      style={{ width: '100%' }}
                                      placeholder="Select skill"
                                      value={skill} onChange={e => setSkill(e)}
                                    >
                                      {filterSkillList.map(item => {
                                        return (<Option value={item.id}>{item.type}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <div className="setting-form-actions">
                                <Button size="default" htmlType="submit" type="primary">
                                  Update Profile
                                </Button>
                                &nbsp; &nbsp;
                                <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </BasicFormWrapper>
                          {/*              
         <BasicFormWrapper>
            <Form name="editprofile" form={form3} onFinish={handleSubmitSkill}>
              <Row className='form-alig'>
              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  initialValue={companyName}
                  rules={[{ message: 'Please enter your company!', required: true }]}>
                  <Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} type='text' />
            </Form.Item>
                  
                </Col>
        
                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                <Form.Item
                  name="experience"
                  initialValue={experience}
                  label="Experience"
                  rules={[{ message: 'Please enter your experience!', required: true }]}>
                  <Input placeholder="Experience" value={experience} onChange={e => setExperience(e.target.value)} type='text' />
              </Form.Item>
                </Col>
              </Row>
              <Row className='form-alig'>
              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                <Form.Item
                  name="level"
                  initialValue={level}
                  label="Level"
                  rules={[{ message: 'Please enter your level!', required: true }]}>
                  <Input placeholder="Level" value={level} onChange={e => setLevel(e.target.value)} type='text' />
              </Form.Item>
                </Col>
                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                <Form.Item
                      name="amount"
                      label="Amount"
                      initialValue={amount}
                      rules={[{ message: 'Please enter your amount!', required: true }]}>
                      <Input placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)} type='numner' />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='form-alig'>
              <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
                <Form.Item
                  name="linkedin"
                  label="LinkedIn"
                  initialValue={linkedin}
                  rules={[{ type: "email", message: 'Please enter your Linkedin profile!', required: true }]}>
                  <Input placeholder="Linkedin" value={linkedin} onChange={e => setLinkedin(e.target.value)} type='text' />
              </Form.Item>
                </Col>
                <Col xxl={12} lg={12} md={12} xs={24} className='paddingcln'>
           
              <Form.Item initialValue={skillDefSelectValue}
                    name="graduation"
                    label="Skills"
                    rules={[{ message: 'Please select your skill!', required: true }]}>
                    <Select
                        mode="multiple"
                        defaultValue={skillDefSelectValue}
                        maxTagCount={1}
                        style={{ width: '100%' }}
                        placeholder="Select skill"
                        value={skill} onChange={e => setSkill(e)}
                    >
                        {filterSkillList.map(item => {
                            return (<Option value={item.id}>{item.name}</Option>)
                        })}
                    </Select>
                </Form.Item>
                </Col>
              </Row>
    
              <div className="setting-form-actions">
                <Button size="default" htmlType="submit" type="primary">
                  Update Profile
                </Button>
                &nbsp; &nbsp;
                <Button size="default" className='clear-btn' onClick={handleCancel} type="light">
                  Cancel
                </Button>
              </div>
            </Form>
            </BasicFormWrapper>  */}
                        </Col>

                      </Row>
                    </Cards>
                  </SettingWrapper>
                </Col>
            }
          </Row>
        </Main>
      }
    </>
    // <div className="profile_page_container p_10">
    //     {isLoading == false ?
    //         <div className="spinner">
    //             <Spin />
    //         </div> : <div className='profile-container'>
    //             <Form name="profile" form={form1} onFinish={handleSubmit} layout="vertical">
    //                 <Row>
    //                     <Col xs={24} sm={24} md={19} lg={19} xl={19}>
    //                         <h2>My Profile</h2>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={5} lg={5} xl={5} push={1}>
    //                         <div className='btn-div'>
    //                             <Row>
    //                                 <Col xs={12} sm={12} md={10} lg={10} xl={10}>
    //                                     <button id='cancel' >CANCEL</button>
    //                                 </Col>
    //                                 <Col xs={12} sm={12} md={14} lg={14} xl={14} pull={2}>
    //                                     <button id='save' >SAVE CHANGES</button>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>

    //                 <Row>
    //                     <Col xs={24} sm={24} md={20} lg={20} xl={20}>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={6} lg={6} xl={6}>
    //                                     <label for=''>First Name</label>
    //                                     <Form.Item
    //                                         name="firstName"
    //                                         initialValue={profileDetails.firstName}
    //                                         rules={[{ message: 'Please enter your first name!', required: true }]}>
    //                                         <input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} type='text' />
    //                                     </Form.Item>

    //                                 </Col>
    //                                 <Col xs={24} sm={24} md={6} lg={6} xl={6} push={2}>
    //                                     <label for=''>Last Name</label>
    //                                     <Form.Item
    //                                         name="lastName"
    //                                         initialValue={profileDetails.lastName}
    //                                         rules={[{ message: 'Please enter your last name!', required: true }]}>
    //                                         <input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} type='text' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Email ID</label>
    //                                     <Form.Item
    //                                         name="email"
    //                                         initialValue={profileDetails.email}
    //                                         rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
    //                                         <input disabled placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Phone Number</label>
    //                                     <Form.Item
    //                                         name="mobile"
    //                                         initialValue={profileDetails.contactNo}
    //                                         rules={[{
    //                                             type: "regexp",
    //                                             pattern: /^(?:\d*)$/,
    //                                             message: "Value should contain just number"
    //                                         },
    //                                         {
    //                                             min: 10,
    //                                             max: 10,
    //                                             message: "phone number must be 10 digits",
    //                                         },
    //                                         {
    //                                             message: 'Please enter your phone number!',
    //                                             required: true
    //                                         }]}>
    //                                         <input disabled id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={4} lg={4} xl={4} pull={5}>
    //                         <div className='card-header'>
    //                             <Avatar src={userImg} />
    //                             <i><EditOutlined /></i>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             </Form>


    //             <Form name="profile" form={form2} onFinish={handleSubmitPassword} layout="vertical">
    //                 <div className='btn-div'>
    //                     <Row>
    //                         <Col xs={24} sm={24} md={8} lg={8} xl={8}>
    //                             <h3>Do you want change the Password?</h3>
    //                         </Col>
    //                         <Col xs={24} sm={24} md={4} lg={4} xl={4}>
    //                             <button id='change' >Change Password</button>
    //                         </Col>
    //                     </Row>
    //                 </div>

    //                 <Row>
    //                     <Col xs={24} sm={24} md={20} lg={20} xl={20}>
    //                         <div class='set'>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                                     <label for=''>Current Password</label>
    //                                     <Form.Item
    //                                         name="currentpassword"
    //                                         rules={[{ message: 'Please enter your current password!', required: true }]}>
    //                                         <input id='currentpassword' placeholder="Current Password" value={currentpassword} onChange={e => setCPassword(e.target.value)} type='password' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col xs={24} sm={24} md={14} lg={14} xl={14}>
    //                         <Row>
    //                             <Col xs={24} sm={23} md={20} lg={20} xl={20}>
    //                                 <label for=''>New Password</label>
    //                                 <Form.Item
    //                                     name="password"
    //                                     rules={[{ message: 'Please enter your password!', required: true }]}>
    //                                     <input id='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} type='password' />
    //                                 </Form.Item>
    //                             </Col>
    //                         </Row>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={10} lg={10} xl={10} pull={2}>
    //                         <div class=''>
    //                             <Row>
    //                                 <Col xs={24} sm={24} md={24} lg={24} xl={24}>
    //                                     <label for=''>Confirm Password</label>
    //                                     <Form.Item
    //                                         name="newpassword"
    //                                         rules={[{ message: 'Please enter your confirm password!', required: true }, { validator: checkPassword }]}>
    //                                         <input id='newpassword' placeholder="Confirm Password" value={newpassword} onChange={e => setNPassword(e.target.value)} type='password' />
    //                                     </Form.Item>
    //                                 </Col>
    //                             </Row>
    //                         </div>
    //                     </Col>
    //                 </Row>

    //                 <Row className='profile-footer'>
    //                     <Col xs={1} sm={1} md={1} lg={1} xl={1}>
    //                         <Checkbox ></Checkbox>
    //                     </Col>
    //                     <Col xs={22} sm={22} md={22} lg={23} xl={22}>
    //                         <h4>Option in to receive communications related new skills or interview in Skillingo</h4>
    //                     </Col>
    //                 </Row>
    //             </Form>


    //         </div>
    //     }
    // </div>
  );
};

export default Profile;
